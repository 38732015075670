const TAXONOMIES = {
    WORKFORCE: 'workforce',
    SELF_SERVE: 'self-serve',
    SELF: 'self-serve',
    CURR: 'returning-dashboard-user',
    SIGN_IN: 'Sign In',
    CLICKED_SURVEY_SETUP_NEXT: 'SurveySetup: Clicked Next',
    CLICKED_SURVEY_CREATE_NEXT: 'SurveyCreate: Clicked Next',
    CLICKED_SURVEY_SETUP_BACK: 'SurveySetup: Clicked Back',
    CLICKED_SURVEY_CREATE_BACK: 'SurveyCreate: Clicked Back',
    CLICKED_CANCEL_BUTTON: 'Survey: Clicked Cancel',
    CLICKED_SAVE_DRAFT: 'SurveyCreate: Clicked Save Draft Button',
    CLICKED_CLOSE_SURVEY: 'SurveyLive: Clicked Close Survey Button',
    DASHBOARD_CREATED: 'DiversioBasic: Dashboard Created',
    DASHBOARD_SESSION_ENDED: 'Dashboard Session Ended',
    VIEW_PAGE: 'View Page',
    PAGE_SESSION_ENDED: 'Page Session Ended',
    DOWNLOAD_BACKUP_CODE: 'Download Backup Code',
    REFRESH_BACKUP_CODE: 'Refresh Backup Code',
    USE_PICHART: 'Use PiChart',
    ADD_YOUR_OWN_SOLUTION_CLICKED: 'Add Your Own Solution Clicked',
    LOAD_MORE_PROGRAMS: 'Load More Programs',
    PROGRAM_DETAILS_VIEWED: 'Program Details Viewed',
    TOOL_CLICKED: 'Tool Clicked',
    CHANGE_PAGE: 'Change Page',
    CHANGE_ROWS_PER_PAGE: 'Change Rows Per Page',
    SEARCH_TERM: 'Search term',
    CHOOSES_KPI_FILTER: 'Chooses KPI Filter',
    CHOOSES_TARGET_GROUP_FILTER: 'Chooses Target Group Filter',
    REMOVE_KPI_FILTER: 'Remove KPI Filter',
    REMOVE_TARGET_GROUP_FILTER: 'Remove Target Group Filter',
    SEARCH_CLICKED: 'Search Clicked',
    RETURN_BUTTON_CLICKED: 'Return Button Clicked',
    CLICKED_CREATE_CUSTOM_GOAL: `Clicked 'Create a Custom Goal'`,
    CLICKED_CREATE_GOAL_FROM_INSIGHT: `Clicked 'Create Goal' from Insight`,
    CLICKED_EXPLORE_PROGRAMS_TO_CREATE_GOAL: `Clicked 'Explore Programs' to create goal`,
    CHANGE_INSIGHT: 'Change Insight',
    SEE_ALL_SOLUTIONS: 'See All Solutions',
    UPDATES_GOAL: 'Updates Goal',
    DELETE_GOAL: 'DeleteGoal',
    SEE_PROGRAM_DETAILS: 'See Program Details',
    FINISHED_CREATING_GOAL_FROM_BANNER: 'Finished Creating Goal from banner.',
    CREATE_GOAL_FROM_BANNER: 'Create Goal from Banner',
    TOGGLE_INSIGHTS: 'Toggle Insights',
    LOAD_MORE_TOOLS: 'Load More Tools',
    SIGN_UP: 'Sign Up',
    RESET_PASSWORD: 'Reset Password',
    SAVE_PROGRAM: 'Save Program',
    REMOVE_PROGRAM_FROM_SAVED: 'Remove Program from Saved',
    ACTIVATE_PROGRAM: 'Activate Program',
    DEACTIVATE_PROGRAM: 'Deactivate Program',
    ARCHIVE_PROGRAM: 'Archive Program',
    REMOVE_FROM_ARCHIVED: 'Remove From Archived',
    SAVE_TOOL: 'Save Tool',
    REMOVE_FROM_SAVED: 'Remove From Saved',
    CLICKS_ON_TOOLS: 'Clicks on Tools',
    TWO_FACTOR_AUTHENTICATION_CODE_REQUESTED: 'Two Factor Authentication Code Requested',
    TWO_FACTOR_AUTHENTICATION_CODE_SUBMITTED: 'Two Factor Authentication Code Submitted',
    MYSOLUTIONS_UPDATE_GOAL: 'MySolutions: Updates Goal',
    MYSOLUTIONS_DELETE_GOAL: 'MySolutions: DeleteGoal',
    MYSOLUTIONS_SEE_PROGRAM_DETAILS: 'MySolutions: See Program Details',
    PORTCO: 'Diversio for Portfolios',
    COMPANY: 'Diversio for Companies',
    SWITCH_DASHBOARD_V1: 'Switch to Dashboard V1',
    SWITCH_DASHBOARD_V2: 'Switch to Dashboard V2',
    SWITCH_SURVEY: 'Switched Survey',
    SWITCH_NATIONAL_BENCHMARK: 'Switched National benchmark',
    SWITCH_INDUSTRY_BENCHMARK: 'Switched Industry benchmark',
    HOME_INTERACT_INCLUSION_SCORE: 'HS: Interacted Inclusion Global Score',
    HOME_INTERACT_NPS_SCORE: 'HS: Interacted NPS Score',
    HOME_SWITCH_NPS_SURVEY: 'HS: NPS: Switched Survey',
    HOME_SWITCH_NPS_VIEW: 'HS: NPS: Switched View ',
    HOME_INTERACT_DIVERSITY_SUMMARY: 'HS: Diversity Summary',
    HOME_INTERACT_INCLUSION_SUMMARY: 'HS: Inclusion Summary',
    ANALYZE_DIVERSITY_PROFILE_CREATED: 'DIV: Intersectionality: Created Profile',
    ANALYZE_DIVERSITY_PROFILE_UPDATED: 'DIV: Intersectionality: Updated Profile',
    ANALYZE_DIVERSITY_PROFILE_DELETED: 'DIV: Intersectionality: Deleted Profile',
    ANALYZE_DIVERSITY_DATA_EXPORT: 'DIV: Downloaded Data CSV',
    ANALYZE_DIVERSITY_DATA_OVER_TIME_EXPORT: 'DIV: Downloaded Data Over Time CSV',
    ANALYZE_DIVERSITY_PROFILES_DATA_EXPORT: 'DIV: Downloaded Profiles Data CSV',
    ANALYZE_DIVERSITY_DEMOGRAPHIC_CHANGE: 'DIV: Demographic Selector',
    ANALYZE_DIVERSITY_VIEW_CHANGE: 'DIV: Changed View',
    ANALYZE_DIVERSITY_RESULTS_OVER_TIME_TOGGLE: 'DIV: Results Over Time',
    ANALYZE_INCLUSION_PROFILE_CREATED: 'INC: Intersectionality: Created Profile',
    ANALYZE_INCLUSION_PROFILE_UPDATED: 'INC: Intersectionality: Updated Profile',
    ANALYZE_INCLUSION_PROFILE_DELETED: 'INC: Intersectionality: Deleted Profile',
    ANALYZE_INCLUSION_DATA_EXPORT: 'INC: Downloaded Data CSV',
    ANALYZE_INCLUSION_DATA_OVER_TIME_EXPORT: 'INC: Downloaded Data Over Time CSV',
    ANALYZE_INCLUSION_FREE_TEXT_EXPORT: 'INC: Downloaded CSV Free Text',
    ANALYZE_INCLUSION_DEMOGRAPHIC_CHANGE: 'INC: Demographic Selector',
    ANALYZE_INCLUSION_CELL_SELECTED: 'INC: Inclusion Cell Selected',
    ANALYZE_INCLUSION_RESULTS_OVER_TIME_TOGGLE: 'INC: Results Over Time',
    ANALYZE_INCLUSION_FREE_TEXT_METRIC_SELECTED: 'INC: Free Text: Inclusion Metric Selected',
    ANALYZE_INCLUSION_FREE_TEXT_PAIN_POINT_SELECTED: 'INC: Free Text: PainPoint Selected',
    LUMI_UPLOADED_FILES: 'LUMI: Uploaded Files',
    LUMI_WRITTEN_JOB_AD: 'LUMI: Added Written Job Ad',
    LUMI_TRANSFORM_JOB_AD: 'LUMI: Transform Job Ad',
    LUMI_DOWNLOAD_JOB_AD: 'LUMI: Downloaded Jobs Transformed',
    LUMI_NEW_PROJECT: 'LUMI: New Project',
    LUMI_REMOVE_FILE: 'LUMI: Remove File From Queue',
}

export default TAXONOMIES
