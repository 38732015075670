import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Actions } from '../../actions';
import { Row, Column } from '../../components/utils/FlexBox';
import Initiative from '../../components/ILNDashboard/Impact/Initiative';

class ILNImpact extends Component {
    componentWillMount () {
      this.props.getIlnInitiatives()
      this.props.getIlnMilestones()
    }

    render() {
        return(
            <div className="App">
                <Row>
                    <Column width="100%">
                        <Initiative 
                            data={this.props.iln.ilnInitiatives}
                            milestones={this.props.iln.ilnMilestones}
                        />
                    </Column>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state,
        iln: state.ilndashboard
    };
};

export default connect(mapStateToProps, Actions)(ILNImpact);
