import React from 'react'
import { useHistory } from 'react-router-dom'

import * as S from './archived.styles'

export const EmptyArchivedSolutions = () => {
    const history = useHistory()

    return (
        <S.Container>
            <S.Message>You do not have any archived solutions</S.Message>
            <S.BackBtn onClick={history.goBack}>Go Back</S.BackBtn>
        </S.Container>
    )
}
