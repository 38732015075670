import React from 'react'
import { UserPrivacyIcon, colors } from '@diversioteam/diversio-ds'

import { TooManyFiltersProps } from './tooManyFilters.types'
import * as S from './tooManyFilters.styles'

export const TooManyFilters = ({ onAdjustFilters, onClearFilters }: TooManyFiltersProps) => (
    <S.TooManyFilters>
        <S.Container>
            <S.Icon>
                <UserPrivacyIcon type="bold" height={34} width={34} color={colors.primary50} />
            </S.Icon>

            <S.Title>Uh oh.. you’ve set too many filters</S.Title>

            <S.Description>
                While we understand the importance of filtering to find precise information, there’s a fine line where
                filtering may delve into sensitive data territories. We prioritize privacy and encourage a balanced
                approach to filtering.
            </S.Description>

            <S.Buttons>
                <S.Button as="a" href="https://diversio.com/privacy-policy" target="_blank" rel="noreferrer">
                    Privacy Policy
                </S.Button>

                <S.Button type="button" onClick={onAdjustFilters}>
                    Adjust Filters
                </S.Button>

                <S.Button type="button" onClick={onClearFilters}>
                    Clear All Filters
                </S.Button>
            </S.Buttons>
        </S.Container>
    </S.TooManyFilters>
)
