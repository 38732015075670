import styled from 'styled-components'
import { getFontStyle } from '@diversioteam/diversio-ds'

import { media } from 'styles/theme/mediaQueries'

export const BannerWrapper = styled.section`
    padding: 0 24px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: center;
    column-gap: 24px;
    grid-area: banner;
    min-height: 204px;
    border-radius: 24px;
    border: 2px solid ${({ theme }) => theme.colors.primaryWhite};
    position: relative;
    overflow: hidden;

    ${media.sm} {
        flex-wrap: nowrap;
    }
`

export const Logo = styled.div`
    height: 176px;
    flex: 0 0 140px;
    position: relative;
    z-index: 10;

    ${media.sm} {
        height: 100%;
    }
`

export const Text = styled.div`
    flex: 1;

    gap: 8px;
    display: flex;
    flex-direction: column;

    ${media.sm} {
        padding: 24px 0;
    }
`

export const Title = styled.h2`
    margin: 0;
    color: ${({ theme }) => theme.colors.primary70};
    position: relative;
    z-index: 10;

    ${getFontStyle('h1Bold')}
`

export const Subtitle = styled.h3`
    margin: 0;
    color: ${({ theme }) => theme.colors.primary70};
    position: relative;
    z-index: 10;

    .cursor {
        border-color: ${({ theme }) => theme.colors.primary70};
    }

    div:first-child {
        visibility: hidden;
    }

    div:last-child {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;

        span {
            position: relative;
        }
    }

    ${getFontStyle('cardHeaderBold')}
`

export const Processing = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${media.sm} {
        width: auto;

        .dds-button {
            position: absolute;
            top: 16px;
            right: 16px;
        }
    }
`

export const ProcessingAnimation = styled.div`
    width: 126px;
    height: 65px;

    ${media.sm} {
        margin-right: 68px;
        width: 184px;
        height: 93px;
    }

    ${media.md} {
        width: 240px;
        height: 123px;
    }
`
