import { useRef } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import { getAllTools } from 'sagas/network'

import { stringifyQueryParams } from '../../utils'
import { Queries } from 'api/queries.enum'
import { getNextPageParam } from 'utils/getNextPageParam'
import { GetToolsParams, GetToolsResponse } from 'api/actions/tools/tools.types'

export const useGetTools = (params?: GetToolsParams) => {
    const queryInitialPage = useRef(params?.page || 1)

    const query = useInfiniteQuery<GetToolsResponse, Error>({
        queryKey: [Queries.getAllTools, params],
        queryFn: ({ pageParam = queryInitialPage.current }) =>
            getAllTools(stringifyQueryParams({ ...params, page: pageParam })),
        getNextPageParam: ({ next }) => getNextPageParam(next),
        keepPreviousData: true,
    })

    return query
}
