import React, { Component } from 'react';

import Card from '../../utils/Card'
import Form from '../../utils/Form'
import { FormInputTypes } from "../../utils/Form/FormInput";

import './index.scss'

var a = {
    id: '',
    validate: () => {
    },
    default: '',
    name: 'full_name',  // required
    label: 'Full Name',
    required: true,
    helperText: '',
    type: FormInputTypes.string,  // required
    properties: {},
    className: ''
};


class Profile extends Component {
    getFormInputs() {
        return [
            [
                {
                    name: 'full_name',  // required
                    label: 'Full Name',  // required
                    type: FormInputTypes.string,  // required
                    defaultValue: this.props.profile.full_name,
                    required: true
                }
            ],
            [
                {
                    name: 'email',  // required
                    label: 'Email',  // required
                    type: FormInputTypes.string,  // required
                    defaultValue: this.props.profile.email,
                    required: true,
                    readOnly: true
                }
            ],
            [
                // {
                //     name: 'company_name',  // required
                //     label: 'Company Name',  // required
                //     type: FormInputTypes.string,  // required
                // },
                {
                    name: 'role',  // required
                    label: 'Company Role',  // required
                    type: FormInputTypes.string,  // required
                    defaultValue: this.props.profile.role,
                    required: true
                }
            ],
            [
                // {
                //     name: 'industry',  // required
                //     label: 'Industry',  // required
                //     type: FormInputTypes.select,  // required
                //     required: true,
                //     properties: {
                //         options: [
                //             {value: 'Agriculture', label: 'Agriculture'},
                //             {value: 'Asset management & institutional investing', label: 'Asset management & institutional investing'},
                //             {value: 'Energy', label: 'Energy'},
                //             {value: 'Financial services', label: 'Financial services'},
                //             {value: 'Government', label: 'Government'},
                //             {value: 'Healthcare', label: 'Healthcare'},
                //             {value: 'Hospitality', label: 'Hospitality'},
                //             {value: 'Manufacturing', label: 'Manufacturing'},
                //             {value: 'Retail', label: 'Retail'},
                //             {value: 'Technology', label: 'Technology'},
                //             {value: 'Telecom & media', label: 'Telecom & media'}
                //         ]
                //     },
                //     fullWidth: true
                // }
            ]
        ];
    }

    onFormSubmit(form){
        this.props.doUpdateProfile(form);
    }

    render() {
        return (
            <div id="profile">
                <h2 className="paragraph_1 bold">Edit Profile</h2>

                <Form inputs={this.getFormInputs()} onSubmit={this.onFormSubmit.bind(this)} submitLabel={'Save Profile'}/>
            </div>
        );
    }
}

export default Profile;
