import { call, put, select } from 'redux-saga/effects';
import { Actions, ActionTypes } from '../actions';
import {saveSurveyInfo, loadCoreSurveys, saveSurveys, editSurveys, draftSurveys, loadAllSurveys, refreshResponseCount, resetSurveyInfo} from "./network";
import {stringifyQueryParams} from "../utils";
import DOMPurify from "dompurify";

export default [
    [ ActionTypes.DO_SAVE_SURVEY_INFO, doSaveSurveyInfo ],
    [ ActionTypes.DO_EDIT_SURVEY_INFO, doEditSurveyInfo ],
    [ ActionTypes.DO_RESET_SURVEY_INFO, doResetSurveyInfo ],
    [ ActionTypes.DO_SAVE_SURVEYS, doSaveSurveys ],
    [ ActionTypes.DO_EDIT_SURVEYS, doEditSurveys ],
    [ ActionTypes.DO_REFRESH_RESPONSE_COUNT, doRefreshResponseCount ],
    [ ActionTypes.GET_CORE_SURVEYS, getCoreSurveys ],
    [ ActionTypes.GET_ALL_SURVEYS, getAllSurveys ]
];

const getProfile = (state) => state.profile;

function * doSaveSurveyInfo ({ payload }) {
    yield put(Actions.doingSaveSurveyInfo());

    const response = yield call(saveSurveyInfo, payload);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.doneSaveSurveyInfo(response.data));
        yield put(Actions.getCoreSurveys({languages: response.data.supported_languages, type: response.data.deploying_for, survey_type: response.data.survey_type}))
    }
}

function * doEditSurveyInfo ({ payload }) {
    yield put(Actions.doingEditSurveyInfo());

    const response = yield call(editSurveys, payload);

    if (!response.successful) {
        if (response.data.data === "FAIL_TO_CLOSE") {
            yield put(Actions.showMessage({ type: 'error', message: 'The survey cannot be closed at the moment as it doesn\'t have enough responses to create the dashboard.' }))
        } else {
            yield put(Actions.showMessage({type: "error", code: response.code}));
        }

        yield put(Actions.failedEditSurveyInfo());
    } else {
        const profile = yield select(getProfile);
        yield put(Actions.doneEditSurveyInfo({...response.data, titan_survey_info_type: profile.surveyInfoType}));
        yield put(Actions.getCoreSurveys({languages: response.data.supported_languages, type: response.data.deploying_for, survey_type: response.data.survey_type}));

        if (response.data.status === "NSR") {
            yield put(Actions.showMessage({ type: 'error', message: 'The survey cannot be closed at the moment as it doesn\'t have enough responses to create the dashboard.' }))
        }
        if (response.data.status === "CLS") {
            if (profile.selfServe) {
                yield put(Actions.showMessage({ type: "success", message: "The results for your survey are being reviewed. The page will be refreshed automatically once the dashboard is ready.", delay: 15000 }));
            } else {
                yield put(Actions.showMessage({ type: "success", message: "The results for your survey are being reviewed. A Diversio member will get back to you soon.", delay: 15000 }));
            }

        }
    }
}

function * doSaveSurveys ({ payload }) {
    yield put(Actions.doingSaveSurveys());

    const response = yield call(saveSurveys, payload);

    if (!response.successful) {
        yield put(Actions.failedSaveSurveys({ ...response.data }));
    } else {
        yield put(Actions.doneSaveSurveys({...response.data}));
    }
}

function * doEditSurveys ({ payload }) {
    yield put(Actions.doingEditSurveys());

    const response = yield call(editSurveys, payload);

    if (!response.successful) {
        yield put(Actions.failedEditSurveys({ ...response.data }));
    } else {
        yield put(Actions.doneEditSurveys({...response.data}));
    }
}

function * getCoreSurveys ({ payload }) {
    yield put(Actions.loadingCoreSurveys());

    const response = yield call(loadCoreSurveys, stringifyQueryParams(payload));

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        const profile = yield select(getProfile);
        const responseData = response.data.map(survey => {
            var sanitizedContent = DOMPurify.sanitize(survey.intro);
            survey.intro = sanitizedContent.replace(/{{company}}/g, `<b>${profile.company}</b>`);
            survey.questions = survey.questions.map(question => {
                question.title = question.title.replace(/{{company}}/gm, profile.company);
                return question;
            });
            return survey;
        });
        yield put(Actions.loadedCoreSurveys(responseData));
    }
}

function * getAllSurveys ({ payload }) {
    yield put(Actions.loadingAllSurveys());

    const response = yield call(loadAllSurveys, payload);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedAllSurveys(response.data));
    }
}

function * doRefreshResponseCount({ payload }) {
    yield put(Actions.doingRefreshResponseCount());
    const response = yield call(refreshResponseCount, payload);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
        yield put(Actions.failedRefreshResponseCount(response.data));
    } else {
        yield put(Actions.doneRefreshResponseCount(response.data));
    }
}

function * doResetSurveyInfo({ payload }) {
    yield put(Actions.doingResetSurveyInfo());
    const response = yield call(resetSurveyInfo, payload);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
        yield put(Actions.failedResetSurveyInfo(response.data));
    } else {
        yield put(Actions.doneResetSurveyInfo(response.data));
    }
}
