import React from 'react'
import { Fade, Modal, Backdrop } from '@material-ui/core'
import { Button, CloseIcon, ChevronRightIcon, CheckmarkIcon, colors } from '@diversioteam/diversio-ds'

import { ChangeInsightModalProps } from './changeInsightModal.types'
import * as S from './changeInsightModal.styles'

export const ChangeInsightModal = ({
    isModalOpen,
    insights,
    onCloseModal,
    onInitCustomGoal,
    onInitRegularGoal,
}: ChangeInsightModalProps) => {
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={isModalOpen}
            onClose={onCloseModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={isModalOpen}>
                <S.Container>
                    <S.Header>
                        <S.Title>Change Insight</S.Title>

                        <Button iconOnly color="transparent" size="medium" onClick={onCloseModal}>
                            <CloseIcon type="line" />
                        </Button>
                    </S.Header>

                    <S.List>
                        {insights.map(({ title, goalCreated, key, targetGroup }, index) => {
                            return (
                                <S.ListItem key={index}>
                                    <S.InsightTitle>{title}</S.InsightTitle>

                                    {goalCreated ? (
                                        <S.Status>
                                            <CheckmarkIcon
                                                type="line"
                                                color={colors.green50}
                                                height={31.5}
                                                width={31.5}
                                            />

                                            <S.StatusText>Goal Created</S.StatusText>
                                        </S.Status>
                                    ) : (
                                        <Button
                                            type="button"
                                            size="medium"
                                            rightIcon={<ChevronRightIcon type="bold" />}
                                            color="secondary"
                                            onClick={() => onInitRegularGoal({ kpi: key, targetGroup, title })}
                                        >
                                            Create Goal
                                        </Button>
                                    )}
                                </S.ListItem>
                            )
                        })}

                        <S.ListItemCustom>
                            <S.InsightTitle>Want to create a goal unrelated to the above insights?</S.InsightTitle>

                            <Button
                                type="button"
                                size="medium"
                                color="secondary"
                                rightIcon={<ChevronRightIcon type="bold" />}
                                onClick={onInitCustomGoal}
                            >
                                Create Custom
                            </Button>
                        </S.ListItemCustom>
                    </S.List>
                </S.Container>
            </Fade>
        </Modal>
    )
}
