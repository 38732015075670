import React from 'react'
import { HeatMapTableBodyCell, SingleSelect } from '@diversioteam/diversio-ds'

import { BenchmarkSwitchProps } from './BenchmarkSwitch.props'
import * as S from './benchmarkSwitch.styles'

export const BenchmarkSwitch = ({
    label,
    tooltipText,
    benchmarks,
    activeBenchmark,
    onChange,
}: BenchmarkSwitchProps) => {
    return (
        <S.BenchmarkSwitchWrapper>
            <SingleSelect options={benchmarks} variant="block-large" value={activeBenchmark} onChange={onChange} />

            <S.Info>
                <S.InfoLabel>{label}</S.InfoLabel>

                <HeatMapTableBodyCell text={'Benchmarks'} textTooltipTitle={tooltipText} />
            </S.Info>
        </S.BenchmarkSwitchWrapper>
    )
}
