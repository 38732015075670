import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Actions} from '../actions';
import Bespoke from "../components/Bespoke";
import mixpanel from "mixpanel-browser";
import TAXONOMIES from '../utils/taxonomies';
import { VersionsContext } from 'context/versions/versionsContext/VersionsContext';
import { Redirect } from 'react-router-dom';
import { generateCompanyURI } from 'sagas/helpers/generateCompanyURI';
import './App.scss';

class BespokeContainer extends Component {
    static contextType = VersionsContext

    componentDidMount() {
        this.props.getBespokeDemographics();
        this.props.getBespokeCompanyFilters();

        mixpanel.track(TAXONOMIES.VIEW_PAGE, {
            name: "Bespoke"
        })
    }

    render() {
        const versionsContext = this.context
        const isV2Enabled = versionsContext?.version === versionsContext?.AVAILABLE_VERSIONS.v2
        const { enableDebugMode } = this.props;

        if (isV2Enabled && !enableDebugMode) {
            return <Redirect to={generateCompanyURI("/analyze2/diversity")} />
        }

        return <Bespoke { ...this.props } />;
    }
}

const mapStateToProps = state => {
    return {
        bespoke: state.bespoke,
        colors: state.colors,
        analyze: state.analyze,
        profile: state.profile,
        home: state.home,
        enableDebugMode: state.auth.enableDebugMode
    };
};

export default connect(mapStateToProps, Actions)(BespokeContainer);
