import React, { Component } from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import { TextField } from '@diversioteam/diversio-ds';

import PasswordInput from "./PasswordInput";

// function arrayEquals(a,b){
//     const c = a.reduce((acc,val)=>{
//         return acc && b.includes(val);
//     },true);
//     return a.length===b.length && c
// }
//
//
// export const FormInputSpec = {
//     string: {name: "string", checkIfUnfilled: x=> x==='' ,defaultValue: ''},
//     int: {name: "int", checkIfUnfilled: x=> x==='' ,defaultValue: ''},
//     float: {name: "float", checkIfUnfilled: x=> x==='' ,defaultValue: ''},
//     date: {name: "date", checkIfUnfilled: x=> x==='' ,defaultValue: ''},
//     select: {name: "select", checkIfUnfilled: x=> x===undefined ,defaultValue: undefined},
//     multiSelect: {name: 'multiSelect', checkIfUnfilled: x=> {return arrayEquals(x,[])} ,defaultValue: []}
// };

export const FormInputSpec = {
    string: {name: "string", onChangeToValue: e => e.target.value, defaultValue: ''},
    int: {name: "int", onChangeToValue: e => e.target.value, defaultValue: ''},
    float: {name: "float", onChangeToValue: e => e.target.value, defaultValue: ''},
    date: {name: "date", onChangeToValue: e => e.target.value, defaultValue: ''},
    select: {name: "select", onChangeToValue: e => e.target.value, defaultValue: undefined},
    multiSelect: {name: 'multiSelect', onChangeToValue: e => e.target.value, defaultValue: []},
    password: {name:'password', onChangeToValue: e => e.target.value, defaultValue:''},
    checkbox: {name:'checkbox', onChangeToValue: e => e.target.checked, defaultValue:false}
};

export const FormInputTypes = Object.keys(FormInputSpec).reduce((acc, name) => {
    return Object.assign(acc, {[name]: FormInputSpec[name].name});
}, {});

var input = {
    id: '',
    name: '',  // required
    label: '',
    value: '',  // required
    required: true,
    helperText: '',
    'aria-describedby': '',  // required
    type: 'string',  // required
    errors: [],
    properties: {},
    className: ''
};

const select = {
    id: '',
    name: '',  // required
    label: '',
    value: '',  // required
    required: true,
    helperText: '',
    'aria-describedby': '',  // required
    type: FormInputTypes.select,  // required
    errors: [],
    properties: {
        options: [{label: 'something', value: 1}],
        multiple: false
    },
    className: ''
};

function requiredProps(obj){
    return  ['id','name','value','className','onChange','required'].reduce((acc,v)=>{
        acc[v] = obj[v];
        return acc;
    },{});
}

const InputMap = {
    [FormInputTypes.string]: (props) =>
        <TextField
            id={props.id}
            key={props.name}
            name={props.name}
            label={props.label}
            inputProps={{type: 'text'}}
            value={props.value}
            className={props.className}
            onChange={props.onChange}
            required={props.required}
            disabled={props.readOnly}
            variant="outlined"
        />
    ,
    [FormInputTypes.int]: (props) => null,
    [FormInputTypes.float]: (props) => null,
    [FormInputTypes.date]: (props) => null,
    [FormInputTypes.select]: (props) =>
        <Select
            key={props.name}
            id={props.id}
            name={props.name}
            value={props.value}
            className={props.className}
            onChange={props.onChange}
            required={props.required}
            multiple={false}
        >
            {props.properties.options.map((x,index) => <MenuItem key={`${index}_muItem`} value={x.value}>{x.label}</MenuItem>)}
        </Select>,
    [FormInputTypes.multiSelect]: (props) => {
        return <Select
            id={props.id}
            name={props.name}
            key={props.name}
            value={props.value}
            className={props.className}
            onChange={props.onChange}
            renderValue={selected => selected.map(x=>{
                    return props.properties.options.find(y=>y.value===x).label
                }).join(", ")
            }
            required={props.required}
            multiple={true}
        >
            {
                props.properties.options.map(x =>
                    <MenuItem key={x.label} value={x.value}>
                        <Checkbox checked={props.value.includes(x.value)}/>
                        <ListItemText primary={x.label}/>
                    </MenuItem>
                )
            }
        </Select>
    },
    [FormInputTypes.password]: (props)=> <PasswordInput {...props} key={props.name}/>,
    [FormInputTypes.checkbox]: (props)=>
        <Checkbox
            {...requiredProps(props)}
            checked={props.value}
        />
};

const inputsWithoutLabels = [FormInputTypes.password, FormInputTypes.string]


class FormInput extends Component {
    labelComponent() {
        if (!this.props.label) {
            return null
        }

        if (inputsWithoutLabels.includes(this.props.type)) {
            return null
        }

        return <FormLabel key={`${this.props.name}_label`}>{this.props.label}</FormLabel>
    }

    helperTextComponent() {
        if (this.props.errors !== undefined && this.props.errors.length > 0) {
            return <FormHelperText>{this.props.errors[0]}</FormHelperText>
        }
        if (!this.props.helperText) {
            return null;
        }
        return <FormHelperText>{this.props.helperText}</FormHelperText>
    }

    onChange(e){
        const value = FormInputSpec[this.props.type].onChangeToValue(e);
        this.props.onChange(value, this.props.name, e);
    }

    render() {
        let content;
        const input = InputMap[this.props.type]({...this.props, onChange:this.onChange.bind(this)});
        if(this.props.labelInline){
            content = <FormControlLabel
                control={input}
                label={this.props.label}
            />
        } else{
            content =[
                this.labelComponent(),
                input
            ]
        }
        return (
            <FormControl
                id={this.props.id}
                error={this.props.errors !== undefined && this.props.errors.length > 0}
                required={this.props.required}
                fullWidth={this.props.fullWidth}
                classes={{root:'formControl'}}
            >
                {content}
                {this.helperTextComponent()}
            </FormControl>
        );
    }
}

export default FormInput
