import React, { useEffect } from 'react'

import { useBreadcrumbs } from 'hooks/useBreadcrumbs'

import { NoInsightsProps } from './noInsights.types'
import * as S from './noInsights.styles'

export const NoInsights = ({ details }: NoInsightsProps) => {
    const breadcrumbs = useBreadcrumbs()

    useEffect(() => {
        breadcrumbs?.setLinks([])
    }, [])

    return (
        <S.NoInsightsWrapper>
            <S.Header>
                <S.IconWrapper>
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M22.8 12.5C22.8 18.4646 17.9647 23.3 12 23.3C6.03532 23.3 1.2 18.4646 1.2 12.5C1.2 6.53528 6.03532 1.69995 12 1.69995C17.9647 1.69995 22.8 6.53528 22.8 12.5Z"
                            fill="#DB1510"
                        />
                        <path
                            d="M8.39999 12.5H15.6"
                            stroke="white"
                            strokeWidth="2.4"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </S.IconWrapper>

                <S.Title>No insights available for this survey period</S.Title>
            </S.Header>

            <S.CardsList>
                {details.map(({ title, description }, index) => (
                    <S.Card key={index} data-testid="card">
                        <S.CardTitle>{title}</S.CardTitle>

                        <S.CardDescription>{description}</S.CardDescription>
                    </S.Card>
                ))}
            </S.CardsList>
        </S.NoInsightsWrapper>
    )
}
