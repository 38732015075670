import React from 'react'

import { useSaveTool } from 'hooks/tools/useSaveTool'
import { useDeleteToolFromSaved } from 'hooks/tools/useDeleteToolFromSaved'

import { ToolsLayout } from './shared/ToolsLayout'
import { ToolsLayoutProps } from './shared/ToolsLayout/toolsLayout.types'

export const Tools = (props: Omit<ToolsLayoutProps, 'onSave' | 'onRemove'>) => {
    const mutationSaveTool = useSaveTool()
    const mutationDeleteToolFromSaved = useDeleteToolFromSaved()

    const handleSave = () => {
        mutationSaveTool.mutate({ tool: props.id })
    }

    const handleRemove = () => {
        if (props.companyToolId) {
            mutationDeleteToolFromSaved.mutate(props.companyToolId)
        }
    }

    return <ToolsLayout {...props} onRemove={handleRemove} onSave={handleSave} />
}
