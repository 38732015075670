import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Actions } from '../../actions';
import { Row, Column } from '../../components/utils/FlexBox';
import ILNAbout from '../../components/ILNDashboard/About'
import ILNAssetSum from '../../components/ILNDashboard/AssetSum';
import ILNPoweredBy from '../../components/ILNDashboard/Powered';
import ILNPartners from '../../components/ILNDashboard/Partners';
import MemberTable from '../../components/ILNDashboard/Members';
import AUMBreakdown from '../../components/ILNDashboard/AUMBreakdown';
import ILNnews from '../../components/ILNDashboard/News';
import ILNCalendar from '../../components/ILNDashboard/Calendar'
import './index.scss'

class ILNHome extends Component {
    componentWillMount () {
        this.props.getIlnMembers();
        this.props.getIlnInvestmentRegions();
        this.props.getIlnAumBreakdown();
        this.props.getIlnNews();
        this.props.getIlnCalendar()
    }

    render() {
        return(
            <div className="App">
                <Row>
                    <Column width="60%">
                        <ILNAbout />
                    </Column>
                    <Column width="40%">
                        <div className='iln-home-icons-flex'>
                            <ILNAssetSum />
                            <ILNPoweredBy />
                        </div>
                    </Column>
                </Row>
                <Row>
                    <Column width="60%">
                        <ILNPartners />
                    </Column>
                    <Column width="40%">
                        <ILNnews news={this.props.iln.ilnNews} />
                    </Column>
                </Row>
                <Row>
                    <Column width="100%">
                        <ILNCalendar 
                            data={this.props.iln.ilnCalendar}
                        />
                    </Column>
                </Row>
                <Row>
                    <Column width="100%">
                        <MemberTable members={this.props.iln.ilnMembers} />
                    </Column>
                </Row>
                <Row>
                    <Column width="100%">
                        <AUMBreakdown investments={this.props.iln.ilnAumBreakdown} />
                    </Column>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        iln: state.ilndashboard
    };
};

export default connect(mapStateToProps, Actions)(ILNHome);
