import styled from 'styled-components'
import { colors, getFontStyle } from '@diversioteam/diversio-ds'

export const Description = styled.p`
    margin: 0 0 24px;
    padding: 0 24px;
    color: ${colors.primary65};
    text-align: center;

    ${getFontStyle('p02Regular')}
`

export { Button, Container, Form, Logo, Title, Fields, TextField, TextLink, Toast } from './../auth.styles'
