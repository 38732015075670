import { getInclusionPanelData } from 'sagas/network'
import { stringifyQueryParams } from 'utils'
import { isEmpty } from 'lodash'

import { GetInclusionPanelParams, GetInclusionPanelResponse } from 'api/actions/inclusionPanel/inclusionPanel.types'
import { Queries } from 'api/queries.enum'
import { useQuery } from 'hooks/useQuery'
import { useGetKpiScoreMetadata } from 'hooks/inclusion/useGetKpiScoreMetadata'

export const useInclusionPanelData = (params?: GetInclusionPanelParams, options?: { enabled?: boolean }) => {
    const { data } = useGetKpiScoreMetadata()

    const query = useQuery<GetInclusionPanelResponse, Error>({
        queryKey: [Queries.getInclusionPanelData, params],
        queryFn: () => getInclusionPanelData(stringifyQueryParams(params)),
        enabled: !isEmpty(data) && options?.enabled,
    })

    return query
}
