import React, {Component} from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

class AdminRoute extends Component {
    render() {
        let { isAuthenticated, isAdmin, isLoading, component: Comp, ...rest } = this.props;

        if (isLoading) {
            return <div id={"connect-form"}>Loading. Please wait.</div>
        }

        return (
            <Route exact {...rest} render={(props) => (
                isAuthenticated && isAdmin
                    ? <Comp {...props} />
                    : <Redirect
                        to={{
                            pathname: "/"
                        }} />
            )} />
        );
    }
}

const mapStateToProps = state => {
    return {
        ...state.auth
    }
};

export default connect(mapStateToProps)(AdminRoute);
