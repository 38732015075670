import ChipInput from "material-ui-chip-input";
import React, {Component} from 'react';
import x from '../../../images/x.png';
import './index.scss'

const Chip = function({value, isFocused, isDisabled, handleClick, handleDelete}, key){
    return (
        <span key={key} onClick={handleClick} className={'chip' +(isFocused?' focused':'')}>
            <span>{value}</span>
            <img src={x} onClick={handleDelete}/>
        </span>
    )
};

class DefaultChipInput extends Component {
    render() {
        return (
            <ChipInput
                {...this.props}
                chipRenderer={Chip}
            />
        );
    }
}

export default DefaultChipInput;
