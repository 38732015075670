import styled from 'styled-components'
import { ProgramsLogo as DdsProgramsLogo, colors, getFontStyle } from '@diversioteam/diversio-ds'

export const ExplorePrograms = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export const Header = styled.header`
    width: 100%;
    display: flex;
    justify-content: space-between;
`

export const Steps = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 260px;
    margin-left: 60px;
    position: relative;
`

export const StepsItem = styled.div`
    position: absolute;

    &:first-child {
        width: 450px;
        top: 10px;
        left: 0;
    }

    &:nth-child(2) {
        width: 450px;
        top: 85px;
        left: 0;
    }

    &:nth-child(3) {
        top: 215px;
        left: 0;
    }
`

export const StepsItemPrimary = styled(StepsItem)`
    display: grid;
    grid-template-areas:
        'title logo'
        'description description';
    gap: 8px 12px;
`

export const StepsItemSecondary = styled(StepsItem)`
    max-width: 450px;
`

export const TitlePrimary = styled.h1`
    margin-block-start: 0;
    margin-block-end: 0;
    grid-area: title;
    color: ${colors.primary90};

    ${getFontStyle('h2Semibold')}
`

export const DescriptionPrimary = styled.div`
    margin-block-start: 0;
    margin-block-end: 0;
    grid-area: description;
    color: ${colors.secondary500};

    ${getFontStyle('captionRegular')}
`

export const TitleSecondary = styled.div`
    margin-block-start: 0;
    margin-block-end: 0;
    color: ${colors.primary90};

    ${getFontStyle('h4Bold')}
`

export const DescriptionSecondary = styled.p`
    margin-block-start: 0;
    margin-block-end: 0;
    color: ${colors.secondary500};

    ${getFontStyle('p02Regular')}
`

export const ProgramsLogo = styled(DdsProgramsLogo)`
    grid-area: logo;
    align-self: center;
`

export const Avatars = styled.div`
    width: 300px;
    height: 146px;
    margin-right: 30px;
    align-self: center;
`

export const SubHeader = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    margin-bottom: 18px;
`

export const List = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`

export const LoadMore = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
    width: 100%;
    background-color: ${colors.primaryWhite};
    border-radius: 15px;
    margin-top: 15px;
    margin-bottom: 25px;
    box-shadow: 6px 12px 30px rgba(0, 0, 0, 0.06);
`
