import {call, put} from 'redux-saga/effects';
import {Actions, ActionTypes} from '../actions';
import {
    loadCommunicationsTokens,
    updateCommunicationsTokens,
    deleteCommunicationsTokens,
    loadCommunicationsSlackAnalytics,
    loadCommunicationsGithubAnalytics
} from './network';


export default [
    // authentication actions
    [ActionTypes.GET_COMMUNICATIONS_TOKENS, getCommunicationsTokens],
    [ActionTypes.DO_UPDATE_COMMUNICATIONS_TOKENS, doUpdateCommunicationsTokens],
    [ActionTypes.DO_DELETE_COMMUNICATIONS_TOKENS, doDeleteCommunicationsTokens],
    // slack actions
    [ActionTypes.GET_COMMUNICATIONS_SLACK_ANALYTICS, getCommunicationsSlackAnalytics],
    // github actions
    [ActionTypes.GET_COMMUNICATIONS_GITHUB_ANALYTICS, getGithubAnalytics]
];

// Util for creating error objects
function createError(id, timestamp, httpStatusCode, httpResponseBody, customStatusCode, details) {
    return {id, timestamp, httpStatusCode, httpResponseBody, customStatusCode, details}
}

function * getCommunicationsTokens({payload}) {
    const requestId = payload.requestId;
    delete payload.requestId;
    yield put(Actions.loadingCommunicationsTokens());
    const response = yield call(loadCommunicationsTokens);
    if (response.successful && !response.data.error) {
        yield put(Actions.loadedCommunicationsTokens(response.data))
    } else {
        const error = createError(requestId, Date.now(), response.code, response.data, response.data.error.code, response.data.error.reason);
        yield put(Actions.failedLoadingCommunicationsTokens(error))
    }
}

function* doUpdateCommunicationsTokens({payload}) {
    const requestId = payload.requestId;
    delete payload.requestId;
    yield put(Actions.doingUpdateCommunicationsTokens());
    const response = yield call(updateCommunicationsTokens, payload);
    if (response.successful) {
        yield put(Actions.doneUpdateCommunicationsTokens(response.data))
    } else {
        yield put(Actions.failedUpdateCommunicationsTokens({id: requestId}))
    }
}

function* doDeleteCommunicationsTokens({payload}) {
    const requestId = payload.requestId;
    delete payload.requestId;
    yield put(Actions.doingDeleteCommunicationsTokens());
    const response = yield call(deleteCommunicationsTokens);
    if (response.successful) {
        yield put(Actions.doneDeleteCommunicationsTokens(response.data))
    } else {
        yield put(Actions.failedDeleteCommunicationsTokens({id: requestId}))
    }
}


function* getCommunicationsSlackAnalytics({payload}) {
    const requestId = payload.requestId;
    delete payload.requestId;
    yield put(Actions.loadingCommunicationsSlackAnalytics());
    const response = yield call(loadCommunicationsSlackAnalytics, payload);
    if (response.successful) {
        yield put(Actions.loadedCommunicationsSlackAnalytics(response.data))
    } else {
        yield put(Actions.failedLoadingCommunicationsSlackAnalytics({id:requestId}))
    }
}

function* getGithubAnalytics({payload}) {
    const requestId = payload.requestId;
    delete payload.requestId;
    yield put(Actions.loadingCommunicationsGithubAnalytics());
    const response = yield call(loadCommunicationsGithubAnalytics, payload);
    if (response.successful) {
        yield put(Actions.loadedCommunicationsGithubAnalytics(response.data))
    } else {
        yield put(Actions.failedLoadingCommunicationsGithubAnalytics({id:requestId}))
    }
}
