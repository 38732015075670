import { KpiKey } from '@diversioteam/diversio-ds'

import { DataItemInput, DataItemOutput, Level, Tags } from './trainings.types'

export const splitDetails = (details: string): string[] => {
    return details.split(/\s*-\s|\s*\n-\s|\s*\n\s*/).filter(Boolean)
}

export const parseImage = (imageLink: string): string => {
    const match = imageLink.match(/\/d\/([^\/]+)\//)
    return match ? `https://lh3.googleusercontent.com/d/${match[1]}` : ''
}

export const transformKpis = (dataItem: DataItemInput): { key: KpiKey }[] => {
    const kpis = [
        { key: 'INCLUSIVE_CULTURE', value: dataItem['Inclusive Culture'] === 'TRUE' },
        { key: 'CAREER_DEVELOPMENT', value: dataItem['Career Development'] === 'TRUE' },
        { key: 'FAIR_MANAGEMENT', value: dataItem['Fair Management'] === 'TRUE' },
        { key: 'WORKPLACE_FLEXIBILITY', value: dataItem['Workplace Flexbility'] === 'TRUE' },
        { key: 'WORKPLACE_SAFETY', value: dataItem['Workplace Safety'] === 'TRUE' },
        { key: 'RECRUITING_HIRING', value: dataItem['Recruitment & Hiring'] === 'TRUE' },
        { key: 'ALL', value: dataItem['All KPIs'] === 'TRUE' },
        { key: 'NO_KPI', value: dataItem['No KPIs'] === 'TRUE' },
    ]

    if (kpis.find((kpi) => kpi.key === 'ALL' && kpi.value)) {
        return [{ key: 'ALL' }]
    }

    if (kpis.find((kpi) => kpi.key === 'NO_KPI' && kpi.value)) {
        return [{ key: 'NO_KPI' }]
    }

    return kpis
        .filter((kpi) => kpi.value && kpi.key !== 'ALL' && kpi.key !== 'NO_KPI')
        .map((kpi) => ({ key: kpi.key })) as { key: KpiKey }[]
}

export const parseData = (data: DataItemInput[]): DataItemOutput[] => {
    return data.map((dataItem) => {
        const kpis = transformKpis(dataItem)

        const targetGroups = [
            { key: dataItem['Target Group 1'] },
            { key: dataItem['Target Group 2'] },
            { key: dataItem['Target Group 3'] },
        ].filter(({ key }) => Boolean(key))

        const targetGroupsTransformed = targetGroups.find(({ key }) => key === 'Everyone')
            ? [{ key: 'Everyone' }]
            : targetGroups

        return {
            title: dataItem['Course title'],
            imageUrl: parseImage(dataItem['Image URL']),
            altText: dataItem['ALT Text'],
            id: dataItem['ID'],
            tags: [
                dataItem['New?'] === 'TRUE' ? 'new' : null,
                dataItem['Popular?'] === 'TRUE' ? 'popular' : null,
                dataItem['Live?'] === 'TRUE' ? 'live' : null,
                dataItem['Async?'] === 'TRUE' ? 'async' : null,
            ].filter(Boolean) as Tags[],
            level: dataItem['Level'] as Level,
            kpis,
            targetGroups: targetGroupsTransformed,
            subtitle: dataItem['Subtitle'],
            objectives: [
                { title: dataItem['Objective 1 title'], details: splitDetails(dataItem['Objective 1 details']) },
                { title: dataItem['Objective 2 title'], details: splitDetails(dataItem['Objective 2 details']) },
                { title: dataItem['Objective 3 title'], details: splitDetails(dataItem['Objective 3 details']) },
                { title: dataItem['Objective 4 title'], details: splitDetails(dataItem['Objective 4 details']) },
                { title: dataItem['Objective 5 title'], details: splitDetails(dataItem['Objective 5 details']) },
            ].filter((obj) => obj.title && obj.details),
            partOfCertificateProgram: dataItem['Part of Certificate Program?'] === 'Yes',
            certificateProgram: dataItem['Certificate Program'],
            linkToMarketingWebsite: dataItem['Link to Marketing Website'],
        }
    })
}
