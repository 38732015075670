import React from 'react';
import Progress from "./Progress";
import brb from '../../assets/images/survey-brb.svg';
import Loader from "../utils/Loader";

const Review = ({ selfServe, returningUser, entity = "Survey" }) => {
    return (
        <div className={"survey-review"}>
            <Progress selected={"review"} selfServe={selfServe}/>

            <div id={"survey-generic"}>
                <div className={"survey-content-container"}>
                    <img src={brb} alt="Survey Review"/>
                    <h5>We’ll Be Back with Your {entity}</h5>
                    <p>We’re in the process of creating your {entity.toLowerCase()}. A Diversio rep will email you when your {entity} is ready to go.</p>
                </div>
            </div>
        </div>
    );
};

export default Review;
