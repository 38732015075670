import React from 'react'

import { LegendItemProps } from './../../dataRepresentation.types'
import * as S from './groupLegendItem.styles'

export const GroupLegendItem = ({ color, label }: LegendItemProps) => (
    <S.GroupLegendItemWrapper>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.5" width="16" height="16" rx="6.4" fill={color} />
        </svg>

        <S.LegendItemLabel>{label}</S.LegendItemLabel>
    </S.GroupLegendItemWrapper>
)
