import styled from 'styled-components'
import { Dialog, getFontStyle } from '@diversioteam/diversio-ds'

export const RenewDialogWrapper = styled(Dialog)`
    .MuiPaper-root.MuiDialog-paper {
        min-height: 475px;
        padding: 24px;
        justify-content: center;
    }

    .dds-button {
        overflow: hidden;
    }
`

export const Alert = styled.p`
    margin: 18px 0 24px;
    padding: 0 12px;
    color: ${({ theme }) => theme.colors.primary70};

    ${getFontStyle('p02Regular')};

    span {
        ${getFontStyle('p02Semibold')};
    }
`

export const Section = styled.section`
    padding: 18px 12px;
    display: flex;
    align-items: center;
    gap: 32px;

    &:first-of-type {
        border-bottom: 1px solid ${({ theme }) => theme.colors.primaryGray2};
    }

    .dds-button {
        min-width: 160px;
        white-space: nowrap;
    }
`

export const SectionColumn = styled.section`
    padding: 18px 12px;
    display: flex;
    flex-direction: column;
`

export const SectionText = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`

export const SectionTitle = styled.h4`
    margin: 0;
    color: ${({ theme }) => theme.colors.primary90};

    ${getFontStyle('p02Semibold')};
`

export const SectionDescription = styled.p`
    margin: 0;
    color: ${({ theme }) => theme.colors.primary65};

    ${getFontStyle('p03Regular')};
`

export const List = styled.ul`
    margin: 0;
    padding: 18px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    list-style-type: none;
`

export const ListOption = styled.li`
    margin: 0;
    padding: 0;
`
