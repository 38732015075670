import styled from 'styled-components'

export { TableRow } from '../TableRow/tableRow.styles'

export const Placeholder = styled.td`
    border: 2px dashed ${({ theme }) => theme.colors.primaryGray1};
    height: 62px;
`

export const SideCell = styled.td`
    div[class*='-icon'].plus-icon {
        background-color: black;
    }

    z-index: 101;
    position: sticky;
    left: 0;
    background: ${({ theme }) => theme.colors.primaryWhite};
    max-width: 173px;
`
