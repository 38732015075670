import React, {Component} from 'react';
import {getDefaultHeaders, parseQueryString} from "../../../utils";
import slack from '../../Communications/Slack/slack.png';
import {SLACK_AUTHORIZE_URL, SLACK_OAUTH_REDIRECT_URI} from "../../Communications/utils/constants";
import {Redirect} from "react-router-dom";
import './index.scss';

const tokenStates = {
    loading:'loading',
    loaded:'loaded',
    error:'error'
};

const OAUTH_URL = `${process.env.REACT_APP_DASHBOARD_API_ROOT}/voyager/slack/oauth/`;

function errorComponent(){
    return (
        <div>
            <img src={slack}/>
            <div>
                An error occurred authenticating Slack.
                <br/>
                Please <a href={SLACK_AUTHORIZE_URL}>try again.</a>
            </div>
        </div>
    )
}

function loadingComponent(){
    return (
        <div>
            Loading...
        </div>
    )
}

function successComponent(){
    return <Redirect to={"/communications"}/>
}

class SlackAuth extends Component {
    state = {
        tokenState:tokenStates.loading,
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const query = parseQueryString(window.location.search);
        const code = query.code;
        if(code===undefined){
            this.setState({tokenState:tokenStates.error});
            return;
        }

        const body = JSON.stringify({
            code,
            redirect_uri: SLACK_OAUTH_REDIRECT_URI
        });

        const method = 'POST';
        const headers = getDefaultHeaders();

        fetch(OAUTH_URL, {method,headers,body})
            .then(response=>{
                if (![200,201].includes(response.status)){
                    throw Error("Request failed")
                }
                return response.json()
            })
            .then(()=>{
                this.setState({tokenState:tokenStates.success});
            }).catch(()=>{
                this.setState({tokenState:tokenStates.error})
             })
    }

    componentMap = {
        [tokenStates.success]: successComponent,
        [tokenStates.error]: errorComponent,
        [tokenStates.loading]: loadingComponent
    };

    render(){
        const component = this.componentMap[this.state.tokenState]();
        return (
            <div className={'slack'}>
                {component}
            </div>
        )
    }
}

export default SlackAuth;
