import React from 'react'
import { Skeleton as MuiSkeleton } from '@material-ui/lab'
import { AlertIcon, NoResults, UnstableChip as Chip } from '@diversioteam/diversio-ds'

import { useGetFreeTextsFilters } from 'hooks/freeTexts/useGetFreeTextsFilters'
import { useGetFreeTexts } from 'hooks/freeTexts/useGetFreeTexts'

import { useFreeTextsState } from './../../hooks/useFreeTextsState'
import { FreeTextAccordionSkeleton } from './../FreeTextAccordionSkeleton'
import { FreeTextAccordion } from './../FreeTextAccordion'
import { ALL_KPIS_TAB_NAME, ALL_KPIS_TAB_VALUE } from './../inclusionCategory.helpers'
import * as S from './../../freeTexts.styles'

export const AllKpisTabPanel = () => {
    const { debouncedSearchValue, selectedInclusionPainPoint, onChangeSelectedInclusionPainPoint } = useFreeTextsState()

    const queryFreeTextsFilters = useGetFreeTextsFilters()

    const queryFreeTexts = useGetFreeTexts({
        search: debouncedSearchValue,
        pain_point: selectedInclusionPainPoint || undefined,
    })

    const getPainPointValue = (painPointName: string): string =>
        queryFreeTextsFilters.data?.painPointMapping?.find(({ title }) => title === painPointName)?.code || ''

    const painPoints = [
        { label: 'All', value: null },
        ...(queryFreeTextsFilters.data?.inclusionFilterData || []).flatMap(({ painPoints }) =>
            painPoints.map(({ painPointTitle }) => ({
                label: painPointTitle,
                value: getPainPointValue(painPointTitle),
            })),
        ),
    ]

    const painPointsSkeleton = new Array(24).fill('').map((_, index) => (
        <S.PainPointsListItem key={index}>
            <Chip label={<MuiSkeleton width={120} />} color="white" />
        </S.PainPointsListItem>
    ))

    const accordionText = queryFreeTexts.data?.pages[0].question || ''

    const responses =
        queryFreeTexts.data?.pages?.flatMap(({ results }) =>
            results?.map(({ painPoint, text, textSearchResult, translatedText, translatedTextSearchResult }) => ({
                kpi: painPoint.kpi.code,
                painPoint: selectedInclusionPainPoint === null ? painPoint.title : '',
                text: translatedTextSearchResult || textSearchResult || translatedText || text,
            })),
        ) || []

    const responsesComponent =
        responses.length === 0 ? (
            <NoResults />
        ) : (
            <FreeTextAccordion
                defaultExpanded
                text={accordionText}
                responses={responses}
                hasNextPage={!!queryFreeTexts.hasNextPage}
                fetchNextPage={queryFreeTexts.fetchNextPage}
                isFetchingNextPage={queryFreeTexts.isFetchingNextPage}
            />
        )

    const responseSkeletonComponent = <FreeTextAccordionSkeleton />

    return (
        <S.TabPanel value={ALL_KPIS_TAB_VALUE} isLoading={queryFreeTexts.isLoading}>
            <S.PainPoints>
                <S.PainPointsHeader>
                    {ALL_KPIS_TAB_NAME} / <strong>Pain Points</strong>
                </S.PainPointsHeader>

                <S.PainPointsList>
                    {painPoints.map(({ label, value }) => (
                        <S.PainPointsListItem key={label}>
                            {value === 'HARASSMENT' ? (
                                <Chip
                                    label={label}
                                    color="danger"
                                    leftIcon={<AlertIcon type="bold" />}
                                    selected={selectedInclusionPainPoint === value}
                                    onClick={() => onChangeSelectedInclusionPainPoint(value)}
                                />
                            ) : (
                                <Chip
                                    label={label}
                                    color="white"
                                    selected={selectedInclusionPainPoint === value}
                                    onClick={() => onChangeSelectedInclusionPainPoint(value)}
                                />
                            )}
                        </S.PainPointsListItem>
                    ))}

                    {queryFreeTextsFilters.isLoading && painPointsSkeleton}
                </S.PainPointsList>
            </S.PainPoints>

            {queryFreeTexts.isLoading ? responseSkeletonComponent : responsesComponent}
        </S.TabPanel>
    )
}
