/** @format */

import { call, put } from "redux-saga/effects";
import { Actions, ActionTypes } from "../actions";
import {
  loadSolutionRecommendedInsights,
  loadSolutionRecommendedPrograms,
  loadSolutionGoalsCreateGoal,
  loadSolutionRecommendedGoalsOptions,
  loadSolutionRecommendedClientGoals,
  loadRecommendedInsights,
  loadGoalsOptions,
  loadRecommendedPrograms,
  saveRecommendedProgramV2,
  deleteRecommendedProgramV2,
  changeRecommendedProgramStatus,
} from "./network";

// All data sagas to add to middleware.
export default [
  [ActionTypes.GET_SOLUTION_RECOMMENDED_INSIGHTS, getSolutionRecommendedInsights],
  [ActionTypes.GET_RECOMMENDED_INSIGHTS, getRecommendedInsights],
  [ActionTypes.GET_SOLUTION_RECOMMENDED_PROGRAMS, getSolutionRecommendedPrograms],
  [ActionTypes.GET_RECOMMENDED_PROGRAMS, getRecommendedPrograms],
  [ActionTypes.GET_SOLUTION_RECOMMENDED_GOAL_OPTIONS, getSolutionRecommendedGoalOptions],
  [ActionTypes.GET_GOALS_OPTIONS_V2, getGoalsOptionsV2],
  [ActionTypes.GET_SOLUTION_RECOMMENDED_CLIENT_GOALS, getSolutionRecommendedClientGoals],
  [ActionTypes.GET_SOLUTION_MORE_RECOMMENDED_PROGRAMS, getSolutionMoreRecommendedPrograms],
  [ActionTypes.GET_MORE_RECOMMENDED_PROGRAMS, getMoreRecommendedPrograms],
  [ActionTypes.DO_SOLUTION_RECOMMENDED_GOALS_CREATE, getSolutionRecommendedGoalsCreate],
  [ActionTypes.DO_SAVE_RECOMMENDED_PROGRAM_V2, doSaveRecommendedProgramV2],
  [ActionTypes.DO_REMOVE_RECOMMENDED_SOLUTION_V2, doRemoveRecommendedSolutionV2],
  [ActionTypes.DO_CHANGE_RECOMMENDED_SOLUTION_STATUS, doChangeRecommendedSolutionStatus],
];

function* getSolutionRecommendedInsights({ payload }) {
  yield put(Actions.loadingSolutionRecommendedInsights());

  const response = yield call(loadSolutionRecommendedInsights, payload);

  if (!response.successful) {
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Something went wrong",
      })
    );
  } else {
    yield put(Actions.loadedSolutionRecommendedInsights(response.data));
  }
}

function* getRecommendedInsights() {
  yield put(Actions.loadingRecommendedInsights());

  const response = yield call(loadRecommendedInsights);

  if (response.data?.detail) {
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Something went wrong",
      })
    );
  } else {
    yield put(Actions.loadedRecommendedInsights(response));
  }
}

function* getSolutionRecommendedPrograms({ payload }) {
  yield put(Actions.loadingSolutionRecommendedPrograms());
  const response = yield call(loadSolutionRecommendedPrograms, payload);

  if (!response.successful) {
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Something went wrong",
      })
    );
  } else {
    if(!response.data.next) {
      yield put(Actions.endOfRecommendedPrograms(true))
    }
    yield put(Actions.loadedSolutionRecommendedPrograms(response.data));
  }
}

function* getSolutionMoreRecommendedPrograms({ payload }) {
  yield put(Actions.loadingSolutionMoreRecommendedPrograms());

  const response = yield call(loadSolutionRecommendedPrograms, payload);

  if (!response.successful) {
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Something went wrong",
      })
    );
  } else {
    if(!response.data.next) {
      yield put(Actions.endOfRecommendedPrograms(true))
    }
    
    yield put(Actions.loadedSolutionMoreRecommendedPrograms(response.data));
  }
}

function* getSolutionRecommendedGoalOptions({ payload }) {

  const response = yield call(loadSolutionRecommendedGoalsOptions, payload);

  if (!response.successful) {
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Something went wrong",
      })
    );
  } else {
    yield put(Actions.loadedSolutionRecommendedGoalOptions(response.data));
  }
}

function* getGoalsOptionsV2() {
  yield put(Actions.loadingGoalsOptionsV2())
  
  const response = yield call(loadGoalsOptions);
  
  if (response.data?.detail) {
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Something went wrong",
      })
    );
  } else {
    yield put(Actions.loadedGoalsOptionsV2(response));
  }
}

// TODO: add client goals api at the end of webpage development
function* getSolutionRecommendedClientGoals({ payload }) {

  const response = yield call(loadSolutionRecommendedClientGoals, payload);

  if (!response.successful) {
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Something went wrong",
      })
    );
  } else {
    yield put(Actions.loadedSolutionRecommendedClientGoals(response.data));
  }
}

function* getSolutionRecommendedGoalsCreate({ payload }) {

  const response = yield call(loadSolutionGoalsCreateGoal, payload);

  if (!response.successful) {
    const firstError = Object.keys(response.data);
    const message = "Please fill out the missing data";
    yield put(
      Actions.showToast({
        type: "error",
        message: `${firstError} - ${message}`,
      })
    );

    yield put(Actions.doneSolutionGoalsCreateError());
  } else {

    yield put(
      Actions.doneSolutionRecommendedGoalsCreate({
        kpi: response.data.kpi_payload[0].id,
        targetGroup: response.data.target_group_payload[0].name,
      })
    );
    yield put(
      Actions.showToast({
        type: "success",
        message: "Successfully created a new Goal!",
      })
    );
  }
}


function* getRecommendedPrograms({ payload }) {
  yield put(Actions.loadingRecommendedPrograms());
  const response = yield call(loadRecommendedPrograms, payload);

  if (response.data?.detail) {
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Something went wrong",
      })
    );
  } else {
    if(!response.next) {
      yield put(Actions.endOfRecommendedPrograms(true))
    }
    yield put(Actions.loadedRecommendedPrograms(response));
  }
}

function* getMoreRecommendedPrograms({ payload }) {
  yield put(Actions.loadingMoreRecommendedPrograms());

  const response = yield call(loadRecommendedPrograms, payload);

  if (response.data?.detail) {
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Something went wrong",
      })
    );
  } else {
    if(!response.next) {
      yield put(Actions.endOfRecommendedPrograms(true))
    }
    
    yield put(Actions.loadedMoreRecommendedPrograms(response));
  }
}

function* doSaveRecommendedProgramV2({ payload }) {
  const response = yield call(saveRecommendedProgramV2, payload);
  
  if (response.data?.detail) {
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Something went wrong",
      })
    );
  } else {
    yield put(
      Actions.doneSaveRecommendedProgramV2({
        program: response.solution,
        companySolution: response,
      })
    );

    yield put(
      Actions.showToast({
        type: "success",
        message: "Program has been saved!",
      })
    );
  }
}

function* doRemoveRecommendedSolutionV2({ payload }) {
  const response = yield call(deleteRecommendedProgramV2, payload.id);

  if (response.data?.detail) {
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Something went wrong",
      })
    );
  } else {
    yield put(
      Actions.doneRemoveRecommendedSolutionV2({ solution: payload.id })
    );
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Program removed",
      })
    );
  }
}

function* doChangeRecommendedSolutionStatus({ payload }) {
  yield put(Actions.doingChangeSolutionStatus());
  
  const response = yield call(changeRecommendedProgramStatus, payload);
  /*
              Process the response
          */
  if (response.data?.detail) {
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Something went wrong",
      })
    );
  } else {
    const page = payload.page;
    yield put(
      Actions.showToast({
        type: response.isArchived ? "warning" : response.isActive ? "success" : "warning",
        message: `Program has been ${response.isArchived ? "archived" : response.isActive ? "activated" : "deactivated"}!`,
      })
    );
    
    yield put(Actions.doneChangeRecommendedSolutionStatus(response))
  }
}