import React from 'react'

import * as S from './emptyList.styles'

export const EmptyList = () => {
    return (
        <S.EmptyListWrapper>
            <svg width="120" height="121" viewBox="0 0 120 121" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_2735_2531)">
                    <circle cx="30" cy="30.5" r="30" fill="#F7F7FF" />
                    <circle cx="30" cy="90.5" r="30" fill="#F7F7FF" />
                    <circle cx="90" cy="30.5" r="30" fill="#F7F7FF" />
                    <circle cx="90" cy="90.5" r="30" fill="#F7F7FF" />
                </g>
                <defs>
                    <clipPath id="clip0_2735_2531">
                        <rect width="120" height="120" fill="white" transform="translate(0 0.5)" />
                    </clipPath>
                </defs>
            </svg>

            <span>Your job posts entries will appear here once you upload or enter text</span>
        </S.EmptyListWrapper>
    )
}
