import React, { useCallback } from 'react'

import { ForgotPassword } from 'components/Auth/ForgotPassword'
import { ForgotPasswordFields, ForgotPasswordFormData } from 'components/Auth/ForgotPassword/forgotPassword.types'
import { useRequestPasswordReset } from 'hooks/auth/useRequestPasswordReset/useRequestPasswordReset'

export const ForgotPasswordContainer = () => {
    const mutationRequestPasswordReset = useRequestPasswordReset()

    const handleSubmit = useCallback(
        async (values: ForgotPasswordFormData): Promise<boolean> => {
            try {
                await mutationRequestPasswordReset.mutateAsync({
                    email: values[ForgotPasswordFields.usernameEmail],
                })

                return true
            } catch (error) {
                return false
            }
        },
        [mutationRequestPasswordReset],
    )

    return (
        <ForgotPassword
            isLoading={mutationRequestPasswordReset.isLoading}
            data={mutationRequestPasswordReset.data}
            isSuccess={mutationRequestPasswordReset.isSuccess}
            isError={mutationRequestPasswordReset.isError}
            error={mutationRequestPasswordReset.error}
            onSubmit={handleSubmit}
        />
    )
}
