import React from 'react';
import { withTranslation } from 'react-i18next';

const Legend = ( {t} ) => {
    return (
        <div className="home-heat-map__legend">
            <div className="home-heat-map__legend_strength home-heat-map__legend_item">{t("HOME.HEATMAP.LEGEND.STRENGTH")}</div>
            <div className="home-heat-map__legend_painpoint home-heat-map__legend_item">{t("HOME.HEATMAP.LEGEND.PAIN_POINT")}</div>
            <div className="home-heat-map__legend_na home-heat-map__legend_item">{t("HOME.HEATMAP.LEGEND.MIN_THRESH")}</div>
            <div className="home-heat-map__legend_prev home-heat-map__legend_item">{t("HOME.HEATMAP.LEGEND.PREV_SCORE")}</div>
        </div>
    );
}

export default withTranslation()(Legend);
