import React from 'react';
import Progress from "./Progress";
import Link from '../utils/Link';
import {Button} from "react-bootstrap";
import surveyApproved from '../../assets/images/survey-approved.svg';
import { generateCompanyURI } from 'sagas/helpers/generateCompanyURI';

const Approved = ({ selfServe }) => {
    return (
        <div className={"survey-review"}>
            <Progress selected={"review"} selfServe={selfServe}/>

            <div id={"survey-generic"}>
                <div className={"survey-content-container"}>
                    <img src={surveyApproved} alt="Survey Approved"/>
                    <h5>Congrats! Your Survey Has Been Approved.</h5>
                    <p>Review survey one last time before deploying. Feel free to update wording in your introduction. Keep in mind that if you update any of your 'additional' questions, the survey will need to be reviewed by the Diversio team again.</p>
                    <Link to={generateCompanyURI("/survey/setup")}>
                        <Button variant={"primary"}>Review Survey</Button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Approved;
