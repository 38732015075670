import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { Mutations } from 'api/actions/useMetadata/mutations.enum'
import {
    SendTrainingEmailErrorResponse,
    SendTrainingEmailPayload,
} from 'api/actions/useMetadata/userMetadataActions.types'
import { sendTrainingEmail } from 'api/actions/useMetadata/userMetadataActions'

export const useSendTrainingEmail = () => {
    const mutation = useMutation<void, AxiosError<SendTrainingEmailErrorResponse>, SendTrainingEmailPayload>({
        mutationKey: [Mutations.sendTrainingEmail],
        mutationFn: sendTrainingEmail,
    })

    return mutation
}
