import React from 'react';
import './index.scss';
import { withTranslation } from 'react-i18next';
import * as m from '../../../../translations/mapping';
import { mapT } from '../../../../translations/utils';
import { getIndustryCompType } from '../../../../utils';
import _ from 'underscore';

const MetricOverviewDetails = (props) => {
    const { t, companyName, data } = props;
    if (_.isEmpty(data)) {
        return null;
    }

    const compType = getIndustryCompType(companyName);

    return (
        <div className="perf-time-detail-panel">
            <div className="perf-time-detail-panel__container">
            <div className="perf-time-detail-panel__header_row">
                <div className="perf-time-detail-panel__top_label_col"><h5 className="perf-time-detail-panel__company_name">{companyName}</h5></div>
                <div className="perf-time-detail-panel__top_label_col"><h5 className="perf-time-detail-panel__industry_avg">
                    {`${t(compType)} ${t("AVERAGE")}`}
                </h5></div>
            </div>
            <div className="perf-time-detail-panel__data_row">
                <div className="perf-time-detail-panel__data_col">
                    <div className="perf-time-detail-panel__company-score perf-time-detail-panel__data_circle"><h5>{data.data}</h5>
                    </div>
                </div>
                <div className="perf-time-detail-panel__data_col">
                    <div className="perf-time-detail-panel__industry-score perf-time-detail-panel__data_circle"><h5>{data.idata}</h5></div>
                </div>
            </div>
            <div className="perf-time-detail-panel__date">
                <h5>{data.date}</h5>
            </div>
            </div>
        </div>
    );
};

export default withTranslation()(MetricOverviewDetails);
