import { useMutation } from '@tanstack/react-query'
import { loadDiversityData } from 'sagas/network'
import { stringifyQueryParams } from 'utils'
import mixpanel from 'mixpanel-browser'

import { DiversityDataParams } from 'api/actions/analyze/diversityData/diversityDataActions.types'
import { Mutations } from 'api/mutations.enum'
import TAXONOMIES from 'utils/taxonomies'

export const useExportDiversityData = (params: DiversityDataParams) => {
    const mutation = useMutation<string, Error, void>({
        mutationKey: [Mutations.exportDiversityData, params],
        mutationFn: () => {
            return loadDiversityData(stringifyQueryParams(params), 'file')
        },
        onSuccess: () => {
            mixpanel.track(TAXONOMIES.ANALYZE_DIVERSITY_DATA_EXPORT)
        },
    })

    return mutation
}
