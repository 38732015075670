import React from 'react'

import { useIsAllowedPath } from 'hooks/useIsAllowedPath'

import { LocationBasedContentProps } from './LocationBasedContent.types'

export const LocationBasedContent = ({ allowedPaths, children }: LocationBasedContentProps) => {
    const { isAllowedPath } = useIsAllowedPath()

    const isContentVisible = isAllowedPath(allowedPaths)

    if (!isContentVisible) {
        return <></>
    }

    return <>{children}</>
}
