import styled from 'styled-components'

export { HeatmapTables, ScrollBar } from './Tables/tables.styles'
export { Table } from './Table/table.styles'

export const Heatmap = styled.section`
    .inclusion-heatmap {
        width: 100%;
    }

    .dds-heat-map-table-header-cell {
        background: none;
    }
`
