import React from 'react'
import Lottie from 'react-lottie'
import { Button } from '@diversioteam/diversio-ds'
import mixpanel from 'mixpanel-browser'

import { Typewriter } from 'components/TypeWriter'
import * as backgroundAnimationData from 'assets/lottie/lumi/background.json'
import * as introInData from 'assets/lottie/lumi/introIn.json'
import * as successInData from 'assets/lottie/lumi/successIn.json'
import * as errorInData from 'assets/lottie/lumi/errorIn.json'
import * as processingAnimationData from 'assets/lottie/lumi/processing.json'
import { useDeleteJobAdvert } from 'hooks/lumi/useDeleteJobAdvert'
import TAXONOMIES from 'utils/taxonomies'

import { Stage } from './../context/LumiContext.types'
import { useLumi } from './../hooks/useLumi'
import * as S from './banner.styles'

const stages = {
    [Stage.Init]: {
        title: 'Hello, I’m Lumi...',
        description:
            'Your partner in crafting inclusive job adverts, illuminating your DEI journey. Simply add your job ads below, and I’ll help turn them into inclusive posts that attract a diverse range of talented applicants.',
        animationIn: introInData,
    },
    [Stage.UpdateData]: {
        title: 'Hello, I’m Lumi...',
        description:
            'Your partner in crafting inclusive job adverts, illuminating your DEI journey. Simply add your job ads below, and I’ll help turn them into inclusive posts that attract a diverse range of talented applicants.',
        animationIn: introInData,
    },
    [Stage.Processing]: {
        title: 'Transforming your job post(s)',
        description:
            'While you wait, I’ve compiled some helpful tips and tricks to encourage inclusivity in the workplace.',
        animationIn: introInData,
    },
    [Stage.Completed]: {
        title: 'You’re all set!',
        description: 'Your job ads are now more inclusive than ever. Would you like to download?',
        animationIn: successInData,
    },
    [Stage.Error]: {
        title: 'Something went wrong...',
        description:
            'Please resolve the issues in the queue then try again. If the error persists, you can contact support@diversio.com',
        animationIn: errorInData,
    },
}

export const Banner = () => {
    const { stage, jobId, onResetStage } = useLumi()

    const { mutate } = useDeleteJobAdvert()

    const handleClickStop = () => {
        mixpanel.track(TAXONOMIES.LUMI_TRANSFORM_JOB_AD, {
            status: 'Stopped',
            jobId,
        })

        mutate(jobId, {
            onSettled: () => {
                onResetStage()
            },
        })
    }

    return (
        <S.BannerWrapper>
            <Lottie
                options={{
                    loop: true,
                    autoplay: true,
                    animationData: backgroundAnimationData,
                    rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice',
                    },
                }}
                speed={0.25}
                style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
            />

            <S.Logo>
                <Lottie
                    options={{
                        loop: 1,
                        autoplay: true,
                        animationData: stages[stage].animationIn,
                        rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                        },
                    }}
                />
            </S.Logo>

            <S.Text key={stage}>
                <S.Title>{stages[stage].title}</S.Title>

                <S.Subtitle>
                    <div>{stages[stage].description}</div>

                    <div>
                        <Typewriter text={stages[stage].description} />
                    </div>
                </S.Subtitle>
            </S.Text>

            {stage === Stage.Processing && (
                <S.Processing>
                    <Button color="primaryLight" onClick={handleClickStop}>
                        Stop
                    </Button>

                    <S.ProcessingAnimation>
                        <Lottie
                            options={{
                                loop: true,
                                autoplay: true,
                                animationData: processingAnimationData,
                                rendererSettings: {
                                    preserveAspectRatio: 'xMidYMid slice',
                                },
                            }}
                        />
                    </S.ProcessingAnimation>
                </S.Processing>
            )}
        </S.BannerWrapper>
    )
}
