import styled from 'styled-components'
import { colors, getFontStyle } from '@diversioteam/diversio-ds'

export const Container = styled.div`
    width: 732px;
    margin-left: 128px;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    background-color: transparent;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

export const Header = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 30px 20px 30px;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    background-color: ${colors.primaryWhite};
`

export const Title = styled.h1`
    color: ${colors.primary90};

    ${getFontStyle('h2Semibold')}
`

export const List = styled.ul`
    width: 100%;
    margin: 0;
    padding: 0;
    max-height: calc(100vh - 116px);
    overflow-y: auto;
    background-color: ${colors.primaryWhite};
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
`

export const ListItem = styled.li`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 25px;
    padding: 0 30px;

    &:not(:last-child) {
        border-bottom: 1px solid rgb(222, 217, 217);
    }
`

export const InsightTitle = styled.p`
    margin-block-start: 0;
    margin-block-end: 0;
    width: 472px;
    padding: 18px 0;
    color: ${colors.primary90};

    ${getFontStyle('p02Regular')}
`

export const ListItemCustom = styled(ListItem)`
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    padding-bottom: 30px;
    background-color: ${colors.primary10};

    ${InsightTitle} {
        width: auto;
    }
`

export const Status = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 8px;
    gap: 8px;
`

export const StatusText = styled.p`
    margin-block-start: 0;
    margin-block-end: 0;
    color: ${colors.primary90};

    ${getFontStyle('captionRegular')}
`
