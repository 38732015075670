import React from 'react'
import { Button } from '@diversioteam/diversio-ds'

import { SwitchModalProps } from './../versionSwitchModal.types'
import * as S from './../versionSwitchModal.styles'

export const V1SwitchModal = ({ open = false, onDismiss, onProceed }: SwitchModalProps) => {
    return (
        <S.Modal closeButtonDisabled={false} open={open}>
            <S.Image $version="v1" />

            <S.Title data-testid="v1-switch-modal-title">A note about the previous design</S.Title>

            <S.Subtitle data-testid="v1-switch-modal-subtitle">
                Certain features in our new design, like enhanced benchmarking, advanced filters, and custom survey
                queries, are not present in the older version. For access to all features, we suggest using the new
                design. If you revert to the previous version, be aware of these feature limitations.
            </S.Subtitle>

            <S.Actions>
                <Button color="secondary" onClick={onDismiss}>
                    Dismiss
                </Button>

                <Button onClick={onProceed}>Go to Previous Design</Button>
            </S.Actions>
        </S.Modal>
    )
}
