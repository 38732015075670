import React, {Component} from 'react';
import { Actions } from '../../actions';
import { connect } from 'react-redux';
import {Redirect} from "react-router-dom";
import { withTranslation } from 'react-i18next';
import Register from "../../components/Auth/register";
import _ from 'underscore';
import queryString from 'query-string'

class RegisterContainer extends Component {
    state = {
        valueUrl: null,
        token: null,
        filledEmail: null,
    };

    componentDidMount() {
        this._parseUrl();
    }

    _parseUrl = () => {
        let url = this.props.location.pathname || "";

        const queryParams = _.getQueryParams(url);
        const tokenKey = Object.keys(queryParams).find(k => k.startsWith('register/'))
        const token = tokenKey ? tokenKey.replace('register/','') : null;
        this.setState({
            token: token,
        });
        if (queryParams.email) {
            this.setState({
                filledEmail: queryParams.email,
            });
        }
    }

    _handleOnSubmit (values) {

        const { filledEmail } = this.state;

        this.props.doRegistration({
            username: values.username,
            password: values.password,
            password_confirm: values.confirmPassword,
            email: filledEmail ? filledEmail : values.email,
            token: this.state.token,
        });
    }

    render() {
        const { from } = this.props.location.state || { from: { pathname: '/' } };
        const { to } = queryString.parse(location.search)
        const { t } = this.props;

        if (this.props.isAuthenticated) {
            return <Redirect to={to || from} />
        }

        if (this.props.isRegistrationDone) {
                return <Redirect to={{
                    pathname: "/login",
                    state: { message: t("AUTH.LOGIN.REGISTER_SUCCESS") }
             }} />
        }

        return <Register 
            filledEmail={this.state.filledEmail}
            onSubmit={this._handleOnSubmit.bind(this)}/>;
    }
}

const mapStateToProps = state => {
    return {
        ...state.auth
    }
};

export default connect(mapStateToProps, Actions)(withTranslation()(RegisterContainer));
