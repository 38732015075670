import React from 'react'
import { SavedProgramSkeleton } from "@diversioteam/diversio-ds"

export const SavedProgramListSkeleton = ({ numberOfElements }) => {
  const array = new Array(numberOfElements).fill('');

  return array.map((_element, index) => {
    return (
        <SavedProgramSkeleton key={index} />
      )
  })
}