import React, {Component} from 'react';
import classNames from "classnames";
import {Cell, Pie, PieChart, ResponsiveContainer, Sector, Tooltip} from "recharts";
import {PiePlaceholder} from '../../../utils/Placeholders';
import './index.scss';
import * as m from '../../../../translations/mapping';
import { mapT } from '../../../../translations/utils';
import { movePntsToEnd } from '../../../../utils'; 
import { INSUFFICIENT_DATA } from 'components/AnalyzeV2/Inclusion/Heatmap/heatmap.utils';


const renderActiveShape = (props) => {
    const {
        cx, cy, innerRadius, outerRadius, startAngle, endAngle,
        fill
    } = props;

    return (
        <g>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                startAngle={0}
                endAngle={360}
                fill={fill}
            />
        </g>
    );
};


class PiChart extends Component {

    getColor = (value) => {
        if (value === INSUFFICIENT_DATA) {
            return 'gray';
        }
    }

    valueCalc = (value) => {
        if (value === INSUFFICIENT_DATA) {
            return '';
        } else {
            return (value ? parseFloat(value).toFixed(1) : 0) + "%";
        }
    }

    render() {
        const { clickCallback, selected, profile } = this.props;
        const sortedPieData = movePntsToEnd(this.props.data);

        if ( (!(sortedPieData && sortedPieData.length)) || (sortedPieData.every(e => e.value == 0)) ) {
            // this.props.showMessage({type: "error", message: "Data cannot be displayed, minimum threshold not met."});
            return (
                <div className="pie-container">
                    <PiePlaceholder isLoading={false}/>
                </div>
            )
        }
        return (
            <div className="bespoke-pie-chart" onClick={clickCallback}>
                { /* These two divs ensure a dynamic number of pies can be rendered */ }
                { /* See https://github.com/recharts/recharts/issues/854#issuecomment-327587504*/ }
                <div style={{ position: 'relative', height: "125px" }} >
                    <div style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}>
                        <ResponsiveContainer>
                            <PieChart>
                                <Pie
                                    stroke="none"
                                    blendstroke
                                    isAnimationActive={false}
                                    activeShape={renderActiveShape}
                                    data={sortedPieData}
                                    innerRadius={this.props.innerRadius}
                                    outerRadius={this.props.outerRadius}
                                    fill="#8884d8"
                                    className="bespoke-pie-chart__pie"
                                >
                                    {this.props.data.map((entry, index) => {
                                        return <Cell key={index} fill={this.props.colors[index]}/>
                                    })}
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                </div>           
                
                <div className="bespoke-pie-chart__legend-container">
                    <div className={"bespoke-pie-chart__label"}> {this.props.label} </div>
                    <div className={"bespoke-pie-chart__all-labels"}>
                        {
                            sortedPieData.map((entry, index) => {
                                return (
                                    <div key={index}
                                        className="bespoke-pie-chart__pie-label">
                                        <div className="bespoke-pie-chart__label-container">
                                            <div className={"bespoke-pie-chart__pie-label-dot-wrap"}>
                                                <div className={"bespoke-pie-chart__pie-label-dot"}
                                                    style={{backgroundColor: this.props.colors[index % this.props.colors.length]}}/>
                                            </div>
                                            {`${
                                                (profile.email == 'amazing_investor_demo_2@diversio.com' && entry.name == 'White') ?
                                                'Dominant group' : 
                                                entry.name
                                            }`}
                                        </div>

                                        <div className={classNames(["bespoke-pie-chart__score", this.getColor(entry.value)])}>

                                            {`${this.valueCalc(entry.value)}`}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>

        );
    };
};

export default PiChart;
