import React, {Component} from 'react';
import './index.scss';
import Form, {FormInputTypes} from '../../utils/Form';
import {validatePassword} from "../../Password/util";

class Password extends Component {
    getFormInputs() {
        return [
            [{
                name: 'old_password',  // required
                label: 'Old Password',  // required
                required: true,
                type: FormInputTypes.password,  // required
            }],
            [{
                name: 'new_password',  // required
                label: 'New Password',  // required
                required: true,
                type: FormInputTypes.password,  // required
                validate: validatePassword
            }],
            [{
                name: 'new_password_confirm',  // required
                label: 'Confirm New Password',  // required
                required: true,
                type: FormInputTypes.password,  // required
                validate: (val, form) => {
                    if (form['new_password'] !== val) {
                        return ['Passwords do not match']
                    }
                    return []
                }
            }]
        ];
    }

    onFormSubmit(form){
        this.props.doChangePassword(form);
    }

    render() {
        return (
            <div id="password">
                <h2 className="paragraph_1 bold">Password</h2>

                <Form inputs={this.getFormInputs()} onSubmit={this.onFormSubmit.bind(this)} submitLabel={'Change Password'}/>
            </div>
        );
    }
}

export default Password;