import { TextField as DdsTextField, getFontStyle, Button as DdsButton } from '@diversioteam/diversio-ds'
import styled from 'styled-components'

export const VerificationCard = styled.form`
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export const Form = styled.form`
    display: flex;
    gap: 10px;
    margin-bottom: 32px;
`

export const TextField = styled(DdsTextField)`
    && {
        ${(props) => props.hidden && 'display: none;'};
        min-width: 350px;
    }
    .MuiOutlinedInput-root {
        background-color: rgba(247, 247, 255, 0.5);
        overflow: hidden;
    }
`

export const Button = styled(DdsButton)`
    box-sizing: border-box;
`

export const Subtitle = styled.p`
    margin: 0 0 18px;
    color: ${({ theme }) => theme.colors.primary90};

    ${getFontStyle('p02Medium')}
`

export { Title, Description } from '../account.styles'
