// const COLORS = [
//     "#5E77FF",
//     "#59D0FF",
//     "#59FBCB",
//     "#FF7AFF",
//     "#9F59FF"
// ];

const COLORS = [
    "#D7CEE4",
    "#CFB4F7",
    "#6221EA",
    "#0725E8",
    "#B6BFF6",
    "#FEBECD",
    "#FBC02D",
    "#109CF1",
    "#FF2455",
    "#FF8A00",
    "#8F40EB",
    "#AF5EEC",
    "#CA7DEF",
    "#DF9CF3",
    "#F1BCF8",
    "#FFDCFF",
    "#FBC4ED",
    "#F8ABD6",
    "#F591BC",
    "#F1779E",
    "#E95D7E",
    "#DE425B",
    "#FF005B",
    "#FF533B",
    "#FF811D",
    "#FFA600",
];

const defaultState = COLORS;


export default (state = defaultState, action) => {
    try {

        switch(action.type) {
            default:
                return state;

        }

    } catch(error) {
        console.error(`Error in colors reducer: ${ error.message || error.code || error }`, error);
    }

};
