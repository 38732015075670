import { getFontStyle } from '@diversioteam/diversio-ds'
import styled from 'styled-components'

import { media } from 'styles/theme/mediaQueries'

export const BenchmarkSwitchWrapper = styled.div`
    display: flex;
    align-items: center;

    &:first-child {
        margin-left: 60px;
    }

    .dds-heat-map-table-body-cell {
        padding: 0;
        height: auto;

        &__text {
            color: ${({ theme }) => theme.colors.secondary300};

            ${getFontStyle('footRegular')}
        }
    }
`

export const Info = styled.div`
    display: none;

    ${media.md} {
        display: block;
        margin-left: 14px;
    }
`

export const InfoLabel = styled.div`
    margin-bottom: 4px;
    color: ${({ theme }) => theme.colors.secondary500};

    ${getFontStyle('footRegular')}
`
