import React from 'react'
import { AlertIcon, colors } from '@diversioteam/diversio-ds'

import { HeroExpirationProps } from './heroExpiration.types'
import * as S from './heroExpiration.styles'

export const HeroExpiration = ({ daysLeft }: HeroExpirationProps) => {
    const daysLeftToRender = daysLeft < 0 ? 0 : daysLeft

    return (
        <S.HeroExpirationWrapper>
            <S.Title>
                {daysLeftToRender} {daysLeftToRender === 1 ? 'day' : 'days'} left{' '}
                <AlertIcon type="bold" width={24} height={24} color={colors.lobsterSharp} />
            </S.Title>

            <S.Subtitle>until your access expires</S.Subtitle>
        </S.HeroExpirationWrapper>
    )
}
