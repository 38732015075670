import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { AuthErrorResponse, AuthResponse, RequestPasswordResetPayload } from 'api/actions/auth/authActions.types'
import { requestPasswordResetAction } from 'api/actions/auth/authActions'
import { Mutations } from 'api/mutations.enum'

export const useRequestPasswordReset = () => {
    const mutation = useMutation<AuthResponse, AxiosError<AuthErrorResponse>, RequestPasswordResetPayload>({
        mutationKey: [Mutations.requestResetPassword],
        mutationFn: requestPasswordResetAction,
    })

    return mutation
}
