import { useMutation } from '@tanstack/react-query'
import mixpanel from 'mixpanel-browser'
import { loadInclusionData } from 'sagas/network'
import { stringifyQueryParams } from 'utils'

import { InclusionDataProps } from 'hooks/useInclusionData'
import { Mutations } from 'api/mutations.enum'
import TAXONOMIES from 'utils/taxonomies'

export const useExportInclusionData = (params: InclusionDataProps) => {
    const mutation = useMutation<string, Error, void>({
        mutationKey: [Mutations.exportInclusionData, params],
        mutationFn: () => {
            return loadInclusionData(stringifyQueryParams(params), 'file')
        },
        onSuccess: () => {
            mixpanel.track(TAXONOMIES.ANALYZE_INCLUSION_DATA_EXPORT)
        },
    })

    return mutation
}
