import React from 'react'
import { Link } from 'react-router-dom'
import { SolutionsTab, SolutionsTabSkeleton } from '@diversioteam/diversio-ds'

import { useGetTools } from 'hooks/useGetTools/useGetTools'
import { useGetBlogs } from 'hooks/useGetBlogs'
import { withErrorBoundary } from 'config/withErrorBoundary/withErrorBoundary'
import { generateCompanyURI } from 'sagas/helpers/generateCompanyURI'
import { AppRoute } from 'routing/AppRoute.enum'

const SolutionsWithoutEB = () => {
    const blogsQuery = useGetBlogs()
    const toolsQuery = useGetTools()

    const isLoading = blogsQuery.isLoading || toolsQuery.isLoading
    const hasData = blogsQuery.data || toolsQuery.data

    if (isLoading) {
        return <SolutionsTabSkeleton data-testid="solutions-skeleton" />
    }

    if (!hasData) {
        return null
    }

    const blogs = blogsQuery.data?.map((blog) => ({ ...blog, target: '_blank', href: blog.url })) || []
    const tools = toolsQuery.data?.map((tool) => ({ ...tool, target: '_blank', description: '', href: tool.url })) || []

    return (
        <SolutionsTab
            blogs={blogs}
            programs={[]}
            tools={tools}
            buttonProps={{
                blogs: {
                    component: 'a',
                    target: '_blank',
                    href: 'https://diversio.com/resources/articles/',
                },
                programs: {
                    component: Link,
                    to: generateCompanyURI(AppRoute.Programs),
                },
                tools: {
                    component: Link,
                    to: generateCompanyURI(AppRoute.Tools),
                },
            }}
        />
    )
}

export const Solutions = withErrorBoundary(SolutionsWithoutEB, {})
