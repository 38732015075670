import { AxiosError, AxiosResponse } from 'axios'
import { Actions } from 'actions'

import { Store } from './../../../App.types'

export const responseSuccessInterceptor = (response: AxiosResponse) => response

export const responseFailureInterceptor = async (error: AxiosError, store: Store) => {
    if (error.response?.status === 401) {
        store.dispatch(Actions.doSignout())
    }

    return Promise.reject(error)
}
