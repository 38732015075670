import React, { useEffect, useMemo, useState } from 'react'
import { Skeleton as MuiSkeleton, TabContext as MuiTabContext } from '@material-ui/lab'
import { Button, DownloadIcon, TabsProps } from '@diversioteam/diversio-ds'

import { useGetFreeTextsFilters } from 'hooks/freeTexts/useGetFreeTextsFilters'

import { useFreeTextsState } from './hooks/useFreeTextsState'
import { InclusionCategory } from './InclusionCategory'
import { CustomCategory } from './CustomCategory'
import { FreeTextCategory } from './freeTexts.types'
import * as S from './freeTexts.styles'

export const FreeTexts = () => {
    const { onDownload } = useFreeTextsState()
    const queryFreeTextsFilters = useGetFreeTextsFilters()

    const isLoading = queryFreeTextsFilters.isLoading

    const hasInclusion = !!queryFreeTextsFilters.data?.inclusionFilterData
    const hasBespoke = !!queryFreeTextsFilters.data?.bespokeFreeText?.bespoke
    const hasEngagement = !!queryFreeTextsFilters.data?.bespokeFreeText?.engagement

    const availableCategories: FreeTextCategory[] = useMemo(() => {
        const categories: FreeTextCategory[] = []

        if (hasInclusion) {
            categories.push(FreeTextCategory.Inclusion)
        }

        if (hasBespoke) {
            categories.push(FreeTextCategory.Bespoke)
        }

        if (hasEngagement) {
            categories.push(FreeTextCategory.Engagement)
        }

        return categories
    }, [hasInclusion, hasBespoke, hasEngagement])

    const hasMultipleCategories = availableCategories.length > 1

    const [activeCategoryTab, setActiveCategoryTab] = useState(availableCategories[0])

    useEffect(() => {
        setActiveCategoryTab(availableCategories[0])
    }, [availableCategories])

    const handleChangeTab: TabsProps['onChange'] = (_, value) => {
        setActiveCategoryTab(value)
    }

    if (!activeCategoryTab) {
        if (isLoading) {
            return (
                <S.FreeTextsWrapper>
                    <MuiSkeleton variant="rect" width="100%" height={1200} data-testid="free-texts-skeleton" />
                </S.FreeTextsWrapper>
            )
        } else {
            return null
        }
    }

    return (
        <S.FreeTextsWrapper>
            <MuiTabContext value={activeCategoryTab}>
                {hasMultipleCategories && (
                    <S.Topbar>
                        <S.TabsWrapper size="medium" onChange={handleChangeTab}>
                            {availableCategories.map((category) => (
                                <S.Tab key={category} label={category} value={category} disableRipple />
                            ))}
                        </S.TabsWrapper>

                        <Button
                            color="transparent"
                            size="small"
                            rightIcon={<DownloadIcon type="bold" />}
                            onClick={() => onDownload(activeCategoryTab)}
                        >
                            CSV
                        </Button>
                    </S.Topbar>
                )}

                {hasInclusion && (
                    <InclusionCategory
                        hasMultipleCategories={hasMultipleCategories}
                        data-testid="free-texts-inclusion"
                    />
                )}

                {hasBespoke && (
                    <CustomCategory
                        category="bespoke"
                        hasMultipleCategories={hasMultipleCategories}
                        isActive={activeCategoryTab === FreeTextCategory.Bespoke}
                        data-testid="free-texts-bespoke"
                    />
                )}

                {hasEngagement && (
                    <CustomCategory
                        category="engagement"
                        hasMultipleCategories={hasMultipleCategories}
                        isActive={activeCategoryTab === FreeTextCategory.Engagement}
                        data-testid="free-texts-engagement"
                    />
                )}
            </MuiTabContext>
        </S.FreeTextsWrapper>
    )
}
