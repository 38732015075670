import { AxiosError } from 'axios'
import { UseQueryOptions } from '@tanstack/react-query'

import { GetJobAdvertDetailResponse } from 'api/actions/lumi/lumiActions.types'
import { useQuery } from 'hooks/useQuery'
import { Queries } from 'api/actions/lumi/queries.enum'
import { getJobAdvertDetailAction } from 'api/actions/lumi/lumiActions'

export const useGetJobAdvertDetail = (jobId: string, options?: UseQueryOptions) => {
    return useQuery<GetJobAdvertDetailResponse, AxiosError>({
        queryKey: [Queries.getJobAdvertDetail],
        queryFn: () => getJobAdvertDetailAction(jobId),
        enabled: !!jobId && options?.enabled,
        refetchInterval: 10 * 1000,
    })
}
