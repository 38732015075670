import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Actions } from '../actions';

class UploadCSV extends Component {
    constructor (props) {
        super(props);
        this.inputFile = React.createRef();
    }

    _uploadCSV () {
        var formData = new FormData();
        if (this.inputFile.current.files[0]) {
            formData.append("file", this.inputFile.current.files[0]);
            this.props.doUploadCsv(formData);
        }
    }

    render () {
        return (
            <div id={"upload-csv"}>
                <div className="Header"> Upload CSV </div>
                <input type="file" ref={this.inputFile}/>
                <button onClick={this._uploadCSV.bind(this)}>Submit</button>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        ...state.auth
    }
};

export default connect(mapStateToProps, Actions)(UploadCSV);
