import { diversioApiV2 } from 'api/axios/axiosInstance'

import { CreateGoalPayload, GetInsightDataResponse, GetSolutionGoalCardOptionsResponse } from './goalsActions.types'
import { endpoints } from './endpoints'

export const getSolutionGoalCardOptions = async (): Promise<GetSolutionGoalCardOptionsResponse> => {
    const response = await diversioApiV2.get(endpoints.goalsOptions)

    return response.data
}

export const createGoal = async (body: CreateGoalPayload): Promise<void> => {
    const response = await diversioApiV2.post(endpoints.myGoal, body)

    return response.data
}

export const getInsightData = async (): Promise<GetInsightDataResponse> => {
    const response = await diversioApiV2.get(endpoints.insightData)

    return response.data
}
