import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { FeedbackCard, HarassmentRiskCard, OnboardingCard, HarassmentRiskCardProps } from '@diversioteam/diversio-ds'
import { Autoplay, Pagination } from 'swiper/modules'

import { useGetRiskBanner } from 'hooks/useGetRiskBanner'
import { useGetProgressBanner } from 'hooks/useGetProgressBanner'
import { withErrorBoundary } from 'config/withErrorBoundary/withErrorBoundary'
import { useUserInformation } from 'hooks/useUserInformation'
import { AppRoute } from 'routing/AppRoute.enum'
import { GetRiskBannerResponse } from 'api/actions/home/riskBanner/riskBannerActions.types'

import { BannerCarouselProps } from './bannerCarousel.types'
import * as S from './bannerCarousel.styles'

const BannerCarouselWithoutEB = ({ isFirstLogin, handleShowOnboarding, onViewDisclaimer }: BannerCarouselProps) => {
    const { fullname } = useUserInformation()
    const { data: riskData, isLoading: isRiskBannerLoading } = useGetRiskBanner()
    const { data: progressData, isLoading: isProgressBannerLoading } = useGetProgressBanner()

    const onboardingComponent = useMemo(
        () => (
            <OnboardingCard
                key={1}
                name={fullname || undefined}
                linkProps={{
                    component: 'div',
                    onClick: handleShowOnboarding,
                }}
                isFirstLogin={isFirstLogin}
            />
        ),
        [],
    )

    if (isRiskBannerLoading || isProgressBannerLoading) {
        return <S.Skeleton variant="rect" height={180} data-testid="banner-carousel-skeleton" />
    }

    const hasProgressData = progressData && progressData.length > 0

    const progressComponents = hasProgressData
        ? progressData.map((progress, id) => <FeedbackCard key={id + 2} title={progress.text} />)
        : []

    const isRiskDataAvailable = (data?: GetRiskBannerResponse): data is HarassmentRiskCardProps<'a'> => {
        if (!data) {
            return false
        }

        return 'title' in data
    }

    const harassmentComponent = isRiskDataAvailable(riskData)
        ? [
              <HarassmentRiskCard
                  key={100}
                  {...riskData}
                  onViewDisclaimer={onViewDisclaimer}
                  linkProps={{
                      component: Link,
                      to: AppRoute.SolutionGoals,
                  }}
              />,
          ]
        : []

    return (
        <S.Carousel
            elements={[...harassmentComponent, onboardingComponent, ...progressComponents]}
            title={undefined}
            topNavigation={false}
            swiperProps={{
                loop: true,
                autoplay: {
                    delay: 4000,
                    pauseOnMouseEnter: true,
                },
                modules: [Autoplay, Pagination],
            }}
            data-testid="banner-carousel"
        />
    )
}

export const BannerCarousel = withErrorBoundary(BannerCarouselWithoutEB, {})
