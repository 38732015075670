import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { Box, Typography } from '@material-ui/core'
import {
    Backdrop,
    CategoryFilter,
    EnterpriseFilter,
    DiversityFilterPanel,
    IntersectionalityFilterDeleteDialog,
    filterCascaderSelectedValues,
    UserPrivacyShadedIcon,
    Tooltip,
    InfoIcon,
    ClockIcon,
    colors,
    CategoryFilterState,
    clearCascaderSelectedValues,
} from '@diversioteam/diversio-ds'
import {
    IntersectionalityFilterRef,
    Profile,
} from '@diversioteam/diversio-ds/dist/components/core/CascaderFilters/IntersectionalityFilter/intersectionalityFilter.types'
import { _btoa_json_encode } from 'components/Analyze/Filters/utils'
import { parseYearQuarter } from 'utils'
import { useParams } from 'react-router-dom'
import { ErrorBoundary } from '@sentry/react'
import mixpanel from 'mixpanel-browser'

import { profilesColors } from 'utils/profilesCors'
import { withErrorBoundary } from 'config/withErrorBoundary/withErrorBoundary'
import { useGetCompanyFilters } from 'hooks/companyFilters/useGetCompanyFilters'
import { useGetCompanyProfiles } from 'hooks/companyProfiles/useGetCompanyProfiles'
import { useGetCompanyProfilesOptions } from 'hooks/companyProfiles/useGetCompanyProfilesOptions'
import { useCreateCompanyProfile } from 'hooks/companyProfiles/useCreateCompanyProfile'
import { useUpdateCompanyProfile } from 'hooks/companyProfiles/useUpdateCompanyProfile'
import { useDeleteCompanyProfile } from 'hooks/companyProfiles/useDeleteCompanyProfile'
import { AnalyzeView } from 'types/analyze.enum'
import { Queries } from 'api/queries.enum'
import { useSetPrimaryBenchmark } from 'hooks/benchmarks/useSetPrimaryBenchmark'
import { useBenchmarks } from 'hooks/benchmarks/useBenchmarks'
import { generateCompanyURI } from 'sagas/helpers/generateCompanyURI'
import { DiversityParams } from '../diversity.types'
import { useBreadcrumbs } from 'hooks/useBreadcrumbs'
import { useMessage } from 'hooks/useMessage'
import { useSurveys } from 'hooks/useSurveys/useSurveys'
import { AppRoute } from 'routing/AppRoute.enum'
import TAXONOMIES from 'utils/taxonomies'

import { useFilters } from './../../hooks/useFilters'
import { IntersectionalityFilters } from './../../IntersectionalityFilters'
import { FilterPaneSkeleton } from './filterPaneSkeleton'
import { FilterPaneProps } from './filterPane.types'

import './index.scss'

const FilterPaneWithoutEB = ({ handleShowProfilesPage, handleOnTabChange, selectedTab }: FilterPaneProps) => {
    const queryClient = useQueryClient()
    const { showMessage } = useMessage()

    const {
        filtersOpen,
        setFiltersOpen,
        demographic,
        resultsOverTime,
        selectedFilters,
        setSelectedFilters,
        setSelectedSurveysFilter,
        setDemographic,
        setResultsOverTime,
    } = useFilters()
    const { survey } = useSurveys()

    const { tab, overTime } = useParams<DiversityParams>()
    const { setLinks } = useBreadcrumbs()

    // Use memoized value to prevent API refetch
    const params = useMemo(() => {
        return {
            year: parseYearQuarter(survey),
        }
    }, [survey])

    const [surveyFilters, setSurveyFilters] = useState<string[]>([])

    const [activeProfileId, setActiveProfileId] = useState<string | null>(null)
    const [profileDeleteDialogOpen, setProfileDeleteDialogOpen] = useState(false)
    const [profileToDelete, setProfileToDelete] = useState<string | null>(null)
    const [intersectionalityFiltersOpen, setIntersectionalityFiltersOpen] = useState(false)
    const intersectionalityFilterRef = useRef<IntersectionalityFilterRef | null>(null)

    const {
        primaryNationalBenchmark,
        primaryIndustryBenchmark,
        queryNationalBenchmarks,
        queryIndustryBenchmarks,
        secondaryIndustryBenchmark,
        onChangeSecondaryIndustryBenchmark,
    } = useBenchmarks()
    const mutationSetPrimaryNationalBenchmark = useSetPrimaryBenchmark('national')

    const queryGetCompanyFilters = useGetCompanyFilters(params)
    const queryGetCompanyProfiles = useGetCompanyProfiles(AnalyzeView.Diversity, params)
    const queryGetCompanyProfilesOptions = useGetCompanyProfilesOptions(AnalyzeView.Diversity, params)
    const mutationCreateCompanyProfile = useCreateCompanyProfile(AnalyzeView.Diversity, params)
    const mutationUpdateCompanyProfile = useUpdateCompanyProfile(AnalyzeView.Diversity, params)
    const mutationDeleteCompanyProfile = useDeleteCompanyProfile(AnalyzeView.Diversity, params)

    const availableDemographics = queryGetCompanyFilters.data?.filtersPane.demographics || []

    const getDemographic = (tab: string) => {
        return availableDemographics.find(({ key, uuid }) => [key, uuid].includes(tab))
    }

    const selectedDemographic = getDemographic(tab)

    useEffect(() => {
        const links = [
            {
                label: 'Diversity',
                to: generateCompanyURI(AppRoute.AnalyzeDiversity),
            },
            selectedDemographic
                ? {
                      label: selectedDemographic.name,
                      to: generateCompanyURI(`${AppRoute.AnalyzeDiversity}/${tab}${location.search}`),
                  }
                : [],
        ].flat()

        if (overTime) {
            links.push({
                label: 'Results Over Time',
                to: generateCompanyURI(`${AppRoute.AnalyzeDiversity}/${tab}/results-over-time/${location.search}`),
            })
        }

        setLinks(links)
    }, [tab, location.search, overTime, selectedDemographic])

    useEffect(() => {
        const parsedSurveys = surveyFilters.map((survey) => {
            return survey.split(', ').reverse().join('')
        })

        setSelectedSurveysFilter?.(_btoa_json_encode(parsedSurveys))
    }, [surveyFilters, setSelectedSurveysFilter])

    const handleDemographicChange = useCallback(
        (option: string | null = '') => {
            const defaultDemographic = availableDemographics?.[0]
            const selectedDemographic = getDemographic(option || '') || defaultDemographic

            if (selectedDemographic) {
                const selectedDemographicOption = selectedDemographic.key || defaultDemographic?.key

                if (selectedDemographicOption) {
                    handleOnTabChange(selectedDemographicOption, selectedDemographic.type)
                }

                setDemographic(selectedDemographic)
            }
        },
        [queryGetCompanyFilters, handleOnTabChange, setDemographic, availableDemographics],
    )

    useEffect(() => {
        handleDemographicChange(selectedTab)
    }, [selectedTab, handleDemographicChange])

    const industryDataSelectPropsValues = useMemo(() => {
        if (primaryIndustryBenchmark) {
            if (secondaryIndustryBenchmark) {
                return [primaryIndustryBenchmark.value, secondaryIndustryBenchmark.value]
            }

            return [primaryIndustryBenchmark.value]
        }

        return []
    }, [primaryIndustryBenchmark, secondaryIndustryBenchmark])

    const industryBenchmarkSelectOptions = useMemo(() => {
        if (queryIndustryBenchmarks.data) {
            return queryIndustryBenchmarks.data.map(({ label, value, uuid }) => ({
                label,
                value,
                ...(primaryIndustryBenchmark?.uuid === uuid && {
                    disabled: true,
                }),
            }))
        }

        return []
    }, [queryIndustryBenchmarks.data, primaryIndustryBenchmark])

    const hasData = queryGetCompanyFilters.data && queryGetCompanyProfiles.data && queryGetCompanyProfilesOptions.data

    const handleApplyNationalData = (options?: string[]) => {
        if (options) {
            mutationSetPrimaryNationalBenchmark.mutate(options[0])
        }
    }

    const handleApplyIndustryData = (options?: string[]) => {
        if (!options) {
            return
        }

        if (!primaryIndustryBenchmark) {
            return
        }

        const secondaryOptionID = options.find((option) => option !== primaryIndustryBenchmark.uuid)
        const secondaryOption = queryIndustryBenchmarks.data?.find(({ uuid }) => uuid === secondaryOptionID)

        secondaryOption
            ? onChangeSecondaryIndustryBenchmark?.(secondaryOption)
            : onChangeSecondaryIndustryBenchmark?.(undefined)
    }

    const handleMultiSurveysApply = (options?: string[]) => {
        // Since the whitelabel options can have varied labels, this function makes
        // sure that the correct survey from the API is plucked based on the selected
        // option label and reparse the value to the correct format i.e. Q2, 2023 which
        // is then automatically handled by API middleware.
        const parsedOptions = options?.map((option) => {
            const selectedSurvey = queryGetCompanyFilters.data?.surveys.find(
                (surveyOption) => surveyOption.name === option,
            )

            return `Q${selectedSurvey?.quarter}, ${selectedSurvey?.year}`
        })

        setSurveyFilters(parsedOptions || [])
    }

    const handleAddNewProfile = () => {
        setActiveProfileId(null)

        handleToggleIntersectionalityFilter({ hasClickedNewProfile: true })
    }

    const handleEditProfile = (id: string) => {
        setActiveProfileId(id)

        handleToggleIntersectionalityFilter()
    }

    const handleClickDeleteProfile = (id: string) => {
        setProfileToDelete(id)

        setProfileDeleteDialogOpen(true)
    }

    const handleConfirmDeleteProfile = () => {
        if (!profileToDelete) {
            return
        }

        mutationDeleteCompanyProfile.mutate(profileToDelete, {
            onSuccess: async () => {
                await queryClient.invalidateQueries({
                    queryKey: [Queries.getDiversityCompanyProfiles, params],
                })

                setProfileDeleteDialogOpen(false)
            },
            onError: () => {
                showMessage({ message: 'Profile deletion was not possible', type: 'error' })
            },
        })
    }

    const handleToggleFilters = () => {
        setFiltersOpen(!filtersOpen)
    }

    const handleToggleIntersectionalityFilter = (options?: { hasClickedNewProfile?: boolean }) => {
        setIntersectionalityFiltersOpen((prevState) => !prevState)

        if (options?.hasClickedNewProfile) {
            intersectionalityFilterRef.current?.onCreateNewProfile()
        }
    }

    const handleFilterDelete = (filter: string) => {
        setSelectedFilters?.(filterCascaderSelectedValues(selectedFilters || [], filter))
    }

    const handleShowResultsOverTime = () => {
        mixpanel.track(TAXONOMIES.ANALYZE_DIVERSITY_RESULTS_OVER_TIME_TOGGLE)

        setResultsOverTime(!resultsOverTime)
    }

    const handleSubmitProfiles = async (profiles: Profile[]) => {
        // Method that returns all promises for create requests
        // If a profile is already created, the request is changed to update
        const profilesCreateUpdateMutations = () =>
            profiles.map(async (profile) => {
                const demographicsCategories = queryGetCompanyProfilesOptions.data?.filters.map(({ key }) => ({
                    name: key,
                    selected_options: null,
                }))

                const demographicsPayload = (demographicsCategories || []).map(({ name }) => {
                    const filter = profile.filters?.find((filter) => filter.key === name)

                    if (filter) {
                        return {
                            name,
                            selected_options: filter.options,
                        }
                    }

                    return {
                        name,
                        selected_options: null,
                    }
                })

                const payload = {
                    label: profile.name,
                    demographics: demographicsPayload,
                }

                if (!profile.id) {
                    return await mutationCreateCompanyProfile.mutateAsync(payload)
                }

                return await mutationUpdateCompanyProfile.mutateAsync({
                    id: profile.id,
                    values: payload,
                })
            })

        const deletedProfiles = queryGetCompanyProfiles.data?.profiles.flatMap(({ uuid }) => {
            if (profiles.find((profile) => profile.id === uuid)) {
                return []
            }

            return uuid
        })

        // Method that returns the list of promises to delete the profiles
        const profilesDeleteMutations = () =>
            deletedProfiles?.map(async (deletedProfile) => {
                return await mutationDeleteCompanyProfile.mutateAsync(deletedProfile, {
                    onError: () => {
                        showMessage({ message: 'Profile deletion was not possible', type: 'error' })
                    },
                })
            }) || []

        // This ensures that update/create happens before deleting profiles
        await Promise.all([...profilesCreateUpdateMutations()])
        await Promise.all([...profilesDeleteMutations()])

        setIntersectionalityFiltersOpen(false)

        await queryClient.invalidateQueries({
            queryKey: [Queries.getDiversityCompanyProfiles, params],
        })
    }

    if (hasData) {
        const demographicSelectOptions = queryGetCompanyFilters.data.filtersPane.demographics.map(
            ({ name, disabled, key, uuid }) => ({
                label: name,
                value: key || uuid,
                disabled,
                ...(disabled && ({ type: 'custom', icon: <UserPrivacyShadedIcon /> } as const)),
            }),
        )

        // label and value needs to be same in order to show correct selected option inthe selectbox
        const surveyOptions = queryGetCompanyFilters.data.surveys.map(({ name }) => ({
            label: name,
            value: name,
        }))

        const filtersTypeIsComplex = queryGetCompanyFilters.data?.filtersModal.some(
            ({ filtersType }) => filtersType === 'complex',
        )

        const enableResultsOverTime = queryGetCompanyFilters.data.surveys.length > 1

        const profileNameToDelete =
            queryGetCompanyProfiles.data.profiles.find(({ uuid }) => uuid === profileToDelete)?.label || ''

        const categoryFilterData = queryGetCompanyFilters.data.filtersModal.map(({ name, key, options }) => ({
            name,
            key,
            options: Array.isArray(options) ? options : [],
        }))

        const filtersPanelProfiles = queryGetCompanyProfiles.data.profiles.map(({ uuid, color, label, filters }) => ({
            id: uuid,
            color: profilesColors[color - 1],
            label,
            groups: filters.map(({ selectedOptions, ...rest }) => ({
                ...rest,
                options: selectedOptions,
            })),
        }))

        const profiles = queryGetCompanyProfiles.data.profiles.map(({ uuid, color, label, filters }) => ({
            id: uuid,
            color: profilesColors[color - 1],
            name: label,
            filters: filters.flatMap(({ selectedOptions, ...rest }) => ({
                ...rest,
                options: selectedOptions || [],
            })),
        }))

        const nextProfilesColors = profilesColors.flatMap((color, index) => {
            if (queryGetCompanyProfiles.data.profiles.find(({ color }) => color === index + 1)) {
                return []
            }

            return color
        })

        const demographicSelectFooterText = (
            <>
                {!!demographicSelectOptions.find(({ disabled }) => disabled) && (
                    <Box mb={1} display="flex">
                        <Box mr={1} alignSelf="flex-start">
                            <UserPrivacyShadedIcon width={16} height={16} />
                        </Box>

                        <Typography>Privacy Protected</Typography>
                    </Box>
                )}

                <Tooltip
                    title={
                        <Box>
                            <ClockIcon type="line" color={colors.secondary500} />

                            <Box mt={1}>
                                Try changing the survey Time Period for demographics available from surveys collected
                                over time.
                            </Box>
                        </Box>
                    }
                    variant="light"
                    size="medium"
                >
                    <Box display="flex">
                        <Box mr={1} alignSelf="flex-start">
                            <InfoIcon type="line" width={16} height={16} color={colors.secondary300} />
                        </Box>

                        <Typography>Demographic not listed?</Typography>
                    </Box>
                </Tooltip>
            </>
        )

        const getSurveyFilterLabels = (surveys: string[]): string[] => {
            return surveys.map(
                (survey) =>
                    queryGetCompanyFilters.data?.surveys.find(
                        (option) => `Q${option.quarter}, ${option.year}` === survey,
                    )?.name || '',
            )
        }

        const handleSurveysFiltersDelete = (filter: string) => {
            // Parse the value to correct format as surveyFilters to remove the deleted value
            const selectedSurvey = queryGetCompanyFilters.data?.surveys.find((option) => option.name === filter)

            if (selectedSurvey) {
                setSurveyFilters((prevState) =>
                    prevState.filter(
                        (prevFilter) => prevFilter !== `Q${selectedSurvey.quarter}, ${selectedSurvey.year}`,
                    ),
                )
            }
        }

        const handleClearFilters = () => {
            setSelectedFilters(clearCascaderSelectedValues(selectedFilters))
        }

        return (
            <>
                {!filtersTypeIsComplex && (
                    <ErrorBoundary>
                        <CategoryFilter
                            open={filtersOpen}
                            onSubmit={(data) => {
                                setSelectedFilters(data)
                                handleToggleFilters()
                            }}
                            onClose={handleToggleFilters}
                            data={categoryFilterData}
                            defaultSelected={selectedFilters as CategoryFilterState}
                        />
                    </ErrorBoundary>
                )}

                {filtersTypeIsComplex && (
                    <ErrorBoundary>
                        <EnterpriseFilter
                            data={queryGetCompanyFilters.data.filtersModal}
                            open={filtersOpen}
                            onSubmit={(data) => {
                                setSelectedFilters(data)
                                handleToggleFilters()
                            }}
                            onClose={handleToggleFilters}
                            defaultSelected={selectedFilters}
                        />
                    </ErrorBoundary>
                )}

                <IntersectionalityFilters
                    ref={intersectionalityFilterRef}
                    open={intersectionalityFiltersOpen}
                    data={queryGetCompanyProfilesOptions.data.filters}
                    profiles={profiles}
                    defaultActiveProfileId={activeProfileId || undefined}
                    nextProfilesColors={nextProfilesColors}
                    onSubmit={handleSubmitProfiles}
                    onClose={handleToggleIntersectionalityFilter}
                    key={survey?.name}
                />

                {profileToDelete && (
                    <ErrorBoundary>
                        <IntersectionalityFilterDeleteDialog
                            open={profileDeleteDialogOpen}
                            profileName={profileNameToDelete}
                            onClose={() => setProfileDeleteDialogOpen(false)}
                            onConfirm={handleConfirmDeleteProfile}
                            hideBackdrop={false}
                            BackdropComponent={Backdrop}
                        />
                    </ErrorBoundary>
                )}

                <ErrorBoundary>
                    <DiversityFilterPanel
                        profiles={filtersPanelProfiles}
                        isResultOverTimeActive={resultsOverTime}
                        enableResultsOverTime={enableResultsOverTime}
                        demographicsSelectProps={{
                            value: demographic?.key,
                            options: demographicSelectOptions,
                            onChange: (value) => {
                                mixpanel.track(TAXONOMIES.ANALYZE_DIVERSITY_DEMOGRAPHIC_CHANGE, {
                                    availableDemographic: demographicSelectOptions.map(({ value }) => value),
                                    nextDemographic: value,
                                    prevDemographic: demographic?.key,
                                })

                                handleDemographicChange(value)
                            },
                            footer: {
                                background: 'gray',
                                content: {
                                    text: demographicSelectFooterText,
                                },
                            },
                            popperProps: {
                                maxHeight: 350,
                            },
                        }}
                        nationalDataSelectProps={{
                            values: primaryNationalBenchmark ? [primaryNationalBenchmark?.value] : [],
                            options: queryNationalBenchmarks.data || [],
                            onApply: handleApplyNationalData,
                            disableAfterRootsSelected: 1,
                        }}
                        industryDataSelectProps={{
                            values: industryDataSelectPropsValues,
                            options: industryBenchmarkSelectOptions,
                            onApply: handleApplyIndustryData,
                            disableAfterRootsSelected: 2,
                        }}
                        multiSurveysSelectProps={{
                            values: getSurveyFilterLabels(surveyFilters),
                            options: surveyOptions,
                            disableAfterRootsSelected: 4,
                            onApply: handleMultiSurveysApply,
                        }}
                        onShowFilters={handleToggleFilters}
                        onEditProfile={handleEditProfile}
                        onDeleteProfile={handleClickDeleteProfile}
                        onAddNewProfile={handleAddNewProfile}
                        onShowResultsOverTime={handleShowResultsOverTime}
                        onShowProfilesPage={handleShowProfilesPage}
                        filters={selectedFilters}
                        onDeleteFilter={handleFilterDelete}
                        onClearFilters={handleClearFilters}
                        surveyFilters={getSurveyFilterLabels(surveyFilters)}
                        onSurveyFilterDelete={handleSurveysFiltersDelete}
                        onDeleteIndustryDataBenchmark={() => onChangeSecondaryIndustryBenchmark?.(undefined)}
                    />
                </ErrorBoundary>
            </>
        )
    }

    return <FilterPaneSkeleton />
}

export const FilterPane = withErrorBoundary(FilterPaneWithoutEB, {})
