import { useContext } from 'react'

import { FreeTextsStateContext } from './../context/FreeTextsStateContext'

export const useFreeTextsState = () => {
    const context = useContext(FreeTextsStateContext)

    if (context === undefined) {
        throw new Error('FreeTextsStateContext must be within FreeTextsStateContextController')
    }

    return context
}
