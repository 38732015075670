import styled from 'styled-components'
import { getFontStyle } from '@diversioteam/diversio-ds'

export const AverageImpactProgramTagWrapper = styled.div`
    display: flex;
    align-items: center;
`

export const LabelTextSecondary = styled.span`
    ${getFontStyle('captionRegular')};
`
