import { useDispatch } from 'react-redux'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { Actions } from 'actions'

import { saveRecommendedProgram } from 'api/actions/recommended/recommendedActions'
import { Mutations } from 'api/actions/recommended/mutations.enum'
import { Queries } from 'api/actions/recommended/queries.enum'
import {
    InfiniteGetRecommendedProgramsListResponseResponse,
    RecommendedProgram,
    SaveRecommendedProgramPayload,
    SaveRecommendedProgramResponse,
} from 'api/actions/recommended/recommendedActions.types'

export const useSaveRecommendedProgram = () => {
    const dispatch = useDispatch()
    const queryClient = useQueryClient()

    const mutation = useMutation<
        SaveRecommendedProgramResponse,
        AxiosError,
        SaveRecommendedProgramPayload,
        {
            previousPrograms?: InfiniteGetRecommendedProgramsListResponseResponse
        }
    >({
        mutationKey: [Mutations.saveRecommendedProgram],
        mutationFn: saveRecommendedProgram,
        onMutate: async ({ solution: id }) => {
            await queryClient.cancelQueries([Queries.getRecommendedProgramsList])

            const previousPrograms = queryClient.getQueryData<InfiniteGetRecommendedProgramsListResponseResponse>([
                Queries.getRecommendedProgramsList,
            ])

            const updateRecommendedProgram = (recommendedProgram: RecommendedProgram): RecommendedProgram =>
                recommendedProgram.id === id
                    ? {
                          ...recommendedProgram,
                          companySolution: {
                              id,
                              isActive: false,
                          },
                      }
                    : recommendedProgram

            if (previousPrograms) {
                queryClient.setQueryData([Queries.getRecommendedProgramsList], {
                    ...previousPrograms,
                    pages: previousPrograms.pages.map((previousPrograms) => ({
                        ...previousPrograms,
                        results: previousPrograms.results.map(updateRecommendedProgram),
                    })),
                })
            }

            return { previousPrograms }
        },
        onError: (err, newProgram, context) => {
            queryClient.setQueryData([Queries.getRecommendedProgramsList], context?.previousPrograms)
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries([Queries.getRecommendedProgramsList])

            dispatch(
                Actions.showToast({
                    type: 'success',
                    message: 'Program has been saved!',
                }),
            )
        },
    })

    return mutation
}
