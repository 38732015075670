import styled, { keyframes } from 'styled-components'

const flickerAnimation = keyframes`    
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
    `

export const TypeWriterWrapper = styled.span`
    .cursor {
        display: inline-block;
        height: 20px;
        margin-left: 2px;
        border-right: 2px solid;
        animation: ${flickerAnimation} 0.75s infinite;
    }
`
