import { QueryKey, UseQueryOptions, useQuery as useRQQuery } from '@tanstack/react-query'

import { getCompanyUUID } from '../utils/getCompanyUUID/getCompanyUUID'

import { useUserInformation } from './useUserInformation'

export const useQuery = <
    TQueryFnData = unknown,
    TError = unknown,
    TData = TQueryFnData,
    TQueryKey extends QueryKey = QueryKey,
>(
    options?: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
) => {
    const { isAuthenticated } = useUserInformation()

    const companyUUID = isAuthenticated && !!getCompanyUUID()

    const customEnabled = options?.enabled

    const enabled = !!companyUUID && customEnabled !== false

    const result = useRQQuery({
        ...options,
        enabled,
    })

    return result
}
