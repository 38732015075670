import React, {Component} from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import SlackAuth from './Slack'

class Oauth extends Component {
    render() {
        return (
            <div className={'oauth'}>
                <Switch>
                    <Route exact path={`${this.props.match.path}/slack`} component={SlackAuth}/>
                    <Route
                        path={'/'} component={()=>
                            <Route
                                path='/'
                                component={()=><Redirect to={{pathname: '/home'}}/>}/>
                        }
                    />
                </Switch>
            </div>
        );
    }
}

export default Oauth;
