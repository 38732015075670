import {categoryChoices} from "../../utils/constants";
import {mean,percent} from "../../utils/functions";

const singleChartSpec = {
    title: '',
    yAxis: '',
    precision: 0,
    range: [0, 'dataMax'],
    data:{
        men: {value: 3, valueDisplay: 3},
        women: {value: 3, valueDisplay: 3},
        undefine: {value: 3, valueDisplay: 3}
    },
    // discrepancy: discrepancyChoices.lowestToHighest
};

// const plusPercent = (percent=10) => (dataMax) =>{
//     return dataMax+dataMax*percent
// }

const showPercent = x => `${x}%`;

const ChartSpecification = [
    {
        category: categoryChoices.Structural,
        title: 'Average Number of Posts Over Selected Time Period',
        yAxis: 'Average Number',
        precision: 0,
        range: [0, 'dataMax'],
        populateData: function (gender, metadata) {
            return mean(gender.post_frequency)*metadata.calc_period_days;
        },
        displayValue: x=>x
        // focusAreaStr: (amount, moreGroup, lessGroup) => `On average, ${moreGroup} post ${amount} times more than ${lessGroup}. This suggests that ${moreGroup} feel more comfortable sharing their thoughts with the team.`
    },
    {
        category: categoryChoices.Structural,
        title: 'Average Proportion of Messages Sent after Working Hours (9am to 5pm)',
        yAxis: 'Average Percent',
        precision: 0,
        range: [0, 100],
        populateData: function (gender) {
            return percent(gender.after_hours);
        },
        displayValue: showPercent
        // focusAreaStr: (amount, moreGroup, lessGroup) => `${moreGroup} send ${amount} times more messages after hours than ${lessGroup}. This suggests that ${moreGroup} are more likely to work after hours.`
    },
    {
        // Calculated out of the provided channels
        category: categoryChoices.Structural,
        title: 'Average Number of Channels Posted In',
        yAxis: 'Average Number',
        precision: 0,
        range: [0, 'dataMax'],
        populateData: function (gender) {
            return mean(gender.n_channels);
        },
        displayValue: x=>x
        // focusAreaStr: (amount, moreGroup, lessGroup) => `On average, ${moreGroup} participate in ${amount} more channels than ${lessGroup}. This may indicate that ${moreGroup} are more integrated within the organization.`
    },
    {
        category: categoryChoices.Structural,
        title: 'Average Number of Members per Channel',
        yAxis: 'Average Number',
        precision: 0,
        range: [0, 'dataMax'],
        populateData: function (gender) {
            return mean(gender.channel_size);
        },
        displayValue: x=>x
        // focusAreaStr: (amount, moreGroup, lessGroup) => `${moreGroup} tend to participate in larger channels than ${lessGroup}, which suggests that ${lessGroup} are more likely to work in smaller groups.`
    },

    /////////////////////////
    //Tone and Content
    {
        // small change to 'average proportion'
        category: categoryChoices.ToneAndContent,
        title: 'Average Proportion of Posts Edited after Sharing',
        yAxis: 'Percent',
        precision: 0,
        range: [0, 100],
        populateData: function (gender) {
            return percent(gender.edited);
        },
        displayValue: showPercent
        // focusAreaStr: (amount, moreGroup, lessGroup) => `${moreGroup} edit their own posts ${amount} times more than ${lessGroup}. This suggests that ${moreGroup} are more self-reflective or less confident.`

    },
    {
        category: categoryChoices.ToneAndContent,
        title: 'Average Number of Words per Post',
        yAxis: 'Average Number',
        precision: 0,
        range: [0, 'dataMax'],
        populateData: function (gender) {
            return mean(gender.post_length);
        },
        displayValue: x=>x
        // focusAreaStr: (amount, moreGroup, lessGroup) => `On average, ${moreGroup} posts are ${amount} times longer than ${lessGroup}. This may indicate that ${moreGroup} have more formal relationships with co-workers and are less bonded with the team.`
    },
    {
        category: categoryChoices.ToneAndContent,
        title: 'Average Number of Emojis per Post',
        yAxis: 'Average Number',
        precision: 2,
        range: [0, 'dataMax'],
        populateData: function (gender) {
            return mean(gender.n_emojis);
        },
        displayValue: x=>x
        // focusAreaStr: (amount, moreGroup, lessGroup) => `${moreGroup} use ${amount} times more emojis than ${lessGroup}. This may point to ${lessGroup} having more formal relationships with co-workers and/or feeling less comfortable expressing sentiment.`
    },
    {
        category: categoryChoices.ToneAndContent,
        title: 'Average Sentiment of Emojis Used in Posts',
        yAxis: 'Percent Positive',
        precision: 0,
        range: [0, 100],
        populateData: function (gender) {
            return percent(gender.emoji_sentiment);
        },
        displayValue: showPercent
        // focusAreaStr: (amount, moreGroup, lessGroup) => `${moreGroup} use positive emojis ${amount} times more than ${lessGroup}. This could mean that ${lessGroup} experience the workplace more negatively than ${moreGroup}.`
    },

    /////////////////////////
    // Replies and Reactions
    {
        category: categoryChoices.RepliesAndReactions,
        title: 'Average Number of Replies Received per Post',
        yAxis: 'Average Number',
        precision: 0,
        range: [0, 'dataMax'],
        populateData: function (gender) {
            return mean(gender.n_replies);
        },
        displayValue: x=>x
        // focusAreaStr: (amount, moreGroup, lessGroup) => `On average, ${moreGroup} receive ${amount} times more replies on their posts than ${lessGroup}. This could mean that that ${lessGroup}’s opinions are subconsciously less valued or sought out.`

    },
    {
        category: categoryChoices.RepliesAndReactions,
        title: 'Average Number of Emojis Received per Post',
        yAxis: 'Average Number',
        precision: 2,
        range: [0, 'dataMax'],
        populateData: function (gender) {
            return mean(gender.n_reactions);
        },
        displayValue: x=>x
        // focusAreaStr: (amount, moreGroup, lessGroup) => `On average ${moreGroup} receive ${amount} times more emojis on their posts than ${lessGroup}. This could mean that ${lessGroup} are less included by the team.`
    },
    {
        category: categoryChoices.RepliesAndReactions,
        title: 'Average Number of Minutes it Takes to Receive First Reply to Post',
        yAxis: 'Average Number',
        precision: 0,
        range: [0, 'dataMax'],
        populateData: function (gender) {
            return mean(gender.reply_speed)*24*60;
        },
        displayValue: x=>x
        // focusAreaStr: (amount, moreGroup, lessGroup) => `${moreGroup} receive a reply to a post ${amount} times faster than ${lessGroup} on average. This may indicate that the team is generally less engaged and collaborative with ${lessGroup}.`
    },
    {
        category: categoryChoices.RepliesAndReactions,
        title: 'Average Sentiment of Emoji Reactions Received',
        yAxis: 'Percent Positive',
        precision: 0,
        range: [0, 100],
        populateData: function (gender) {
            return percent(gender.reaction_sentiment);
        },
        displayValue: showPercent
        // focusAreaStr: (amount, moreGroup, lessGroup) =>`Employees react with positive emojis to messages posted by ${moreGroup} ${amount} times more than to messages posted by ${lessGroup}. This points to the fact that ${lessGroup} may receive less validation and support from the team.`
    },
    /////////////////////////
    // Collaboration
    {
        category: categoryChoices.Collaboration,
        title: `Average Number of Replies Made on Others' Posts`,
        yAxis: 'Average Number',
        precision: 0,
        range: [0, 'dataMax'],
        populateData: function (gender, metadata) {
            return mean(gender.user_reply_frequency)*metadata.calc_period_days;
        },
        displayValue: x=>x
        // focusAreaStr: (amount, moreGroup, lessGroup) => `On average, ${moreGroup} reply to posts ${amount} times more than ${lessGroup}. This suggests that ${moreGroup} feel more comfortable contributing to team discussions or helping out fellow employees.`
    },
    {
        category: categoryChoices.Collaboration,
        title: `Average Length of Time Taken to Reply to Others' Posts`,
        yAxis: 'Minutes',
        precision: 0,
        range: [0, 'dataMax'],
        populateData: function (gender) {
            return mean(gender.user_reply_speed)*24*60;
        },
        displayValue: x=>x
        // focusAreaStr: (amount, moreGroup, lessGroup) => `On average, ${moreGroup} reply to posts ${amount} times faster than ${lessGroup}. This could mean that ${moreGroup} feel more comfortable jumping in and sharing their opinion.`

    },
    {
        category: categoryChoices.Collaboration,
        title: `Average Number of Emoji's Posted on Others' Posts`,
        yAxis: 'Average Number',
        precision: 0,
        range: [0, 'dataMax'],
        populateData: function (gender, metadata) {
            return mean(gender.user_react_frequency)*metadata.calc_period_days;
        },
        displayValue: x=>x
        // focusAreaStr: (amount, moreGroup, lessGroup) => `${moreGroup} post ${amount} times more emojis than ${lessGroup} on average. This may point to ${moreGroup} having more informal relationships with co-workers and/or feeling more comfortable expressing sentiment.`
    },
    {
        category: categoryChoices.Collaboration,
        title: `Proportion of Users Tagged in Posts Who Are Women`,
        yAxis: 'Percent Women',
        precision: 0,
        range: [0, 100],
        populateData: function (gender) {
            return percent(gender.women_citations);
        },
        displayValue: showPercent
        // focusAreaStr: (amount, moreGroup, lessGroup) => `Women are tagged in posts ${amount} times more by ${moreGroup} than ${lessGroup}. This suggests that ${moreGroup} are more likely than ${amount} to collaborate with women.`
    },
];

export default ChartSpecification

// provided data, populates data for each spec with calculated value
export function populateChartSpecification(data, metadata){
    return ChartSpecification.map(x => {
        x.data = Object.keys(data).reduce((acc,gender)=>{
            acc[gender] = x.populateData(data[gender], metadata);
            return acc
        },{});
        return x
    });
}