import { parseYearQuarter, stringifyQueryParams } from 'utils'

import { getPeopleSurveyedByRole } from '../sagas/network'
import { GetPeopleSurveyedByRoleResponse } from 'api/actions/home/peopleSurveyed/peopleSurveyedActions.types'
import { Queries } from 'api/queries.enum'

import { useQuery } from './useQuery'
import { useSurveys } from './useSurveys/useSurveys'

export const useGetPeopleSurveyedByRole = () => {
    const { survey } = useSurveys()

    const params = {
        year: parseYearQuarter(survey),
    }

    const query = useQuery<GetPeopleSurveyedByRoleResponse, Error>({
        queryKey: [Queries.getPeopleSurveyedByRole, ...Object.values(params)],
        queryFn: () => {
            return getPeopleSurveyedByRole(stringifyQueryParams(params))
        },
        enabled: survey !== null,
    })

    return query
}
