import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { useTranslation } from 'react-i18next'
import { Button, ChevronRightIcon } from '@diversioteam/diversio-ds'

import { Footer } from './../Footer'
import { Toast } from './../Toast'
import { resetPasswordSchema } from './resetPassword.schema'
import { ResetPasswordFields, ResetPasswordFormData, ResetPasswordProps } from './resetPassword.types'
import diversioLogo from './../../../assets/images/diversio-logo-with-symbol-for-light-bg.svg'
import * as S from './resetPassword.styles'

export const ResetPassword = ({ isLoading, data, isSuccess, error, isError, onSubmit }: ResetPasswordProps) => {
    const { t } = useTranslation()

    const { register, handleSubmit, errors } = useForm<ResetPasswordFormData>({
        resolver: yupResolver(resetPasswordSchema),
    })

    const handleSubmitCallback = useCallback(
        async (data: ResetPasswordFormData) => {
            await onSubmit(data)
        },
        [onSubmit],
    )

    const getErrorMessage = (fieldName: ResetPasswordFields) => {
        return t(errors[fieldName]?.message || '')
    }

    return (
        <S.Container>
            <S.Form onSubmit={handleSubmit(handleSubmitCallback)}>
                <Toast
                    isLoading={isLoading}
                    data={data}
                    isSuccess={isSuccess}
                    error={error}
                    isError={isError}
                    translationPrefix="AUTH.RESET_PASSWORD"
                />

                <S.Logo src={diversioLogo} className="logo" alt="Diversio" />

                <S.Title>{t('AUTH.RESET_PASSWORD.TITLE')}</S.Title>

                <S.Fields>
                    <S.TextField
                        id={ResetPasswordFields.password}
                        inputRef={register}
                        error={Boolean(errors[ResetPasswordFields.password])}
                        helperText={getErrorMessage(ResetPasswordFields.password)}
                        name={ResetPasswordFields.password}
                        label={t('AUTH.LOGIN.PASSWORD')}
                        type="password"
                        disabled={isLoading}
                        fullWidth
                    />

                    <S.TextField
                        id={ResetPasswordFields.confirmPassword}
                        inputRef={register}
                        error={Boolean(errors[ResetPasswordFields.confirmPassword])}
                        helperText={getErrorMessage(ResetPasswordFields.confirmPassword)}
                        name={ResetPasswordFields.confirmPassword}
                        label={t('AUTH.LOGIN.CONFIRM_PASSWORD')}
                        type="password"
                        disabled={isLoading}
                        fullWidth
                    />
                </S.Fields>

                <Button
                    loading={isLoading}
                    type="submit"
                    color="primary"
                    size="large"
                    rightIcon={<ChevronRightIcon type="bold" />}
                    fullWidth
                >
                    {t('AUTH.FORGOT_PASSWORD.RESET_PASSWORD')}
                </Button>

                <Footer />
            </S.Form>
        </S.Container>
    )
}
