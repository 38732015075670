import React, { useState } from 'react'
import ReactJoyride, { STATUS, Step } from 'react-joyride'

import { withErrorBoundary } from 'config/withErrorBoundary/withErrorBoundary'
import { useGetPastSurveys } from 'hooks/useGetPastSurveys'

import { Tooltip } from './Tooltip'
import { getSteps } from './onboarding.helpers'
import { OnboardingProps } from './onboarding.types'

const OnboardingWithoutEB = (props: OnboardingProps) => {
    const { data } = useGetPastSurveys()

    const pastSurveys = data?.pastSurveys || []
    const hasMultiplePastSurveys = pastSurveys.length > 1

    const [steps] = useState<Step[]>(getSteps(hasMultiplePastSurveys))

    return (
        <ReactJoyride
            steps={steps}
            scrollOffset={300}
            continuous={true}
            locale={{ last: 'Close' }}
            styles={{
                options: {
                    primaryColor: '#6824ec',
                    overlayColor: 'rgba(60, 36, 120, 0.2)',
                    zIndex: 120,
                },
                spotlight: {
                    borderRadius: 20,
                },
            }}
            tooltipComponent={Tooltip}
            floaterProps={{
                hideArrow: true,
            }}
            callback={(data) => {
                if (data.status === STATUS.FINISHED) {
                    localStorage.setItem('home_v2_onboarding_complete', '1')
                    props.onComplete()
                }
            }}
        />
    )
}

export const Onboarding = withErrorBoundary(OnboardingWithoutEB, {})
