import React from 'react'
import { ProgramCellSkeleton } from "@diversioteam/diversio-ds";

export const ProgramCellListSkeleton = ({ numberOfElements, listElementWrapperClassName }) => {
  const array = new Array(numberOfElements).fill('');

  return array.map((_element, index) => {
      return (
        <div className={listElementWrapperClassName} key={index}>
          <ProgramCellSkeleton />
        </div>
      )
    })
}