import React, { useCallback, useEffect } from 'react'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { AppRoute } from 'routing/AppRoute.enum'
import { RootState } from 'reducers/rootState.types'
import { SsoFields, SsoFormData } from 'components/Auth/Sso/sso.types'
import { Sso } from 'components/Auth/Sso'
import { useInitializeSso } from 'hooks/auth/useInitializeSso/useInitializeSso'
import { useSsoCallback } from 'hooks/auth/useSsoCallback/useSsoCallback'

import { SearchParam } from './../auth.types'
import { RedirectAuthenticatedUser } from './../RedirectAuthenticatedUser'

const LOCAL_STORAGE_TO_PARAM_KEY = 'TO_PARAM'
const EMAIL_PLACEHOLDER = 'EMAIL_PLACEHOLDER'

export const SSOContainer = () => {
    const { mfaRequired, mfaDeviceId, isAuthenticated } = useSelector((state: RootState) => state.auth)

    const history = useHistory()

    const location = useLocation()

    const mutationInitializeSso = useInitializeSso()
    const mutationSsoCallback = useSsoCallback()

    const searchParams = new URLSearchParams(location.search)

    const codeParam = searchParams.get(SearchParam.Code) || ''
    const stateParam = searchParams.get(SearchParam.State) || ''
    const toParam = searchParams.get(SearchParam.To) || ''

    const emailPlaceholder = localStorage.getItem(EMAIL_PLACEHOLDER)
    const emailPlaceholderProp = typeof emailPlaceholder === 'string' ? emailPlaceholder : undefined

    const handleSubmit = useCallback(
        async (values: SsoFormData): Promise<boolean> => {
            try {
                if (toParam) {
                    localStorage.setItem(LOCAL_STORAGE_TO_PARAM_KEY, toParam)
                }

                localStorage.setItem(EMAIL_PLACEHOLDER, values[SsoFields.email])

                const response = await mutationInitializeSso.mutateAsync(values)

                window.location.href = response.authorization_url

                return true
            } catch (error) {
                return false
            }
        },
        [mutationInitializeSso],
    )

    const handleSsoCallback = useCallback(async () => {
        try {
            const toParamLocalStorage = localStorage.getItem(LOCAL_STORAGE_TO_PARAM_KEY)

            if (toParamLocalStorage) {
                const params = new URLSearchParams(location.search)

                params.append(SearchParam.To, toParamLocalStorage)

                history.replace({ pathname: location.pathname, search: params.toString() })

                localStorage.removeItem(LOCAL_STORAGE_TO_PARAM_KEY)
            }

            await mutationSsoCallback.mutateAsync({
                code: codeParam,
                state: stateParam,
            })
        } catch (error) {
            window.history.replaceState(null, '', AppRoute.Sso)
        } finally {
            localStorage.removeItem(EMAIL_PLACEHOLDER)
        }
    }, [codeParam, stateParam])

    useEffect(() => {
        if (codeParam && stateParam) {
            handleSsoCallback()
        }
    }, [codeParam, handleSsoCallback, stateParam])

    if (mfaRequired && !mfaDeviceId) {
        searchParams.delete(SearchParam.Code)
        searchParams.delete(SearchParam.State)

        return <Redirect to={{ pathname: AppRoute.SendVerificationCode, search: searchParams.toString() }} />
    }

    if (isAuthenticated) {
        return <RedirectAuthenticatedUser />
    }

    return (
        <Sso
            isLoading={
                mutationInitializeSso.isLoading || mutationInitializeSso.isSuccess || mutationSsoCallback.isLoading
            }
            isError={mutationInitializeSso.isError || mutationSsoCallback.isError}
            error={mutationInitializeSso.error || mutationSsoCallback.error}
            onSubmit={handleSubmit}
            emailPlaceholder={emailPlaceholderProp}
        />
    )
}
