import React, { useCallback, useEffect } from 'react'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import mixpanel from 'mixpanel-browser'

import { LoginPayload } from 'api/actions/auth/authActions.types'
import { RootState } from 'reducers/rootState.types'
import { Login } from 'components/Auth/Login'
import { useLogin } from 'hooks/auth/useLogin/useLogin'
import { AppRoute } from 'routing/AppRoute.enum'
import { isDateMoreThanDays } from '../utils'
import { useGetConfiguration } from 'hooks/useGetConfiguration'

import { LocalStorageKey } from './../auth.types'
import { RedirectAuthenticatedUser } from './../RedirectAuthenticatedUser'
import { LocationState } from './login.types'

export const LoginContainer = () => {
    const {
        mfaRequired,
        mfaDeviceId,
        isAuthenticated,
        last2faReminderTime,
        uuid: userUUID,
    } = useSelector((state: RootState) => state.auth)

    const history = useHistory()

    const { state } = useLocation<LocationState>()

    useEffect(() => {
        if (state?.resetMixpanel) {
            history.replace(location.pathname, '')

            mixpanel.reset()
        }
    }, [])

    const queryGetConfiguration = useGetConfiguration()

    const daysBefore2faReminder = queryGetConfiguration.data?.mfaReminderDays ?? 7
    const mutationLogin = useLogin(daysBefore2faReminder)

    const handleSubmit = useCallback(
        async (values: LoginPayload): Promise<boolean> => {
            try {
                history.replace({ pathname: location.pathname, search: location.search }, '')

                const mfaDeviceId = localStorage.getItem(`${LocalStorageKey.MfaDeviceId}${values.username}`)

                if (mfaDeviceId) {
                    values['2fa_device_id'] = mfaDeviceId
                }

                await mutationLogin.mutateAsync(values)

                return true
            } catch (error) {
                return false
            }
        },
        [mutationLogin],
    )

    const showEnableMfaScreen = (): boolean => {
        // MFA is already enabled for the user
        if (isAuthenticated) {
            return false
        }
        // First time user OR Existing user who has never enabled or skipped 2FA before
        if (userUUID && !mfaRequired && !last2faReminderTime) {
            localStorage.setItem(`${LocalStorageKey.FirstTimeMfaUser}${userUUID}`, 'true')
            return true
        }
        // User previously skipped 2FA more than 'daysBefore2faReminder' days ago
        if (
            daysBefore2faReminder &&
            last2faReminderTime &&
            isDateMoreThanDays(last2faReminderTime, daysBefore2faReminder)
        ) {
            localStorage.setItem(`${LocalStorageKey.FirstTimeMfaUser}${userUUID}`, 'true')
            return true
        }
        // A user that closed the page before enabling or disabling 2FA and has now logged in again. Still a first_time_user technically.
        if (localStorage.getItem(`${LocalStorageKey.FirstTimeMfaUser}${userUUID}`) === 'true') {
            return true
        }
        return false
    }

    // Old users for whom 2FA is already compulsory
    if (mfaRequired && !mfaDeviceId) {
        return <Redirect to={{ pathname: AppRoute.SendVerificationCode, search: location.search }} />
    }

    if (showEnableMfaScreen()) {
        return <Redirect to={{ pathname: AppRoute.EnableMFA }} />
    }

    // Users with mfaRequired who previously checked `trust for 30 days` OR Users who skipped 2FA in the last 'daysBefore2faReminder' days
    if (isAuthenticated) {
        return <RedirectAuthenticatedUser />
    }

    return (
        <Login
            isLoading={mutationLogin.isLoading}
            isSuccess={!!state?.mutation?.isSuccess}
            data={state?.mutation?.data}
            isError={mutationLogin.isError}
            error={mutationLogin.error}
            onSubmit={handleSubmit}
        />
    )
}
