import React, { Component } from 'react';
import {NavLink as Link, Redirect} from 'react-router-dom';
import { Helmet } from 'react-helmet'
import { Actions } from '../actions';
import { connect } from 'react-redux';
import {
    Navbar,
    HomeIcon,
    AnalyzeIcon,
    FeatherIcon,
    GoalIcon,
    CommentIcon,
    WriteIcon,
    PainPointIcon,
    ToolsIcon,
    TrainingIcon,
    TrainingsIcon,
    SaveIcon,
    GlobalStyles,
    GlobalScrollbar,
    GlobalFonts,
    Toast,
    DiversityIcon,
    InclusionIcon,
    LumiShadedIcon
} from "@diversioteam/diversio-ds";
import Loader from "../components/utils/Loader";
import {urlMap} from '../dashboardRoutes';
import TopNavBar from "../components/TopNavBar";
import IdleTimer from 'react-idle-timer'
import _ from 'underscore';
import queryString from 'query-string'
import mixpanel from "mixpanel-browser";
import { beaconIdentify, beaconInit, beaconShowNps } from "../utils/beacon";
import {withTranslation} from "react-i18next";
import TAXONOMIES from "../utils/taxonomies";
import { isDiversioDemo } from '../utils/companyChecks';
import { featureFlagger } from '../featureFlagger';
import classNames from 'classnames';
import { doesUrlMatch } from 'utils/doesUrlMatch';
import { Company } from './Company';
import { SurveyInformation } from 'components/SurveyInformation';
import { generateCompanyURI } from '../sagas/helpers/generateCompanyURI';
import { VersionsContext } from 'context/versions/versionsContext/VersionsContext';
import { withHooks } from 'config/withHooks/withHooks';
import { getActiveCompany } from 'utils';
import './App.scss';
import { VersionSwitch } from 'components/HomeV2/VersionSwitchModal';
import { DashboardProviders } from 'providers/DashboardProviders';
import { RenewDialog } from 'components/RenewDialog';

const primary_30 = "#c9bcf8ff"
const linkPropsMap = [
    {
        home: { path: `/${urlMap.home}`, icon: <HomeIcon color={primary_30} height={20} width={20} type="bold" />, label: 'NAVBAR.HOME', v1: true },
        home2: { path: `/${urlMap.home2}`, icon: <HomeIcon color={primary_30} height={20} width={20} type="bold" />, label: 'NAVBAR.HOME2' },
        analyze: { path: `/${urlMap.analyze}`, icon: <AnalyzeIcon color={primary_30} height={20} width={20} type="bold" />, label: 'NAVBAR.ANALYZE', v1: true },
        [urlMap.analyze_diversity]: { path: `/${urlMap.analyze_diversity}`, icon: <DiversityIcon color={primary_30} height={20} width={20} type="bold" />, label: 'NAVBAR.DIVERSITY' },
        [urlMap.analyze_inclusion]: { path: `/${urlMap.analyze_inclusion}`, icon: <InclusionIcon color={primary_30} height={20} width={20} type="bold" />, label: 'NAVBAR.INCLUSION' },
        bespoke: { path: `/${urlMap.bespoke}`, icon: <FeatherIcon color={primary_30} height={20} width={20} type="bold" />, label: 'NAVBAR.BESPOKE', v1: true },
        voyager: { path: `/${urlMap.voyager}`, icon: <CommentIcon color={primary_30} height={20} width={20} type="bold" />, label: 'NAVBAR.COMMUNICATION', v1: true },
        survey: { path: `/${urlMap.survey}`, icon: <WriteIcon color={primary_30} height={20} width={20} type="bold" />, label: 'NAVBAR.SURVEY', v1: true },
        ilnDashboardHome: { path: `/${urlMap.iln_dashboard_home}`, icon: <HomeIcon color={primary_30} height={20} width={20} type="bold" />, label: 'NAVBAR.HOME', v1: true},
        ilnDashboardImpact: { path: `/${urlMap.iln_dashboard_impact}`, icon: <PainPointIcon color={primary_30} height={20} width={20} type="bold" />, label: 'NAVBAR.IMPACT', v1: true },
    },
    {
        solutionGoals: {
            icon: <GoalIcon color={primary_30} height={20} width={20} type="bold" />,
            label: 'NAVBAR.GOALS',
            path: `/${urlMap.solution_goals}`,
            children: {
                solutionGoals: { path: `/${urlMap.solution_goals}`, label: 'NAVBAR.GET_STARTED' },
                solutionPrograms: {path: `/${urlMap.solution_programs}`, label: 'NAVBAR.PROGRAMS'},
                solutionRecommended: {path: `/${urlMap.solution_recommended}`, label: 'NAVBAR.RECOMMENDED'},
            }
        }
    },
    {
        solutionTools: {path: `/${urlMap.solution_tools}`, label: 'NAVBAR.TOOLS', icon: <ToolsIcon color={primary_30} height={20} width={20} type="bold" />},
        solutionTrainings: { path: `/${urlMap.solution_trainings}`, label: 'NAVBAR.TRAININGS', icon: <TrainingsIcon color={primary_30} height={20} width={20} type="bold" /> },
        solutionReporting: {path: `/${urlMap.solution_reporting}`, label: 'NAVBAR.REPORTING', icon: <TrainingIcon color={primary_30} height={20} width={20} type="bold" />},
        solutionMySolutions: {path: `/${urlMap.solution_my_solutions}`, icon: <SaveIcon color={primary_30} height={20} width={20} type="bold" />, label: 'NAVBAR.MY_SOLUTIONS'},
        lumiJobAds: {path: `/${urlMap.lumi}`, icon: <div className='lumi-navbar'><LumiShadedIcon  height={20} width={20} /></div>, label: 'NAVBAR.LUMI'},
    }
];


class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.idleTimer = null;
        this.isNavbarCollapsed = this.isNavbarCollapsed.bind(this)
        this.state = {
            mixpanelGroupSet: "",
            mixpanelInitialized: false,
            activeDashboard: {
                label: "",
                value: ""
            },
            pastSurveysActive: false,
            isNavbarInFocus: false
        }
    }

    _permissionToListItem(idx, permissions, parentPermission, permission) {
        const path = window.location.pathname;
        const props = parentPermission ? linkPropsMap[idx][parentPermission]['children'][permission] : linkPropsMap[idx][permission];
        const selected = props.path === '/' ? props.path === path : (path.indexOf(props.path) > -1);

        const { t } = this.props;

        const option = {
            label: t(props.label),
            icon: props.icon,
            to: generateCompanyURI(props.path),
            activeClassName: 'active'
        }

        if (props.children) {
            option.options = Object.keys(props.children).filter(x => {
                return permissions[x]
            }).map(this._permissionToListItem.bind(this, idx, permissions, permission))
        }

        return option;
    }

    _shouldNavbarCollapse () {
        const path = window.location.pathname;
        return doesUrlMatch(path, [urlMap.new_analyze])
    }

    async componentWillMount () {
        await featureFlagger.loadFeatures()

        if (this.props.isAuthenticated) {
            // The following data is used on a number of pages so we retrieve it upon successful login.
            // For users with access to multiple companies, this data is loaded again for the selected
            // dashboard with the createActiveDashboard() saga.
            this.props.getCompanyFilters();
            this.props.getProfile();

            const username = this.props.username;
            const email = this.props.email;
            const user_id = this.props.loggedinUserId;
            
            const companyName = getActiveCompany(this.props.userMetadata?.companies)?.name

            beaconInit()
            beaconIdentify(username, email)

            featureFlagger.setAttributes({
                id: String(user_id),
                company: companyName,
            })

            if (this.props.showNps == true) {
                beaconShowNps()
            }

            window.__STORAGE_HANDLER__ = e => {
                if (e.key === 'auth_access_token' && e.oldValue && !e.newValue) {
                    this.props.doSignout();
                }
            };

            window.addEventListener('storage', window.__STORAGE_HANDLER__, true);
        }
    }

    componentDidMount () {
        window.addEventListener("unload", this.handleTabClose = (e) => {
            mixpanel.track(TAXONOMIES.DASHBOARD_SESSION_ENDED, {
                dashboardName: getActiveCompany(this.props.userMetadata?.companies)?.name,
                action: "Window or tab closed"
            }, {transport: "sendBeacon"});
        })
    }


    componentWillUnmount () {
        window.removeEventListener("unload", this.handleTabClose)
    }

    handleOnAction () {
        let accessToken = localStorage.getItem("auth_access_token");
        let refreshToken = localStorage.getItem("auth_refresh_token");

        this.props.doRefreshToken({ accessToken, refreshToken });
    }

    makeRedirect = (authCheck, permissions) => {
        const makeRedirectString = (authCheck, permissions) => {
            const {
                homeAuthCheck,
                ilnAuthCheck,
            } = authCheck;

            if (homeAuthCheck) {
                if(permissions.ilnDashboardHome) {
                    return "/iln-dashboard/home";
                }
            }

            if (ilnAuthCheck) {
                if(permissions.home) {
                    return "/home"; }
            }

            return null;
        }

        const redirectString = makeRedirectString(authCheck, permissions);

        if (redirectString) {
            return <Redirect to={redirectString} />
        } else {
            return null;
        }
    }



    componentDidUpdate (prevProps) {
        const prevCompanyName = getActiveCompany(prevProps.userMetadata?.companies)?.name
        const currCompanyName = getActiveCompany(this.props.userMetadata?.companies)?.name

        if (!this.props.loggedinUserId || !currCompanyName) {
            return
        }

        const username = this.props.username;
        const email = this.props.email;
        const user_id = this.props.loggedinUserId;
        const dashboardSwitchPage = this.props.history.location.pathname === '/choose-dashboard'


        if (!dashboardSwitchPage 
            && (!this.state.mixpanelInitialized || (prevCompanyName !== currCompanyName))) {
            this.setState({
                mixpanelInitialized: true
            })
            featureFlagger.setAttributes({
                id: String(user_id),
                company: currCompanyName,
            })

            mixpanel.identify(user_id);
            mixpanel.time_event(TAXONOMIES.DASHBOARD_SESSION_ENDED)
            mixpanel.people.set({
                $username: username,
                $email: email,
                user_id: user_id,
                accountType: TAXONOMIES[this.props.profile.surveyInfoType] || TAXONOMIES.WORKFORCE,
                npsLastShown: this.props.npsLastShown,
                dashboardType: getActiveCompany(this.props.userMetadata?.companies)?.isPortfolio ? TAXONOMIES.PORTCO: TAXONOMIES.COMPANY,
                companyName: currCompanyName
            });
        }
    }

    handleCompanySelect (uuid) {
        if (typeof uuid !== "string") {
            return
        }

        const { companies } = this.props.userMetadata
        const foundCompany = _.findWhere(companies, { uuid })
        if (!foundCompany) {
            return
        }
        const activeCompanyName = foundCompany.name
        const username = this.props.username;

        mixpanel.track(TAXONOMIES.DASHBOARD_SESSION_ENDED, {
            dashboardName: activeCompanyName,
            user: username,
            action: 'dashboard switch'
        });

        window.open(
            `/company/${foundCompany.uuid}/home`,
            '_blank'
        )
    }

    setActiveDashboard(company) {
        this.setState({
            activeDashboard: {
                label: company.company,
                value: company.uuid
            }
        })
    }

    showPastSurveys (status) {
        this.setState({
            pastSurveysActive: status
        })
    }

    handleNavbarMouseEnter () {
        clearTimeout(this.navbarCollapseTimeout)
        this.navbarExpandTimeout = setTimeout(() => {
            this.setState({ isNavbarInFocus: true })
        }, 1000)
    }

    handleNavbarMouseLeave () {
        clearTimeout(this.navbarExpandTimeout)
        this.navbarCollapseTimeout = setTimeout(() => {
            this.setState({ isNavbarInFocus: false })
        }, 300)
    }

    isNavbarCollapsed () {
        if (this.state.isNavbarInFocus) {
            return false
        }

        return true
    }

    render() {
        const { companies } = this.props?.userMetadata || {}
        const companyInformation = getActiveCompany(companies)

        const permissions = companyInformation?.permissions

        if (permissions) {
            permissions.new_analyze = permissions.hasOwnProperty("new_analyze") ? permissions.new_analyze : true;
            permissions[urlMap.analyze_diversity] = permissions.hasOwnProperty("new_analyze") && true,
            permissions[urlMap.analyze_inclusion] = permissions.hasOwnProperty("new_analyze") && true
        }

        const { enableDebugMode } = this.props;
        
        const { selectedLanguage, handleLanguageChange } = this.props;
        if (!this.props.isAuthenticated) {
            const clearBeforeSignout = sessionStorage.getItem("clear_before_signout") === "true"
            if (clearBeforeSignout) {
                sessionStorage.removeItem("companyUUID")
                sessionStorage.removeItem("clear_before_signout")

                this.props.queryClient.removeQueries()

                return <Redirect
                            to={{
                            pathname: "/login",
                            state: { resetMixpanel: true }
                            }}
                        />
              
            }
            const { to: defaultRedirectsTo } = queryString.parse(location.search)
            if (defaultRedirectsTo) {
                return <Redirect to={`/login?to=${encodeURIComponent(defaultRedirectsTo)}`}></Redirect>
            } else {
                if (location.pathname !== "/" && sessionStorage.getItem("companyUUID")) {
                    return <Redirect to={`/login?to=${encodeURIComponent(location.pathname + location.search)}`}></Redirect>
                } else {
                    return <Redirect to={`/login`}></Redirect>
                }
                
            }
        }

        if (!permissions) {
            return <Loader />;
        }

        const dashboardSwitch = this.props.history.location.pathname === '/choose-dashboard'
        const url = this.props.history.location.pathname;

        // By adding the trailing slash '/' to each auth check, it will redirect to the base home page for a specific dashboard
        const translatedPages = [
            '/',
            '/home',
            '/analyze/diversity',
            '/analyze/inclusion'
        ];

        const homeAuthUrls = [
            '/',
            '/home',
            '/analyze/diversity',
            '/analyze/inclusion',
        ]

        const ilnAuthUrls = [
            '/',
            '/iln-dashboard/home',
            '/iln-dashboard/impact'
        ]

        const homeAuthCheck = homeAuthUrls.indexOf(url) > -1 && !permissions.home
        const ilnAuthCheck = ilnAuthUrls.indexOf(url) > -1 && !permissions.ilnDashboardHome

        const authCheck = {
            homeAuthCheck,
            ilnAuthCheck,
        };

        const inChooseDashboard = (url === '/choose-dashboard')

        const possibleRedirect = this.makeRedirect(authCheck, permissions);
        if (possibleRedirect) {
            return possibleRedirect;
        }

        const companyName = companyInformation?.name
        if(this.state.mixpanelGroupSet !== companyName){
            mixpanel.add_group("company", companyName);
            this.setState({ 
               mixpanelGroupSet: companyName
            });
        }

        let cleanCompanyList = []

        for(const i in companies) {
            const obj = {
                "label": companies[i]["name"],
                "value": companies[i]["uuid"]
            }
            cleanCompanyList.push(obj)
        }

        let linkProps = { ...linkPropsMap }
        if (isDiversioDemo(companyName)) {
            linkProps[0].bespoke.label = "BESPOKE.TABS.ENGAGEMENT"
        }

        const links = linkPropsMap.map((linkPropsSection, idx)=> {
            return [
                ...Object.keys(linkPropsSection).filter((page) => {
                    const isV2Enabled = this.state.isV2Enabled

                    if (!linkPropsSection[page]?.v1 && !isV2Enabled) {
                        return false
                    }

                    // TODO: @sahanarula V2 home page is referenced as home2, below we replace 2 with "" so that it passes the permissions 
                    // check and shows the home2 for testing until we completely switch to home.
                    if (page === urlMap.home2 && !isV2Enabled) {
                        return false
                    }
                    if (page === urlMap.analyze2 && !isV2Enabled) {
                        return false
                    }
                    if (page === urlMap.home && isV2Enabled && !enableDebugMode) {
                        return false
                    }
                    if ([urlMap.analyze, urlMap.bespoke].includes(page) && isV2Enabled && !enableDebugMode) {
                        return false
                    }

                    if ([urlMap.analyze_diversity, urlMap.analyze_inclusion].includes(page) && isV2Enabled && permissions[urlMap.analyze]) {
                        return true;
                    }

                    return permissions[page.replace(/[0-9]/gmi, "")]
                }).map(this._permissionToListItem.bind(this, idx, permissions, null))
            ]
        }).filter(links => links?.length);

        const activeDashboardData = _.find(companies, { uuid: this.state.activeDashboard.value })

        const renderLogo = () => {
            if (activeDashboardData) {
                return <img src={activeDashboardData.logo}/>
            }
        }

        const renderCollapsedLogo = () => {
            if (activeDashboardData) {
                return <img src={activeDashboardData.logoCollapsed}/>
            }
        }

        const renderFavicon = () => {
            if (activeDashboardData) {
                return <Helmet>
                    <link rel="shortcut icon" href={activeDashboardData.favicon} />
                </Helmet>
            }
        }

        return (
            <DashboardProviders>
                <div className={classNames("app-container", {"is-navbar-collapsed": this.isNavbarCollapsed()})}>
                    <GlobalStyles />
                    <GlobalScrollbar />
                    <GlobalFonts />
                    <IdleTimer
                        ref={ref => { this.idleTimer = ref }}
                        timeout={1000 * 60 * Number(process.env.REACT_APP_INACTIVE_EXPIRE_TIME)}
                        onAction={this.handleOnAction.bind(this)}
                        throttle={1000 * 60 * Number(process.env.REACT_APP_TOKEN_REFRESH_TIME)}
                    />
                    
                    {!inChooseDashboard &&
                        <TopNavBar
                            selectedLanguage={selectedLanguage}
                            showLanguages={translatedPages.includes(url) && !permissions.solution_goals}
                            companyList={cleanCompanyList}
                            activeDashboard={this.state.activeDashboard}
                            onChangeCompany={this.handleCompanySelect.bind(this)}
                            onChangeLanguage={handleLanguageChange}
                            onShowPastSurveys={() => this.showPastSurveys(true)}
                        />
                    }
                    
                    {!dashboardSwitch &&
                        <Navbar
                            options={links}
                            subMenuItemsVisibleForCollapsed={false}
                            menuLink={Link}
                            logo={renderLogo()}
                            collapsedLogo={renderCollapsedLogo()}
                            collapsed={this.isNavbarCollapsed()}
                            onMouseEnter={this.handleNavbarMouseEnter.bind(this)}
                            onMouseLeave={this.handleNavbarMouseLeave.bind(this)} />
                    }
                    {
                        this.props.displayAnonymityWarning 
                        ? <Toast className='anonymity-warning' type='error' message='Anonymity is disabled for this company for Diversio staff accounts, client accessing this dashboard might see different scores/values. Please make sure to disable it you’re going to use this dashboard for a demo.' open variant='slim'/>
                        : null
                    }
                    {
                        this.state.pastSurveysActive
                        ? <SurveyInformation isOpen={this.state.pastSurveysActive} setIsOpen={(status) => this.showPastSurveys(status)}/>
                        : null
                    }
                    
                    <Company permissions={permissions} url={url} companyInformation={companyInformation} setActiveDashboard={this.setActiveDashboard.bind(this)} />

                    {renderFavicon()}

                    <VersionsContext.Consumer>
                        {versionsContext => {
                            const isV2Enabled = versionsContext?.version === versionsContext?.AVAILABLE_VERSIONS.v2
                            if (this.state.isV2Enabled !== isV2Enabled) {
                                this.setState({
                                    isV2Enabled
                                })
                            }
                        }}
                    </VersionsContext.Consumer>

                    <VersionSwitch />

                    <RenewDialog />
                </div>
            </DashboardProviders>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        isDefaultAuthenticated: state.auth.isDefaultAuthenticated,
        enableDebugMode: state.auth.enableDebugMode,
        showNps: state.auth.showNps,
        username: state.auth.username,
        email: state.auth.email,
        loggedinUserId: state.auth.loggedinUserId,
        displayAnonymityWarning: state.analyze.filters.display_anonymity_warning,
        selectedLanguage: state.app.selectedLanguage,
        profile: state.profile,
    }
};

export default connect(mapStateToProps, Actions)(withTranslation()(withHooks(Dashboard)));
