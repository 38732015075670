import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import './index.scss';

export const DashboardTooltip = ({ content, placement="bottom", ...props }) => {
    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
      setOpen(false);
    };
  
    const handleTooltipOpen = () => {
      setOpen(true);
    };

    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip arrow title={content} placement={placement}
                     PopperProps={{
                         disablePortal: true,
                     }}
                     onClose={handleTooltipClose}
                     className="clickable"
                     classes={{ popper: "custom-tooltip-popper", tooltip: "custom-tooltip", arrow: "custom-tooltip--arrow" }}
                     open={open}
                     disableFocusListener
                     disableHoverListener
                     disableTouchListener
                    >
                { React.cloneElement( props.children, { onClick: handleTooltipOpen } ) }
            </Tooltip>
        </ClickAwayListener>
    );
};
