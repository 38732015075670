import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import Table from 'react-bootstrap/Table';
import _ from 'underscore';

import MainCard from '../../utils/MainCard';
import * as m from '../../../translations/mapping';
import {getTranslation, mapT} from '../../../translations/utils';
import { _makeCell, _getSideHeaderClass } from './utils';

import classNames from 'classnames';
import "./index.scss";
import { DashboardTooltip } from '../../utils/DashboardTooltip';
import { industryOrRegional, mapChoiceLabel, mapMetaKpiLabels, } from '../../../utils/labels';
import { isBenchmarkingCompany, isPortfolioCompany } from '../../../utils/companyChecks';
import Legend from './Legend';
import { INSUFFICIENT_DATA, NOT_AVAILABLE } from '../../AnalyzeV2/Inclusion/Heatmap/heatmap.utils';
import { MINIMUM_DATA_THRESHOLD } from '../../../utils';

/* Table columns in desired order of appearance */
const COLUMN_KEYS = [
    'INCLUSIVE_CULTURE',
    'FAIR_MANAGEMENT',
    'CAREER_DEVELOPMENT',
    'WORKPLACE_FLEXIBILITY',
    'WORKPLACE_SAFETY',
    'RECRUITING_HIRING'
];

class HeatMap extends Component {
    
    getSubheader = () => {
        const {t} = this.props;
        
        if (isPortfolioCompany(this.props.companyName)) {
            return t("HOME.CARD_HEADERS.INCLUSION_SCORECARD.subheader_p");
        }

        return getTranslation("HOME.CARD_HEADERS.INCLUSION_SCORECARD.subheader", this.props.companyName)
    }

    getHeatmapRowHeader = (title, companyName) => {
        const header = mapT(m.HEATMAP_SIDEBAR, mapChoiceLabel(industryOrRegional(title, companyName)))
        if (isBenchmarkingCompany(companyName)) {
            if (header == "Industry Score") return "Global Average";
            if (header == "Your KPI Score") return "Pensions & Financial Services";
            return header;
        }
        else return header;
    }

    render() {
        const { metadata = [], companyName, t, data, prevData } = this.props;
        
        let heatmapMetricData = [];

        // Check whether all of the company's KPI scores are N/A or INSUFFICIENT_DATA
        const hasData = data.length && (typeof data[1] === "object") && !(Object.values(data[1]).filter((value) => ((value === NOT_AVAILABLE) || (value === INSUFFICIENT_DATA))).length == MINIMUM_DATA_THRESHOLD)

        if (!(_.isEmpty(data))) {
            /* Contains strengths and weaknesses */
            heatmapMetricData = _.compact([
                data[0], // industry
                data[1]  // your score
            ]);
        }

        let prevMetricData = [];
        
        if (prevData) {
            prevMetricData = [
                prevData[0],
                prevData[1]
            ]
        }

        let table;
        if (!(data.length)) {
            table = null;
        } else {
            table = (
                <Table bordered className="home-heat-map">
                    <thead className="home-heat-map__thead">
                        <tr>
                            <th width="16%" />
                            {
                                metadata.map((meta, index) => 
                                <th
                                width="14%"
                                 className="home-heat-map__header home-heat-map__cell" key={index}>
                                    <DashboardTooltip content={mapT(m.HEATMAP_TOOLTIPS, meta.helperText, companyName)}>
                                        <span>
                                            {mapT(m.METRIC, mapMetaKpiLabels(companyName, meta.name), companyName)}
                                        </span>
                                    </DashboardTooltip>
                                    </th>)
                            }
                        </tr>
                    </thead>
                    <tbody>
                        { !(_.isEmpty(heatmapMetricData)) ?
                        heatmapMetricData.map((metric, id) => {
                            return (
                                <tr key={`tr-${id}`}>
                                    <td className={classNames(["home-heat-map__cell", "home-heat-map__side_header", _getSideHeaderClass(id)])}
                                    key={`td-${id}`}>
                                        <DashboardTooltip content={mapT(m.HEATMAP_SIDE_TOOLTIPS, metric?.help_text || "")}>
                                            <span>{this.getHeatmapRowHeader(metric.title, this.props.companyName)}</span>
                                        </DashboardTooltip>
                                    </td>
                                    {COLUMN_KEYS.map(key => (_makeCell(metric, prevMetricData[id], key, id)))}
                                </tr>
                            )
                        }) : <tr><th colspan={COLUMN_KEYS.length + 1} >No results.</th></tr> }
                    </tbody>
                </Table>
            );
        }

        return (
            hasData ?
            <MainCard title={t("HOME.CARD_HEADERS.INCLUSION_SCORECARD.title")}
            subheader={this.getSubheader()}
            toolTipContent={getTranslation("HOME.CARD_HEADERS.INCLUSION_SCORECARD.tooltip", this.props.companyName)}>
                <div className="home-heat-map">
                    {table}
                </div>
                <Legend/>
            </MainCard> : null
        );
    }
};

export default withTranslation()(HeatMap);
