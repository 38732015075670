import React from 'react'
import { ToolCardSkeleton } from "@diversioteam/diversio-ds";

export const ToolCardListSkeleton = ({ size, numberOfElements, listElementWrapperClassName }) => {
  const array = new Array(numberOfElements).fill('');

  return <>
    {
      array.map((_element, index) => {
        return (
          <div className={listElementWrapperClassName} key={index}>
            <ToolCardSkeleton size={size} />
          </div>
        )
      })
    }
  </>
} 