import { useDispatch } from 'react-redux'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { saveTool } from 'sagas/network'
import { Actions } from 'actions'

import { Mutations } from 'api/mutations.enum'
import { Queries } from 'api/queries.enum'
import { SaveToolPayload } from 'api/actions/tools/tools.types'

export const useSaveTool = () => {
    const queryClient = useQueryClient()
    const dispatch = useDispatch()

    const mutation = useMutation<void, Error, SaveToolPayload>({
        mutationKey: [Mutations.saveTool],
        mutationFn: (payload) => {
            return saveTool(payload)
        },
        onSuccess: () => {
            dispatch(
                Actions.showToast({
                    type: 'success',
                    message: 'Tool has been saved!',
                }),
            )

            queryClient.invalidateQueries([Queries.getAllTools])
        },
        onError: () => {
            Actions.showToast({
                type: 'warning',
                message: 'Something went wrong',
            })
        },
    })

    return mutation
}
