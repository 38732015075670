import React from 'react'
import { EventReminders, EventRemindersSkeleton } from '@diversioteam/diversio-ds'

import { useGetEventReminders } from 'hooks/useGetEventReminders/useGetEventReminders'
import { withErrorBoundary } from 'config/withErrorBoundary/withErrorBoundary'

const EventsWithoutEB = () => {
    const { isLoading, data } = useGetEventReminders()

    if (isLoading) {
        return <EventRemindersSkeleton data-testid="event-reminders-skeleton" />
    }

    if (!data || data.length === 0) {
        return null
    }

    return <EventReminders events={data} />
}

export const Events = withErrorBoundary(EventsWithoutEB, {})
