import styled from 'styled-components'
import { getFontStyle } from '@diversioteam/diversio-ds'

export const EmailStatusWrapper = styled.div`
    width: 100%;
    height: 253px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .dds-button {
        min-width: 176px;
    }
`

export const Title = styled.h3`
    margin: 12px 0;
    color: ${({ theme }) => theme.colors.primary90};

    ${getFontStyle('h2Bold')}
`

export const Description = styled.p`
    margin: 0 0 32px;
    color: ${({ theme }) => theme.colors.secondary500};
    text-align: center;

    a {
        text-decoration: none;
        color: inherit;
    }

    ${getFontStyle('p01Regular')}
`
