import React from 'react'
import { Button, DownloadIcon, NoResults, SearchBox } from '@diversioteam/diversio-ds'

import { useGetBespokeFreeTexts } from 'hooks/freeTexts/useGetBespokeFreeTexts'

import { FreeTextCategory } from './../freeTexts.types'
import { useFreeTextsState } from './../hooks/useFreeTextsState'
import { CustomCategoryProps } from './customCategory.types'
import { FreeTextAccordion } from './FreeTextAccordion'
import { FreeTextAccordionSkeleton } from './FreeTextAccordionSkeleton'
import * as S from './../freeTexts.styles'

export const CustomCategory = ({ category, hasMultipleCategories, isActive }: CustomCategoryProps) => {
    const { searchValue, onChangeSearchValue, debouncedSearchValue, onDownload } = useFreeTextsState()

    const freeTextCategory = category === 'bespoke' ? FreeTextCategory.Bespoke : FreeTextCategory.Engagement

    const queryBespokeFreeTexts = useGetBespokeFreeTexts(
        {
            search: debouncedSearchValue,
            category,
        },
        {
            enabled: isActive,
        },
    )

    const responses = queryBespokeFreeTexts.data || []

    const responseSkeletonComponent = <FreeTextAccordionSkeleton />

    const responsesComponent =
        responses.length === 0 ? (
            <NoResults />
        ) : (
            responses.map(({ question, uuid }, index) => (
                <FreeTextAccordion
                    key={`${uuid}${debouncedSearchValue}`}
                    text={question}
                    bespokeQuestion={uuid}
                    category={category}
                    defaultExpanded={index === 0}
                />
            ))
        )

    return (
        <S.TabPanel value={freeTextCategory} isLoading={queryBespokeFreeTexts.isLoading}>
            <S.Header>
                <S.TitleWrapper hasMultipleCategories={hasMultipleCategories}>
                    <S.Title>Employee Feedback</S.Title>

                    <S.Filters size="medium">
                        <SearchBox value={searchValue} onChange={onChangeSearchValue} maxWidth={220} />

                        {!hasMultipleCategories && (
                            <Button
                                color="transparent"
                                size="small"
                                rightIcon={<DownloadIcon type="bold" />}
                                onClick={() => onDownload(freeTextCategory)}
                            >
                                CSV
                            </Button>
                        )}
                    </S.Filters>
                </S.TitleWrapper>
            </S.Header>

            {queryBespokeFreeTexts.isLoading ? responseSkeletonComponent : responsesComponent}
        </S.TabPanel>
    )
}
