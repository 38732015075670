/** @format */

import { call, put } from "redux-saga/effects";
import { Actions, ActionTypes } from "../actions";
import {
  loadSolutionGoalsOptions,
  loadSolutionGoalsInsightData,
  loadSolutionGoalsCreateGoal,
  loadSolutionGoalsRecommendedPrograms,
  loadSolutionGoalDelete,
  createGoal,
  deleteGoal,
  loadSolutionGoalsOptionsV2,
  loadSolutionGoalsInsightDataV2
} from "./network";

// All data sagas to add to middleware.
export default [
  [ActionTypes.GET_SOLUTION_GOALS_OPTIONS, getSolutionGoalsOptions],
  [ActionTypes.GET_SOLUTION_GOALS_INSIGHT_DATA, getSolutionGoalsInsightData],
  [ActionTypes.DO_SOLUTION_GOALS_CREATE, doSolutionGoalsCreate],
  [
    ActionTypes.GET_SOLUTION_GOALS_RECOMMENDED_PROGRAMS,
    getSolutionGoalsRecommendedPrograms,
  ],
  [ActionTypes.DO_SOLUTION_GOAL_DELETE, doSolutionGoalDelete],
  [ActionTypes.DO_CREATE_GOAL, doCreateGoal],
  [ActionTypes.DO_DELETE_GOAL, doDeleteGoal],
  [ActionTypes.GET_SOLUTION_GOALS_OPTIONS_V2, getSolutionGoalsOptionsV2],
  [ActionTypes.DO_SOLUTION_GOAL_DELETE_V2, doSolutionGoalDeleteV2],
  [ActionTypes.GET_SOLUTION_GOALS_INSIGHT_DATA_V2, getSolutionGoalsInsightDataV2],
];

function* getSolutionGoalsOptions({ payload }) {
  const response = yield call(loadSolutionGoalsOptions, payload);

  if (!response.successful) {
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Something went wrong",
      })
    );
  } else {
    yield put(Actions.loadedSolutionGoalsOptions(response.data));
  }
}

function* getSolutionGoalsOptionsV2() {
  const response = yield call(loadSolutionGoalsOptionsV2);

  if (response.data?.detail) {
    yield put(
      Actions.showToast({
        type: "error",
        message: response.data.detail,
      })
    );
  } else {
    yield put(Actions.loadedSolutionGoalsOptionsV2(response));
  }
}

function* getSolutionGoalsInsightData({ payload }) {
  const response = yield call(loadSolutionGoalsInsightData, payload);

  if (!response.successful) {
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Something went wrong",
      })
    );
  } else {
    yield put(Actions.loadedSolutionGoalsInsightData(response.data));
  }
}

function* doSolutionGoalsCreate({ payload }) {
  /*
    Loading que's if any

    example:
    yield put(Actions.loadingSomething())
    */
  /*
        Call the api endpoint

    */
  const response = yield call(loadSolutionGoalsCreateGoal, payload);
  /*
              Process the response
          */
  if (!response.successful) {
    // Show the error message
    // Should hopefully never get to here with the checks in the frontend, use this as a failsafe
    const firstError = Object.keys(response.data);
    const message = "Please fill out the missing data";
    yield put(
      Actions.showToast({
        type: "error",
        message: `${firstError} - ${message}`,
      })
    );
    yield put(Actions.doneSolutionGoalsCreateError());
  } else {
    yield put(
      Actions.doneSolutionGoalsCreate({
        kpi: payload.kpis[0],
        targetGroup: payload.target_groups[0],
      })
    );
    yield put(
      Actions.showToast({
        type: "success",
        message: "Successfully created a new Goal!",
      })
    );
  }
}

function* getSolutionGoalsRecommendedPrograms({ payload }) {
  /*
    Loading que's if any

    example:
    yield put(Actions.loadingSomething())
    */
  /*
        Call the api endpoint

    */
  const response = yield call(loadSolutionGoalsRecommendedPrograms, payload);
  /*
        Process the response
    */
  if (!response.successful) {
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Something went wrong",
      })
    );
  } else {
    if(!response.data.next) {
      yield put(Actions.endOfGoalsPagePrograms(true));
    }
    yield put(Actions.loadedSolutionGoalsRecommendedPrograms(response.data));
  }
}

function* doSolutionGoalDelete({ payload }) {
  const response = yield call(loadSolutionGoalDelete, payload.goal);

  if (!response.successful) {
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Something went wrong",
      })
    );
  } else {
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Goal deleted",
      })
    );
    yield put(Actions.doneSolutionGoalDelete(payload.goal));
  }
}

function* doCreateGoal({ payload }) {
  yield put(Actions.doingCreateGoal())

  const response = yield call(createGoal, payload);

  if (response.data?.detail) {
    yield put(
      Actions.showToast({
        type: "error",
        message: `Something went wrong while creating the goal.`,
      })
    );
    yield put(Actions.failedCreateGoal());
  } else {
    yield put(
      Actions.doneCreateGoal({
        kpi: payload.kpis[0],
        targetGroup: payload.target_groups_v2[0],
      })
    );
    yield put(
      Actions.showToast({
        type: "success",
        message: "Successfully created a new Goal!",
      })
    );
  }
}

function* doDeleteGoal({ payload }) {
  yield put(Actions.doingDeleteGoal())

  const response = yield call(deleteGoal, payload.goal);

  if (response.data?.detail) {
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Something went wrong",
      })
    );
  } else {
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Goal deleted",
      })
    );
    yield put(Actions.doneDeleteGoal(payload.goal));
  }
}

function* doSolutionGoalDeleteV2({ payload }) {
  const response = yield call(loadSolutionGoalDelete, payload.goal);

  if (!response.successful) {
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Something went wrong",
      })
    );
  } else {
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Goal deleted",
      })
    );
    yield put(Actions.doneSolutionGoalDelete(payload.goal));
  }
}

function* getSolutionGoalsInsightDataV2() {
  const response = yield call(loadSolutionGoalsInsightDataV2);

  if (response.data?.detail) {
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Something went wrong",
      })
    );
  } else {
    yield put(Actions.loadedSolutionGoalsInsightDataV2(response));
  }
}