import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { getToolsFilters } from 'sagas/network'

import { Queries } from 'api/queries.enum'
import { ParsedProgramFilters, ProgramFilters } from 'types/programs/programFilters.types'

export const useToolsFilters = () => {
    const { t } = useTranslation()

    const transformData = (data: ProgramFilters) => {
        return data.filters.map((filter) => {
            const options = filter.options.map((option) => {
                return {
                    category: t(`GROUP_TYPES.${option.filterType?.toUpperCase()}`, ''),
                    title: option.label,
                    value: option.value,
                }
            })

            return {
                name: filter.name,
                type: filter.type,
                label: filter.label,
                options,
            }
        }, [])
    }

    const query = useQuery<ProgramFilters, Error, ParsedProgramFilters[]>({
        queryKey: [Queries.getToolsFilters],
        queryFn: getToolsFilters,
        select: transformData,
    })

    return query
}
