import { useEffect } from 'react'
import mixpanel from 'mixpanel-browser'

import TAXONOMIES from 'utils/taxonomies'

export const useViewTracking = (viewName: string) => {
    useEffect(() => {
        mixpanel.time_event(TAXONOMIES.PAGE_SESSION_ENDED)

        return () => {
            mixpanel.track(TAXONOMIES.PAGE_SESSION_ENDED, { viewName })
        }
    }, [viewName])
}
