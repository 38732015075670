import { call, put } from 'redux-saga/effects';
import { Actions, ActionTypes } from '../actions';
import {
    loadBespokeDemographics,
    loadInclusionMetricsDataBespoke,
    loadNonOverviewMetricsDataBespoke,
} from './network';
import {stringifyQueryParams} from "../utils";


// All data sagas to add to middleware.
export default [
    [ ActionTypes.GET_BESPOKE_DEMOGRAPHICS, getBespokeDemographics],
    [ ActionTypes.GET_INCLUSION_METRICS_DATA_BESPOKE, getInclusionMetricsDataBespoke],
    [ ActionTypes.GET_NON_OVERVIEW_METRICS_DATA_BESPOKE, getNonOverviewMetricsDataBespoke ],
];


function * getBespokeDemographics ({payload}) {
    yield put(Actions.loadingBespokeDemographics());
    const response = yield call(loadBespokeDemographics, stringifyQueryParams(payload));
    
    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedBespokeDemographics(response.data));
    }
}

function * getInclusionMetricsDataBespoke ({ payload }) {
    yield put(Actions.loadingInclusionMetricsDataBespoke());

    const response = yield call(loadInclusionMetricsDataBespoke, stringifyQueryParams(payload.data));

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedInclusionMetricsDataBespoke(response.data));
    }
}

function * getNonOverviewMetricsDataBespoke ({ payload }) {
    yield put(Actions.loadingNonOverviewMetricsDataBespoke());

    const response = yield call(loadNonOverviewMetricsDataBespoke, stringifyQueryParams(payload.data));

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedNonOverviewMetricsDataBespoke(response.data));
    }
}
