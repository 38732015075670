import styled from 'styled-components'

import { media } from 'styles/theme/mediaQueries'

import { Stage } from './context/LumiContext.types'

export const LumiWrapper = styled.section<{ stage: Stage }>`
    height: 100%;
    padding: 24px;
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 420px;
    grid-template-rows: 258px 1fr;
    gap: 24px;
    grid-template-areas:
        'banner banner'
        'post queue';
    position: relative;

    padding-top: ${({ stage }) => {
        if (stage === Stage.Processing) {
            return '54px'
        }
    }};

    ${media.sm} {
        grid-template-rows: 204px 1fr;
    }
`
