import React, {Component} from 'react';
import './index.scss';
import Card from '../../../utils/Card';
import DefaultChipInput from "../../../utils/DefaultChipInput";
import ContentSummary from "../../utils/ContentSummary";
import {formatDate, getGenderLabel} from "../../utils/functions";

let requestId = 0;

class Summary extends Component {
    constructor(props) {
        super(props);

        const analytics = this.props.slack_analytics;
        let channels = [];
        if (analytics.data && analytics.metadata && analytics.metadata.channels) {
            channels = analytics.metadata.channels
        }
        const latest = this.formatDate(new Date());
        let cutoff = new Date();
        cutoff.setDate(cutoff.getDate()-30);
        cutoff = this.formatDate(cutoff);

        this.state = {
            channels,
            channelsText: '',
            latest,
            cutoff,
            last_requested: null // timestamp of last request for analytics. compared w/ props.slack_analytics.last_retrieved to infer loading state
        }
    }

    newErrorOccurred(prevProps) {
        return this.props.slack_analytics.errors.length > prevProps.slack_analytics.errors.length
    }

    formatDate(d) {
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        let year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return `${year}-${month}-${day}`;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // if received metadata re: channels, set channels to this.
        if (!(prevProps.slack_analytics && this.props.slack_analytics)) {
            return;
        }

        if (!this.props.slack_analytics.metadata) {
            return;
        }

        if (prevProps.slack_analytics.data !== this.props.slack_analytics.data) {
            let {channels, cutoff, latest} = this.props.slack_analytics.metadata;
            cutoff = this.formatDate(new Date(cutoff));
            latest = this.formatDate(new Date(latest));
            this.setState({channels, cutoff, latest});
        }

        if (this.newErrorOccurred(prevProps)) {
            this.showError();
        }
    }

    showError() {
        const type = 'error';
        let message = 'An error occurred, could not retrieve results.';
        if (this.props.slack_analytics.errors.length >= 3) {
            message += ' If problem persists you may need to reset your Slack authentication in your Account settings.';
            this.props.showMessage({type, message, delay: 10000});
        } else {
            this.props.showMessage({type, message});
        }
    }

    dataHasLoaded() {
        const data = this.props.slack_analytics.data;
        return data && data.features && data.features.gender;
    }

    getTimeStamp(timeStr) {
        let date = new Date(timeStr);
        return new Date((date.getTime() / 1000 + date.getTimezoneOffset() * 60) * 1000).getTime() / 1000;
    }

    getAnalytics() {
        this.setState({
            channels: [...this.state.channels, this.sanitizeOctothorpe(this.state.channelsText)].filter(x => x.length),
            channelsText: ''
        }, () => {
            const channels = this.state.channels.reduce((acc, x) => {
                if (acc.includes(x)) {
                    return acc
                }
                return [...acc, x]
            }, []);
            const latest = this.getTimeStamp(this.state.latest);
            const cutoff = this.getTimeStamp(this.state.cutoff);
            if ((latest - cutoff) > this.props.authentication.slack_calc_days_max * 86400) {
                this.props.showMessage({
                    type: 'error',
                    message: `The selected date range is too long, please select dates within ${this.props.authentication.slack_calc_days_max} days.`,
                    delay: 10000
                })
            } else {
                this.props.getAnalytics({channels, latest, cutoff, requestId: requestId++});
                this.setState({last_requested: Date.now()});
            }
        });
    }

    sanitizeOctothorpe(str) {
        return str.split("#").join("")
    }

    handleDateChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    render() {
        let content, genderData = [];
        const dataHasLoaded = this.dataHasLoaded();
        if (dataHasLoaded) {
            const {n_replies, n_messages, n_reactions} = this.props.slack_analytics.data.summary;
            const replies = Array(n_replies).fill("Replies");
            const reactions = Array(n_reactions).fill("Reactions");
            const messages = Array(n_messages).fill("Messages");
            content = [...messages, ...replies, ...reactions];
            genderData = this.props.slack_analytics.data.features.gender.map(getGenderLabel);
        }
        return (
            <Card>
                <h2>Overview</h2>
                <p>
                    Set your filters and start exploring. The graphs below indicate the gender and content breakdown
                    of employees active on Slack in your chosen Channel(s).
                </p>
                <div className={'filters'}>
                    <div className="filter">
                        <h3>Date Range</h3>
                        <div className="dates">
                            <div className='date'>
                                <span>From (dd/mm/yyyy)</span>
                                <input type="date" value={this.state.cutoff} name="cutoff"
                                       onChange={this.handleDateChange.bind(this)}/>
                            </div>
                            <div className='date'>
                                <span>To (dd/mm/yyyy)</span>
                                <input type="date" value={this.state.latest} name="latest"
                                       onChange={this.handleDateChange.bind(this)}/>
                            </div>

                        </div>
                    </div>
                    <div className="filter">
                        <h3>Slack Channels</h3>
                        <div>
                            <DefaultChipInput
                                value={this.state.channels}
                                fullWidth
                                onUpdateInput={e => {
                                    this.setState({channelsText: e.target.value})
                                }}
                                onAdd={channel => {
                                    this.setState({
                                        channels: [...this.state.channels, this.sanitizeOctothorpe(channel)],
                                        channelsText: ''
                                    })
                                }}
                                onDelete={channel => {
                                    this.setState({channels: [...this.state.channels.filter(x => x !== channel)]})
                                }}
                                newChipKeyCodes={[32, 9, 13, 188]}
                            />
                            <button className="apply-filter" onClick={this.getAnalytics.bind(this)}>Enter</button>
                        </div>
                    </div>

                    <div>{this.props.slack_analytics.last_retrieved < this.state.last_requested ? 'loading...' : ''}</div>
                </div>
                {!dataHasLoaded ? null :
                    <div className={'header-info'}>
                        <div className={'left'}>
                            <div>
                                {this.props.slack_analytics.metadata.n_users} Total Users
                            </div>
                        </div>
                        <div className={'right'}>
                            <div>
                                {`${formatDate(this.props.slack_analytics.metadata.cutoff)} - ${formatDate(this.props.slack_analytics.metadata.latest)} | `}
                                {this.props.slack_analytics.metadata.channels.join(", ")}
                            </div>
                        </div>
                    </div>
                }

                {!dataHasLoaded ? null : <ContentSummary genders={genderData} content={content}/>}
            </Card>
        )
    }
}

export default Summary;


