import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Snackbar } from '@material-ui/core'

import { RootState } from 'reducers/rootState.types'
import { getErrorMessage } from 'utils/getErrorMessage/getErrorMessage'
import './App.scss'

const GlobalMessage = () => {
    const [isOpen, setIsOpen] = useState(false)
    const { code, message, type } = useSelector((state: RootState) => {
        return state.message
    })

    const hasDetails = code || message

    useEffect(() => {
        if (hasDetails) {
            setIsOpen(true)
        }
    }, [hasDetails])

    const handleClose = () => {
        setIsOpen(false)
    }

    if (hasDetails) {
        return (
            <div className={`${type}-message`}>
                {message.map((msg: string, index: number) => (
                    <Snackbar
                        key={index}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        open={isOpen}
                        onClose={handleClose}
                        autoHideDuration={5000}
                        message={<span>{getErrorMessage(code, msg)}</span>}
                        // Add margin to avoid overlap between snackbars
                        style={{ marginBottom: `${index * 60}px` }}
                    />
                ))}
            </div>
        )
    }

    return null
}

export default GlobalMessage
