import React from 'react'
import { Button, ChevronLeftIcon, ChevronRightIcon } from '@diversioteam/diversio-ds'
import { TooltipRenderProps } from 'react-joyride'

import * as S from './tooltip.styles'

export const Tooltip = ({
    continuous,
    index,
    step,
    backProps,
    primaryProps,
    tooltipProps,
    size,
}: TooltipRenderProps) => {
    return (
        <S.TooltipWrapper {...tooltipProps} ref={tooltipProps.ref}>
            <S.Header>
                <S.Title>{step.title}</S.Title>

                <S.Steps>
                    {index + 1}/{size}
                </S.Steps>
            </S.Header>

            <hr />

            <S.Content>{step.content}</S.Content>

            <S.Actions>
                {index > 0 && (
                    <Button {...backProps} size="small" iconOnly color="secondary">
                        <ChevronLeftIcon type="line" />
                    </Button>
                )}

                {continuous && (
                    <Button {...primaryProps} size="small" iconOnly color="secondary">
                        <ChevronRightIcon type="line" />
                    </Button>
                )}
            </S.Actions>
        </S.TooltipWrapper>
    )
}
