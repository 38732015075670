import i18n from "../i18n";
import {isBenchmarkingCompany, isPortfolioCompany, isOrganizationCompany, isMcrockDueDiligence} from "../utils/companyChecks"

/* Do translation based on mapping.js, return original string if mapping not found */
export const mapT  = (map, str, companyName=null) => {
    if (map && map[str]) {
        let constituentString = i18n.t("GROUP_MISC.EMPLOYEES");

        if (companyName) {
            constituentString = mapCompanyToConstituent(companyName);
        }

        const output = i18n.t(map[str]).replaceAll("[client_constituent]", constituentString);
        return output;
    }
    return str;
};

export const getTranslation = (str, companyName=null) => {
    let constituentString = i18n.t("GROUP_MISC.EMPLOYEES");

    if (companyName) {
        constituentString = mapCompanyToConstituent(companyName);
    }

    if (str) {
        return i18n.t(str).replaceAll("[client_constituent]", constituentString);
    }

    return str;

};

const mapCompanyToConstituent = (companyName) => {
    if(isPortfolioCompany(companyName))
        return i18n.t("GROUP_MISC.PORTFOLIO_RESPONDENTS");
    else if(isOrganizationCompany(companyName))
        return i18n.t("GROUP_MISC.MEMBER_COMPANIES");
    else if(isBenchmarkingCompany(companyName))
        return i18n.t("GROUP_MISC.PEERS_EMPLOYEES");
    else if(isMcrockDueDiligence(companyName))
        return i18n.t("GROUP_MISC.PROSPECT_COMPANY_EMPLOYEES")
    else
        return i18n.t("GROUP_MISC.EMPLOYEES");
};
