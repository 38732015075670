import React from 'react'
import { Button, CheckmarkIcon, CloseCirclerIcon, colors } from '@diversioteam/diversio-ds'

import { EmailStatusProps } from './emailStatus.types'
import * as S from './emailStatus.styles'

export const EmailStatus = ({ type, onCloseDialog }: EmailStatusProps) => {
    const getContent = () => {
        if (type === 'success') {
            return (
                <>
                    <CheckmarkIcon width={80} height={80} color={colors.green50} type="bold" />

                    <S.Title>Processing your renewal</S.Title>

                    <S.Description>
                        An Account Executive from our team will reach out to your administrator with more details
                    </S.Description>
                </>
            )
        }

        if (type === 'warning') {
            return (
                <>
                    <CheckmarkIcon width={80} height={80} color={colors.tangerineSharp} type="bold" />

                    <S.Title>Thank you for the feedback</S.Title>

                    <S.Description>
                        An Account Executive from our team will reach out to your administrator to process the
                        cancellation.
                    </S.Description>
                </>
            )
        }

        if (type === 'error') {
            return (
                <>
                    <CloseCirclerIcon width={80} height={80} color={colors.lobster50} type="bold" />

                    <S.Title>Message failed to send</S.Title>

                    <S.Description>
                        Sorry about that, please try again later, or reach out directly to{' '}
                        <a href="mailto:hello@diversio.com">hello@diversio.com</a>
                    </S.Description>
                </>
            )
        }
    }

    return (
        <S.EmailStatusWrapper>
            {getContent()}

            <Button onClick={onCloseDialog}>Close</Button>
        </S.EmailStatusWrapper>
    )
}
