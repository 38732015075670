import { useMutation } from '@tanstack/react-query'
import { loadDiversityDataOverTime } from 'sagas/network'
import { stringifyQueryParams } from 'utils'
import mixpanel from 'mixpanel-browser'

import { DiversityDataOverTimeParams } from 'api/actions/analyze/diversityData/diversityDataOverTimeActions.types'
import { Mutations } from 'api/mutations.enum'
import TAXONOMIES from 'utils/taxonomies'

export const useExportDiversityDataOverTime = (params: DiversityDataOverTimeParams) => {
    const mutation = useMutation<string, Error, void>({
        mutationKey: [Mutations.exportDiversityDataOverTime, params],
        mutationFn: () => {
            return loadDiversityDataOverTime(stringifyQueryParams(params), 'file')
        },
        onSuccess: () => {
            mixpanel.track(TAXONOMIES.ANALYZE_DIVERSITY_DATA_OVER_TIME_EXPORT)
        },
    })

    return mutation
}
