import React, { useCallback, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { Trans, useTranslation } from 'react-i18next'
import { FormHelperText } from '@material-ui/core'
import { Checkbox, ChevronRightIcon } from '@diversioteam/diversio-ds'
import OtpInput from 'react-otp-input'

import { Footer } from './../Footer'
import { Toast } from './../Toast'
import { VerifyEmailCodeFields, VerifyEmailCodeFormData, VerifyEmailCodeProps } from './verifyEmailCode.types'
import { verifyEmailCodeSchema } from './verifyEmailCode.schema'
import diversioLogo from './../../../assets/images/diversio-logo-with-symbol-for-light-bg.svg'
import * as S from './verifyEmailCode.styles'

export const VerifyEmailCode = ({
    isLoading,
    isSuccess,
    data,
    isError,
    error,
    email,
    onResendCode,
    onClickBack,
    onSubmit,
}: VerifyEmailCodeProps) => {
    const { t } = useTranslation()

    const { control, handleSubmit, errors, setError, formState, watch } = useForm<VerifyEmailCodeFormData>({
        resolver: yupResolver(verifyEmailCodeSchema),
    })

    const { isValidating } = formState
    const verificationCode = watch(VerifyEmailCodeFields.verificationCode)

    const errorDetail = error?.response?.data.detail || ''

    useEffect(() => {
        if (isError && errorDetail === 'INVALID_CODE') {
            const errorType = 'server'

            setError(VerifyEmailCodeFields.verificationCode, {
                type: errorType,
            })
        }
    }, [isError])

    const handleSubmitCallback = useCallback(
        async (data: VerifyEmailCodeFormData) => {
            await onSubmit(data)
        },
        [onSubmit],
    )

    useEffect(() => {
        if (verificationCode?.length === 6 && !isValidating) {
            handleSubmit(handleSubmitCallback)()
        }
    }, [verificationCode?.length])

    const getErrorMessage = (fieldName: VerifyEmailCodeFields) => {
        return t(errors[fieldName]?.message || '')
    }

    return (
        <S.Container>
            <S.Form onSubmit={handleSubmit(handleSubmitCallback)}>
                <Toast
                    isLoading={isLoading}
                    isSuccess={isSuccess}
                    data={data}
                    isError={isError}
                    error={error}
                    translationPrefix="AUTH.MFA.MESSAGES"
                />

                <S.Logo src={diversioLogo} className="logo" alt="Diversio" />

                <S.Title>{t('AUTH.MFA.VERIFY_EMAIL_CODE_TITLE')}</S.Title>

                <S.Description>
                    <Trans
                        i18nKey={'AUTH.MFA.VERIFY_EMAIL_CODE_DESCRIPTION'}
                        components={{ strong: <strong /> }}
                        values={{
                            email,
                        }}
                    />
                </S.Description>

                <S.Fields>
                    <Controller
                        control={control}
                        render={(props) => (
                            <S.OtpInputWrapper>
                                <OtpInput
                                    onChange={props.onChange}
                                    value={props.value}
                                    numInputs={6}
                                    inputStyle={{ width: '46px' }}
                                    containerStyle={{
                                        display: 'flex',
                                        margin: '0 auto',
                                        gap: '12px',
                                    }}
                                    renderInput={(props) => {
                                        return (
                                            <S.TextField
                                                error={Boolean(errors[VerifyEmailCodeFields.verificationCode])}
                                                disabled={isLoading}
                                                inputProps={{
                                                    ...props,
                                                    style: {
                                                        textAlign: 'center',
                                                    },
                                                }}
                                            />
                                        )
                                    }}
                                />

                                {Boolean(errors[VerifyEmailCodeFields.verificationCode]) ? (
                                    <FormHelperText error>
                                        {getErrorMessage(VerifyEmailCodeFields.verificationCode)}
                                    </FormHelperText>
                                ) : null}
                            </S.OtpInputWrapper>
                        )}
                        name={VerifyEmailCodeFields.verificationCode}
                    />

                    <S.ResendCode>
                        {t('AUTH.MFA.VERIFY_EMAIL_CODE_RESEND_CODE')}{' '}
                        <span onClick={onResendCode}>{t('AUTH.MFA.VERIFY_EMAIL_CODE_RESEND_CODE_BUTTON')}</span>
                    </S.ResendCode>

                    <S.FormControlLabel
                        control={
                            <Controller
                                name={VerifyEmailCodeFields.trustedDevice}
                                control={control}
                                render={(props) => (
                                    <Checkbox
                                        {...props}
                                        checked={props.value}
                                        onChange={(e) => props.onChange(e.target.checked)}
                                    />
                                )}
                            />
                        }
                        label={t('AUTH.MFA.TRUSTED_DEVICE')}
                    />
                </S.Fields>

                <S.Button
                    loading={isLoading}
                    type="submit"
                    color="primary"
                    size="large"
                    rightIcon={<ChevronRightIcon type="bold" />}
                    fullWidth
                >
                    {t('AUTH.MFA.LOGIN')}
                </S.Button>

                <S.TextLink as="div" onClick={onClickBack}>
                    {t('AUTH.MFA.GO_BACK')}
                </S.TextLink>

                <Footer />
            </S.Form>
        </S.Container>
    )
}
