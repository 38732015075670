/** @format */
import React from 'react'

import {
  DocumentIcon,
  PractionerIcon,
  AudioIcon,
  WebAltIcon,
  VideoIcon
} from "@diversioteam/diversio-ds";

export const kpiMap = {
  "Career Development": "Career Development",
  "Fair Management": "Fair Management",
  "Inclusive Culture": "Inclusive Culture",
  "Recruiting & Hiring": "Recruiting & Hiring",
  "Workplace Flexibility": "Workplace Flexibility",
  "Workplace Safety": "Workplace Safety",
};

export const mapIcons = (tool) => {
  const type = tool.mediaType || tool.media_type
  if (type === "Document") {
    return <DocumentIcon type="line" />
  }
  if(type === "Webpage") {
    return <WebAltIcon type="line" />
  }

  if(type === "Video") {
    return <VideoIcon type="line" />
  }

  if(type === "Audio") {
    return <AudioIcon type="line" />
  }

  if(type === "Practitioner") {
    return <PractionerIcon type="line" />
  }

  if(type === "diversio_tool") {
    //TODO: Update
    return <DocumentIcon type="line" />
  }
  return <DocumentIcon type="line" />
}

