import { stringifyQueryParams } from 'utils'

import { diversioApiV2 } from 'api/axios/axiosInstance'

import {
    ChangeRecommendedProgramStatusPayload,
    ChangeRecommendedProgramStatusResponse,
    GetRecommendedProgramsListParams,
    GetRecommendedProgramsListResponse,
    SaveRecommendedProgramPayload,
    SaveRecommendedProgramResponse,
} from './recommendedActions.types'
import { endpoints } from './endpoints'

export const getRecommendedProgramsList = async (
    params?: GetRecommendedProgramsListParams,
): Promise<GetRecommendedProgramsListResponse> => {
    const response = await diversioApiV2.get(endpoints.programsList, {
        params,
        paramsSerializer: (params) => stringifyQueryParams(params),
    })

    return response.data
}

export const saveRecommendedProgram = async (
    body: SaveRecommendedProgramPayload,
): Promise<SaveRecommendedProgramResponse> => {
    const response = await diversioApiV2.post(endpoints.saveProgram, body)

    return response.data
}

export const deleteRecommendedProgram = async (id: number): Promise<void> => {
    await diversioApiV2.delete(endpoints.deleteProgram(id))
}

export const changeRecommendedProgramStatus = async (
    body: ChangeRecommendedProgramStatusPayload,
): Promise<ChangeRecommendedProgramStatusResponse> => {
    const response = await diversioApiV2.post(endpoints.changeProgramStatus, body)

    return response.data
}
