import { useRef } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import { getReports } from 'sagas/network'
import { stringifyQueryParams } from 'utils'

import { GetReportsParams, GetReportsResponse } from 'api/actions/reporting/reporting.types'
import { Queries } from 'api/queries.enum'
import { getNextPageParam } from 'utils/getNextPageParam'

export const useGetReports = (params?: GetReportsParams) => {
    const queryInitialPage = useRef(params?.page || 1)

    const query = useInfiniteQuery<GetReportsResponse, Error>({
        queryKey: [Queries.getReports, params],
        queryFn: ({ pageParam = queryInitialPage.current }) =>
            getReports(stringifyQueryParams({ ...params, page: pageParam })),
        getNextPageParam: ({ next }) => getNextPageParam(next),
        keepPreviousData: true,
    })

    return query
}
