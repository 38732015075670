import { AxiosError } from 'axios'
import { useMutation } from '@tanstack/react-query'

import { downloadJobAdvertAction } from 'api/actions/lumi/lumiActions'
import { Mutations } from 'api/actions/lumi/mutations.enum'

export const useDownloadJobAdvert = () => {
    const mutation = useMutation<void, AxiosError, string>({
        mutationKey: [Mutations.downloadJobAdvert],
        mutationFn: downloadJobAdvertAction,
    })

    return mutation
}
