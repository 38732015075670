/** @format */

import { ActionTypes } from "../actions";

const defaultState = {
  myGoals: [],
  myPrograms: [],
  myTools: [],
  myFilters: [],
  isLoading: {
    myGoals: false,
    myPrograms: false,
    myTools: false,
  }
};

export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case ActionTypes.DONE_SOLUTION_MY_ADD_PROGRAM:
        return {
          ...state,
        };
      case ActionTypes.LOADING_SOLUTION_MY_GOALS:
      case ActionTypes.LOADING_SOLUTION_MY_GOALS_V2:
        return {
          ...state,
          isLoading: {
            ...state.isLoading,
            myGoals: true
          }
        };
      case ActionTypes.LOADING_SOLUTION_MY_PROGRAMS:
      case ActionTypes.LOADING_SOLUTION_MY_PROGRAMS_V2:
        return {
          ...state,
          isLoading: {
            ...state.isLoading,
            myPrograms: true
          }
        };
      case ActionTypes.LOADING_SOLUTION_MY_TOOLS:
      case ActionTypes.LOADING_SOLUTION_MY_TOOLS_V2:
        return {
          ...state,
          isLoading: {
            ...state.isLoading,
            myTools: true
          }
        };
      case ActionTypes.LOADED_SOLUTION_MY_GOALS:
      case ActionTypes.LOADED_SOLUTION_MY_GOALS_V2:
        return {
          ...state,
          myGoals: action.payload,
          isLoading: {
            ...state.isLoading,
            myGoals: false
          }
        };
      case ActionTypes.LOADED_SOLUTION_MY_PROGRAMS:
      case ActionTypes.LOADED_SOLUTION_MY_PROGRAMS_V2:
        return {
          ...state,
          myPrograms: {
            ...action.payload,
            archived: action.payload?.archived || []
          },
          isLoading: {
            ...state.isLoading,
            myPrograms: false
          }
        };
      case ActionTypes.LOADED_SOLUTION_MY_TOOLS:
      case ActionTypes.LOADED_SOLUTION_MY_TOOLS_V2:
        return {
          ...state,
          myTools: action.payload,
          isLoading: {
            ...state.isLoading,
            myTools: false
          }
        };
      case ActionTypes.LOADED_SOLUTION_MY_SOLUTION_FILTERS:
        return {
          ...state,
          myFilters: action.payload,
        };
      case ActionTypes.DONE_SOLUTION_MY_SOLUTION_UPDATE_GOAL: {
        const goalsCopy = [...state.myGoals];
        const goalID = action.payload.id;
        for (const i in goalsCopy) {
          if (goalsCopy[i].id === goalID) {
            goalsCopy[i] = action.payload
            break;
          }
        }
        return {
          ...state,
          myGoals: goalsCopy,
        };
      }
      case ActionTypes.MY_SOLUTION_PAGE_ARCHIVE: {
        const programID = action.payload.id;
        const isActive = action.payload.is_active || action.payload.isActive;
        const savedCopy = [...state.myPrograms?.non_active];
        const activeCopy = [...state.myPrograms?.active];
        let index = null;

        if (isActive) {
          for (const i in activeCopy) {
            if (activeCopy[i].id === programID) {
              index = i;
              break;
            }
          }

          if (index) activeCopy.splice(index, 1);
        } else {
          for (const i in savedCopy) {
            if (savedCopy[i].id === programID) {
              index = i;
              break;
            }
          }

          if (index) savedCopy.splice(index, 1);
        }

        const updatedPrograms = {
          non_active: savedCopy,
          active: activeCopy,
          archived: [...state.myPrograms.archived]
        };
        return {
          ...state,
          myPrograms: updatedPrograms,
        };
      }

      case ActionTypes.MY_SOLUTION_PAGE_ARCHIVE_V2: {
        const programID = action.payload.id;

        const archivedProgram = state.myPrograms?.non_active.filter(program => program.id !== programID)?.[0] 
          || state.myPrograms?.active.filter(program => program.id !== programID)?.[0]

        return {
          ...state,
          myPrograms: {
            ...state.myPrograms,
            non_active: state.myPrograms?.non_active.filter(program => program.id !== programID),
            active: state.myPrograms?.active.filter(program => program.id !== programID),
            archived: [
              ...state.myPrograms?.archived,
              archivedProgram
            ]
          }
        };
      }
      
      case ActionTypes.MY_SOLUTION_PAGE_UNARCHIVE: {
        return {
          ...state,
        };
      }
      case ActionTypes.MY_SOLUTION_PAGE_ACTIVATE: {
        const programID = action.payload.program;
        const savedCopy = [...state.myPrograms?.non_active];
        const activeCopy = [...state.myPrograms?.active];

        let index = null;

        for (const i in savedCopy) {
          if (savedCopy[i].id === programID) {
            savedCopy[i].is_active = true;
            index = i;
            break;
          }
        }
        const programToMove = { ...savedCopy[index] };

        if (index) savedCopy.splice(index, 1);
        activeCopy.push(programToMove);

        const updatedPrograms = {
          non_active: savedCopy,
          active: activeCopy,
          archived: [...state.myPrograms?.archived]
        };
        return {
          ...state,
          myPrograms: updatedPrograms,
        };
      }
      case ActionTypes.MY_SOLUTION_PAGE_DEACTIVATE: {
        const programID = action.payload.program.id;
        const activeCopy = [...state.myPrograms?.active];
        const savedCopy = [...state.myPrograms?.non_active];

        let index = null;
        for (const i in activeCopy) {
          if (activeCopy[i].solution.id === programID) {
            activeCopy[i].is_active = false;
            index = i;
            break;
          }
        }

        const programToMove = { ...activeCopy[index] };

        if (index) activeCopy.splice(index, 1);
        savedCopy.push(programToMove);
        const updatedPrograms = {
          non_active: savedCopy,
          active: activeCopy,
          archived: [...state.myPrograms?.archived]
        };
        return {
          ...state,
          myPrograms: updatedPrograms,
        };
      }
      case ActionTypes.DO_SOLUTION_MY_REMOVE_COMPANY_TOOL: {
        const toolsCopy = [...state.myTools];
        const id = action.payload.companyToolId;
        let toolIndex = null;
        for (const i in toolsCopy) {
          if (toolsCopy[i].id === id) {
            toolIndex = i;
            break;
          }
        }

        if (toolIndex) toolsCopy.splice(toolIndex, 1);

        return {
          ...state,
          myTools: toolsCopy,
        };
      }
      case ActionTypes.DONE_SOLUTION_GOAL_DELETE: {
        const id = action.payload;
        const goalsCopy = [...state.myGoals];
        let index = null;
        for (const i in goalsCopy) {
          if (goalsCopy[i].id === id) {
            index = i;
            break;
          }
        }

        if (index) goalsCopy.splice(index, 1);

        return {
          ...state,
          myGoals: goalsCopy,
        };
      }
      case ActionTypes.DONE_DELETE_GOAL: {
        const id = action.payload;
        const goalsCopy = [...state.myGoals];
        let index = null;
        for (const i in goalsCopy) {
          if (goalsCopy[i].id === id) {
            index = i;
            break;
          }
        }

        if (index) goalsCopy.splice(index, 1);

        return {
          ...state,
          myGoals: goalsCopy,
        };
      }
      case ActionTypes.MY_SOLUTION_PAGE_REMOVE_PROGRAM: {
        const companySolutionID = action.payload.companySolutionID;
        const isActive = action.payload.isActive;
        const savedCopy = [...state.myPrograms?.non_active];
        const activeCopy = [...state.myPrograms?.active];

        let index = null;
        if (isActive) {
          // remove from active programs
          for (const i in activeCopy) {
            if (activeCopy[i].id === companySolutionID) {
              index = i;
              break;
            }
          }
          if (index) activeCopy.splice(index, 1);
        } else {
          // remove from saved programs
          for (const i in savedCopy) {
            if (savedCopy[i].id === companySolutionID) {
              index = i;
              break;
            }
          }
          if (index) savedCopy.splice(index, 1);
        }
        const updatedPrograms = {
          non_active: savedCopy,
          active: activeCopy,
          archived: [...state.myPrograms?.archived],
        };

        return {
          ...state,
          myPrograms: updatedPrograms,
        };
      }
      case ActionTypes.DONE_MY_SOLUTION_REMOVE_V2:
        return {
          ...state,
          myPrograms: {
            ...state.myPrograms,
            non_active: state.myPrograms?.non_active?.filter(program => program.id !== action.payload)
          }
        }
      case ActionTypes.RESET_PROGRAMS_AND_SOLUTIONS:
        return {
          ...defaultState
        }
      default:
        return state;
    }
  } catch (error) {
    console.error(
      `Error in appState reducer: ${error.message || error.code || error}`,
      error
    );
  }
};
