import React from 'react';
import Progress from "./Progress";
import brb from '../../assets/images/survey-brb.svg';
import Loader from "../utils/Loader";

const Processing = ({ selfServe, entity = "Survey" }) => {
    React.useEffect(() => {
        const refreshTimeout = setTimeout(() => { location.reload() }, 10000)
        return function cleanup() {
            clearTimeout(refreshTimeout);
        };
    }, []);

    return (
        <div className={"survey-review"}>
            <Progress selected={"review"} selfServe={selfServe}/>

            <div id={"survey-generic"}>
                <div className={"survey-content-container"}>
                    <img src={brb} alt="Survey/Dashboard Processing"/>
                    <h5>We’ll Be Back with Your {entity}</h5>
                    <p>We’re in the process of creating your {entity}. The page will automatically refresh once the {entity} is ready.</p>
                    <Loader inline/>
                </div>
            </div>
        </div>
    );
};

export default Processing;
