import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { Mutations } from 'api/actions/useMetadata/mutations.enum'
import {
    SendOrganizationEmailErrorResponse,
    SendOrganizationEmailPayload,
    SendOrganizationEmailResponse,
} from 'api/actions/useMetadata/userMetadataActions.types'
import { sendOrganizationEmail } from 'api/actions/useMetadata/userMetadataActions'

export const useSendOrganizationEmail = () => {
    const mutation = useMutation<
        SendOrganizationEmailResponse,
        AxiosError<SendOrganizationEmailErrorResponse>,
        SendOrganizationEmailPayload
    >({
        mutationKey: [Mutations.sendOrganizationEmail],
        mutationFn: sendOrganizationEmail,
    })

    return mutation
}
