import React, { useEffect, useMemo, useState } from 'react'

import { useGetJobAdvertItemList } from 'hooks/lumi/useGetJobAdvertItemList'

import { LumiContextControllerProps } from './LumiContextController.types'
import { FileMetadata, Stage } from './LumiContext.types'
import { LumiContext } from './LumiContext'

const DEFAULT_STAGE = Stage.Init

export const LumiContextController = ({ children }: LumiContextControllerProps) => {
    const [acceptedFiles, setAcceptedFiles] = useState<FileMetadata[]>([])
    const [stage, setStage] = useState(DEFAULT_STAGE)
    const [jobId, setJobId] = useState('')

    const { mutate } = useGetJobAdvertItemList()

    useEffect(() => {
        if (stage === Stage.UpdateData) {
            mutate(jobId, {
                onSuccess: (data) => {
                    setAcceptedFiles((prevState) =>
                        prevState.map((item) => {
                            const dataItem = data.find(({ uuid }) => item.uuid === uuid)

                            if (!dataItem) {
                                return item
                            }

                            return {
                                ...item,
                                processingStatus: dataItem.status,
                            }
                        }),
                    )
                },
            })
        }
    }, [stage])

    const handleChangeStage = (stage: Stage) => {
        setStage(stage)
    }

    const handleChangeJobId = (jobId: string) => {
        setJobId(jobId)
    }

    const handleResetAcceptedFiles = () => {
        setAcceptedFiles([])
    }

    const handleResetStage = () => {
        setStage(DEFAULT_STAGE)

        handleResetAcceptedFiles()
    }

    const contextValue = useMemo(
        () => ({
            acceptedFiles,
            onChangeAcceptedFiles: setAcceptedFiles,
            onResetStage: handleResetStage,
            stage,
            onChangeStage: handleChangeStage,
            jobId,
            onChangeJobId: handleChangeJobId,
        }),
        [acceptedFiles, stage, jobId],
    )

    return <LumiContext.Provider value={contextValue}>{children}</LumiContext.Provider>
}
