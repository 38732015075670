import React, { Component } from "react";
import Card from "../../utils/Card";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Text } from "recharts";
import CircularProgress from '@material-ui/core/CircularProgress';
import _ from 'underscore';
import "./index.scss";

const ILN_PRIMARY_COLOR = "#27829E"

const CustomizedXAxisTick = (props) => {
  const { x, y, payload } = props;
  return (
    <Text className="axis-style" x={x} y={y} width={50} textAnchor="middle" verticalAnchor="start">{payload.value}</Text>)
}

const CustomizedYAxisTick = (props) => {
  const formatter = (value) => `$${value}B`;
  const { x, y, payload } = props;
  let newY = y - 5
  let newX = x - 40
  return (
    <Text className="axis-style" x={newX} y={newY} width={50} textAnchor="middle" verticalAnchor="start">{formatter(payload.value)}</Text>)
}

class AUMBreakdown extends Component {
  cleanData = (investments) => {
    // Convert the amount in each row to a float for the Y-Axis in the barchart
    // to be able to render properly

    if(_.isEmpty(investments)) return null

    const cleanedData = []
    for (const i in investments) {
      const newObj = {
        "company_name": investments[i].company_name,
        "amount": parseFloat(investments[i].amount)
      }
      cleanedData.push(newObj)
    }
    return cleanedData
  }

  render() {
    const showComponent = !_.isEmpty(this.props.investments)
    const investments = this.cleanData(this.props.investments)

    return (
      <Card className="iln-card">
        <h2 className="iln-aum-h2">AUM Breakdown (USD)</h2>
        <div className="iln-aum-barchart">
          {showComponent ? <ResponsiveContainer width="100%" height={400} padding={100}>
            <BarChart
              width={1100}
              height={300}
              data={investments}
              margin={{
                top: 5, right: 30, left: 20, bottom: 5,
              }}
            >
              <CartesianGrid vertical={false} />
              <XAxis dataKey="company_name" interval={0} tick={<CustomizedXAxisTick />} height={100} />
              <YAxis tick={<CustomizedYAxisTick />} />
              <Tooltip formatter={(value) => Math.round(value * 100) / 100} />
              <Bar dataKey="amount" fill={ILN_PRIMARY_COLOR} />
            </BarChart>
          </ResponsiveContainer> :
          <div className="iln-aum-loading">
            <CircularProgress style={{ color: ILN_PRIMARY_COLOR }} />
          </div>}
        </div>
      </Card>
    )
  }
}

export default AUMBreakdown
