import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { Mutations } from 'api/actions/lumi/mutations.enum'
import { createJobItemAction } from 'api/actions/lumi/lumiActions'
import { JobAdvertItemResponse, UploadJobFileMutationPayload } from 'api/actions/lumi/lumiActions.types'

import { useUploadJobFile } from './useUploadJobFile'

export const useCreateJobAdvertItem = () => {
    const mutationUploadFile = useUploadJobFile()

    const mutation = useMutation<JobAdvertItemResponse, AxiosError, UploadJobFileMutationPayload>({
        mutationFn: (variables) => createJobItemAction(variables.jobId),
        mutationKey: [Mutations.createJobAdvertItem],
        onSuccess: (data, variables) => {
            mutationUploadFile.mutate({ ...variables, jobFileUUID: data.uuid })
        },
    })

    return mutation
}
