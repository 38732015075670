import { AxiosError } from 'axios'

import { AuthErrorResponse, AuthResponse } from 'api/actions/auth/authActions.types'

export type VerifyEmailCodeProps = {
    isLoading: boolean
    isSuccess: boolean
    data?: AuthResponse
    isError: boolean
    error: AxiosError<AuthErrorResponse> | null
    email: string
    onResendCode: () => void
    onClickBack: () => void
    onSubmit(values: VerifyEmailCodeFormData): Promise<boolean>
}

export enum VerifyEmailCodeFields {
    verificationCode = 'verification_code',
    trustedDevice = 'trustedDevice',
}

export type VerifyEmailCodeFormData = {
    [VerifyEmailCodeFields.verificationCode]: string
    [VerifyEmailCodeFields.trustedDevice]: boolean
}
