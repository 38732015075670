import { getFontStyle } from '@diversioteam/diversio-ds'
import styled from 'styled-components'

export const Email = styled.div`
    color: ${({ theme }) => theme.colors.primary70};

    ${getFontStyle('cardHeaderBold')};
`

export const Buttons = styled.div`
    width: 100%;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 12px;
`

export const Footer = styled.footer`
    margin-top: 20px;
    text-align: center;

    ${getFontStyle('p02Regular')};
`

export const Help = styled.div`
    margin-top: 24px;
    text-align: center;
    color: ${({ theme }) => theme.colors.secondary500};

    ${getFontStyle('p02Regular')};

    a {
        color: ${({ theme }) => theme.colors.primary50};

        ${getFontStyle('p02Underline')};
    }
`

export { Button, SecondaryButton, Container, Form, Logo, Title, TextLink } from './../auth.styles'
