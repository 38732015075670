import React, { forwardRef } from 'react'
import { IntersectionalityFilterRef } from '@diversioteam/diversio-ds'

import { withErrorBoundary } from 'config/withErrorBoundary/withErrorBoundary'
import { DataRepresentationOverTime } from '../DataRepresentationOverTime'

import { useFilters } from './../../hooks/useFilters'
import { Heatmap } from './../Heatmap'
import { FreeTextsContainer } from './../FreeTexts'
import { DataProps } from './data.types'

const DataWithoutEB = forwardRef<IntersectionalityFilterRef, DataProps>(function Data(
    { selectedTab, selectedTabType },
    ref,
) {
    const { resultsOverTime } = useFilters()

    return !resultsOverTime ? (
        <>
            <Heatmap selectedTab={selectedTab} selectedTabType={selectedTabType} ref={ref} />

            <FreeTextsContainer />
        </>
    ) : (
        <DataRepresentationOverTime selectedTab={selectedTab} />
    )
})

export const Data = withErrorBoundary<DataProps & { ref: React.Ref<IntersectionalityFilterRef> }>(DataWithoutEB, {})
