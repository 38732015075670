import React from 'react';
import Card from '../../utils/Card';
import { withTranslation } from 'react-i18next';
import bvpLogo from '../../../images/logos/bvp_logo.jpeg';
import cifarLogo from '../../../images/logos/cifarLogo.jpg'
import acmeLogo from '../../../images/logos/acme.png'
import { isCifarWithStages, isBessemerBriefPortfolio, isBessemerAnonymized } from '../../../utils/companyChecks'
import './index.scss';

/*
Custom Inclusion Scorecard Logo's
*/

const InclusionScoreCustomLogo = ({companyName}) => {
    
    const findCompanyMetaData = () => {
        const companyData = {}
        if(isCifarWithStages(companyName)) {
            companyData.title = 'CIFAR Dashboard'
            companyData.image = cifarLogo
        }
        else if(isBessemerBriefPortfolio(companyName)) {
            companyData.title = 'BVP Portfolio'
            companyData.image = bvpLogo
        }
        else if(isBessemerAnonymized(companyName)) {
            companyData.title = 'Acme Capital'
            companyData.image = acmeLogo
        }
        return companyData
    }

    const companyMetaData = findCompanyMetaData()

    return (
        <Card>
            <div className="inclusion-score">
                <h3 className="inclusion-score__card-title-bvp">{companyMetaData.title}</h3>
                <div className="inclusion-score__container">
                    <img src={companyMetaData.image} width="200px"/>
                </div>
            </div>
        </Card>
    )
};

export default withTranslation()(InclusionScoreCustomLogo);
