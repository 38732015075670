import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const PARAM_NAME = 'sentry_test'

export const useCheckSentry = () => {
    const location = useLocation()

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search)
        const sentryTest = queryParams.get(PARAM_NAME)

        if (sentryTest === 'true') {
            throw new Error('Test error to verify Sentry.')
        }
    }, [location])
}
