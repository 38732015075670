import React from 'react';
import './index.scss';

const ILNAbout = () => {
    return(
        <div className="iln-about">
            <div className="icon-iln-logo-container">
                <img className="icon-iln-logo"/>
            </div>
            <div className="iln-text">
                <p>Welcome to the ILN Dashboard. Your access to
                portfolio metrics to help track investment and diversity performance.
                </p>
                <a href={"https://www.investorleadershipnetwork.org/"} target="_blank" rel="noopener noreferrer">
                    Learn about the initiative
                </a>
            </div>
        </div>
    )
}

export default (ILNAbout);
