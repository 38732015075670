import { loadPastSurveys } from 'sagas/network'

import { Queries } from 'api/queries.enum'
import { GetPastSurveysResponse } from 'api/actions/home/pastSurveys/pastSurveysActions.types'

import { useQuery } from './useQuery'

export const useGetPastSurveys = () => {
    const query = useQuery<GetPastSurveysResponse, Error>({
        queryKey: [Queries.getPastSurveys],
        queryFn: loadPastSurveys,
    })

    return query
}
