import React, { useEffect, useMemo, useState } from 'react'
import { SelectedDataItem } from '@diversioteam/diversio-ds/dist/components/core/CascaderFilters/cascaderFilters.types'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { parseYearQuarter } from 'utils'
import { createFiltersFromPayload, createFiltersPayloadV2 } from 'components/Analyze/Filters/utils'

import { SelectedFilters } from 'components/AnalyzeV2/Diversity'
import { Demographic } from 'api/actions/analyze/companyFilters/companyFiltersActions.types'
import { useGetCompanyFilters } from 'hooks/companyFilters/useGetCompanyFilters'
import { useSurveys } from 'hooks/useSurveys/useSurveys'
import { useUrlState } from 'hooks/useUrlState/useUrlState'
import { AnalyzeParams, AnalyzeRouteParamValue } from '../analyze.types'

import { FiltersContext } from './FiltersContext'
import { FiltersContextControllerProps } from './FiltersContextController.types'

export const FiltersContextController = ({ children }: FiltersContextControllerProps) => {
    const { setUrlState, removeFromUrlState, urlState } = useUrlState()
    const history = useHistory()
    const location = useLocation()

    const { overTime } = useParams<AnalyzeParams>()
    const hasOverTimeParam = overTime === AnalyzeRouteParamValue.OvertTime

    const [resultsOverTime, setResultsOverTime] = useState(hasOverTimeParam)

    const { filters_in_profiles, ...params } = urlState

    const filtersParams = Object.fromEntries(
        Object.entries(params).filter(([key]) => !['name', 'year', 'quarter'].includes(key)),
    ) as SelectedFilters

    const [filtersOpen, setFiltersOpen] = useState(false)
    const [selectedFilters, setSelectedFilters] = useState<SelectedDataItem[]>([])

    const [selectedSurveysFilter, setSelectedSurveysFilter] = useState('')
    const [profilesActive, setProfilesActive] = useState(false)
    const [defaultSelectedProfileId, setDefaultSelectedProfileId] = useState<string | undefined>(undefined)
    const [demographic, setDemographic] = useState<Demographic | undefined>()

    const { survey } = useSurveys()

    const hasFiltersInProfiles = filters_in_profiles !== undefined
    const defaultFiltersInProfiles = hasFiltersInProfiles ? filters_in_profiles === 'true' : true
    const [isFiltersInProfilesActive, setIsFiltersInProfilesActive] = useState(defaultFiltersInProfiles)

    const handleToggleFiltersInProfilesActive = () => {
        setIsFiltersInProfilesActive((prevState) => !prevState)
    }

    const apiParams = {
        year: parseYearQuarter(survey),
    }

    const queryGetCompanyFilters = useGetCompanyFilters(apiParams)

    const ogFilters = queryGetCompanyFilters.data?.filtersModal

    const selectedFiltersPayload = useMemo(() => {
        return createFiltersPayloadV2(selectedFilters, ogFilters)
    }, [ogFilters, selectedFilters])

    useEffect(() => {
        if (queryGetCompanyFilters.isFetched) {
            _setSelectedFilters(
                createFiltersFromPayload(
                    filtersParams,
                    queryGetCompanyFilters.data?.filtersModal,
                ) as SelectedDataItem[],
            )
        }
    }, [queryGetCompanyFilters.isFetched])

    useEffect(() => {
        if (!ogFilters) {
            return
        }

        const selectedFiltersParams = Object.entries(selectedFiltersPayload).filter(([_key, value]) => {
            // W10= is the base64 value of an empty array "[]" which we don't want to send as a query param
            // e30= is the base64 value of a not valid (error) value
            return value !== 'W10=' && value !== 'e30='
        })

        if (selectedFiltersParams.length === 0) {
            return
        }

        const selectedFiltersParamsObj = Object.fromEntries(selectedFiltersParams)

        const newUrlState = { ...selectedFiltersParamsObj, year: params.year, quarter: params.quarter }

        setUrlState(newUrlState, { replace: true })
    }, [selectedFiltersPayload])

    useEffect(() => {
        if (resultsOverTime && !hasOverTimeParam) {
            history.replace(`${location.pathname}/${AnalyzeRouteParamValue.OvertTime}${location.search}`)
        }

        if (!resultsOverTime) {
            history.replace(
                `${location.pathname.replace(`/${AnalyzeRouteParamValue.OvertTime}`, '')}${location.search}`,
            )
        }
    }, [resultsOverTime])

    const _setSelectedFilters = (filters: SelectedDataItem[]) => {
        if (queryGetCompanyFilters.isLoading) {
            return
        }

        const ogFilters = queryGetCompanyFilters.data?.filtersModal

        if (!ogFilters) {
            return
        }

        const withDefaultValues = ogFilters
            ?.map((filter) => {
                const availableOptions =
                    filters.find((f) => f.name === filter.name) || filters.find((f) => f.name === filter.key)

                if (!availableOptions) {
                    return null
                }

                if (Array.isArray(availableOptions.options) && !Array.isArray(filter.options)) {
                    removeFromUrlState(filter.key)
                    removeFromUrlState(`${filter.key}_type`)

                    return null
                }

                if (Array.isArray(filter.options) && !Array.isArray(availableOptions.options)) {
                    removeFromUrlState(filter.key)
                    removeFromUrlState(`${filter.key}__type`)

                    return null
                }

                return {
                    name: filter.name,
                    key: filter.key,
                    options: availableOptions?.options,
                }
            })
            .filter(Boolean) as SelectedDataItem[]

        setSelectedFilters(withDefaultValues)
    }

    return (
        <FiltersContext.Provider
            value={{
                filtersOpen,
                setFiltersOpen,
                selectedFilters,
                setSelectedFilters: _setSelectedFilters,
                resultsOverTime,
                setResultsOverTime,
                selectedSurveysFilter,
                setSelectedSurveysFilter,
                selectedFiltersPayload,
                profilesActive,
                setProfilesActive,
                defaultSelectedProfileId,
                setDefaultSelectedProfileId,
                demographic,
                setDemographic,
                isFiltersInProfilesActive,
                handleToggleFiltersInProfilesActive,
            }}
        >
            {children}
        </FiltersContext.Provider>
    )
}
