import {ActionTypes} from '../actions';

const defaultState = {
    painpoint: [],
    diversityComposition: [],
    industryDiversityComposition: [],
    diversityCompositionBreakdown: [],
    metricOverview: [],
    focusArea: [],
    inclusionScore: undefined,
    execSummary: undefined,
    isInformingPurchase: false,
    companyInformation: {
        company_permission: undefined
    },
    purchased: false,
    choseCompany: false,
    employeeOverview: [],
};


export default (state = defaultState, action) => {
    try {
        switch (action.type) {
            case ActionTypes.LOADED_PAINPOINT_DATA: {
                return {
                    ...state,
                    painpoint: action.payload
                };
            }
            case ActionTypes.LOADED_DIVERSITY_COMPOSITION_DATA: {
                return {
                    ...state,
                    diversityComposition: action.payload.diversity_composition,
                    industryDiversityComposition: action.payload.industry_diversity_composition,
                    diversityCompositionBreakdown: action.payload.diversity_breakdown
                };
            }
            case ActionTypes.LOADED_METRIC_OVERVIEW_DATA: {
                return {
                    ...state,
                    metricOverview: action.payload.metric_overview
                };
            }
            case ActionTypes.LOADED_FOCUS_AREA_DATA: {
                return {
                    ...state,
                    focusArea: action.payload
                };
            }
            case ActionTypes.LOADED_INCLUSION_SCORE_DATA: {
                return {
                    ...state,
                    inclusionScore: action.payload
                };
            }
            case ActionTypes.LOADED_EXEC_SUMMARY_DATA: {
                return {
                    ...state,
                    execSummary: action.payload
                };
            }
            case ActionTypes.DOING_INFORM_PURCHASE:
                return {
                    ...state,
                    isInformingPurchase: true
                };
            case ActionTypes.LOADED_COMPANY_INFORMATION:
                return {
                    ...state,
                    companyInformation: Object.assign({}, state.companyInformation, action.payload)
                };
            case ActionTypes.LOADED_EMPLOYEE_OVERVIEW_DATA:
                return {
                    ...state,
                    employeeOverview: action.payload,
                };
            case ActionTypes.DONE_ACTIVE_DASHBOARD:
                return {
                    ...state,
                    choseCompany: true
                }
            case ActionTypes.DO_CLEAR_COMPANY_INFORMATION:
                return {
                    ...state,
                    companyInformation: {
                        company_permission: undefined
                    }
                }
            case ActionTypes.DONE_SIGNOUT:
                return {
                    ...defaultState
                }
            default:
                return state;

        }
    } catch (error) {
        console.error(`Error in appState reducer: ${error.message || error.code || error}`, error);
    }
};
