import * as yup from 'yup'

import { ResetPasswordFields } from './resetPassword.types'

export const resetPasswordSchema = yup.object().shape({
    [ResetPasswordFields.password]: yup
        .string()
        .required('AUTH.REQUIRED_FIELD')
        .min(8, 'AUTH.REGISTER.LENGTH_ERR')
        .max(128, 'AUTH.REGISTER.LENGTH_ERR')
        .matches(/^(?=.*[!@#$%^&*])/, 'AUTH.REGISTER.SPECIAL_CHAR_ERR')
        .matches(/^(?=.*[a-z])/, 'AUTH.REGISTER.LOWER_CASE_ERR')
        .matches(/^(?=.*[A-Z])/, 'AUTH.REGISTER.UPPER_CASE_ERR')
        .matches(/^(?=.*[0-9])/, 'AUTH.REGISTER.NUMERIC_ERR'),
    [ResetPasswordFields.confirmPassword]: yup
        .string()
        .oneOf([yup.ref(ResetPasswordFields.password)], 'AUTH.REGISTER.NOT_MATCH'),
})
