import React, {Component} from 'react';
import './index.scss'
import {SLACK_AUTHORIZE_URL} from "../../Communications/utils/constants";

class Tokens extends Component {
    render() {
        return (
            <div id="tokens">
                <h2>Slack</h2>
                <p>Please follow <a href={SLACK_AUTHORIZE_URL}>this link</a> to reset your Slack credentials used in
                    the Communications tab. You may revoke your access tokens at any time <a href="https://api.slack.com/tokens">here</a>.
                </p>
            </div>
        );
    }
}

export default Tokens;
