import React from 'react'
import { colors, GlobeIcon } from '@diversioteam/diversio-ds'

import { RegionSwitchProps } from './regionSwitch.types'
import * as S from './regionSwitch.styles'

export const RegionSwitch = ({ sso = false, ...props }: RegionSwitchProps) => {
    const region = process.env.REACT_APP_DEPLOYMENT_REGION

    const regionLink =
        region === 'eu-north-1' ? process.env.REACT_APP_CA_REGION_LINK : process.env.REACT_APP_EU_REGION_LINK
    const link = sso ? `${regionLink}/sso` : regionLink

    const regionToSwitch = region === 'eu-north-1' ? 'Global' : 'EU'

    return (
        <S.RegionSwitchWrapper href={link} {...props}>
            <GlobeIcon type="line" color={colors.secondary500} />
            Switch region to {regionToSwitch}
        </S.RegionSwitchWrapper>
    )
}
