import { useRef } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import {
    GetRecommendedProgramsListParams,
    GetRecommendedProgramsListResponse,
} from 'api/actions/recommended/recommendedActions.types'
import { Queries } from 'api/actions/recommended/queries.enum'
import { getRecommendedProgramsList } from 'api/actions/recommended/recommendedActions'
import { getNextPageParam } from 'utils/getNextPageParam'

export const useGetRecommendedProgramsList = (params?: GetRecommendedProgramsListParams) => {
    const queryInitialPage = useRef(params?.page || 1)

    const query = useInfiniteQuery<GetRecommendedProgramsListResponse, AxiosError>({
        queryKey: [Queries.getRecommendedProgramsList],
        queryFn: ({ pageParam = queryInitialPage.current }) =>
            getRecommendedProgramsList({ ...params, page: pageParam }),
        getNextPageParam: ({ next }) => getNextPageParam(next),
        keepPreviousData: true,
    })

    return query
}
