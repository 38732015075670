import styled from 'styled-components'
import { getFontStyle, makeLineClamp } from '@diversioteam/diversio-ds'

const BASE_CLASS = 'chart-legend-item'

export const ChartLegendItemWrapper = styled.div.attrs({
    className: BASE_CLASS,
})`
    max-width: fit-content;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
`

export const Block = styled.span.attrs({
    className: `${BASE_CLASS}__block`,
})`
    flex: 1;
    display: flex;
    align-items: center;
    gap: 8px;

    div[class*='-icon']:not(.${BASE_CLASS}__marker div) {
        width: 12px;
        height: 12px;
        background-color: ${({ theme }) => theme.colors.secondary300};
    }
`

export const Marker = styled.div.attrs({
    className: `${BASE_CLASS}__marker`,
})`
    background: white;
    height: 20px;
    width: 20px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.colors.secondary400};
`

export const Label = styled.span.attrs({
    className: `${BASE_CLASS}__label`,
})`
    word-break: break-all;
    color: ${({ theme }) => theme.colors.secondary500};

    ${getFontStyle('captionRegular')};

    ${makeLineClamp(1)};
`
