import { useContext } from 'react'
import { UseQueryOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { Queries } from 'api/actions/home/queries.enum'
import {
    BenchmarkTransformed,
    GetBenchmarksResponse,
    GetBenchmarksTransformedResponse,
} from 'api/actions/home/benchmarks/benchmarksActions.types'
import { BenchmarksContext } from 'context/benchmarks/benchmarksContext/BenchmarksContext'
import { useQuery } from 'hooks/useQuery'
import { getIndustryBenchmarks, getNationalBenchmarks } from 'api/actions/home/benchmarks/benchmarkActions'
import { preventRefetchQueryOptions } from 'utils/queryOptions'

export const useBenchmarks = () => {
    const context = useContext(BenchmarksContext)

    const transformData = (data: GetBenchmarksResponse): GetBenchmarksTransformedResponse => {
        return (
            data?.map((item) => ({
                ...item,
                value: item.uuid,
            })) || []
        )
    }

    const findPrimary = ({ isPrimary }: BenchmarkTransformed) => isPrimary

    const sharedQueryOptions: UseQueryOptions<GetBenchmarksResponse, AxiosError, GetBenchmarksTransformedResponse> = {
        keepPreviousData: true,
        select: transformData,
        ...preventRefetchQueryOptions,
    }

    const queryNationalBenchmarks = useQuery<GetBenchmarksResponse, AxiosError, GetBenchmarksTransformedResponse>({
        queryKey: [Queries.getNationalBenchmarks],
        queryFn: getNationalBenchmarks,
        ...sharedQueryOptions,
    })

    const queryIndustryBenchmarks = useQuery<GetBenchmarksResponse, AxiosError, GetBenchmarksTransformedResponse>({
        queryKey: [Queries.getIndustryBenchmarks],
        queryFn: getIndustryBenchmarks,
        ...sharedQueryOptions,
    })

    const primaryNationalBenchmark = queryNationalBenchmarks.data?.find(findPrimary)
    const primaryIndustryBenchmark = queryIndustryBenchmarks.data?.find(findPrimary)

    return {
        queryNationalBenchmarks,
        queryIndustryBenchmarks,
        primaryNationalBenchmark,
        primaryIndustryBenchmark,
        ...context,
    }
}
