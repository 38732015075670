import React from 'react'

import { withErrorBoundary } from 'config/withErrorBoundary/withErrorBoundary'

import { FreeTextsStateContextController } from './context/FreeTextsStateContextController'
import { FreeTexts } from './freeTexts'

const FreeTextsContainerWithoutEB = () => {
    return (
        <FreeTextsStateContextController>
            <FreeTexts />
        </FreeTextsStateContextController>
    )
}

export const FreeTextsContainer = withErrorBoundary(FreeTextsContainerWithoutEB, {})
