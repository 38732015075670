import { GrowthBook } from "@growthbook/growthbook-react";

// Growthbook is an open source feature flag service in which one can create a new flag in any of the 
// local, staging, production environment to hide an experimental feature behind a feature flag. In order
// to test the experimental feature one can force enable it for just that id through the growthbook portal or
// for all customers with just a button click. 

// The goal of using the feature flag service is to release with a button click and to insist daily code pushes 
// within the frontend team so that small code pushes can be reviewed
// and deployed on a daily basis. Once the experimental build is tested on a 
// test user, releasing it to the user will only require toggling the flag from the portal.

// Once the flag is created and set for each one of the local, prod, staging environment in the growthbook dashboard
// Developer can access the value using following 3 options. 

// // Option 1: For a function based component
// import { useFeature } from "@growthbook/growthbook-react";

// function MyComponent() {
//   const isEnabled = useFeature("home-analyze-v2").on;
//   return (
//     <div>{isEnabled ? "ON" : "OFF"}</div>
//   )
// }

// // Option 2: Using growthbook provided IfFeatureEnabled Component and wrapping around the experimental feature.
// import { IfFeatureEnabled } from "@growthbook/growthbook-react";

// function MyOtherComponent() {
//   return (
//     <IfFeatureEnabled feature="home-analyze-v2">
//       The feature is <strong>ON</strong>
//     </IfFeatureEnabled>
//   )
// }

// Option3: For a class based component
// import { featureFlagger } from "src/featureFlagger";

// featureFlagger.getFeatureValue("home-analyze-v2")

// For the cdn hosted version of growthbook, there is a 30sec delay until it's reflected in the UI. This will be fixed in future
// once we have a self-hosted version. 

// Create a GrowthBook instance
export const featureFlagger = new GrowthBook({
  apiHost: process.env.REACT_APP_GROWTHBOOK_HOST,
  clientKey: process.env.REACT_APP_GROWTHBOOK_CLIENT_KEY,
  enableDevMode: process.env.REACT_APP_GROWTHBOOK_DEV_MODE,
});
