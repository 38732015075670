import React from 'react';
import { withTranslation } from "react-i18next";
import "./index.scss";
import _ from "underscore";
import {Button} from "react-bootstrap";
import {Link} from "react-router-dom";

const ERROR_MESSAGES = {
    INVALID_TOKEN: "The registration link seems to be expired. Please email <a href='mailto:admin@diversio.com'>admin@diversio.com</a> to generate a new link.",
    EXPIRED_OR_INVALID_SSO_TOKEN: "The registration link seems to be expired. Please email <a href='mailto:admin@diversio.com'>admin@diversio.com</a> to generate a new link.",
    DEFAULT: "The page you are looking for might be removed or temporarily unavailable",
    GATEWAY_ERROR: "Bad Gateway: Not receiving a valid response from the servers.",
}

const ErrorPage = ({ t, location }) => {
    let url = location.search || "";
    const queryParams = _.getQueryParams(url);
    const errorCode = queryParams?.code;
    const errorMessage = errorCode && ERROR_MESSAGES[errorCode] ? ERROR_MESSAGES[errorCode] : ERROR_MESSAGES["DEFAULT"]

    return (
        <div className="error-page bootstrap-override">
            <h1>Oops!</h1>
            <p dangerouslySetInnerHTML={{ __html: errorMessage }}></p>
            <Link to={'/login'}>
                <Button className={"login__btn"} type={"submit"} variant="primary" color="primary">
                    {t("AUTH.REGISTER.GOTO_LOGIN")}
                </Button>
            </Link>
        </div>
    );
};

export default withTranslation()(ErrorPage);
