import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import { useIdleTimer } from 'react-idle-timer'
import mixpanel from 'mixpanel-browser'

import { AppRoute } from 'routing/AppRoute.enum'
import { RootState } from 'reducers/rootState.types'
import { SendVerificationCode } from 'components/Auth/SendVerificationCode'
import { useRequestVerificationCode } from 'hooks/auth/useRequestVerificationCode/useRequestVerificationCode'

import TAXONOMIES from './../../../utils/taxonomies'
import { Actions } from './../../../actions'
import { VerificationTypeLocationState } from './../auth.types'

export const SendVerificationCodeContainer = () => {
    const { accessToken, refreshToken, email, username } = useSelector((state: RootState) => state.auth)

    const dispatch = useDispatch()

    const history = useHistory()

    const location = useLocation()

    const mutationRequestVerificationCode = useRequestVerificationCode()

    const handleSubmit = useCallback(async (): Promise<boolean> => {
        try {
            mixpanel.track(TAXONOMIES.TWO_FACTOR_AUTHENTICATION_CODE_REQUESTED, {
                twoFactorClickedAt: new Date().toISOString(),
                username: username,
                verificationType: 'email',
            })

            await mutationRequestVerificationCode.mutateAsync({
                verification_type: 'email',
            })

            return true
        } catch (error) {
            return false
        }
    }, [mutationRequestVerificationCode])

    const handleOnAction = () => {
        dispatch(Actions.doRefreshToken({ accessToken, refreshToken }))
    }

    const handleOnIdle = () => {
        dispatch(Actions.doSignout())
    }

    const idleTimer = useIdleTimer({
        onIdle: handleOnIdle,
        onAction: handleOnAction,
        timeout: 1000 * 60 * Number(process.env.REACT_APP_INACTIVE_EXPIRE_TIME),
        throttle: 1000 * 60 * Number(process.env.REACT_APP_TOKEN_REFRESH_TIME),
    })

    const handleBack = () => {
        idleTimer.pause()

        dispatch(Actions.doSignout())
    }

    const handleBackupClick = () => {
        const state: VerificationTypeLocationState = { verificationType: 'backup_code' }

        return history.push({ pathname: AppRoute.TwoStepVerificationBackup, search: location.search, state })
    }

    if (mutationRequestVerificationCode.isSuccess) {
        const state: VerificationTypeLocationState = { verificationType: 'email' }

        return <Redirect to={{ pathname: AppRoute.TwoStepVerification, search: location.search, state }} />
    }

    if (!accessToken) {
        return <Redirect to={AppRoute.Login} />
    }

    return (
        <SendVerificationCode
            email={email}
            onSubmit={handleSubmit}
            onClickBack={handleBack}
            onClickBackup={handleBackupClick}
            isLoading={mutationRequestVerificationCode.isLoading}
            isSuccess={mutationRequestVerificationCode.isSuccess}
            data={mutationRequestVerificationCode.data}
            isError={mutationRequestVerificationCode.isError}
            error={mutationRequestVerificationCode.error}
        />
    )
}
