import { useContext } from 'react'

import { SurveysContext } from 'context/surveys/surveysContext/SurveysContext'
import { SurveysContextValueType } from 'context/surveys/surveysContext/SurveysContext.types'

export const useSurveys: () => SurveysContextValueType = () => {
    const context = useContext(SurveysContext)

    if (context === undefined) {
        throw new Error('useSurveys must be used within an SurveysContextController')
    }

    return context
}
