import * as Sentry from '@sentry/react';

export function beaconInit() {
    try {
        Beacon('init', process.env.REACT_APP_BEACON_ID);
    }
    catch(error) {
        console.error(`Beacon error: ${ error.message || error.code || error }`, error);
        Sentry.captureException(`Beacon error: ${ error.message || error.code || error }`);
    }
}

export function beaconIdentify(username, email, companies, current_company) {
    try {
        let obj = {
            name: username,
            email: email,
        }
        if (current_company) {
            obj.user_company = current_company
        }
        if (companies) {
            obj.user_companies = companies
        }
        Beacon('identify', obj)
    }
    catch(error) {
        console.error(`Beacon error: ${ error.message || error.code || error }`, error);
        Sentry.captureException(`Beacon error: ${ error.message || error.code || error }`);
    }
}

export function beaconDestroy() {
    try {
        Beacon('destroy');
    }
    catch(error) {
        console.error(`Beacon error: ${ error.message || error.code || error }`, error);
        Sentry.captureException(`Beacon error: ${ error.message || error.code || error }`);
    }
}

export function beaconShowNps() {
    try {
        Beacon('show-message', process.env.REACT_APP_BEACON_NPS_ID)
    }
    catch(error) {
        console.error(`Beacon error: ${ error.message || error.code || error }`, error);
        Sentry.captureException(`Beacon error: ${ error.message || error.code || error }`);
    }
}

