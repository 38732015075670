import { getFontStyle } from '@diversioteam/diversio-ds'
import styled from 'styled-components'

import { media } from 'styles/theme/mediaQueries'

export const EmptyListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    color: ${({ theme }) => theme.colors.secondary500};
    text-align: center;
    padding: 24px;

    ${getFontStyle('p02Regular')}

    ${media.lg} {
        padding: 72px 0 24px;
    }
`
