import { loadProgressBanner } from 'sagas/network'

import { Queries } from 'api/queries.enum'
import { GetProgressBannerResponse } from 'api/actions/home/progressBanner/progressBannerActions.types'

import { useQuery } from './useQuery'

export const useGetProgressBanner = () => {
    const query = useQuery<GetProgressBannerResponse, Error>({
        queryKey: [Queries.getProgressBanner],
        queryFn: loadProgressBanner,
    })

    return query
}
