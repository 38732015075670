type Breakpoints = {
    sm: number
    md: number
    lg: number
}

export const breakpoints: Breakpoints = {
    sm: 920,
    md: 1300,
    lg: 1900,
}

export const mediaQuery = (key: keyof typeof breakpoints) => `@media (min-width: ${breakpoints[key]}px)`

export const media: { [key in keyof typeof breakpoints]: string } = Object.keys(breakpoints).reduce((acc, key) => {
    const breakpointKey = key as keyof typeof breakpoints

    acc[breakpointKey] = mediaQuery(breakpointKey)

    return acc
}, {} as { [key in keyof typeof breakpoints]: string })
