import React from 'react'
import { Skeleton as MuiSkeleton } from '@material-ui/lab'
import { ChevronDownIcon } from '@diversioteam/diversio-ds'

import * as S from './../FreeTextAccordion/freeTextAccordion.styles'

export const FreeTextAccordionSkeleton = () => {
    return (
        <S.Accordion>
            <S.AccordionSummary
                expandIcon={<ChevronDownIcon type="line" />}
                aria-controls="skeleton-content"
                id="skeleton-header"
                IconButtonProps={{
                    disableTouchRipple: true,
                }}
            >
                <MuiSkeleton width={360} />
            </S.AccordionSummary>

            <S.AccordionDetails>
                <S.Responses>
                    <S.Response>
                        <MuiSkeleton width="100%" />
                    </S.Response>
                </S.Responses>
            </S.AccordionDetails>
        </S.Accordion>
    )
}
