import React, { useEffect } from 'react'
import { ToyFaces, GoalCard, ScoreCard } from '@diversioteam/diversio-ds'

import { CreateGoalProps } from './createGoal.types'
import * as S from './createGoal.styles'

export const CreateGoal = ({
    isCustomGoal,
    goalCardData,
    scoreCardData,
    goalTitle,
    onOpenModal,
    onScrollToTop,
}: CreateGoalProps) => {
    useEffect(() => {
        onScrollToTop()
    }, [])

    return (
        <S.CreateGoal>
            <S.Steps>
                <S.StepsItemSecondary>
                    <S.TitleSecondary>Insight</S.TitleSecondary>

                    <S.DescriptionSecondary>
                        {isCustomGoal ? 'You chose to create a custom goal unrelated. ' : `${goalTitle}. `}

                        <S.Action onClick={onOpenModal}>Change</S.Action>
                    </S.DescriptionSecondary>
                </S.StepsItemSecondary>

                <S.StepsItem>
                    <S.TitlePrimary>Create a goal</S.TitlePrimary>

                    <S.DescriptionPrimary>Tailor this goal to your organization</S.DescriptionPrimary>
                </S.StepsItem>
            </S.Steps>

            <S.Avatars>
                <ToyFaces numAvatars={2} />
            </S.Avatars>

            <S.GoalCardWrapper>{<GoalCard {...goalCardData} />}</S.GoalCardWrapper>

            <S.ScoreCardWrapper>
                <ScoreCard {...scoreCardData} />
            </S.ScoreCardWrapper>
        </S.CreateGoal>
    )
}
