import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { AppRoute } from 'routing/AppRoute.enum'
import { Footer } from '../Footer'

import diversioLogo from './../../../assets/images/diversio-logo-with-symbol-for-light-bg.svg'
import * as S from './login.styles'

export const ScheduledMaintenance = () => {
    const { t } = useTranslation()

    return (
        <S.Container>
            <S.Form>
                <S.Logo src={diversioLogo} className="logo" alt="Diversio" />

                <S.MaintenanceMessage>
                    <p>
                        We’re constantly working to improve your experience with Diversio. To improve our services, we
                        have scheduled maintenance on <b>29th March 2024 from 8 AM EST to 12 PM EST</b>.
                    </p>
                    <p>
                        <b>During this time, Diversio will be temporarily unavailable.</b> We understand how important
                        it is for you to have uninterrupted access, and we apologize for any inconvenience this may
                        cause.
                    </p>
                    <p>
                        We appreciate your patience and understanding as we work to enhance our platform for you. If you
                        have any questions or need assistance, please don’t hesitate to contact our support team at
                        <br />
                        <a href="mailto:client-success@diversio.com">client-success@diversio.com</a>.
                    </p>

                    <p>Thank you for your support and understanding. </p>
                    <p>Team Diversio</p>
                </S.MaintenanceMessage>

                <S.TextLink as={Link} to={{ pathname: AppRoute.Login, search: location.search }}>
                    {t('AUTH.FORGOT_PASSWORD.GO_BACK')}
                </S.TextLink>

                <Footer />
            </S.Form>
        </S.Container>
    )
}
