import { AxiosError } from 'axios'
import { loadAnnouncements } from 'sagas/network'

import { Queries } from 'api/queries.enum'
import { GetAnnouncementsResponse } from 'api/actions/home/announcements/announcementsActions.types'

import { useQuery } from './useQuery'

export const useGetAnnouncements = () => {
    const query = useQuery<GetAnnouncementsResponse, AxiosError>({
        queryKey: [Queries.getAnnouncements],
        queryFn: loadAnnouncements,
    })

    return query
}
