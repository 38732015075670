import { useCallback } from 'react'
import { loadDiversityData } from 'sagas/network'
import { cleanString, stringifyQueryParams } from 'utils'
import { useQuery } from '@tanstack/react-query'
import { ColumnDef } from '@tanstack/react-table'
import { colors } from '@diversioteam/diversio-ds'
import isEmpty from 'lodash/isEmpty'

import {
    DiversityDataParams,
    DiversityDataResponse,
    Result as DiversityDataResult,
    TableData as DiversityTableData,
    TransformedData as DiversityTransformedData,
    GroupIdentifier,
    TableData,
    TransformedBarChartDataItem,
} from 'api/actions/analyze/diversityData/diversityDataActions.types'
import { getUUIDFromURL } from 'utils/getUUIDFromURL/getUUIDFromURL'
import { Queries } from 'api/queries.enum'

import { useBenchmarks } from './benchmarks/useBenchmarks'

const ascAlphaText = 'A to Z'
const ascNumericText = 'Low to High'
const descAlphaText = 'Z to A'
const descNumericText = 'High to Low'

// TODO: @sahanarula, make a dictionary of all the colors to be easily accessible in JS
const blue = 'blue'
const tangerine = 'tangerine'
const gray = 'gray'
const purple = 'purple'

export const useDiversityBarChart = (params: DiversityDataParams) => {
    const { primaryNationalBenchmark, primaryIndustryBenchmark, secondaryIndustryBenchmark } = useBenchmarks()
    const companyUUID = sessionStorage.getItem('companyUUID') || getUUIDFromURL()

    const transformData = (data?: DiversityDataResponse): DiversityTransformedData | undefined => {
        if (isEmpty(data)) {
            return undefined
        }

        const companyName = data.results?.[0]?.company?.label

        const hasIndustriesResults = data.results.some((result) => !isEmpty(result.industries))
        const hasBenchmarksResults = data.results.some((result) => !isEmpty(result.nationalBenchmarks))

        const industries = (() => {
            if (!hasIndustriesResults) {
                return []
            }

            if (primaryIndustryBenchmark) {
                if (secondaryIndustryBenchmark) {
                    return [primaryIndustryBenchmark.label, secondaryIndustryBenchmark.label]
                }

                return [primaryIndustryBenchmark.label]
            }

            return []
        })()

        const nationalAverage = primaryNationalBenchmark?.label

        const tableColumns: ColumnDef<TableData>[] = [
            {
                accessorKey: 'group',
                header: 'Group',
                meta: {
                    tooltip: {
                        sort: {
                            asc: ascAlphaText,
                            desc: descAlphaText,
                        },
                    },
                },
            },
            {
                accessorKey: cleanString(companyName),
                header: companyName,
                meta: {
                    color: gray,
                    tooltip: {
                        sort: {
                            asc: ascNumericText,
                            desc: descNumericText,
                        },
                    },
                },
            },
        ]

        if (hasBenchmarksResults && nationalAverage) {
            tableColumns.push({
                accessorKey: cleanString(nationalAverage),
                header: nationalAverage,
                meta: {
                    color: purple,
                    tooltip: {
                        sort: {
                            asc: ascNumericText,
                            desc: descNumericText,
                        },
                    },
                },
            })
        }

        if (hasIndustriesResults) {
            industries.map((industry, idx) => {
                tableColumns.push({
                    accessorKey: cleanString(industry),
                    header: industry,
                    meta: {
                        color: idx === 1 ? tangerine : blue,
                        tooltip: {
                            sort: {
                                asc: ascNumericText,
                                desc: descNumericText,
                            },
                        },
                    },
                })
            })
        }

        const parseTableData = (dataItem: DiversityDataResult): DiversityTableData => {
            let parsedTableData: DiversityTableData = {
                group: dataItem.group,
                [cleanString(companyName)]: dataItem.company.score,
            }

            if (nationalAverage && !isEmpty(dataItem.nationalBenchmarks)) {
                parsedTableData = {
                    ...parsedTableData,
                    [cleanString(nationalAverage)]: dataItem.nationalBenchmarks?.find(
                        ({ uuid }) => uuid === primaryNationalBenchmark.uuid,
                    )?.score,
                }
            }

            if (dataItem.industries?.length) {
                const industryData: { [key: string]: number } = {}

                dataItem.industries.map((industry) => {
                    industryData[cleanString(industry.label)] = industry.score
                })

                parsedTableData = {
                    ...parsedTableData,
                    ...industryData,
                }
            }

            if (dataItem.subRows?.length) {
                parsedTableData = {
                    ...parsedTableData,
                    subRows: dataItem.subRows.map((subRow) => parseTableData(subRow)),
                }
            }

            return parsedTableData
        }

        const tableData = data.results?.map((dataItem) => {
            return parseTableData(dataItem)
        })

        const createChartDataItem = (dataItem: DiversityDataResult): TransformedBarChartDataItem => {
            const nationalDataItem = !isEmpty(dataItem?.nationalBenchmarks)
                ? dataItem?.nationalBenchmarks?.find(({ uuid }) => primaryNationalBenchmark?.uuid === uuid)
                : null

            const primaryIndustryDataItem = dataItem?.industries?.find(
                ({ uuid }) => primaryIndustryBenchmark?.uuid === uuid,
            )

            const secondaryIndustryDataItem = dataItem?.industries?.find(
                ({ uuid }) => secondaryIndustryBenchmark?.uuid === uuid,
            )

            const backgroundColor: keyof typeof colors = (() => {
                switch (dataItem.groupDetails?.groupIdentifier) {
                    case GroupIdentifier.DominantNonDominantGroup:
                        return 'primary80'

                    case GroupIdentifier.NonDominantGroupBreakdownData:
                    case GroupIdentifier.NonDominantGroupBreakdownDataMultiple:
                        return 'primary65'

                    case GroupIdentifier.AdditionalIdentifiers:
                        return 'blue70'
                }

                return 'primary80'
            })()

            const data = {
                group: dataItem.group,
                totalGroupRepresentatives: dataItem.totalGroupRepresentatives,
                totalParticipants: dataItem.totalParticipants,
                company: {
                    label: dataItem.company.label,
                    value: Number(dataItem.company.score),
                },
                nationalBenchmark: {
                    label: nationalDataItem?.label ?? '',
                    value: Number(nationalDataItem?.score) ?? null,
                },
                industries: [
                    {
                        label: primaryIndustryDataItem?.label ?? '',
                        value: Number(primaryIndustryDataItem?.score) ?? null,
                    },
                    secondaryIndustryBenchmark
                        ? {
                              label: secondaryIndustryDataItem?.label ?? '',
                              value: Number(secondaryIndustryDataItem?.score) ?? null,
                          }
                        : [],
                ].flatMap((industry) => industry),
                backgroundColor,
                groupDetails: dataItem.groupDetails,
            }

            return data
        }

        const processRows = (rows: DiversityDataResult[]): TransformedBarChartDataItem[] =>
            rows?.flatMap((row: DiversityDataResult) => {
                const items = [createChartDataItem(row)]

                if (row.subRows && row.subRows.length > 0) {
                    items.push(...processRows(row.subRows))
                }

                return items
            })

        const chartResults = processRows(data.results)

        return {
            ...data,
            chartResults,
            tableResults: {
                columns: tableColumns,
                data: tableData,
            },
        }
    }

    const hasBenchmarks = primaryNationalBenchmark !== null && primaryIndustryBenchmark !== null
    const enabled = hasBenchmarks && !!companyUUID && !!params.value

    return useQuery<DiversityDataResponse | undefined, Error, DiversityTransformedData | undefined>({
        queryKey: [Queries.getDiversityData, params],
        queryFn: () => {
            return loadDiversityData(stringifyQueryParams(params))
        },
        select: useCallback(transformData, [
            primaryNationalBenchmark,
            primaryIndustryBenchmark,
            secondaryIndustryBenchmark,
        ]),
        enabled,
    })
}
