import React from 'react'
import { InclusionInsightsCard, InclusionInsightsCardSkeleton } from '@diversioteam/diversio-ds'
import { Link } from 'react-router-dom'

import { withErrorBoundary } from 'config/withErrorBoundary/withErrorBoundary'
import { useGetInclusionInsights } from 'hooks/useGetInclusionInsights'
import { generateCompanyURI } from 'sagas/helpers/generateCompanyURI'
import { AppRoute } from 'routing/AppRoute.enum'

const InclusionInsightsWithoutEB = () => {
    const { data, isLoading } = useGetInclusionInsights()

    const hasNoData = !data
    const hasNoDataItems = data?.positive.length === 0 && data?.negative.length === 0

    if (isLoading) {
        return <InclusionInsightsCardSkeleton data-testid="inclusion-insights-skeleton" />
    }

    if (hasNoData || hasNoDataItems) {
        return null
    }

    return (
        <InclusionInsightsCard
            {...data}
            title="Inclusion Insights"
            titleWrapperProps={{
                component: Link,
                to: generateCompanyURI(`${AppRoute.AnalyzeInclusion}/target_groups`),
            }}
            actionButtonProps={{
                component: Link,
                to: generateCompanyURI(AppRoute.SolutionGoals),
            }}
        />
    )
}

export const InclusionInsights = withErrorBoundary(InclusionInsightsWithoutEB, {})
