import React from 'react'
import { Skeleton as MuiSkeleton, TabContext as MuiTabContext } from '@material-ui/lab'
import { Button, DownloadIcon, SearchBox } from '@diversioteam/diversio-ds'

import { useGetFreeTextsFilters } from 'hooks/freeTexts/useGetFreeTextsFilters'

import { InclusionTab } from './../context/FreeTextsStateContext.types'
import { useFreeTextsState } from './../hooks/useFreeTextsState'
import { FreeTextCategory } from './../freeTexts.types'
import { AllKpisTabPanel } from './AllKpisTabPanel'
import { SingleKpiTabPanel } from './SingleKpiTabPanel'
import { ALL_KPIS_TAB_NAME, ALL_KPIS_TAB_VALUE } from './inclusionCategory.helpers'
import { InclusionCategoryProps } from './inclusionCategory.types'
import * as S from './../freeTexts.styles'

export const InclusionCategory = ({ hasMultipleCategories, ...props }: InclusionCategoryProps) => {
    const { searchValue, onChangeSearchValue, activeInclusionTab, onChangeActiveInclusionTab, onDownload } =
        useFreeTextsState()

    const queryFreeTextsFilters = useGetFreeTextsFilters()

    const inclusionFilterData = queryFreeTextsFilters.data?.inclusionFilterData || []

    const getKpiCode = (label: string) =>
        queryFreeTextsFilters.data?.kpiMapping.find(({ name }) => name === label)?.code

    const tabsSkeleton = new Array(5).fill('').map((_, index) => <MuiSkeleton key={index} width={80} />)

    return (
        <S.TabPanel value={FreeTextCategory.Inclusion} {...props}>
            <MuiTabContext value={activeInclusionTab}>
                <S.Header>
                    <S.TitleWrapper hasMultipleCategories={hasMultipleCategories}>
                        <S.Title>Employee Feedback</S.Title>

                        <S.Filters size="medium">
                            <SearchBox value={searchValue} onChange={onChangeSearchValue} maxWidth={220} />

                            {!hasMultipleCategories && (
                                <Button
                                    color="transparent"
                                    size="small"
                                    rightIcon={<DownloadIcon type="bold" />}
                                    onClick={() => onDownload(FreeTextCategory.Inclusion)}
                                >
                                    CSV
                                </Button>
                            )}
                        </S.Filters>
                    </S.TitleWrapper>

                    <S.Nav>
                        <S.TabsWrapper
                            size="medium"
                            onChange={(_, value: InclusionTab) => onChangeActiveInclusionTab(value)}
                        >
                            {[
                                <S.Tab
                                    key={ALL_KPIS_TAB_VALUE}
                                    label={ALL_KPIS_TAB_NAME}
                                    value={ALL_KPIS_TAB_VALUE}
                                    disableRipple
                                />,
                                queryFreeTextsFilters.isLoading
                                    ? tabsSkeleton
                                    : inclusionFilterData.flatMap(({ kpiName }) => {
                                          const kpiCode = getKpiCode(kpiName)

                                          if (!kpiCode) {
                                              return []
                                          }

                                          return <S.Tab key={kpiCode} label={kpiName} value={kpiCode} disableRipple />
                                      }),
                            ]}
                        </S.TabsWrapper>
                    </S.Nav>
                </S.Header>

                {activeInclusionTab === 'ALL' && <AllKpisTabPanel />}

                {inclusionFilterData.map(({ kpiName }) => {
                    const kpiCode = getKpiCode(kpiName)

                    if (!kpiCode) {
                        return null
                    }

                    if (activeInclusionTab === kpiCode) {
                        return <SingleKpiTabPanel key={kpiCode} kpiCode={kpiCode} kpiName={kpiName} />
                    }

                    return null
                })}
            </MuiTabContext>
        </S.TabPanel>
    )
}
