import React, { useState, useEffect } from 'react'

import { TypeWriterProps } from './typeWriter.types'
import * as S from './typeWriter.styles'

export const Typewriter = ({ text, delay = 50, infinite = false }: TypeWriterProps) => {
    const [currentText, setCurrentText] = useState('')
    const [currentIndex, setCurrentIndex] = useState(0)

    const isTyping = text.length !== currentText.length

    useEffect(() => {
        let timeout: NodeJS.Timeout

        if (currentIndex < text.length) {
            timeout = setTimeout(() => {
                setCurrentText((prevText) => prevText + text[currentIndex])
                setCurrentIndex((prevIndex) => prevIndex + 1)
            }, delay)
        } else if (infinite) {
            setCurrentIndex(0)
            setCurrentText('')
        }

        return () => clearTimeout(timeout)
    }, [currentIndex, delay, infinite, text])

    return (
        <S.TypeWriterWrapper>
            {currentText}
            {isTyping && <span className="cursor" />}
        </S.TypeWriterWrapper>
    )
}
