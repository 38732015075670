export enum Stage {
    Init = 'init',
    UpdateData = 'updateData',
    Processing = 'processing',
    Completed = 'completed',
    Error = 'error',
}

export enum ProcessingStatus {
    Pending = 'Pending',
    Completed = 'Completed',
    Failed = 'Failed',
}

export enum UploadStatus {
    Loading = 'Loading',
    Completed = 'Completed',
    Failed = 'Failed',
}

export type FileMetadata = {
    file: File
    readonly path?: string
    uuid?: string
    processingStatus?: string
    uploadStatus?: UploadStatus
}

export type LumiContextValue = {
    acceptedFiles: readonly FileMetadata[]
    onChangeAcceptedFiles: (update: (prevAcceptedFiles: FileMetadata[]) => FileMetadata[]) => void
    onResetStage: () => void
    stage: Stage
    onChangeStage: (stage: Stage) => void
    jobId: string
    onChangeJobId: (jobId: string) => void
}
