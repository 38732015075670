import { getFontStyle, Button as DdsButton } from '@diversioteam/diversio-ds'
import styled from 'styled-components'

export const Title = styled.h3`
    color: ${({ theme }) => theme.colors.primary90};
    margin: 0 0 32px;

    ${getFontStyle('h3Black')}
`
export const Description = styled.p`
    color: ${({ theme }) => theme.colors.secondary500};
    margin: 0;

    ${getFontStyle('p02Regular')}
`

export const Button = styled(DdsButton)`
    box-sizing: border-box;
`
