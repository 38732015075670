import { useDispatch } from 'react-redux'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { Actions } from 'actions'

import { deleteRecommendedProgram } from 'api/actions/recommended/recommendedActions'
import { Mutations } from 'api/actions/recommended/mutations.enum'
import { Queries } from 'api/actions/recommended/queries.enum'
import {
    InfiniteGetRecommendedProgramsListResponseResponse,
    RecommendedProgram,
} from 'api/actions/recommended/recommendedActions.types'

export const useDeleteRecommendedProgram = () => {
    const dispatch = useDispatch()
    const queryClient = useQueryClient()

    const mutation = useMutation<
        void,
        AxiosError,
        number,
        {
            previousPrograms?: InfiniteGetRecommendedProgramsListResponseResponse
        }
    >({
        mutationKey: [Mutations.deleteRecommendedProgram],
        mutationFn: deleteRecommendedProgram,
        onMutate: async (id) => {
            await queryClient.cancelQueries([Queries.getRecommendedProgramsList])

            const previousPrograms = queryClient.getQueryData<InfiniteGetRecommendedProgramsListResponseResponse>([
                Queries.getRecommendedProgramsList,
            ])

            const updateRecommendedProgram = (recommendedProgram: RecommendedProgram): RecommendedProgram =>
                recommendedProgram.companySolution?.id === id
                    ? {
                          ...recommendedProgram,
                          companySolution: null,
                      }
                    : recommendedProgram

            if (previousPrograms) {
                queryClient.setQueryData([Queries.getRecommendedProgramsList], {
                    ...previousPrograms,
                    pages: previousPrograms.pages.map((previousPrograms) => ({
                        ...previousPrograms,
                        results: previousPrograms.results.map(updateRecommendedProgram),
                    })),
                })
            }

            return { previousPrograms }
        },
        onError: (err, newProgram, context) => {
            queryClient.setQueryData([Queries.getRecommendedProgramsList], context?.previousPrograms)
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries([Queries.getRecommendedProgramsList])

            dispatch(
                Actions.showToast({
                    type: 'warning',
                    message: 'Program has been removed!',
                }),
            )
        },
    })

    return mutation
}
