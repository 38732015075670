import React, { useCallback, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { Trans, useTranslation } from 'react-i18next'
import { Checkbox, ChevronRightIcon } from '@diversioteam/diversio-ds'

import { Toast } from '../Toast'

import { Footer } from './../Footer'
import { VerifyBackupCodeFields, VerifyBackupCodeFormData, VerifyBackupCodeProps } from './verifyBackupCode.types'
import { verifyBackupCodeSchema } from './verifyBackupCode.schema'
import diversioLogo from './../../../assets/images/diversio-logo-with-symbol-for-light-bg.svg'
import * as S from './verifyBackupCode.styles'

export const VerifyBackupCode = ({ isLoading, isError, error, onClickBack, onSubmit }: VerifyBackupCodeProps) => {
    const { t } = useTranslation()

    const { control, register, handleSubmit, errors, setError } = useForm<VerifyBackupCodeFormData>({
        resolver: yupResolver(verifyBackupCodeSchema),
    })

    const errorDetail = error?.response?.data.detail || ''

    useEffect(() => {
        if (isError && errorDetail === 'INVALID_CODE') {
            const errorType = 'server'

            setError(VerifyBackupCodeFields.verificationCode, {
                type: errorType,
            })
        }
    }, [isError])

    const handleSubmitCallback = useCallback(
        async (data: VerifyBackupCodeFormData) => {
            await onSubmit(data)
        },
        [onSubmit],
    )

    const getErrorMessage = (fieldName: VerifyBackupCodeFields) => {
        return t(errors[fieldName]?.message || '')
    }

    return (
        <S.Container>
            <S.Form onSubmit={handleSubmit(handleSubmitCallback)}>
                <Toast isLoading={isLoading} isError={isError} error={error} translationPrefix="AUTH.MFA.MESSAGES" />

                <S.Logo src={diversioLogo} className="logo" alt="Diversio" />

                <S.Title>{t('AUTH.MFA.VERIFY_BACKUP_CODE_TITLE')}</S.Title>

                <S.Description>{t('AUTH.MFA.VERIFY_BACKUP_CODE_DESCRIPTION')}</S.Description>

                <S.Fields>
                    <S.TextField
                        id={VerifyBackupCodeFields.verificationCode}
                        inputRef={register}
                        error={Boolean(errors[VerifyBackupCodeFields.verificationCode])}
                        helperText={getErrorMessage(VerifyBackupCodeFields.verificationCode)}
                        name={VerifyBackupCodeFields.verificationCode}
                        label={t('AUTH.MFA.VERIFY_BACKUP_CODE_INPUT_PLACEHOLDER')}
                        type="password"
                        disabled={isLoading}
                        fullWidth
                    />

                    <S.FormControlLabel
                        control={
                            <Controller
                                name={VerifyBackupCodeFields.trustedDevice}
                                control={control}
                                render={(props) => (
                                    <Checkbox
                                        {...props}
                                        checked={props.value}
                                        onChange={(e) => props.onChange(e.target.checked)}
                                    />
                                )}
                            />
                        }
                        label={t('AUTH.MFA.TRUSTED_DEVICE')}
                    />
                </S.Fields>

                <S.Button
                    loading={isLoading}
                    type="submit"
                    color="primary"
                    size="large"
                    rightIcon={<ChevronRightIcon type="bold" />}
                    fullWidth
                >
                    {t('AUTH.MFA.LOGIN')}
                </S.Button>

                <S.TextLink as="div" onClick={onClickBack}>
                    {t('AUTH.MFA.GO_BACK')}
                </S.TextLink>

                <S.Help>
                    <Trans i18nKey={'AUTH.MFA.VERIFY_BACKUP_CODE_HELP'} components={{ a: <a /> }} />
                </S.Help>

                <Footer />
            </S.Form>
        </S.Container>
    )
}
