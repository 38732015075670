import i18n from 'i18n'

const mapCodeToMessage = (code: number) => {
    switch (code) {
        case 400:
            return 'Bad Request: Request sent invalid syntax.'
        case 401:
            return 'Unauthorized: Sorry, you have not been authenticated.'
        case 403:
            return 'Forbidden: You do not have permission.'
        case 404:
            return "Not Found: Uh oh! We couldn't find what you are looking for!"
        case 500:
            return 'Internal Server Error: Cannot process the request.'
        case 502:
            return 'Bad Gateway: Not receiving a valid response from the servers.'
        case 503:
            return 'Service Unavailable: Server is overloaded or under maintenance.'
        case 504:
            return 'Gateway Timeout: Not receiving a response from the servers.'
        default:
            return 'Something went wrong! :('
    }
}

export const getErrorMessage = (code: number, message: string) => {
    if (code === 429 && message) {
        const time = message.match(/[0-9]+/)

        if (time) {
            const timeout = Math.ceil(Number(time) / 60)

            return i18n.t('AUTH.MFA.MESSAGES.TOO_MANY_REQUESTS', { timeout })
        }
    }

    if (message) {
        return message
    }

    return mapCodeToMessage(code)
}
