import React, {useEffect} from 'react';
import history from "../../history";
import SurveyInit from "./SurveyInit";
import './index.scss';
import Setup from "./Setup";
import Create from "./Create";
import Review from "./Review";
import Live from "./Live";
import Launch from './Launch';
import Approved from "./Approved";
import Draft from "./Draft";
import RequireUpdates from "./RequireUpdates";
import Processing from './Processing';
import Loader from "../utils/Loader";
import {withTranslation} from "react-i18next";
import { generateCompanyURI } from 'sagas/helpers/generateCompanyURI';
import { withHooks } from 'config/withHooks/withHooks';
import { getActiveCompany } from 'utils';

const Survey = (props) => {
    const { pathname } = history.location;

    useEffect(() => {
        history.replace(generateCompanyURI("/survey/init"));
        checkSurveyStatus();
    }, []);

    useEffect(() => {
        const pathName = window?.location?.pathname?.split("/").pop()
        const toPath = pathName === "survey" ? "init" : pathName;
        props.setCurrentSelectedSurveyPage(toPath);
        history.push(generateCompanyURI(`/survey/${toPath}`));
    }, [window.location.pathname]);

    useEffect(() => {
        history.push(generateCompanyURI(`/survey/${props.currentSelected}`));
    }, [props.currentSelected]);

    useEffect(() => {
        props.setSelectedSurvey(props.savedSurveys[0]);
        checkSurveyStatus();
    }, [props.savedSurveys]);

    const checkSurveyStatus = () => {
        if (props.savedSurveys && props.savedSurveys.length) {
            const currentSurvey = props.savedSurveys[0];
            const isCreatingSurvey = !currentSurvey.surveys?.[0].collectorLink
            const activeCompany = getActiveCompany(props.userMetadata?.companies)
            const permissions = activeCompany?.permissions

            switch(currentSurvey.status) {
                case "SET":
                    history.push(generateCompanyURI("/survey/create"));
                    break
                case "UFF":
                    history.push(generateCompanyURI("/survey/create"));
                    props.showMessage({ message: props.t("SURVEY.UFF") })
                    break
                case "UPF":
                    history.push(generateCompanyURI("/survey/create"));
                    props.showMessage({ message: props.t("SURVEY.UPF") })
                    break;
                case "DRF":
                    const path = window?.location?.pathname?.split("/").pop()
                    if (path === "setup" || path === "create") {
                        history.push(generateCompanyURI("/survey/create"));
                    } else {
                        history.push(generateCompanyURI("/survey/draft"));
                    }
                    break;
                case "US":
                    if ((props.selfServe && props.savedSurveys[0].is_active) || isCreatingSurvey) {
                        history.push(generateCompanyURI("/survey/processing"));
                    } else {
                        history.push(generateCompanyURI("/survey/review"));
                    }
                    break;
                case "REV":
                    history.push(generateCompanyURI("/survey/review"));
                    break;
                case "LIV":
                    history.push(generateCompanyURI("/survey/live"));
                    break;
                case "NSR":
                    history.push(generateCompanyURI("/survey/live"));
                    break;
                case "CLS":
                    if (props.selfServe && permissions.home && localStorage.getItem("is_self_serve_navigated_to_home_post_dashboard_creation") !== "1") {
                        localStorage.setItem("is_self_serve_navigated_to_home_post_dashboard_creation", "1");
                        history.push(generateCompanyURI({
                            pathname: '/home',
                            state: { from: 'diversio-basic-dashboard-create' }
                        }))
                    } else if (props.savedSurveys[0].is_active && props.selfServe) {
                        history.push(generateCompanyURI("/survey/processing"));
                    } else if (props.savedSurveys[0].is_active) {
                        history.push(generateCompanyURI("/survey/review"));
                    } else {
                        props.setSelectedSurvey(undefined);
                    }
            }
        }
    };

    const renderComp = (pathname) => {
        let currentSurvey;
        if (props.savedSurveys && props.savedSurveys.length) {
            currentSurvey = props.savedSurveys[0];
        }

        switch(pathname) {
            case generateCompanyURI('/survey/init'):
                return <SurveyInit { ...props } />;
            case generateCompanyURI('/survey/setup'):
                return <Setup { ...props } />;
            case generateCompanyURI('/survey/create'):
                return <Create { ...props } />;
            case generateCompanyURI('/survey/review'):
                return <Review { ...props } entity={currentSurvey?.status === "CLS" ? "Dashboard" : "Survey"}/>;
            case generateCompanyURI('/survey/approved'):
                return <Approved { ...props } />;
            case generateCompanyURI('/survey/draft'):
                return <Draft { ...props } />;
            case generateCompanyURI('/survey/requires-updates'):
                return <RequireUpdates { ...props } />;
            case generateCompanyURI('/survey/launch'):
                return <Launch { ...props } />;
            case generateCompanyURI('/survey/live'):
                return <Live { ...props } />;
            case generateCompanyURI('/survey/processing'):
                return <Processing { ...props } entity={currentSurvey?.status === "CLS" ? "Dashboard" : "Survey"}/>
        }
    };

    if (!props.company) {
        return null;
    }

    return (
        <div id={"survey"} className={"bootstrap-override"}>
            { props.defaultLoader ? <Loader /> : null }
            { renderComp(pathname) }
        </div>
    );
};

export default withTranslation()(withHooks(Survey));
