import React from 'react';
import './index.scss';

const FocusArea = ({ label, sublabel, score, color, groups }) => {
    /* colour needs to come from backend and determine the color of frontend */
    // const focusGroups = groups ? groups.join(', ') : '';
    return (
        <div className="focus-area">
            <div className="focus-area__title">
                <h3>{label}</h3>
            </div>
            <div className="focus-area__score">
                <span className="focus-area__numerator">{score.toFixed(1)}</span><span className="focus-area__denominator">/10</span>
            </div>
            <div className="focus-area__description">
                <h4>{sublabel}</h4>
            </div>
        </div>
    )
};

export default FocusArea;
