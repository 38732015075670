import { useInfiniteQuery } from '@tanstack/react-query'
import { getFreeTexts } from 'sagas/network'
import { stringifyQueryParams } from 'utils'
import { useRef } from 'react'

import { GetFreeTextsParams, GetFreeTextsResponse } from 'api/actions/analyze/freeTexts/freeTextsActions.types'
import { Queries } from 'api/queries.enum'
import { useSurveys } from 'hooks/useSurveys/useSurveys'
import { getNextPageParam } from 'utils/getNextPageParam'
import { preventRefetchQueryOptions } from 'utils/queryOptions'

export const useGetFreeTexts = (defaultParams?: GetFreeTextsParams, options?: { enabled?: boolean }) => {
    const queryInitialPage = useRef(defaultParams?.page || 1)
    const { survey } = useSurveys()

    const selectedSurveyParams = survey
        ? {
              year: survey.year,
              quarter: survey.quarter,
          }
        : {}

    const params: GetFreeTextsParams = { ...selectedSurveyParams, ...defaultParams }

    const query = useInfiniteQuery<GetFreeTextsResponse, Error>({
        queryKey: [Queries.getFreeTexts, params],
        queryFn: ({ pageParam = queryInitialPage.current }) =>
            getFreeTexts(stringifyQueryParams({ ...params, page: pageParam })),
        getNextPageParam: ({ next }) => getNextPageParam(next),
        enabled: options?.enabled,
        ...preventRefetchQueryOptions,
    })

    return query
}
