import React from 'react'
import { useTranslation } from 'react-i18next'
import { CopyIcon } from '@diversioteam/diversio-ds'

import { BackupCodesProps } from './backupCodes.types'
import * as S from './backupCodes.styles'

export const BackupCodes = ({
    isGeneratingNewBackupCodes,
    backupCodes,
    isCopiedToClipboard,
    onClickBackupCode,
    onClickCopyAllCodes,
    onClickGenerateNewCodes,
}: BackupCodesProps) => {
    const { t } = useTranslation()

    return (
        <>
            <S.Title>{t('AUTH.MFA.SAVE_BACKUP_CODES_TITLE')}</S.Title>
            <S.Toast open type="warning" message={t('AUTH.MFA.SAVE_BACKUP_CODES_WARNING')} />

            <S.Buttons>
                <S.Button onClick={() => onClickCopyAllCodes(backupCodes)} size="small">
                    {t('AUTH.MFA.COPY_ALL_BACKUP_CODES')}
                </S.Button>
                <S.Button
                    onClick={() => onClickGenerateNewCodes()}
                    size="small"
                    color="primaryLight"
                    data-testid="generate-codes-button"
                >
                    Generate New Codes
                </S.Button>
            </S.Buttons>
            <S.CodeButtons>
                {backupCodes.map((backupCode, index) => {
                    return (
                        <S.Button
                            onClick={() => onClickBackupCode(backupCode)}
                            size="small"
                            color="secondaryLight"
                            key={index}
                        >
                            {backupCode}
                        </S.Button>
                    )
                })}
            </S.CodeButtons>

            <S.Description>{t('AUTH.MFA.SAVE_BACKUP_CODES_DESCRIPTION')}</S.Description>

            {isCopiedToClipboard && (
                <S.ClipboardToast color="errorSecondary">
                    <CopyIcon type="line" /> {t('AUTH.MFA.COPIED_TO_CLIPBOARD')}
                </S.ClipboardToast>
            )}
            {isGeneratingNewBackupCodes && (
                <S.ClipboardToast
                    color="errorSecondary"
                    loading={isGeneratingNewBackupCodes}
                    data-testid="generating-codes-toast"
                >
                    Generating New Codes
                </S.ClipboardToast>
            )}
        </>
    )
}
