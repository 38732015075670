import React from 'react';
import './index.scss';
import { Row, Column } from '../../utils/FlexBox';

const Feedback = ({ label, feedback }) => {
    return (
        <div className="feedback-card">
            <Row>
                <Column classes={"feedback-card__icon-container"}>
                    <img className={"icon icon-employee-comment"}/>
                </Column>
                <Column classes={"feedback-card__comment-container"}>
                    <p>{feedback}</p>
                </Column>
            </Row>
        </div>
    )
};

export default Feedback;
