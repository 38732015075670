import React from 'react'

import { useUserMetadata } from 'hooks/metadata/useUserMetadata'
import { useGetActiveCompanyInformation } from 'hooks/useGetActiveCompany'
import { SurveyInfoType } from 'types/surveyInfo.types'

export function withHooks(Component: React.ComponentClass) {
    return function WrappedComponent(props: any) {
        const { data } = useUserMetadata()
        const { companyInformation } = useGetActiveCompanyInformation()

        return (
            <Component
                {...props}
                userMetadata={data}
                selfServe={companyInformation?.titanSurveyInfoType === SurveyInfoType.SELF}
                returningUser={companyInformation?.titanSurveyInfoType === SurveyInfoType.RETURNING_USER}
            />
        )
    }
}
