import React, { Component, useState } from 'react';
import './index.scss';
import { LineChart, Line, CartesianGrid, Tooltip, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import { isMcrockDueDiligence, hideLongitudinal } from "../../../utils/companyChecks";

import { withTranslation } from 'react-i18next';
import MainCard from '../../utils/MainCard';
import MetricOverviewDetails from './MetricOverviewDetails';
import { SAMPLE } from './sampleData';
import Legend from './Legend';
import { getIndustryCompType } from '../../../utils';
import CustomizedAxisTick from '../../utils/CustomizedAxisTick';

import { INDUSTRY_COLOR, SCORE_COLOR, AXIS_COLOR } from './colors';

const CustomTooltip = ({ payload, companyName, t }) => {
    let content = null;
    const compType = getIndustryCompType(companyName);

    if (payload[0]) {
        content = (
            <div className="custom-tooltip perf-time-custom-tooltip">
                <h4 className="perf-time-custom-tooltip__title">Inclusion Score</h4>
                <div className="perf-time-custom-tooltip__row">
                    <div className="perf-time-custom-tooltip__label_score">
                        {`${companyName}`}
                    </div>
                    <div className="perf-time-custom-tooltip__value">
                        {payload[0].value}
                    </div>
                </div>
                {(payload[1] &&
                <div className="perf-time-custom-tooltip__row">
                    <div className="perf-time-custom-tooltip__label_industry">{`${t(compType)} ${t("AVERAGE")}`}</div>
                    <div className="perf-time-custom-tooltip__value">{payload[1].value}</div>
                </div>
                )}
            </div>
        );
    }

    return content;
  };

const BKPISetUp = ( data, BKPIdata, t) => {
    let mergeData = [];
    for (let entry=0; entry < data.length; entry++) {
        /* Attrition Rate and Acceptance Rate */
        mergeData.push({month: t(`MONTHS.${data[entry].month.toLowerCase()}`), score: data[entry].score, attrition: BKPIdata[0].rates[entry].rate, acceptance: BKPIdata[1].rates[entry].rate, responders: data[entry].responders});
    }
    return mergeData;
};

const MetricOverview = ({ data, BKPIdata, companyName, t }) => {
    /* Control the data shown in right hand box */
    const [surveyIndex, setSurveyIndex] = useState(0);

    if ( (isMcrockDueDiligence(companyName)) ||
            (hideLongitudinal(companyName))) {
            return null;
        }

    /* TODO: industry data currently does not change over time,
    mock this until backend change */
    const _mockIndustryData = (data) => {
        return data.map((monthObj, i) => ({...monthObj,
            "date": t(`MONTHS.${monthObj.month.toLowerCase()}`),
            "industry_score": (70 + i + 2 )}));
    };

    // const mockedData = _mockIndustryData(data);
    const mockedData = data.filter(data => !!data.score && data.score !== "N/A");

    if (!mockedData.length) {
        return null
    }

    return (
        <MainCard title={t("HOME.CARD_HEADERS.PERFORMANCE_OVER_TIME.title")}
            toolTipContent={t("HOME.CARD_HEADERS.PERFORMANCE_OVER_TIME.tooltip")}>
            <div className="perf-time">
                <div className="perf-time__subtitle">{t("HOME.CARD_HEADERS.PERFORMANCE_OVER_TIME.subtitle")}</div>
                <div className="perf-time__charting-wrapper">
                    <div className="perf-time__line-chart">
                        <ResponsiveContainer height={200}>
                            <LineChart data={mockedData}
                            onMouseMove={state => {
                                if (state.isTooltipActive) {
                                setSurveyIndex(state.activeTooltipIndex);
                                }}}>
                                <CartesianGrid stroke="#DBD9DB" vertical={false} />
                                <XAxis height={45} interval="preserveStartEnd" fontSize="12" tick={{fill: AXIS_COLOR}} dataKey="month" axisLine={false} tickLine={false} tickMargin={15} tick={<CustomizedAxisTick activeIndex={surveyIndex}/>}/>
                                <YAxis width={30} fontSize="12" tick={{fill: AXIS_COLOR}} tickCount={5} orientation='left' axisLine={false} tickLine={false} tickMargin={5}/>
                                <Line dataKey="score" stroke={SCORE_COLOR} strokeWidth={2} />
                                <Line strokeDasharray="5 2" dataKey="industry_score" stroke={INDUSTRY_COLOR} strokeWidth={2} />
                                <Tooltip allowEscapeViewBox={true} content={<CustomTooltip companyName={companyName} t={t}/>}/>
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>
        </MainCard>
    )
};

export default withTranslation()(MetricOverview);
