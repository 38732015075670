import { diversioApiV2 } from 'api/axios/axiosInstance'

import { endpoints } from './endpoints'
import {
    SendOrganizationEmailPayload,
    SendOrganizationEmailResponse,
    SendTrainingEmailPayload,
} from './userMetadataActions.types'

export const sendTrainingEmail = async ({ values, userUUID }: SendTrainingEmailPayload): Promise<void> => {
    const response = await diversioApiV2.post(endpoints.sendTrainingEmail(userUUID), values)

    return response.data
}

export const sendOrganizationEmail = async (
    values: SendOrganizationEmailPayload,
): Promise<SendOrganizationEmailResponse> => {
    const response = await diversioApiV2.post(endpoints.sendOrganizationEmail, values)

    return response.data
}
