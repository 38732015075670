import { Demographic } from 'api/actions/analyze/companyFilters/companyFiltersActions.types'

import { SelectedFilters } from './../index'

export type DataRepresentationProps = {
    demographic?: Demographic
    selectedFiltersPayload?: SelectedFilters
    selectedTab: string
}

export enum RepresentationView {
    Chart = 'chart',
    Table = 'table',
    ChartTable = 'chart-table',
}

export type LegendItemProps = {
    color: string
    label: string
}

export type SelectValue = RepresentationView | 'download'

export type SelectOptions = { label: string; value: SelectValue }[]
