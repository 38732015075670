import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Button, ChevronRightIcon } from '@diversioteam/diversio-ds'

import { Footer } from './../Footer'
import { Toast } from './../Toast'
import { SendVerificationCodeProps } from './sendVerificationCode.types'
import diversioLogo from './../../../assets/images/diversio-logo-with-symbol-for-light-bg.svg'
import * as S from './sendVerificationCode.styles'

export const SendVerificationCode = ({
    email,
    isLoading,
    isSuccess,
    data,
    isError,
    error,
    onSubmit,
    onClickBackup,
    onClickBack,
}: SendVerificationCodeProps) => {
    const { t } = useTranslation()

    return (
        <S.Container>
            <S.Form as="div">
                <Toast
                    isLoading={isLoading}
                    isSuccess={isSuccess}
                    data={data}
                    isError={isError}
                    error={error}
                    translationPrefix="AUTH.MFA.MESSAGES"
                />

                <S.Logo src={diversioLogo} className="logo" alt="Diversio" />

                <S.Title>{t('AUTH.MFA.SEND_VERIFICATION_CODE_TITLE')}</S.Title>

                <S.Email>{email}</S.Email>

                <S.Buttons>
                    <Button
                        onClick={onSubmit}
                        loading={false}
                        type="submit"
                        color="primary"
                        size="large"
                        rightIcon={<ChevronRightIcon type="bold" />}
                        fullWidth
                    >
                        {t('AUTH.MFA.SEND_VERIFICATION_CODE_PRIMARY_BUTTON')}
                    </Button>

                    <S.SecondaryButton
                        onClick={onClickBackup}
                        loading={false}
                        type="submit"
                        color="secondary"
                        size="large"
                        rightIcon={<ChevronRightIcon type="bold" />}
                        fullWidth
                    >
                        {t('AUTH.MFA.SEND_VERIFICATION_CODE_SECONDARY_BUTTON')}
                    </S.SecondaryButton>
                </S.Buttons>

                <S.TextLink as="div" onClick={onClickBack}>
                    {t('AUTH.MFA.GO_BACK')}
                </S.TextLink>

                <S.Help>
                    <Trans i18nKey={'AUTH.MFA.VERIFY_BACKUP_CODE_HELP'} components={{ a: <a /> }} />
                </S.Help>

                <Footer />
            </S.Form>
        </S.Container>
    )
}
