import React, {Component} from 'react';
import FormInput, {FormInputSpec, FormInputTypes} from "./FormInput";
import {Button} from '@diversioteam/diversio-ds';
import {Row} from '../FlexBox';
import './index.scss'
import {uniq} from 'lodash'

const inputs = [
    {
        id: '',
        name: '',
        type: 'string',
        validate: () => {
        },
        default: '',
        properties: {},
        style: {}
    }
];

class Form extends Component {

    constructor(props) {
        super(props);
        const field_names = this.flatten(this.props.inputs).map(x=>x.name);
        const hasDuplicates = uniq(field_names).length !== field_names.length;
        if(hasDuplicates){
            throw Error("Form cannot have duplicate fields.")
        }

        this.state = {
            form: this._propInputsToStateForm(this.props.inputs),
            inputs: this.props.inputs.map(x=>Object.assign(x,{errors:[]}))
        }
    }

    _propInputsToStateForm(inputs){
        function reducer (acc,val){
            if (Array.isArray(val)){
                return acc.concat(val.reduce(reducer,[]));
            }
            const value = val.defaultValue===undefined?FormInputSpec[val.type].defaultValue:val.defaultValue;
            return acc.concat([{[val.name]: value}]);
        }
        return inputs.reduce(reducer, []).reduce((acc,val)=>{
            return Object.assign(acc,val);
        },{});
    }

    onInputChange(value, name, e){
        const form = Object.assign(this.state.form,{[e.target.name]:value});
        this.setState(Object.assign(this.state,{form}));

        // If no submit button, trigger submit on any change
        if(this.props.hideSubmit){
            this.submit()
        }
    }

    inputToComponent(input,index) {
        if (Array.isArray(input)) {
            return (
                <Row key={`input_${index}`}>
                    {input.map(this.inputToComponent.bind(this))}
                </Row>
            )
        }
        return <FormInput key={input.name} {...input} onChange={this.onInputChange.bind(this)} value={this.state.form[input.name]}/>
    }

    flatten(array){
        function reducer (acc,val){
            if (Array.isArray(val)){
                return acc.concat(val.reduce(reducer,[]));
            }
            return acc.concat([val])
        }
        return array.reduce(reducer,[]);
    }

    deepMap(array,fn){
        function r (x){
            if(Array.isArray(x)){
                return x.map(r)
            }
            return fn(x)
        }
        return array.map(r);
    }

    submit() {
        const totalErrors = [];
        const newInputs = this.deepMap(this.state.inputs, x=>{
           let errors = [];
           if(typeof x.validate === 'function'){
               errors = x.validate(this.state.form[x.name], this.state.form);
           }
           if(x.required && (
               this.state.form[x.name] === FormInputSpec[x.type].defaultValue) || 
               this.state.form[x.name] === null ||
               !this.state.form[x.name].trim()
            ) {
               errors.push("This field is required.")
           }
           totalErrors.push(...errors);
           return Object.assign(x,{errors});
        });

        if(totalErrors.length === 0){
            this.props.onSubmit(this.state.form)
        }
        this.setState(Object.assign(this.state, {inputs:newInputs}));
    }

    render() {
        const inputs = this.state.inputs.map(this.inputToComponent.bind(this));
        return (
            <div className="form">
                {inputs}
                {
                    this.props.hideSubmit?null:
                        <div className="submit-button">
                            <Button className='save-button' onClick={this.submit.bind(this)}>{this.props.submitLabel || "submit"}</Button>
                        </div>
                }
            </div>
        );
    }
}

export default Form;
export {FormInputTypes, FormInputSpec} from './FormInput'
