import React from 'react';
import Alert from "@material-ui/lab/Alert/Alert";
import Container from "react-bootstrap/Container";

const GlobalSurveyErrors = ({ errors }) => {
    if (!errors || !errors.non_field_errors) {
        return null;
    }

    return (
        <div className={"global-survey-message"}>
            <Container>
                {
                    errors.non_field_errors.map(error => <Alert severity="error">{ error }</Alert>)
                }
            </Container>
        </div>
    );
};

export default GlobalSurveyErrors;
