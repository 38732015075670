import { ValidateGoalFieldsParams } from './goals.types'

export const getScore = (score?: 'N/A' | 'INSUFFICIENT_DATA' | number) => {
    if (typeof score === 'number') {
        return score
    }

    return null
}

export const validateGoalFields = ({
    pointImprovementScore,
    kpiId,
    targetGroup,
    timeline,
}: ValidateGoalFieldsParams) => {
    const fields = [
        { field: pointImprovementScore, message: 'Please select an Improvement Score before continuing!' },
        { field: kpiId, message: 'Please select a KPI before continuing!' },
        { field: targetGroup, message: 'Please select a Target Group before continuing!' },
        { field: timeline, message: 'Please select a Timeline before continuing!' },
    ]

    for (const { field, message } of fields) {
        if (!field) {
            return message
        }
    }

    return null
}
