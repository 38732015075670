import React from 'react'
import { AnnouncementsCardCarousel, AnnouncementsCardCarouselSkeleton } from '@diversioteam/diversio-ds'

import { withErrorBoundary } from 'config/withErrorBoundary/withErrorBoundary'
import { useGetAnnouncements } from 'hooks/useGetAnnouncements'

const AnnouncementsWithoutEB = () => {
    const { isLoading, data } = useGetAnnouncements()

    if (isLoading) {
        return <AnnouncementsCardCarouselSkeleton data-testid="announcements-skeleton" />
    }

    if (!data || data.length == 0) {
        return null
    }

    return <AnnouncementsCardCarousel announcements={data} />
}

export const Announcements = withErrorBoundary(AnnouncementsWithoutEB, {})
