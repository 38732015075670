import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { Mutations } from 'api/actions/lumi/mutations.enum'
import { getJobItemListAction } from 'api/actions/lumi/lumiActions'
import { JobAdvertItemResponse } from 'api/actions/lumi/lumiActions.types'

export const useGetJobAdvertItemList = () => {
    const mutation = useMutation<JobAdvertItemResponse[], AxiosError, string>({
        mutationFn: getJobItemListAction,
        mutationKey: [Mutations.getJobAdvertItemList],
    })

    return mutation
}
