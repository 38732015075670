import React from 'react'

import { CustomChartLegendProps } from './legend.types'
import * as S from './legend.styles'

export const CustomChartLegend = ({ icon, label, marker, ...props }: CustomChartLegendProps) => {
    return (
        <S.ChartLegendItemWrapper {...props}>
            <S.Block>
                {marker?.enable && <S.Marker>{marker.icon}</S.Marker>}

                <S.Label>{label}</S.Label>

                {icon}
            </S.Block>
        </S.ChartLegendItemWrapper>
    )
}
