import React, { useEffect } from 'react';
import { Nav } from 'react-bootstrap';
import Link from '../utils/Link';
import {Column, Row} from "../utils/FlexBox";
import history from "../../history";
import Demographics from "./Demographics";
import Engagement from "./Engagement";
import _ from 'underscore';
import Loader from "../utils/Loader";
import {withTranslation} from "react-i18next";
import { generateCompanyURI } from 'sagas/helpers/generateCompanyURI';
import { getCompanyRelativePathName } from 'sagas/util';
import './index.scss';


const Bespoke = (props) => {
    const { pathname } = history.location;
    const { t } = props;
    const showDemographicsTab = props.analyze.filters.bespoke_demographic_quarters.length !== 0
    const showEngagementTab = props.analyze.filters.bespoke_engagement_quarters.length !== 0

    useEffect(() => {
        if (getCompanyRelativePathName(pathname) === "/bespoke") {
            history.replace(showDemographicsTab ? "/bespoke/demographics" : showEngagementTab ? "/bespoke/engagement" : "/home");
        }
    }, [pathname]);

    const renderComp = (pathname) => {
        switch(pathname) {
            case '/bespoke/demographics':
                if (!showDemographicsTab) {
                    history.replace("/home");
                }
                return <Demographics { ...props } />;
            case '/bespoke/engagement':
                if (!showEngagementTab) {
                    history.replace("/home");
                }
                return <Engagement { ...props } />;
        }
    };

    if (_.isEmpty(props.bespoke)) {
        return <Loader />
    }

    return (
        <div>
            <div className="App">
                <Row>
                    <Column grow={1}>
                        <h1 className={"transparent-header analyze-title"}>{t("BESPOKE.TITLE")}</h1>
                    </Column>
                </Row>
                <Row>
                    <Column grow={1}>
                        <div className={"bootstrap-override"}>
                            <Nav className={"bespoke-nav"} justify variant="tabs" defaultActiveKey="/bespoke/demographics">
                                {showDemographicsTab &&
                                <Nav.Item>
                                    <Nav.Link active={getCompanyRelativePathName(history.location.pathname) === "/bespoke/demographics"} as={Link} to={generateCompanyURI("/bespoke/demographics")}>{t("BESPOKE.TABS.DEMOGRAPHICS")}</Nav.Link>
                                </Nav.Item>}
                                {showEngagementTab &&
                                <Nav.Item>
                                    <Nav.Link active={getCompanyRelativePathName(history.location.pathname) === "/bespoke/engagement"} as={Link} to={generateCompanyURI("/bespoke/engagement")}>{t("BESPOKE.TABS.ENGAGEMENT")}</Nav.Link>
                                </Nav.Item>}
                            </Nav>
                        </div>
                    </Column>
                </Row>

                {
                    renderComp(getCompanyRelativePathName(history.location.pathname))
                }
            </div>
        </div>
    );
};

export default withTranslation()(Bespoke);
