import { AxiosError } from 'axios'
import { useMutation } from '@tanstack/react-query'

import { Queries } from 'api/actions/lumi/queries.enum'
import { processJobAdvertAction } from 'api/actions/lumi/lumiActions'

export const useProcessJobAdvert = () => {
    const mutation = useMutation<void, AxiosError, string>({
        mutationKey: [Queries.processJobAdvert],
        mutationFn: (jobId) => processJobAdvertAction(jobId),
    })

    return mutation
}
