import { AxiosError } from 'axios'

import { AuthErrorResponse } from 'api/actions/auth/authActions.types'

export type VerifyBackupCodeProps = {
    isLoading: boolean
    isError: boolean
    error: AxiosError<AuthErrorResponse> | null
    onClickBack: () => void
    onSubmit(values: VerifyBackupCodeFormData): Promise<boolean>
}

export enum VerifyBackupCodeFields {
    verificationCode = 'verification_code',
    trustedDevice = 'trustedDevice',
}

export type VerifyBackupCodeFormData = {
    [VerifyBackupCodeFields.verificationCode]: string
    [VerifyBackupCodeFields.trustedDevice]: boolean
}
