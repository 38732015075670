import { useMutation } from '@tanstack/react-query'
import { getDiversityCompanyProfiles } from 'sagas/network'
import { stringifyQueryParams } from 'utils'
import mixpanel from 'mixpanel-browser'

import { CompanyProfilesParams } from 'api/actions/analyze/companyProfiles/companyProfilesActions.types'
import { Mutations } from 'api/mutations.enum'
import TAXONOMIES from 'utils/taxonomies'

export const useExportDiversityProfilesData = (params: CompanyProfilesParams) => {
    const mutation = useMutation<string, Error, void>({
        mutationKey: [Mutations.exportDiversityProfilesData, params],
        mutationFn: () => {
            return getDiversityCompanyProfiles(stringifyQueryParams(params), 'file')
        },
        onSuccess: () => {
            mixpanel.track(TAXONOMIES.ANALYZE_DIVERSITY_PROFILES_DATA_EXPORT)
        },
    })

    return mutation
}
