import { colors, getFontStyle } from '@diversioteam/diversio-ds'
import styled from 'styled-components'

import { media } from 'styles/theme/mediaQueries'

export const PostWrapper = styled.section`
    grid-area: post;
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 24px;
    border-left: 1px solid ${({ theme }) => theme.colors.secondary100Transparent};
    background-color: ${({ theme }) => theme.colors.primaryWhite};
    padding: 24px;

    ${media.lg} {
        padding: 32px 48px 48px;
    }
`

export const Title = styled.h2`
    margin: 0;
    color: ${({ theme }) => theme.colors.primary90};

    ${getFontStyle('cardHeaderBold')};
`

export const Dropzone = styled.div<{ isDragAccept: boolean; isDragReject: boolean; isFocused: boolean }>`
    flex-grow: 1;
    padding: 12px 48px;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-width: ${({ isFocused, isDragAccept, isDragReject }) => {
        if (isFocused || isDragAccept || isDragReject) {
            return '4px'
        }

        return '1px'
    }};
    border-radius: 18px;
    border-color: ${({ theme, isDragAccept, isDragReject, isFocused }) => {
        if (isDragAccept) {
            return theme.colors.green50
        }

        if (isDragReject) {
            return theme.colors.lobster50
        }

        if (isFocused) {
            return theme.colors.primary40
        }

        return theme.colors.primary30
    }};
    border-style: ${({ isFocused, isDragAccept, isDragReject }) => {
        if (isFocused || isDragAccept || isDragReject) {
            return 'solid'
        }

        return 'dashed'
    }};
    background-color: ${({ isDragAccept, isDragReject, isFocused }) => {
        if (isDragAccept) {
            return 'rgba(217, 249, 236, 0.10)'
        }

        if (isDragReject) {
            return 'rgba(251, 224, 223, 0.10)'
        }

        if (isFocused) {
            return 'rgba(247, 247, 255, 0.60)'
        }

        return 'rgba(247, 247, 255, 0.40)'
    }};
    color: rgb(189, 189, 189);
    outline: none;

    :hover {
        border-color: ${colors.primary40};
        background-color: rgba(247, 247, 255, 0.6);
    }
`

export const IconWrapper = styled.div<{ isDragReject?: boolean }>`
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background-color: ${({ theme, isDragReject }) => {
        if (isDragReject) {
            return theme.colors.lobster50
        }

        return theme.colors.primaryWhite
    }};
`

export const DropzoneTitle = styled.div`
    margin: 24px 0 12px;
    color: ${({ theme }) => theme.colors.primary50};

    ${getFontStyle('cardHeaderBold')};
`

export const DropzoneDescription = styled.span`
    display: block;
    color: ${({ theme }) => theme.colors.secondary500};

    ${getFontStyle('captionRegularM')};
`

export const Textarea = styled.textarea`
    flex: 1;
    min-height: 245px;
    width: 100%;
    padding: 16px;
    color: ${({ theme }) => theme.colors.secondary500};
    border-radius: 10px;
    border: 2px solid ${({ theme }) => theme.colors.primary30};
    outline: none;

    &:focus,
    &:active {
        border-color: ${({ theme }) => theme.colors.primary40};
    }

    ${getFontStyle('p02Regular')};
`

export const TextareaButtons = styled.div`
    display: flex;
    width: 100%;
    gap: 24px;

    button {
        flex: 1;
    }
`
