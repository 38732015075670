import { AxiosError } from 'axios'

import { AuthErrorResponse, AuthResponse } from 'api/actions/auth/authActions.types'

export type ResetPasswordProps = {
    isLoading: boolean
    isSuccess: boolean
    data?: AuthResponse
    isError: boolean
    error: AxiosError<AuthErrorResponse> | null
    onSubmit(values: ResetPasswordFormData): Promise<boolean>
}

export enum ResetPasswordFields {
    password = 'password',
    confirmPassword = 'confirmPassword',
}

export type ResetPasswordFormData = {
    [ResetPasswordFields.password]: string
    [ResetPasswordFields.confirmPassword]: string
}
