import React from 'react'
import { Redirect, Route } from 'react-router-dom'

import { BenchmarksContextController } from 'context/benchmarks/benchmarksContextController/BenchmarksContextController'
import { generateCompanyURI } from 'sagas/helpers/generateCompanyURI'
import { AppRoute } from 'routing/AppRoute.enum'

import { Diversity } from './Diversity'
import { Inclusion } from './Inclusion'
import { FiltersContextController } from './context/FiltersContextController'

export const AnalyzeV2 = () => {
    return (
        <BenchmarksContextController>
            <Route
                path={generateCompanyURI(`${AppRoute.AnalyzeDiversity}/:tab?/:overTime?`)}
                render={() => (
                    <FiltersContextController>
                        <Diversity />
                    </FiltersContextController>
                )}
            />

            <Route
                path={generateCompanyURI(`${AppRoute.AnalyzeInclusion}/:tab?/:overTime?`)}
                render={() => (
                    <FiltersContextController>
                        <Inclusion />
                    </FiltersContextController>
                )}
            />

            <Route exact path={generateCompanyURI(AppRoute.Analyze)}>
                <Redirect to={generateCompanyURI(AppRoute.AnalyzeDiversity)} />
            </Route>
        </BenchmarksContextController>
    )
}
