import styled from 'styled-components'
import { FormControlLabel as MuiFormControlLabel } from '@material-ui/core'
import { getFontStyle } from '@diversioteam/diversio-ds'

import { Fields as AuthFields } from './../auth.styles'

export const Fields = styled(AuthFields)`
    margin-bottom: 24px;
`

export const FormControlLabel = styled(MuiFormControlLabel)`
    padding: 0 10px;
    display: flex;
    gap: 12px;

    .MuiFormControlLabel-label {
        color: ${({ theme }) => theme.colors.secondary500};

        ${getFontStyle('p02Medium')}
    }
`

export const Help = styled.div`
    color: ${({ theme }) => theme.colors.secondary500};
    text-align: center;

    ${getFontStyle('p02Regular')}

    a {
        color: ${({ theme }) => theme.colors.primary50};

        ${getFontStyle('p02Underline')}
    }
`

export {
    Button,
    SecondaryButton,
    Container,
    Form,
    Logo,
    Title,
    Description,
    TextField,
    TextLink,
} from './../auth.styles'
