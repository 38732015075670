import { useMutation } from '@tanstack/react-query'
import { deleteDiversityCompanyProfile, deleteInclusionCompanyProfile } from 'sagas/network'
import { stringifyQueryParams } from 'utils'
import mixpanel from 'mixpanel-browser'

import { AnalyzeView } from 'types/analyze.enum'
import { Mutations } from 'api/mutations.enum'
import { CompanyProfilesParams } from 'api/actions/analyze/companyProfiles/companyProfilesActions.types'
import TAXONOMIES from 'utils/taxonomies'

export const useDeleteCompanyProfile = (view: AnalyzeView, params?: CompanyProfilesParams) => {
    const mutationKey =
        view === AnalyzeView.Diversity
            ? [Mutations.deleteDiversityCompanyProfile]
            : [Mutations.deleteInclusionCompanyProfile]
    const mutationFn = view === AnalyzeView.Diversity ? deleteDiversityCompanyProfile : deleteInclusionCompanyProfile

    const trackingEventName =
        view === AnalyzeView.Diversity
            ? TAXONOMIES.ANALYZE_DIVERSITY_PROFILE_DELETED
            : TAXONOMIES.ANALYZE_INCLUSION_PROFILE_DELETED

    const mutation = useMutation<void, Error, string>({
        mutationKey,
        mutationFn: (id) => {
            return mutationFn({ id, queryString: stringifyQueryParams(params) })
        },
        onSuccess: (_data, payload) => {
            mixpanel.track(trackingEventName, { profileId: payload })
        },
    })

    return mutation
}
