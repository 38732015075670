import { getFontStyle, makeLineClamp } from '@diversioteam/diversio-ds'
import styled from 'styled-components'

export const AcceptedFile = styled.li<{ hasFailed?: boolean }>`
    padding: 8px;
    display: flex;
    align-items: center;
    gap: 12px;
    border-bottom: 1px solid #ddd8f080;
    background: ${({ hasFailed }) => {
        if (hasFailed) {
            return 'rgba(251, 244, 233, 0.5)'
        }
    }};
`

export const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
`

export const Details = styled.div`
    display: flex;
    flex-direction: column;
`

export const Path = styled.div`
    ${makeLineClamp(2)};
    word-break: break-all;
    color: ${({ theme }) => theme.colors.primary70};

    ${getFontStyle('captionRegularM')};
`

export const Size = styled.div`
    color: ${({ theme }) => theme.colors.primary62};

    ${getFontStyle('captionRegular')}
`

export const Buttons = styled.div`
    margin-left: auto;
`
