import React from 'react'
import ReactDOM from 'react-dom'
import './styles/index.scss'
import './index.scss'
import { I18nextProvider } from 'react-i18next'
import { StylesProvider, createGenerateClassName } from '@material-ui/core/styles'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Actions } from 'actions'

import App from './App'
import { initializeStore } from './store'
import initializeSentry from './sentry'
import i18n from './i18n'
import { initializeUnderscoreMixins } from './utils'

initializeSentry()
initializeUnderscoreMixins()

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 0,
            onError: async (error) => {
                const errorResponse = await error

                if (errorResponse instanceof Response) {
                    if (errorResponse.status === 401) {
                        const store = initializeStore()

                        queryClient.cancelQueries()
                        queryClient.clear()
                        store.dispatch(Actions.resetAuth())
                        store.dispatch(Actions.doSignout())
                    }
                }
            },
        },
    },
})

const generateClassName = createGenerateClassName({
    productionPrefix: 'p',
})

const rootComponent = (
    <QueryClientProvider client={queryClient}>
        <StylesProvider generateClassName={generateClassName} injectFirst>
            <I18nextProvider i18n={i18n}>
                <App store={initializeStore()} />
            </I18nextProvider>
        </StylesProvider>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />
    </QueryClientProvider>
)

ReactDOM.render(rootComponent, document.getElementById('root'))
