import {call, put} from 'redux-saga/effects';
import {Actions, ActionTypes} from '../actions';
import {
    loadILNmembers,
    loadILNmilestones,
    loadILNinitiatives,
    loadILNinvestmentRegions,
    loadILNAUMBreakdown,
    loadILNnews,
    loadILNCalendar
} from './network';

// All data sagas to add to middleware.
export default [
    [ ActionTypes.GET_ILN_MEMBERS, getIlnMembers ],
    [ ActionTypes.GET_ILN_MILESTONES, getIlnMilestones ],
    [ ActionTypes.GET_ILN_INITIATIVES, getIlnInitiatives ],
    [ ActionTypes.GET_ILN_INVESTMENT_REGIONS, getIlnInvestmentRegions ],
    [ ActionTypes.GET_ILN_AUM_BREAKDOWN, getIlnAumBreakdown ],
    [ ActionTypes.GET_ILN_NEWS, getIlnNews ],
    [ ActionTypes.GET_ILN_CALENDAR, getIlnCalendar]
];

function * getIlnMembers () {
    yield put(Actions.loadingIlnMembers());

    const response = yield call(loadILNmembers);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedIlnMembers(response.data));
    }
}

function * getIlnNews () {
    yield put(Actions.loadingIlnNews());

    const response = yield call(loadILNnews);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedIlnNews(response.data));
    }
}

function * getIlnInvestmentRegions () {
    yield put(Actions.loadingIlnInvestmentRegions());

    const response = yield call(loadILNinvestmentRegions);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedIlnInvestmentRegions(response.data));
    }
}

function * getIlnAumBreakdown () {
    yield put(Actions.loadingIlnAumBreakdown());

    const response = yield call(loadILNAUMBreakdown);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedIlnAumBreakdown(response.data));
    }
}

function * getIlnInitiatives () {
    yield put(Actions.loadingIlnInitiatives());

    const response = yield call(loadILNinitiatives);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedIlnInitiatives(response.data));
    }
}

function * getIlnMilestones () {
    yield put(Actions.loadingIlnMilestones());

    const response = yield call(loadILNmilestones);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedIlnMilestones(response.data));
    }
}

function * getIlnCalendar () {
    yield put(Actions.loadingIlnCalendar());

    const response = yield call(loadILNCalendar);

    if(!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedIlnCalendar(response.data))
    }
}

