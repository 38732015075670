export const getUUIDFromURL = () => {
    const hasCompanyPath = location.pathname.indexOf('company') > -1

    if (hasCompanyPath) {
        const uuid = location.pathname.split('/')[2]

        return !['undefined', 'null'].includes(uuid) ? uuid : undefined
    }

    return undefined
}
