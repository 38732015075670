import React, { Component } from 'react'
import { 
    Bar, 
    BarChart, 
    CartesianGrid, 
    Cell, 
    ResponsiveContainer, 
    XAxis, 
    YAxis, 
    Text, 
    Tooltip } from "recharts"

class CustomizedAxisTick extends Component {
    render() {
        const { x, y, payload, groups } = this.props;
        const spacing = 55;

        let dom = groups[payload.value]["dom"]
        let nonDom = groups[payload.value]["non_dom"]

        // Hard Coded to allow for a line break so the labels arn't overlapping
        // Since the text is being displayed as a svg element conventional css linebreaks do not work
        if (dom == "Heterosexual") {
            dom = "Hetero- sexual"
        }
        return (
            <g transform={`translate(${x},${y})`}>
                <Text
                    style={
                        {
                            fontFamily: "LatoWeb",
                            fontSize: 12,
                            fill: '#322352',
                            fontWeight: "bold"
                        }
                    }
                    width={60}
                    x={0}
                    y={0}
                    dx={-spacing}
                    dy={4}
                    verticalAnchor="start"
                    textAnchor="middle"
                >
                    {dom}
                </Text>
                <Text
                    style={
                        {
                            fontFamily: "LatoWeb",
                            fontSize: 12,
                            fill: '#322352',
                            fontWeight: "bold"
                        }
                    }
                    width={60}
                    x={0}
                    y={0}
                    dx={0}
                    dy={4}
                    verticalAnchor="start"
                    textAnchor="middle"
                >
                    {nonDom}
                </Text>
                <Text
                    style={
                        {
                            fontFamily: "LatoWeb",
                            fontSize: 12,
                            fill: '#322352',
                            fontWeight: "bold"
                        }
                    }
                    width={60}
                    x={0}
                    y={0}
                    dx={spacing + 7}
                    dy={4}
                    verticalAnchor="start"
                    textAnchor="middle"
                >
                    {"Prefer not to say"}
                </Text>
            </g>
        );
    }
}


export const FreeBarChart = ({
    barGraphData,
    colors,
    colorIndexA,
    colorIndexB,
    colorIndexC,
    secondaryColors,
    secondaryColorIndexA,
    secondaryColorIndexB,
    secondaryColorIndexC,
    onMouseMove,
    groups
}) => {
    return (
        <ResponsiveContainer height={300} width="100%">
            <BarChart
                data={barGraphData}
                barGap={20}
                onMouseMove={onMouseMove}
            >
                <CartesianGrid stroke="#DBD9DB" vertical={false} />
                <Tooltip cursor={true} />
                <XAxis
                    height={80}
                    interval={0}
                    fontSize={11}
                    fontFamily="Lato"
                    tick={
                        <CustomizedAxisTick
                            activeIndex={null}
                            groups={groups}
                        />
                    }
                    dataKey="name"
                    tickSize={0}
                    axisLine={false}
                    tickLine={false}
                    tickMargin={25}
                    xAxisId={0} />
                <XAxis
                    height={80}
                    interval={0}
                    fontSize={11}
                    fontFamily="Lato"
                    tick={
                        <CustomizedAxisTick
                            activeIndex={null}
                            groups={groups}
                        />
                    }
                    dataKey="name"
                    tickSize={0}
                    axisLine={false}
                    tickLine={false}
                    tickMargin={15}
                    xAxisId={1}
                    padding={{ left: 1 }}
                    hide />
                <YAxis
                    tickCount={7}
                    fontSize={11}
                    fontFamily="LatoWeb"
                    orientation='left'
                    tick={{
                        fontSize: 12,
                        fill: '#322352',
                        fontWeight: "bold"
                    }}
                    axisLine={false}
                    tickLine={false}
                    tickMargin={5}
                    domain={[0, 100]} />
                <Bar
                    dataKey="overall_demographic_a"
                    xAxisId={0}
                    barSize={40}
                >
                    {
                        barGraphData.map((entry, index) => (
                            <Cell key={`cell-${index}`}
                                fill={colors[colorIndexA++]} />
                        ))
                    }
                </Bar>
                <Bar
                    dataKey="demographic_a"
                    xAxisId={1}
                    barSize={20}
                    fillOpacity={0.7}
                >
                    {
                        barGraphData.map((entry, index) => (
                            <Cell key={`cell-${index}`}
                                fill={secondaryColors[secondaryColorIndexA++]} />
                        ))
                    }
                </Bar>
                <Bar
                    dataKey="demographic_a"
                    xAxisId={1}
                    barSize={0}
                    fillOpacity={0.7}
                >
                    {
                        barGraphData.map((entry, index) => (
                            <Cell key={`cell-${index}`}
                                fill={'transparent'} />
                        ))
                    }
                </Bar>
                <Bar
                    dataKey="overall_demographic_b"
                    xAxisId={0}
                    barSize={40}
                >
                    {
                        barGraphData.map((entry, index) => (
                            <Cell key={`cell-${index}`}
                                fill={colors[colorIndexB++]} />
                        ))
                    }
                </Bar>
                <Bar
                    dataKey="demographic_b"
                    xAxisId={1}
                    barSize={20}
                    fillOpacity={0.7}
                >
                    {
                        barGraphData.map((entry, index) => (
                            <Cell key={`cell-${index}`}
                                fill={secondaryColors[secondaryColorIndexB++]} />
                        ))
                    }
                </Bar>
                <Bar
                    dataKey="overall_demographic_c"
                    xAxisId={0}
                    barSize={40}
                >
                    {
                        barGraphData.map((entry, index) => (
                            <Cell key={`cell-${index}`}
                                fill={colors[colorIndexC++]} />
                        ))
                    }
                </Bar>
                <Bar dataKey="demographic_c" xAxisId={1} barSize={0} fillOpacity={0.7}>
                    {
                        barGraphData.map((entry, index) => (
                            <Cell key={`cell-${index}`}
                                fill={'transparent'} />
                        ))
                    }
                </Bar>
                <Bar
                    dataKey="demographic_c"
                    xAxisId={1}
                    barSize={20}
                    fillOpacity={0.7}
                >
                    {
                        barGraphData.map((entry, index) => (
                            <Cell key={`cell-${index}`}
                                fill={secondaryColors[secondaryColorIndexC++]} />
                        ))
                    }
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    )
}
