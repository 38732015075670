import { getFontStyle, Toast as DdsToast, Button as DdsButton } from '@diversioteam/diversio-ds'
import styled from 'styled-components'

export const Title = styled.h2`
    color: ${({ theme }) => theme.colors.primary90};
    margin: 0 0 20px;

    ${getFontStyle('h3Bold')}
`

export const CodeButtons = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    margin: 0 0 32px;
`

export const Buttons = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    margin: 0 0 12px;
`

export const Toast = styled(DdsToast)`
    & button {
        display: none;
    }
    border-radius: 12px;
    margin: 0 0 32px;
    padding: 10px;
`

export const ClipBoardButtons = styled.div`
    display: flex;
    gap: 12px;
    width: 100%;
    justify-content: flex-end;
`

export const ClipboardToast = styled(DdsButton)`
    && {
        display: flex;
        justify-content: flex-start;
    }
    pointer-events: none;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.primaryGray1};
    border-radius: 10px;
    padding: 12px;
    margin-top: 32px;
    color: ${({ theme }) => theme.colors.primary90};

    ${getFontStyle('p02Regular')};
`

export { Description, Button } from '../account.styles'
