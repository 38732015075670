export enum Mutations {
    login = 'login',
    initializeSso = 'initializeSso',
    ssoCallback = 'ssoCallback',
    requestResetPassword = 'requestResetPassword',
    resetPassword = 'resetPassword',
    requestVerificationCode = 'requestVerificationCode',
    validateVerificationCode = 'validateVerificationCode',
    createDiversityCompanyProfile = 'createDiversityCompanyProfile',
    updateDiversityCompanyProfile = 'updateDiversityCompanyProfile',
    deleteDiversityCompanyProfile = 'deleteDiversityCompanyProfile',
    createInclusionCompanyProfile = 'createInclusionCompanyProfile',
    updateInclusionCompanyProfile = 'updateInclusionCompanyProfile',
    deleteInclusionCompanyProfile = 'deleteInclusionCompanyProfile',
    exportDiversityData = 'exportDiversityData',
    exportDiversityDataOverTime = 'exportDiversityDataOverTime',
    exportDiversityProfilesData = 'exportDiversityProfilesData',
    exportInclusionData = 'exportInclusionData',
    exportInclusionDataOverTime = 'exportInclusionDataOverTime',
    exportFreeTexts = 'exportFreeTexts',
    exportBespokeFreeTexts = 'exportBespokeFreeTexts',
    saveReport = 'saveReport',
    deleteReportFromSaved = 'deleteReportFromSaved',
    saveTool = 'saveTool',
    deleteToolFromSaved = 'deleteToolFromSaved',
    updateUserMetadataFeatureFlags = 'updateUserMetadataFeatureFlags',
    enableMFA = 'enableMFA',
}
