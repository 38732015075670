import React from 'react'
import parser from 'html-react-parser'
import { ChevronDownIcon, getKpiLabel, Button, ReloadAltIcon } from '@diversioteam/diversio-ds'
import { Skeleton as MuiSkeleton } from '@material-ui/lab'

import { FreeTextAccordionProps } from './freeTextAccordion.types'
import * as S from './freeTextAccordion.styles'

export const FreeTextAccordion = ({
    responses,
    text,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    ...props
}: FreeTextAccordionProps) => {
    const isLoadMoreButtonVisible = hasNextPage && !isFetchingNextPage

    return (
        <S.Accordion {...props}>
            <S.AccordionSummary
                expandIcon={<ChevronDownIcon type="line" />}
                aria-controls={`${text}-content`}
                id={`${text}-header`}
                IconButtonProps={{
                    disableTouchRipple: true,
                }}
            >
                {text}
            </S.AccordionSummary>

            <S.AccordionDetails>
                <S.Responses>
                    {responses.map(({ kpi, painPoint, text }, index) => {
                        const getDetails = () => {
                            if (kpi && painPoint) {
                                return (
                                    <S.Details>
                                        {getKpiLabel(kpi)} / {painPoint}
                                    </S.Details>
                                )
                            }

                            if (kpi) {
                                return <S.Details>{getKpiLabel(kpi)}</S.Details>
                            }

                            if (painPoint) {
                                return <S.Details>{painPoint}</S.Details>
                            }

                            return null
                        }

                        return (
                            <S.Response key={index}>
                                {parser(text)}
                                {getDetails()}
                            </S.Response>
                        )
                    })}

                    {isFetchingNextPage && (
                        <S.Response>
                            <MuiSkeleton width="100%" />
                        </S.Response>
                    )}

                    {isLoadMoreButtonVisible && (
                        <S.LoadMoreButtonWrapper>
                            <Button
                                size="small"
                                color="transparent"
                                rightIcon={<ReloadAltIcon type="line" />}
                                onClick={fetchNextPage}
                            >
                                Load More
                            </Button>
                        </S.LoadMoreButtonWrapper>
                    )}
                </S.Responses>
            </S.AccordionDetails>
        </S.Accordion>
    )
}
