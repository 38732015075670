import React from 'react'

import { withErrorBoundary } from 'config/withErrorBoundary/withErrorBoundary'
import { DataRepresentation } from '../DataRepresentation'
import { DataRepresentationOverTime } from '../DataRepresentationOverTime'
import { ProfilesDataRepresentation } from '../ProfilesDataRepresentation'

import { useFilters } from './../../hooks/useFilters'
import { DataProps } from './data.types'

const DataWithoutEB = ({ showProfilesPage, selectedTab }: DataProps) => {
    const { resultsOverTime, demographic, selectedFiltersPayload, selectedSurveysFilter } = useFilters()

    return showProfilesPage ? (
        <ProfilesDataRepresentation />
    ) : !resultsOverTime ? (
        <DataRepresentation
            selectedTab={selectedTab}
            demographic={demographic}
            selectedFiltersPayload={selectedFiltersPayload}
        />
    ) : (
        <DataRepresentationOverTime demographic={demographic} selectedSurveys={selectedSurveysFilter} />
    )
}

export const Data = withErrorBoundary(DataWithoutEB, {})
