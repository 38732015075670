import React, { forwardRef } from 'react'
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'
import queryString from 'query-string'

const InternalLink = forwardRef<HTMLAnchorElement, RouterLinkProps>(({ children, onClick, to, ...rest }, ref) => {
    const { to: defaultRedirectsTo } = queryString.parse(location.search)

    const redirectsTo = defaultRedirectsTo ? `${to}?to=${encodeURIComponent(defaultRedirectsTo as string)}` : to

    return (
        <RouterLink to={redirectsTo} onClick={onClick} {...rest} ref={ref}>
            {children}
        </RouterLink>
    )
})

export default InternalLink
