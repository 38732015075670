import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'

import { loginSchema } from '../../Auth/Login/login.schema'
import { AccountLoginProps as VerifyLoginProps, LoginFields, LoginFormData } from '../../Auth/Login/login.types'

import * as S from './verifyLogin.styles'

export const VerifyLogin = ({ isLoading, onSubmit, username }: VerifyLoginProps) => {
    const { t } = useTranslation()

    const { register, handleSubmit, errors } = useForm<LoginFormData>({
        resolver: yupResolver(loginSchema),
    })

    const handleSubmitCallback = useCallback(
        async (data: LoginFormData) => {
            await onSubmit(data)
        },
        [onSubmit],
    )

    const getErrorMessage = (fieldName: LoginFields) => {
        return t(errors[fieldName]?.message || '')
    }

    return (
        <S.VerificationCard onSubmit={handleSubmit(handleSubmitCallback)}>
            <S.Title>{t('AUTH.MFA.SAVE_BACKUP_CODES_TITLE')}</S.Title>
            <S.Subtitle>Please enter your password to access your codes</S.Subtitle>

            <S.Form>
                <S.TextField
                    id={LoginFields.username}
                    inputRef={register}
                    error={Boolean(errors[LoginFields.username])}
                    helperText={getErrorMessage(LoginFields.username)}
                    name={LoginFields.username}
                    label={t('AUTH.LOGIN.USERNAME_EMAIL')}
                    type="text"
                    disabled={isLoading}
                    value={username}
                    hidden={true}
                />

                <S.TextField
                    id={LoginFields.password}
                    inputRef={register}
                    error={Boolean(errors[LoginFields.password])}
                    helperText={getErrorMessage(LoginFields.password)}
                    name={LoginFields.password}
                    label="Enter Password"
                    type="password"
                    disabled={isLoading}
                />
                <S.Button loading={isLoading} type="submit" color="primary" size="large">
                    Continue
                </S.Button>
            </S.Form>
            <S.Description>{t('AUTH.MFA.SAVE_BACKUP_CODES_DESCRIPTION')}</S.Description>
        </S.VerificationCard>
    )
}
