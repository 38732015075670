import React from 'react';
import _Legend from '../../../Home/HeatMap/Legend';

const Legend = () => {
    return (
        <_Legend/>
    );
}

export default Legend;
