import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Actions} from '../actions';
import Analyze from "../components/Analyze";
import {createFiltersPayload} from "../components/Analyze/Filters/utils"
import mixpanel from "mixpanel-browser";
import TAXONOMIES from '../utils/taxonomies';
import './App.scss';

class AnalyzeContainer extends Component {
    componentWillMount() {
        this.props.getInclusionMetricsData({data: {year: "", level: "", office: "", department: ""}});
        this.props.getInclusionBreakdownData({data:createFiltersPayload({group_a:{"genders": ["Male"]}, group_b:{"genders": ["Female"]}})});
        this.props.getDiversityBreakdownData({data: {offices: "%", roles: "%"}});
        this.props.getInclusionTrackerData({data: {year: "", level: "", office: "", department: ""}});
        this.props.getDiversityTrackerData({data: {year: "", level: "", office: "", department: ""}});
        this.props.getDiversityBreakdownByRoleData({data: {year: "", level: "", office: "", department: ""}});
        this.props.getFreeText();
        this.props.getCompanyFilters();
    }

    componentDidMount() {
        mixpanel.track(TAXONOMIES.VIEW_PAGE, {
            name: "Analyze"
        })
    }

    render() {
        return <Analyze { ...this.props } />;
    }
}

const mapStateToProps = state => {
    return {
        ...state.groups,
        analyze: state.analyze,
        home: state.home,
        colors: state.colors,
        kpimap: state.kpimap,
        profile: state.profile,
        enableDebugMode: state.auth.enableDebugMode
    };
};

export default connect(mapStateToProps, Actions)(AnalyzeContainer);
