import { AxiosError } from 'axios'

import { AuthErrorResponse, AuthResponse } from 'api/actions/auth/authActions.types'

export type ForgotPasswordProps = {
    isLoading: boolean
    isSuccess: boolean
    data?: AuthResponse
    isError: boolean
    error: AxiosError<AuthErrorResponse> | null
    onSubmit(values: ForgotPasswordFormData): Promise<boolean>
}

export enum ForgotPasswordFields {
    usernameEmail = 'usernameEmail',
}

export type ForgotPasswordFormData = {
    [ForgotPasswordFields.usernameEmail]: string
}
