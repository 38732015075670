import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { AuthErrorResponse, AuthResponse, ResetPasswordPayload } from 'api/actions/auth/authActions.types'
import { resetPasswordAction } from 'api/actions/auth/authActions'
import { Mutations } from 'api/mutations.enum'

export const useResetPassword = () => {
    const mutation = useMutation<AuthResponse, AxiosError<AuthErrorResponse>, ResetPasswordPayload>({
        mutationKey: [Mutations.resetPassword],
        mutationFn: resetPasswordAction,
    })

    return mutation
}
