import { Store } from './../../App.types'
import { diversioApi, diversioApiV2, diversioApiV2FormData } from './axiosInstance'
import { requestSuccessInterceptor } from './interceptors/requestInterceptors'
import { responseFailureInterceptor, responseSuccessInterceptor } from './interceptors/responseInterceptors'

export const setupInterceptors = (store: Store) => {
    diversioApi.interceptors.request.use(requestSuccessInterceptor)

    diversioApiV2.interceptors.request.use(requestSuccessInterceptor)

    diversioApiV2FormData.interceptors.request.use(requestSuccessInterceptor)

    diversioApi.interceptors.response.use(responseSuccessInterceptor, (config) =>
        responseFailureInterceptor(config, store),
    )

    diversioApiV2.interceptors.response.use(responseSuccessInterceptor, (config) =>
        responseFailureInterceptor(config, store),
    )

    diversioApiV2FormData.interceptors.response.use(responseSuccessInterceptor, (config) =>
        responseFailureInterceptor(config, store),
    )
}
