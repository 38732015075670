import { useDispatch } from 'react-redux'

import { Message } from 'types/message.types'
import { Actions } from '../actions'

export const useMessage = () => {
    const dispatch = useDispatch()

    return {
        showMessage: (args: Message) => {
            dispatch(Actions.showMessage(args))
        },
    }
}
