import styled from 'styled-components'

import { Reporting, Reports } from 'containers/Reporting/reporting.styles'

export * from 'containers/Reporting/reporting.styles'

export const Tools = Reporting

export const ToolsList = Reports

export const Filters = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 30px 0;
`
