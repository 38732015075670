import React from 'react'

import { LegendItemProps } from './../../dataRepresentation.types'
import * as S from './benchmarkLegendItem.styles'

export const BenchmarkLegendItem = ({ color, label }: LegendItemProps) => (
    <S.BenchmarkLegendItemWrapper>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 10.5H16" stroke={color} strokeWidth="3" strokeLinecap="round" />
        </svg>

        <S.LegendItemLabel>{label}</S.LegendItemLabel>
    </S.BenchmarkLegendItemWrapper>
)
