import { useDispatch } from 'react-redux'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { saveReport } from 'sagas/network'
import { Actions } from 'actions'

import { Mutations } from 'api/mutations.enum'
import { Queries } from 'api/queries.enum'
import { SaveReportPayload } from 'api/actions/reporting/reporting.types'

export const useSaveReport = () => {
    const queryClient = useQueryClient()
    const dispatch = useDispatch()

    const mutation = useMutation<void, Error, SaveReportPayload>({
        mutationKey: [Mutations.saveReport],
        mutationFn: (payload) => {
            return saveReport(payload)
        },
        onSuccess: () => {
            dispatch(
                Actions.showToast({
                    type: 'success',
                    message: 'Report has been saved!',
                }),
            )

            queryClient.invalidateQueries([Queries.getReports])
            queryClient.invalidateQueries([Queries.getMyReports])
        },
        onError: () => {
            Actions.showToast({
                type: 'warning',
                message: 'Something went wrong',
            })
        },
    })

    return mutation
}
