import { AxiosError } from 'axios'

import { AuthErrorResponse, InitializeSsoPayload } from 'api/actions/auth/authActions.types'

export type SsoProps = {
    isLoading: boolean
    isError: boolean
    error: AxiosError<AuthErrorResponse> | null
    onSubmit(values: InitializeSsoPayload): Promise<boolean>
    emailPlaceholder?: string
}

export enum SsoFields {
    email = 'email',
}

export type SsoFormData = {
    [SsoFields.email]: string
}
