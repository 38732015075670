import styled from 'styled-components'

import { Table } from '../Table/table.styles'

import { Size } from './sideCells.types'

export { TableHeader } from '../Table/table.styles'
export { TableRow } from '../Table/TableRow/tableRow.styles'

export const SideCells = styled(Table)<{ size?: Size }>`
    z-index: 100;
    position: sticky;
    left: 0;
    padding: 1px;
    border-spacing: 4px;
    background: ${({ theme }) => theme.colors.primaryWhite};
    width: ${({ size }) => (size?.width ? `${size.width - 4}px` : 'auto')};
`

export const MetricSelectContainer = styled.th`
    height: 62px;
    background: ${({ theme }) => theme.colors.primaryWhite};
    z-index: 101;
    position: relative;
`

export const EmptyCell = styled.td`
    z-index: 100;
    position: sticky;
    left: 0;
    height: 63px;
    background: ${({ theme }) => theme.colors.primaryWhite};
`

export const SideCell = styled.td`
    padding: 0;
    position: sticky;
    left: -1px;
    z-index: 100;
    max-width: 100%;
    background: ${({ theme }) => theme.colors.primaryWhite};
    cursor: pointer;

    div.dds-heat-map-side-cell {
        border-bottom: 1px solid ${({ theme }) => theme.colors.primaryGray2};
    }

    > div::before {
        content: '';
        display: block;
        position: absolute;
        z-index: -1;
        background-color: white;
        border-radius: 0px;
    }
`
