import React from 'react'
import { NpsCard, NpsCardSkeleton } from '@diversioteam/diversio-ds'
import mixpanel from 'mixpanel-browser'

import { useGetNps } from 'hooks/useGetNps'
import { useSurveys } from 'hooks/useSurveys/useSurveys'
import TAXONOMIES from 'utils/taxonomies'

export const Nps = () => {
    const { data, isLoading } = useGetNps()
    const { survey } = useSurveys()

    if (isLoading || !survey) {
        return <NpsCardSkeleton data-testId="nps-skeleton" />
    }

    if (data?.data && survey) {
        return (
            <NpsCard
                results={data.data}
                selectedSurvey={{
                    year: survey.year,
                    quarter: survey.quarter,
                    yearQuarterLabel: survey.name,
                }}
                onTrigger={(trigger) => mixpanel.track(TAXONOMIES.HOME_INTERACT_NPS_SCORE, { trigger })}
                onTabChange={(tab) => mixpanel.track(TAXONOMIES.HOME_SWITCH_NPS_VIEW, { tab })}
                onSurveySwitch={(selectedSurveys) =>
                    mixpanel.track(TAXONOMIES.HOME_SWITCH_NPS_SURVEY, { selectedSurveys })
                }
                data-testId="nps"
            />
        )
    }

    return null
}
