import { colors } from '@diversioteam/diversio-ds'

export const profilesColors = [
    colors.orangeSharp,
    colors.green65,
    colors.primary35,
    colors.primary70,
    colors.fuchsia20,
    colors.fuchsia40,
    colors.primary50,
    colors.orange20,
    colors.tangerine70,
    colors.tangerineSharp,
    colors.purple60,
    colors.green60,
    colors.secondary200,
]
