import { KEY_MAP } from './utils';
import _ from 'underscore';

/* Rearrange data objects into format required by Recharts.
See ./testReshapeData.js for more details. */
export const reshapeData = (data, iData, ndg, breakdown, companyName) => {
    const isSprucegrove = companyName && (companyName == "Sprucegrove" || companyName == "Sprucegrove Shareholders")

    const reshapedData = data.map((dimension, i) => {
        const dimName = Object.keys(dimension)[0];
        let ndList;

        if (!(breakdown[i] && breakdown[i][dimName])){
            ndList = []
        } else {
            if (dimName == "Disability") {
                // Shape of this payload is different: https://app.clickup.com/t/7ew52q
                // It is also sometimes not present for companies whose surveys did not include disability breakdowns
                ndList = breakdown[i][dimName].map(i => i["name"]);
            } else {
                ndList = Object.keys(breakdown[i][dimName]);
            }
        }

        let companyData;

        // returned value is [] if threshold not met
        // or all 0; i.e.
        // [{"name":"White","value":0},{"name":"POC","value":0},{"name":"Prefer not to answer","value":0}]
        if (dimension[dimName].length == 0 ||
            dimension[dimName].map(i => i.value).reduce((a,b) => (parseFloat(a)+parseFloat(b))) == 0) {
            companyData = {
                    th: 100,
                }
        } else {
            if (dimension[dimName].length == 3) {
                companyData = {
                    d: _.maybe(dimension[dimName], 0, "value"),
                    nd: _.maybe(dimension[dimName], 1, "value"),
                    pnts: _.maybe(dimension[dimName], 2, "value")
                }
            }
            else if (dimension[dimName].length > 3) {
                // FE sum of float could lead to rounding errors?
                let ndScore;
                let pntsScore;
                if (dimName === "Gender") {
                    // Handles the case when say Gender has 4 values: (Men, Women, Non-Binary, PNTS) OR (Men, Women+, PNTA and Transgender(yes))
                    ndScore = 0;
                    for (const obj of dimension[dimName]) {
                        if (!["Male", "Prefer not to answer", "Transgender(yes)"].includes(obj["name"])) {
                            ndScore += obj["value"];
                        }
                        if (obj["name"] === "Prefer not to answer") {
                            pntsScore = obj["value"];
                        }
                    }
                }
                else if (dimName == "Ethnicity") {
                    // Handles the case when say Ethnicity has 4 values: Dominant Eth(say White), POC, Indigenous, PNTS)
                    // In this case we do not want to include Indigenous in NDG
                    ndScore = 0;
                    for (const obj of dimension[dimName]) {
                        if (![_.maybe(dimension[dimName], 0, "name"), "Prefer not to answer", "Indigenous"].includes(obj["name"])) {
                            ndScore += obj["value"];
                        }
                        if (obj["name"] === "Prefer not to answer") {
                            pntsScore = obj["value"];
                        }
                    }
                }
                else {
                    // For dimension other than Gender we assume here that first item is always dominant group and last one in PNTS and everything in between is NDG.
                    ndScore = dimension[dimName].slice(1, dimension[dimName].length - 1).reduce((prevValue, currentValue) => prevValue["value"] + currentValue["value"]);
                    pntsScore = _.maybe(dimension[dimName], dimension[dimName].length - 1, "value");
                }
                companyData = {
                    d: _.maybe(dimension[dimName], 0, "value"),
                    nd: parseFloat(ndScore.toFixed(1)),
                    pnts: pntsScore
                }
            }
        }

        return {
            "type": dimName,
            "data": companyData,
            "ndList": ndList,
        }
    });

    let newList = [];

    for (const dim of reshapedData) {
        const dimName = dim.type;
        let domEth = data?.[1]?.["Ethnicity"]?.[0]?.name || "White"
        let ndList = dim.ndList.filter(group => !["Male", "Prefer not to answer", domEth, "Heterosexual"].includes(group));

        if (!iData[dimName]) {
            continue;
        }

        let industryData = {
            d: _.maybe(iData[dimName], 0, "value"),
            nd: _.maybe(iData[dimName], 1, "value"),
            pnts: _.maybe(iData[dimName], 2, "value")
        }
        // For Sprucegrove dashboards, manually show static industry benchmark data for ethnicity 
        if (isSprucegrove && dimName == "Ethnicity") {
            industryData = {
                d: 18.8,
                nd: 74.6,
                pnts: 6.6,
            } 
        }

        let domKey = {
            // Use actual dominant ethnicity rather than assuming it is "White"
            d: dimName == "Ethnicity" ? domEth : _.maybe(iData[dimName], 0, "name"),
            nd: _.maybe(iData[dimName], 1, "name"),
            pnts: _.maybe(iData[dimName], 2, "name"),
        }
        newList.push(
            {...dim,
                "idata": (dimName == "Ethnicity" && domEth !== "White" && !isSprucegrove) ? null : industryData,
                "domKey": domKey,
                "ndList": ndList,
                });
    }

    return newList;
};
