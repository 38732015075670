import React from 'react'
import {
    ProfilesBarChart,
    ProfilesBarChartProps,
    SecondaryHorizontalIcon,
    SingleSelect,
} from '@diversioteam/diversio-ds'
import { parseYearQuarter } from 'utils'
import { ErrorBoundary } from '@sentry/react'

import { profilesColors } from 'utils/profilesCors'
import { withErrorBoundary } from 'config/withErrorBoundary/withErrorBoundary'
import { useExportDiversityProfilesData } from 'hooks/diversity/export/useExportDiversityProfilesData'
import { useGetCompanyProfiles } from 'hooks/companyProfiles/useGetCompanyProfiles'
import { AnalyzeView } from 'types/analyze.enum'
import { CompanyProfilesParams } from 'api/actions/analyze/companyProfiles/companyProfilesActions.types'
import { GetCompanyProfilesResponse } from 'sagas/actions/analyze/companyProfiles/companyProfilesActions.types'
import { useSurveys } from 'hooks/useSurveys/useSurveys'

import * as S from './profilesDataRepresentation.styles'

const SELECT_LABELS = {
    download: 'Download CSV',
}

const SELECT_OPTIONS = [
    {
        label: SELECT_LABELS.download,
        value: 'download',
    },
]

const transformToBarChart = (data: GetCompanyProfilesResponse): ProfilesBarChartProps['data'] => {
    return data.profiles.map((profile) => {
        return {
            name: profile.label,
            uuid: profile.uuid,
            color: profilesColors[profile.color - 1],
            totalGroupRepresentatives: profile.totalGroupRepresentatives,
            totalParticipants: profile.totalParticipants,
        }
    })
}

const ProfilesDataRepresentationWithoutEB = () => {
    const { survey } = useSurveys()

    const profilesDataParams: CompanyProfilesParams = {
        year: parseYearQuarter(survey),
    }

    const { data, isLoading } = useGetCompanyProfiles(AnalyzeView.Diversity, profilesDataParams)

    const mutationExportProfilesData = useExportDiversityProfilesData({
        ...profilesDataParams,
        export_format: 'csv',
    })

    const handleSelectDropdownOption = (value: string) => {
        if (value === SELECT_LABELS.download) {
            return mutationExportProfilesData.mutate()
        }
    }

    if (isLoading || !data) {
        return null
    }

    const barChartData = transformToBarChart(data)

    return (
        <>
            <S.Header>
                <S.Title>Representation Rates</S.Title>

                <ErrorBoundary>
                    <SingleSelect
                        color="light"
                        variant="block"
                        listboxId="kpi"
                        isPlaceholderPermanent
                        placeholder={''}
                        icon={<SecondaryHorizontalIcon type="line" />}
                        options={SELECT_OPTIONS}
                        value={null}
                        onChange={handleSelectDropdownOption}
                        hideRightIcon
                    />
                </ErrorBoundary>
            </S.Header>

            <ErrorBoundary>
                <ProfilesBarChart data={barChartData} enableTooltipAnimation={false} />
            </ErrorBoundary>
        </>
    )
}

export const ProfilesDataRepresentation = withErrorBoundary(ProfilesDataRepresentationWithoutEB, {})
