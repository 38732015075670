import React, {useContext} from 'react';
import Select from "../../utils/Select";
import {FilterContext} from "./index";
import {withTranslation} from "react-i18next";

const Kpis = React.memo(({ label, valId = "kpi", dataId = "kpis", onOpen = () => {}, onClose = () => {}, t }) => {
    const { data, onChange, values } = useContext(FilterContext);
    label = label || t("ANALYZE.FILTERS.KPIS");
    return <Select
                options={data[dataId]}
                onOpen={onOpen}
                onClose={onClose}
                label={label}
                multiple={true}
                onChange={value => onChange(valId, value)}
                selectedValues={values[valId] || data[dataId]}/>;
});

Kpis.className = "Kpis";

export default withTranslation()(Kpis);
