export const categoryChoices = {
    Structural: 'Structural',
    ToneAndContent: "Tone & Content",
    RepliesAndReactions: "Replies & Reactions",
    Collaboration: "Collaboration"
};

export const discrepancyChoices = {
    highestToLowest: 'Highest to Lowest',
    lowestToHighest: 'Lowest to Highest'
};

export const SLACK_OAUTH_REDIRECT_URI = `${window.location.protocol}//${window.location.host}/oauth/slack`;

const client_id = process.env.REACT_APP_DD_SLACK_APP_CLIENT_ID;
const user_scope = 'channels:read,groups:read,im:read,mpim:read,channels:history,groups:history,im:history,mpim:history,users:read';
export const SLACK_AUTHORIZE_URL = `https://slack.com/oauth/v2/authorize/?client_id=${client_id}&redirect_uri=${SLACK_OAUTH_REDIRECT_URI}&user_scope=${user_scope}`;
