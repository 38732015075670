import _ from 'underscore'
import { DataItemOption } from '@diversioteam/diversio-ds/dist/components/core/CascaderFilters/EnterpriseFilter/enterpriseFilter.types'

interface SelectedOptions {
    [key: string]: string[] | boolean | SelectedOptions
}

export const getSelectedFilters = (selectedOptions: SelectedOptions, allOptions: SelectedOptions) => {
    if (_.isArray(selectedOptions)) {
        return selectedOptions
    }

    return _.keys(selectedOptions).reduce((reducer, optionKey: string) => {
        const innerOptions = allOptions[optionKey as keyof typeof allOptions]
        if (_.isObject(selectedOptions[optionKey]) && _.isObject(innerOptions) && !_.isArray(innerOptions)) {
            reducer[optionKey] = getSelectedFilters(selectedOptions[optionKey] as SelectedOptions, innerOptions)
        } else if (selectedOptions[optionKey] === true && _.isObject(innerOptions)) {
            reducer[optionKey] = innerOptions as string[]
        } else {
            reducer[optionKey] = selectedOptions[optionKey] as string[]
        }

        return reducer
    }, {} as DataItemOption)
}
