import React from 'react';
import './index.scss';

const ILNAssetSum = ( ) => {
    return(
        <div className="iln-assets">
            <div className="icon-iln-asset-sum" />
        </div>
    )
}

export default (ILNAssetSum);
