import styled from 'styled-components'

import { media } from 'styles/theme/mediaQueries'

export const HomeWrapper = styled.div<{ isDisclaimerVisible: boolean; hasDiversity: boolean; hasInclusion: boolean }>`
    flex: 1;
    display: flex;
    justify-content: space-between;
    min-width: 1px;

    .dds-people-surveyed-card .scrollbar-container {
        height: auto;
    }

    .dds-quote-card {
        min-height: ${({ hasDiversity, hasInclusion }) => {
            if (hasInclusion && !hasDiversity) {
                return 'unset'
            }
        }};
    }

    .dds-toast--bulky {
        z-index: 1100;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
`

export const Content = styled.div`
    display: flex;
    gap: 18px;
    flex-grow: 1;
    margin: 0 auto;
    min-width: 1px;
    width: 100%;
    padding: ${({ theme }) => theme.columnsSpacing.sm.margin}px;

    ${media.lg} {
        padding: ${({ theme }) => theme.columnsSpacing.lg.margin}px;
    }
`

export const PrimaryColumn = styled.div`
    width: calc(100% - 252px);
    z-index: 50;

    & > * {
        margin-bottom: 24px;
    }
`

export const SecondaryColumn = styled.div`
    width: 234px;
    z-index: 100;

    & > * {
        margin-bottom: 24px;
    }
`
