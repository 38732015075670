import { checkIfImageExists } from './../checkIfImageExists'

const BASE_URL = 'https://diversio.com/wp-content/'
const IMAGE_EXTENSIONS = ['.jpeg', '.jpg', '.png', '.gif']
const SIZE = '150x150'

export const transformThumbnailUrl = async (url: string) => {
    if (url.startsWith(BASE_URL)) {
        const fileExtension = url.substring(url.lastIndexOf('.'))

        if (IMAGE_EXTENSIONS.includes(fileExtension.toLowerCase())) {
            const transformedUrl = url.replace(fileExtension, `-${SIZE}${fileExtension}`)

            const ifImageExists = await checkIfImageExists(transformedUrl)

            if (ifImageExists) {
                return transformedUrl
            }
        }
    }

    const ifImageExists = await checkIfImageExists(url)

    if (ifImageExists) {
        return url
    }

    return undefined
}
