import styled from 'styled-components'
import { Toast as DdsToast } from '@diversioteam/diversio-ds'

export const ToastBox = styled.div`
    position: absolute;
    top: -36px;
    transform: translateY(-100%);
    padding: 0 16px;
    width: 100%;
`

export const Toast = styled(DdsToast)`
    box-sizing: border-box;
    border-radius: 12px;
`
