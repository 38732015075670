import React from 'react'
import { HeatMapTableBodyCell, HeatMapTableBodyCellProps } from '@diversioteam/diversio-ds'
import { ErrorBoundary } from '@sentry/react'

import { withErrorBoundary } from 'config/withErrorBoundary/withErrorBoundary'
import { RawDataScores } from 'hooks/useInclusionData'

import { InsufficientData, NotAvailableData } from './../../heatmap.types'
import { INSUFFICIENT_DATA, NOT_AVAILABLE } from './../../heatmap.utils'
import { HeatmapTableRowProps } from './tableRow.types'
import * as S from './tableRow.styles'

const HeatmapTableRowWithoutEB = ({ onClick, data, rowId, selectedCellInfo }: HeatmapTableRowProps) => {
    const getParsedScore = (score?: number | NotAvailableData | InsufficientData) => {
        if ([NOT_AVAILABLE, undefined, null].includes(score as NotAvailableData | undefined | null)) {
            return null
        }

        if (score === INSUFFICIENT_DATA) {
            return 'privacyProtected'
        }

        return Number(score)
    }

    const getScores = (inclusionData: RawDataScores): HeatMapTableBodyCellProps['scores'] => {
        const previous = getParsedScore(inclusionData.previous)
        const current = getParsedScore(inclusionData.current)

        if (current === previous) {
            return [{ value: previous }]
        } else {
            return [{ value: previous }, { value: current }]
        }
    }

    return (
        <S.TableRow>
            {data.map((inclusionData, idx) => {
                const cellProps: HeatMapTableBodyCellProps = {
                    scores: getScores(inclusionData),
                }

                cellProps['color'] = 'gray'

                if (inclusionData.isStrength || inclusionData.isWeakness) {
                    cellProps['color'] = inclusionData.isStrength ? 'green' : 'red'
                }

                const selected = selectedCellInfo?.row === rowId && selectedCellInfo.column === idx

                return (
                    <td key={`inclusion-table-row-${idx}`}>
                        <ErrorBoundary>
                            <HeatMapTableBodyCell
                                {...cellProps}
                                onClick={() => onClick(rowId, idx)}
                                selected={selected}
                            />
                        </ErrorBoundary>
                    </td>
                )
            })}
        </S.TableRow>
    )
}

export const HeatmapTableRow = withErrorBoundary(HeatmapTableRowWithoutEB, {})
