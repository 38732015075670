import React from 'react'
import { SurveyInfoModal } from '@diversioteam/diversio-ds'
import { SurveyInfoModalProps } from '@diversioteam/diversio-ds/dist/components/core/Modals/SurveyInfoModal/surveyInfoModal.types'
import { getActiveCompany } from 'utils'

import { useUserMetadata } from 'hooks/metadata/useUserMetadata'
import { useGetPastSurveys } from 'hooks/useGetPastSurveys'

import { SurveyInformationProps } from './surveyInformation.types'

export const SurveyInformation = ({ isOpen, setIsOpen }: SurveyInformationProps) => {
    const { data, isLoading } = useGetPastSurveys()
    const { data: userMetadata } = useUserMetadata()

    if (isLoading || !data) {
        return null
    }

    const modalData: SurveyInfoModalProps<'a'> = {
        companyName: getActiveCompany(userMetadata?.companies)?.name,
        buttonProps: {
            component: 'a',
            href: 'mailto:client-success@diversio.com',
        },
        open: isOpen,
        ...data,
        pastSurveys: data.pastSurveys.map((survey) => ({
            ...survey,
            numberOfEmployees: survey.numberOfResponses,
        })),
    }

    return <SurveyInfoModal {...modalData} open={isOpen} onClose={() => setIsOpen(false)} />
}
