import styled from 'styled-components'
import { SystemButton } from '@diversioteam/diversio-ds'

import { media } from 'styles/theme/mediaQueries'

export const RightContentPanelWrapper = styled.div`
    height: 100%;
    position: absolute;
    right: 0;
    z-index: 60;

    ${media.md} {
        position: relative;
    }
`

export const ToggleOpenButton = styled(SystemButton)`
    &.dds-system-button {
        position: absolute;
        left: -35px;
        top: 10px;

        div[class*='-icon'] {
            background-color: ${({ theme }) => theme.colors.primary90};
        }
    }
`

export const Content = styled.div`
    height: 100%;
    width: 276px;
    background-color: ${({ theme }) => theme.colors.primaryGray1};
    border-left: 1px solid ${({ theme }) => theme.colors.primaryWhite};
    box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.08);

    .dds-event-reminders,
    .dds-solutions-tab,
    .dds-announcements-card-carousel {
        border-bottom: 1px solid ${({ theme }) => theme.colors.secondary100Transparent};
    }

    *:last-child {
        border-bottom: none;
    }
`
