import { useSelector } from 'react-redux'

export const useUserInformation = () => {
    const userId = useSelector((state) => (state as any).auth.loggedinUserId)
    const username = useSelector((state) => (state as any).profile.username)
    const fullname = useSelector((state) => (state as any).profile.full_name)
    const email = useSelector((state) => (state as any).profile.email)
    const companyInformation = useSelector((state) => (state as any).home.companyInformation)
    const userUUID = useSelector((state) => (state as any).auth.uuid)
    const enableDebugMode = useSelector((state) => (state as any).auth.enableDebugMode)
    const isAuthenticated = useSelector((state) => (state as any).auth.isAuthenticated)

    return {
        userId: userId,
        username,
        fullname,
        email,
        companyInformation,
        userUUID,
        enableDebugMode,
        isAuthenticated,
    }
}
