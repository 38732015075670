import React, {Component} from 'react';
import {BarChart, Bar, Cell, XAxis, YAxis, LabelList} from "recharts";
import './index.scss';
import {discrepancyChoices} from "../constants";

// const singleChartSpec = {
//     title: '',
//     yAxis: '',
//     data: {
//         men: {value: 3, valueDisplay: 3},
//         women: {value: 3, valueDisplay: 3},
//         undefine: {value: 3, valueDisplay: 3}
//     },
// };

const barStyles = {
    "Men": {
        fill: '#cfb4f7',
    },
    "Women": {
        fill: '#6221EA',
    },
    "Unknown": {
        fill: '#d7cee4',
    },
    "NaN": {
        strokeDashArray: 4,
        stroke: 'black',
        fill: '#dadada',
        fillOpacity: '0.38',
        strokeOpacity: '0.38'
    }
};


class SimpleBarChart extends Component {
    height = 250;
    width = 315;

    customLabel(props) {
        const {x, width, value} = props;
        let fill = (props.fill === '#6221EA' && props.height >= 20) ? "#FFF" : "#000";
        return (
            <text x={x + width / 2} y={205} fill={fill} textAnchor="middle" dominantBaseline="middle">
                {value}
            </text>
        );
    }

    render() {
        const precision = Math.pow(10, this.props.precision ? this.props.precision : 0);
        let data = {};
        Object.keys(this.props.data).forEach(x => {
            data[x] = {name: x, value: Math.round(this.props.data[x] * precision) / precision};
            data[x].displayValue = this.props.displayValue(data[x].value);
        });
        data = Object.values(data).sort((a, b) => {
            if (isNaN(a.value)) {
                return 1
            }
            if (this.props.order === discrepancyChoices.highestToLowest) {
                return b.value - a.value;
            } else {
                return a.value - b.value;
            }
        });
        let dataMax = Math.max(...data.map(x => x.value).filter(x => !isNaN(x)));
        dataMax = dataMax !== -Infinity ? dataMax : Infinity;
        data = data.map(x => {
            if (isNaN(x.value)) {
                if (this.props.range[1] === 'dataMax') {
                    x.value = dataMax
                } else {
                    x.value = this.props.range[1]
                }
                x.displayValue = 'N/A'
            }
            return x;
        });

        const cells = data.map(({displayValue, name}, index) => {
            const style = barStyles[displayValue === 'N/A' ? 'NaN' : name];
            return <Cell key={name} {...style}/>
        });

        return (
            <div className={'bar-chart'}>
                <div className={'chart-container'} style={{height: this.height}}>
                    <div className={'y-axis-label'}>
                        <div>{this.props.yAxis}</div>
                    </div>
                    <BarChart data={data}
                              width={this.width}
                              height={this.height}
                    >
                        <YAxis domain={this.props.range} tickCount={6} hide/>
                        <XAxis dataKey="name"/>
                        <Bar
                            isAnimationActive={false}
                            dataKey={'value'}
                            labelKey={'displayValue'}
                            fill={"#fff000"}
                        >
                            <LabelList dataKey={'displayValue'} content={this.customLabel.bind(this)}
                                       position={'insideBottom'}/>
                            {cells}
                        </Bar>
                    </BarChart>
                </div>
                <div style={{width: this.width}} className={'x-axis-label'}>{this.props.title}</div>
            </div>
        );
    }
}

export default SimpleBarChart;
