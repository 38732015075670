export function mean(list, removeNull=true){
    list = list.filter(x=>x!==null);
    return list.reduce((a,b)=>a+b,0)/list.length
}

export function percent(list, removeNull=true){
    return mean(list, removeNull)*100
}


export const genderLabels = {
    'M': 'Men',
    'F': 'Women',
    'O': 'Non-binary',
    'Unknown': 'Unknown'
};

export function getGenderLabel(key) {
    return genderLabels[key] ? genderLabels[key] : key
}

export function formatDate(dateStr){
    const months = [
        'Jan.',
        'Feb.',
        'Mar.',
        'Apr.',
        'May',
        'Jun.',
        'Jul.',
        'Aug.',
        'Sep.',
        'Oct.',
        'Nov.',
        'Dec.'
    ];
    const date = new Date(dateStr);
    return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
}
