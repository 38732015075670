import React from 'react'

import { Report } from 'components/Report'
import { useGetMyReports } from 'hooks/reporting/useGetMyReports'

export const MyReports = () => {
    const queryMyReports = useGetMyReports()

    const handleProvideFeedback = (title: string) => {
        window.location.href = `mailto:cs@diversio.com?subject=Diversio Reporting Feedback - ${title}`
    }

    if (queryMyReports.data) {
        return queryMyReports.data.map((props) => (
            <div className="tool-card-wrapper my-solutions-tool" key={props.id}>
                <Report {...props} onProvideFeedback={() => handleProvideFeedback(props.title)} size="small" />
            </div>
        ))
    }

    return null
}
