import { getTools } from 'sagas/network'

import { Queries } from 'api/queries.enum'
import { GetToolsResponse, GetToolsResponseTransformed } from 'api/actions/home/solutions/solutionsActions.types'
import { useQuery } from 'hooks/useQuery'

import { transformData } from './transformData'

export const useGetTools = () => {
    const query = useQuery<GetToolsResponse, Error, GetToolsResponseTransformed>({
        queryKey: [Queries.getTools],
        queryFn: getTools,
        select: transformData,
    })

    return query
}
