import styled from 'styled-components'

export const TableRow = styled.tr`
    td,
    td > div {
        min-width: 88px;
    }

    th {
        width: 168px;
    }

    td {
        position: sticky;
        min-width: 160px;
        border: 1px;
        padding: 1px;
    }
`
