import { useMutation } from '@tanstack/react-query'
import { updateDiversityCompanyProfile, updateInclusionCompanyProfile } from 'sagas/network'
import { stringifyQueryParams } from 'utils'
import mixpanel from 'mixpanel-browser'

import { AnalyzeView } from 'types/analyze.enum'
import {
    CompanyProfilesParams,
    UpdateCompanyProfilePayload,
} from 'api/actions/analyze/companyProfiles/companyProfilesActions.types'
import { Mutations } from 'api/mutations.enum'
import TAXONOMIES from 'utils/taxonomies'

export const useUpdateCompanyProfile = (view: AnalyzeView, params?: CompanyProfilesParams) => {
    const mutationKey =
        view === AnalyzeView.Diversity
            ? [Mutations.updateDiversityCompanyProfile]
            : [Mutations.updateInclusionCompanyProfile]
    const mutationFn = view === AnalyzeView.Diversity ? updateDiversityCompanyProfile : updateInclusionCompanyProfile

    const trackingEventName =
        view === AnalyzeView.Diversity
            ? TAXONOMIES.ANALYZE_DIVERSITY_PROFILE_UPDATED
            : TAXONOMIES.ANALYZE_INCLUSION_PROFILE_UPDATED

    const mutation = useMutation<void, Error, UpdateCompanyProfilePayload>({
        mutationKey,
        mutationFn: ({ id, values }) => {
            return mutationFn({ id, values, queryString: stringifyQueryParams(params) })
        },
        onSuccess: (_data, payload) => {
            mixpanel.track(trackingEventName, payload)
        },
    })

    return mutation
}
