import styled from 'styled-components'
import { Skeleton } from '@material-ui/lab'
import { getFontStyle } from '@diversioteam/diversio-ds'

import shape from './../../assets/images/choose-dashboard-shape.svg'

export const ChooseDashboard = styled.div`
    height: 100%;
    width: 100%;
    padding: 80px 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: ${({ theme }) => theme.colors.primaryWhite};

    &::before {
        content: '';
        display: block;
        width: 548px;
        height: 850px;
        position: absolute;
        left: -124px;
        bottom: -210px;
        background-image: url(${shape});
        background-size: cover;
        z-index: 0;
    }

    &::after {
        content: '';
        display: block;
        width: 548px;
        height: 850px;
        position: absolute;
        right: -124px;
        bottom: -210px;
        background-image: url(${shape});
        background-size: cover;
        transform: scaleX(-1);
        z-index: 0;
    }
`

export const Logo = styled.img`
    width: 115px;
    z-index: 1;
`

export const Title = styled.h1`
    margin: 20px 0 60px;
    color: ${({ theme }) => theme.colors.primary70};
    z-index: 1;

    ${getFontStyle('h1Bold')};
`

export const List = styled.ul`
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
    overflow: auto;
    z-index: 1;
`

export const ListItem = styled.li`
    min-width: 240px;
    max-width: 100%;
    list-style-type: none;
`

export const ButtonSkeleton = styled(Skeleton)`
    border-radius: 18px;
    width: 100%;
    height: 60px;
`
