import { useMutation } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { AxiosError } from 'axios'
import { Actions } from 'actions'

import { AuthErrorResponse, LoginResponse, ValidateVerificationCodePayload } from 'api/actions/auth/authActions.types'
import { Mutations } from 'api/mutations.enum'
import { validateVerificationCodeAction } from 'api/actions/auth/authActions'
import { handleAuthCredentials } from 'utils/handleAuthCredentials'

export const useValidateVerificationCode = () => {
    const dispatch = useDispatch()

    const mutation = useMutation<
        LoginResponse,
        AxiosError<AuthErrorResponse>,
        ValidateVerificationCodePayload & { trustedDevice?: boolean }
    >({
        mutationKey: [Mutations.validateVerificationCode],
        mutationFn: validateVerificationCodeAction,
        onSuccess: (response, { trustedDevice }) => {
            dispatch(Actions.doneValidateVerificationCode(handleAuthCredentials(response, trustedDevice)))
        },
    })

    return mutation
}
