import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper/modules'
import { Skeleton } from '@material-ui/lab'

import { useGetInspirationalQuotes } from 'hooks/useGetInspirationalQuotes'

import * as S from './processingCard.styles'

export const ProcessingCard = () => {
    const { data } = useGetInspirationalQuotes()

    const modules = [Pagination, Autoplay]

    const pagination = {
        clickable: true,
    }

    if (!data) {
        return (
            <S.ProcessingCard skeleton>
                <S.Title>
                    <Skeleton width="100%" />
                </S.Title>

                <S.Description>
                    <Skeleton width={200} />
                </S.Description>
            </S.ProcessingCard>
        )
    }

    return (
        <S.ProcessingCard>
            <Swiper
                tag="section"
                modules={modules}
                pagination={pagination}
                loop
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
            >
                {data.map(({ quote, author }, index) => {
                    return (
                        <SwiperSlide key={index}>
                            <S.Title>{quote}</S.Title>

                            <S.Description>{author}</S.Description>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </S.ProcessingCard>
    )
}
