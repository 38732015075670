import React from 'react'
import { DiversityTable, DiversityTableSkeleton } from '@diversioteam/diversio-ds'
import { Link } from 'react-router-dom'
import { useMediaQuery } from '@material-ui/core'
import mixpanel from 'mixpanel-browser'

import { useGetDiversityBreakdown } from 'hooks/useGetDiversityBreakdown'
import { withErrorBoundary } from 'config/withErrorBoundary/withErrorBoundary'
import { generateCompanyURI } from 'sagas/helpers/generateCompanyURI'
import { AppRoute } from 'routing/AppRoute.enum'
import { breakpoints } from 'styles/theme/mediaQueries'
import TAXONOMIES from 'utils/taxonomies'

const DiversityBreakdownWithoutEB = () => {
    const { isLoading, data } = useGetDiversityBreakdown()

    const matches = useMediaQuery(`(min-width:${breakpoints.lg}px)`)

    if (isLoading) {
        return <DiversityTableSkeleton data-testid="diversity-breakdown-skeleton" />
    }

    if (!data || data.results.length === 0) {
        return null
    }

    return (
        <DiversityTable
            {...data}
            data-testid="diversity-breakdown"
            title="Diversity"
            titleWrapperProps={{
                component: Link,
                to: generateCompanyURI(AppRoute.AnalyzeDiversity),
            }}
            initialNumberOfRows={matches ? 1 : undefined}
            onTrigger={(trigger) => mixpanel.track(TAXONOMIES.HOME_INTERACT_DIVERSITY_SUMMARY, { trigger })}
            data-joyride="diversity-breakdown"
        />
    )
}

export const DiversityBreakdown = withErrorBoundary(DiversityBreakdownWithoutEB, {})
