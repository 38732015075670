import { getFontStyle } from '@diversioteam/diversio-ds'
import styled from 'styled-components'

export const RegionSwitchWrapper = styled.a`
    display: flex;
    align-items: center;
    gap: 4px;
    color: ${({ theme }) => theme.colors.secondary500};

    ${getFontStyle('p02Regular')}
`
