import styled from 'styled-components'
import { getFontStyle } from '@diversioteam/diversio-ds'

import { media } from 'styles/theme/mediaQueries'

export const QueueWrapper = styled.section`
    grid-area: queue;
    display: flex;
    flex-direction: column;
    border-radius: 24px;
    border-left: 1px solid ${({ theme }) => theme.colors.secondary100Transparent};
    background-color: ${({ theme }) => theme.colors.primaryWhite};
    position: relative;
    padding: 24px;

    ${media.lg} {
        padding: 32px 48px 48px;
    }
`
export const Title = styled.h2`
    margin: 0 0 24px;
    color: ${({ theme }) => theme.colors.primary90};

    ${getFontStyle('cardHeaderBold')};
`

export const List = styled.ul`
    flex: 1;
    padding: 0;
    margin: 0 0 24px;
`

export const Description = styled.div`
    margin-top: 8px;
    position: static;
    text-align: center;
    color: ${({ theme }) => theme.colors.secondary500};

    ${media.lg} {
        position: absolute;
        bottom: 28px;
        left: 0;
        right: 0;
        text-align: center;
    }

    ${getFontStyle('footRegular')};
`
