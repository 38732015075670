import styled from 'styled-components'
import { getFontStyle } from '@diversioteam/diversio-ds'

export const HeroExpirationWrapper = styled.div`
    width: 100%;
    height: 128px;
    padding: 32px 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    overflow: hidden;
    border-radius: 12px;
    background-color: ${({ theme }) => theme.colors.lobster10};
    text-align: left;

    &:before {
        content: '';
        display: block;
        background-color: ${({ theme }) => theme.colors.lobster50};
        border-radius: 390px;
        filter: blur(50px);
        position: absolute;
        width: 390px;
        height: 390px;
        right: -162px;
        bottom: -365px;
    }
`

export const Title = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    color: ${({ theme }) => theme.colors.lobsterSharp};

    ${getFontStyle('h2Bold')}
`

export const Subtitle = styled.div`
    color: ${({ theme }) => theme.colors.lobsterSharp};
    opacity: 0.6;

    ${getFontStyle('h2Bold')}
`
