import React, {useContext} from 'react';
import Select from "../../utils/Select";
import {FilterContext} from "./index";
import _ from "underscore";
import {withTranslation} from "react-i18next";

const Tenure = React.memo(({ label, valId = "tenure", onOpen = () => {}, onClose = () => {}, dataId = "tenures", t }) => {
    const { data, onChange, values } = useContext(FilterContext);
    label = label || t("ANALYZE.FILTERS.TENURE");
    if (_.isEmpty(data[dataId])) { return null; }
    return <Select options={data[dataId]}
                   onOpen={onOpen}
                   onClose={onClose}
                   label={label}
                   multiple={true} onChange={value => onChange(valId, value)}
                   selectedValues={values[valId] || data[dataId]}/>;
});

export default withTranslation()(Tenure);
