import React, { Component } from 'react';
import {Cell, Pie, PieChart, ResponsiveContainer, Sector, Tooltip} from "recharts";
import './index.scss';
import { withTranslation } from 'react-i18next';


const renderActiveShape = (props, total, count, t) => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle,
    fill} = props;

    const formatCount = (count) => {
        if (parseFloat(count) > 1000) {
            const thousands = (parseFloat(count) / 1000).toFixed(1);
            return `${thousands}K`;
        }
        return count;
    }

    return (
        <g>
            <text className="employees-surveyed__total-label" x={cx} y={cy} dy={0} textAnchor="middle">{formatCount(count)}</text>
            <text className="employees-surveyed__total-text" x={cx} y={cy+14} dy={0} textAnchor="middle">{t("HOME.CARD_HEADERS.EMPLOYEES_SURVEYED.total")}</text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
        </g>
    );
};

const CustomTooltip = ({ payload, label, total }) => {
    let content = null;
    const active = true;

    if (payload[0]) {
        content = (
            <div className="custom-tooltip">
                <p className="custom-tooltip__percent">{`${(payload[0].value * 100 / total).toFixed(0)}%`}</p>
                <p className="custom-tooltip__name">{`${payload[0].name}`}</p>
            </div>
        );
    }

    if (active) {
      return content;
    }



    return null;
  };

class PiChart extends Component {
    state = {
        active: 0,
        activeSlice: null,
    }


    render() {
        const {colors, data, total, count, t} = this.props;

        return (
                <div className="employees-surveyed__pichart">
                    <ResponsiveContainer>
                        <PieChart>
                        <Tooltip allowEscapeViewBox={{x: true, y: true}} content={<CustomTooltip total={this.props.total}/>}/>
                            <Pie
                                stroke="none"
                                blendstroke
                                isAnimationActive={true}
                                activeIndex={this.state.active}
                                activeShape={(props) => {
                                    return renderActiveShape(props, total, count, t)
                                }}
                                data={this.props.data}
                                dataKey="value"
                                innerRadius="40%"
                                outerRadius="100%"
                            >
                                {
                                    this.props.data.map((entry, index) => (<Cell key={index} fill={this.props.colors[index]} onClick={() => {
                                        this.setState({ activeSlice: index })
                                    }}/>))
                                }
                            </Pie>
                        </PieChart>
                    </ResponsiveContainer>
                </div>
        );
    };
};

export default withTranslation()(PiChart);
