import React from 'react'

import { AddProfileRowProps } from './addProfileRow.types'
import * as S from './addProfileRow.styles'

export const AddProfileRow = ({ columns }: AddProfileRowProps) => {
    return (
        <S.TableRow>
            {columns.map((col, key) => (
                <S.Placeholder key={key}></S.Placeholder>
            ))}
        </S.TableRow>
    )
}
