/** @format */

import { ActionTypes } from "../actions";

const defaultState = {
  programs: [],
  count: 0,
  programsPagination: {},
  programsFilters: [],
  isLoading: {
    programs: false
  }
};

export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case ActionTypes.LOADING_SOLUTION_ALL_PROGRAMS:
      case ActionTypes.LOADING_ALL_PROGRAMS_V2:
        return {
          ...state,
          isLoading: {
            programs: true
          }
        };
      case ActionTypes.LOADED_SOLUTION_ALL_PROGRAMS:
      case ActionTypes.LOADED_ALL_PROGRAMS_V2:
        return {
          ...state,
          programs: action.payload.results,
          count: action.payload.count,
          programsPagination: action.payload,
          isLoading: {
            programs: false
          }
        };
      case ActionTypes.LOADED_SOLUTION_ALL_PROGRAMS_FILTERS:
        return {
          ...state,
          programsFilters: action.payload,
        };
      case ActionTypes.ALL_PROGRAMS_PAGE_ADD_PROGRAM: {
        const programID = action.payload.program;
        const newCompanySolution = action.payload.companySolution
        const value = true;
        const programsCopy = [...state.programs];
        for (const i in programsCopy) {
          if (programsCopy[i].id === programID) {
            programsCopy[i].is_saved = value;
            programsCopy[i].company_solution = newCompanySolution
            break;
          }
        }
        return {
          ...state,
          programs: programsCopy,
        };
      }
      case ActionTypes.DONE_SAVE_PROGRAM_V2: {
        const programsCopy = state.programs.map(program => {
          if (program.id === action.payload.program) {
            return {
              ...program,
              isSaved: true,
              companySolution: action.payload.companySolution
            }
          }

          return program
        });
        
        return {
          ...state,
          programs: programsCopy,
        };
      }
      case ActionTypes.ALL_PROGRAMS_PAGE_REMOVE_PROGRAM: {
        const programID = action.payload.program;
        const value = false;
        const programsCopy = [...state.programs];
        for (const i in programsCopy) {
          if (programsCopy[i].id === programID) {
            programsCopy[i].is_saved = value;
            programsCopy[i].company_solution = null
            break;
          }
        }
        return {
          ...state,
          programs: programsCopy,
        };
      }
      case ActionTypes.DONE_SOLUTION_REMOVE_V2: {
        const programsCopy = state.programs.map(program => {
          if (program.companySolution?.id === action.payload.solution) {
            return {
              ...program,
              isSaved: false,
              companySolution: null
            }
          }

          return program
        });

        return {
          ...state,
          programs: programsCopy,
        };
      }
      case ActionTypes.ALL_PROGRAMS_PAGE_ACTIVATE: {
        const programID = action.payload.program.id;
        const value = true;
        const programsCopy = [...state.programs];
        const newCompanySolution = action.payload.companySolution
        for (const i in programsCopy) {
          if (programsCopy[i].id === programID) {
            programsCopy[i].company_solution.is_active = value;
            programsCopy[i].company_solution = newCompanySolution
            break;
          }
        }
        return {
          ...state,
          programs: programsCopy,
        };
      }
      case ActionTypes.ALL_PROGRAMS_PAGE_DEACTIVATE: {
        const programID = action.payload.program.id;
        const value = false;
        const programsCopy = [...state.programs];
        for (const i in programsCopy) {
          if (programsCopy[i].id === programID) {
            programsCopy[i].company_solution.is_active = value;
            break;
          }
        }
        return {
          ...state,
          programs: programsCopy,
        };
      }
      case ActionTypes.DONE_CHANGE_SOLUTION_STATUS:
        const programsCopy = state.programs.map(program => {
          if (program.companySolution?.id === action.payload.id) {
            return {
              ...program,
              companySolution: {
                ...action.payload
              }
            }
          }

          return program
        });

        return {
          ...state,
          programs: programsCopy,
        };
      case ActionTypes.DONE_SOLUTION_ARCHIVE: {
        return {
          ...state,
        };
      }
      case ActionTypes.RESET_PROGRAMS_AND_SOLUTIONS:
        return {
          ...defaultState
        }
      default:
        return state;
    }
  } catch (error) {
    console.error(
      `Error in appState reducer: ${error.message || error.code || error}`,
      error
    );
  }
};
