const CATEGORIES1 = [
    {
        label: "Gender",
        options: ["Men", "Women"]
    },
    {
        label: "Ethnicity",
        options: ["White", "People of Color"]
    },
    {
        label: "LGBTQ2+",
        options: ["Heterosexual", "LGBTQ2+"]
    },
    {
        label: "Disability",
        options: ["No disability", "With disability"]
    }
];

const CATEGORIES2 = ["Men", "People of Color", "LGBTQ2+", "Persons with disablities"];

const defaultState = {
    categories: CATEGORIES2,
    extendedCategories: CATEGORIES1
};


export default (state = defaultState, action) => {
    try {

        switch(action.type) {
            default:
                return state;

        }

    } catch(error) {
        console.error(`Error in colors reducer: ${ error.message || error.code || error }`, error);
    }

};