import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import {
    Button,
    GoalIcon,
    ToyFaces,
    CheckmarkIcon,
    ChevronRightIcon,
    ReloadIcon,
    colors,
} from '@diversioteam/diversio-ds'

import { SelectInsightProps } from './selectInsight.types'
import * as S from './selectInsight.styles'

const ITEMS_PER_PAGE = 3

export const SelectInsight = forwardRef<HTMLDivElement, SelectInsightProps>(function SelectInsight(
    {
        insights,
        isIndustryBenchmarkAvailable,
        onScrollToTop,
        onInitCustomGoal,
        onInitRegularGoal,
        onViewRecommended,
        onChangeInsightListSpacingParams,
    },
    forwardedRef,
) {
    const [page, setPage] = useState(1)
    const listRef = useRef<HTMLDivElement | null>(null)

    useImperativeHandle<HTMLDivElement | null, HTMLDivElement | null>(forwardedRef, () => listRef.current)

    const insightsLoaded = insights !== undefined
    const hasInsights = insightsLoaded && insights.length !== 0
    const paginatedInsights = insights?.slice(0, ITEMS_PER_PAGE * page)
    const isLoadMoreVisible = paginatedInsights?.length !== insights?.length
    const hasLoadedMore = page > 1

    useEffect(() => {
        onScrollToTop()
    }, [])

    useEffect(() => {
        if (!listRef.current) {
            return
        }

        if (hasLoadedMore) {
            const listLoadedMoreHeight = 390

            const listClippedHeight = listRef.current?.scrollHeight - listRef.current?.offsetHeight
            const listPosition = listRef.current?.scrollTop + listLoadedMoreHeight

            onChangeInsightListSpacingParams({ clippedHeight: listClippedHeight, position: listPosition })
        }
    }, [page])

    const handleClickLoadMore = () => {
        setPage(page + 1)
    }

    const getSelectInsightTitle = () => {
        if (hasInsights) {
            return 'Select an insight'
        } else {
            if (isIndustryBenchmarkAvailable) {
                return 'Congratulations!'
            } else {
                return 'Get Started'
            }
        }
    }

    const getNoInsightsText = () => {
        if (isIndustryBenchmarkAvailable) {
            return (
                <>
                    <p>You&apos;re an industry leader scoring above the benchmark!</p>
                    <p>Set goals to maintain progress</p>
                </>
            )
        } else {
            return <p>Stay accountable when you create goals</p>
        }
    }

    const renderInsightsDisplay = () => (
        <S.ListWrapper>
            <S.List ref={listRef} hasLoadedMore={hasLoadedMore}>
                {paginatedInsights?.map(({ title, key, targetGroup, goalCreated }, index) => {
                    return (
                        <S.ListItem
                            key={index}
                            onClick={
                                goalCreated
                                    ? () =>
                                          onViewRecommended({
                                              kpi: key,
                                              targetGroup,
                                              insightArrPosition: index,
                                          })
                                    : () => onInitRegularGoal({ kpi: key, targetGroup, title })
                            }
                        >
                            <S.Title>{title}</S.Title>

                            {goalCreated ? (
                                <S.Status>
                                    <CheckmarkIcon type="line" color={colors.green50} height={31.5} width={31.5} />

                                    <S.StatusText>Goal Created</S.StatusText>
                                </S.Status>
                            ) : (
                                <Button
                                    type="button"
                                    size="small"
                                    color="transparent"
                                    rightIcon={<ChevronRightIcon type="bold" />}
                                >
                                    Create Goal
                                </Button>
                            )}
                        </S.ListItem>
                    )
                })}
            </S.List>

            {isLoadMoreVisible && (
                <S.LoadMore>
                    <Button
                        rightIcon={<ReloadIcon type="line" />}
                        type="button"
                        color="transparent"
                        onClick={handleClickLoadMore}
                    >
                        Load More
                    </Button>
                </S.LoadMore>
            )}
        </S.ListWrapper>
    )

    const renderNoInsightsDisplay = () => (
        <S.NoInsights>
            <S.NoInsightsText>{getNoInsightsText()}</S.NoInsightsText>

            <Button
                onClick={onInitCustomGoal}
                type="button"
                size="large"
                color="primary"
                rightIcon={<GoalIcon type="line" />}
            >
                Create Goal
            </Button>
        </S.NoInsights>
    )

    if (insightsLoaded) {
        return (
            <S.SelectInsight>
                <S.Steps>
                    <S.StepsTitle>{getSelectInsightTitle()}</S.StepsTitle>

                    {hasInsights && (
                        <S.StepsDescription>Create a goal based on one of these insights</S.StepsDescription>
                    )}
                </S.Steps>

                {hasInsights ? renderInsightsDisplay() : renderNoInsightsDisplay()}

                <S.Avatars>
                    <ToyFaces />
                </S.Avatars>

                {hasInsights && (
                    <S.Custom onClick={onInitCustomGoal}>
                        <Button type="button" size="small" color="transparent" rightIcon={<GoalIcon type="line" />}>
                            Custom Goal
                        </Button>

                        <S.CustomDescription>Create a KPI goal for a target group</S.CustomDescription>
                    </S.Custom>
                )}
            </S.SelectInsight>
        )
    }

    return null
})
