export enum AnalyzeRouteParam {
    Tab = 'tab',
    OvertTime = 'overTime',
}

export type AnalyzeParams = {
    tab: string
    overTime: string
}

export enum AnalyzeRouteParamValue {
    OvertTime = 'results-over-time',
}
