import React from 'react'
import { CopyIcon } from '@diversioteam/diversio-ds'
import { useTranslation } from 'react-i18next'

import * as S from './saveBackupCodes.styles'
import { SaveBackupCodesProps } from './saveBackupCodes.types'

export const SaveBackupCodes = ({
    backupCodes,
    isCopiedToClipboard,
    onClickBackupCode,
    onClickCopyAllCodes,
    onClickContinue,
}: SaveBackupCodesProps) => {
    //TODO: Add html text to i18n files like de.json
    const { t } = useTranslation()

    return (
        <S.Container>
            <S.Form>
                <S.Title>{t('AUTH.MFA.SAVE_BACKUP_CODES_TITLE')}</S.Title>
                <S.Toast open type="warning" message={t('AUTH.MFA.SAVE_BACKUP_CODES_WARNING')} />

                <S.CodeButtons>
                    <S.Button onClick={() => onClickCopyAllCodes(backupCodes)} size="small">
                        {t('AUTH.MFA.COPY_ALL_BACKUP_CODES')}
                    </S.Button>
                    {backupCodes.map((backupCode, index) => {
                        return (
                            <S.Button
                                onClick={() => onClickBackupCode(backupCode)}
                                size="small"
                                color="secondaryLight"
                                key={index}
                            >
                                {backupCode}
                            </S.Button>
                        )
                    })}
                </S.CodeButtons>

                <S.Description>{t('AUTH.MFA.SAVE_BACKUP_CODES_DESCRIPTION')}</S.Description>

                <S.ClipBoardButtons>
                    {isCopiedToClipboard && (
                        <S.ClipboardToast as="div">
                            <CopyIcon type="line" /> {t('AUTH.MFA.COPIED_TO_CLIPBOARD')}
                        </S.ClipboardToast>
                    )}
                    <S.Button onClick={onClickContinue}>Continue</S.Button>
                </S.ClipBoardButtons>
            </S.Form>
        </S.Container>
    )
}
