import styled from 'styled-components'
import { getFontStyle } from '@diversioteam/diversio-ds'

export const Title = styled.h3`
    margin: 24px 0 8px;
    padding: 0 12px;
    color: ${({ theme }) => theme.colors.primary90};

    ${getFontStyle('h2Bold')};
`

export const Description = styled.h3`
    margin: 0 0 24px;
    padding: 0 12px;
    color: ${({ theme }) => theme.colors.secondary500};

    ${getFontStyle('p02Regular')};
`

export const Textarea = styled.textarea`
    min-height: 245px;
    margin-bottom: 24px;
    width: 100%;
    padding: 16px;
    color: ${({ theme }) => theme.colors.secondary500};
    border-radius: 10px;
    border: 2px solid ${({ theme }) => theme.colors.primary30};
    outline: none;

    &:focus,
    &:active {
        border-color: ${({ theme }) => theme.colors.primary40};
    }

    ${getFontStyle('p02Regular')};
`

export const Status = styled.div`
    margin-top: 147px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
`

export const StatusTitle = styled.h3`
    margin: 12px 0;
    color: ${({ theme }) => theme.colors.primary90};

    ${getFontStyle('h2Bold')};
`

export const StatusDescription = styled.h3`
    margin: 0 0 24px;
    color: ${({ theme }) => theme.colors.secondary500};

    ${getFontStyle('p01Regular')};

    a {
        text-decoration: none;
        color: inherit;
    }
`

export const SendButtonWrapper = styled.div`
    padding: 0 12px;
`

export { Container } from './../sidePanel.styles'
