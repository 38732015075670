import React from 'react'
import { KpiKey, ToolCard } from '@diversioteam/diversio-ds'
import { toTitleCase } from 'utils/programFeaturesFunctions'
import { mapT } from 'translations/utils'
import * as m from 'translations/mapping'

import { trimExtension } from 'utils/trimExtension/trimExtension'
import { useSaveReport } from 'hooks/reporting/useSaveReport'
import { useDeleteReportFromSaved } from 'hooks/reporting/useDeleteReportFromSaved'
import { Kpi } from 'types/common'

import * as S from './report.styles'
import { ReportProps } from './report.types'

export const Report = ({
    id,
    title,
    description,
    kpis,
    targetGroups,
    url,
    mediaType,
    created,
    companyReportId,
    isNew,
    isSaved,
    size = 'medium',
    onProvideFeedback,
}: ReportProps) => {
    const mutationSaveReport = useSaveReport()
    const mutationDeleteReportFromSaved = useDeleteReportFromSaved()

    const formattedTargetGroups = targetGroups.map(({ name }) => mapT(m.GROUP_BREAKDOWN, name))

    const newLabel = isNew ? ({ variant: 'new' } as const) : undefined

    const formatKpis = (kpis: [Kpi] | [Kpi, Kpi]): [KpiKey] | [KpiKey, KpiKey] | undefined => {
        const keys = kpis.map(({ code }) => code).filter((key) => key !== 'NO_KPI')

        return keys.length > 0 ? (keys as [KpiKey] | [KpiKey, KpiKey]) : undefined
    }

    const handleSave = () => {
        mutationSaveReport.mutate({ report: id })
    }

    const handleRemove = () => {
        if (companyReportId) {
            mutationDeleteReportFromSaved.mutate(companyReportId)
        }
    }

    if (size === 'medium') {
        return (
            <S.Report>
                <ToolCard
                    category={toTitleCase(mediaType)}
                    kpis={formatKpis(kpis)}
                    publicationYear={created}
                    description={description}
                    targetGroups={formattedTargetGroups}
                    title={trimExtension(title)}
                    onSave={handleSave}
                    isSaved={isSaved}
                    newLabel={newLabel}
                    href={url}
                    onRemove={handleRemove}
                    size="medium"
                />
            </S.Report>
        )
    }

    if (size === 'small') {
        return (
            <S.Report>
                <ToolCard
                    category={toTitleCase(mediaType)}
                    kpis={formatKpis(kpis)}
                    publicationYear={created}
                    description={description}
                    title={trimExtension(title)}
                    isSaved={isSaved}
                    newLabel={newLabel}
                    href={url}
                    onRemove={handleRemove}
                    size="small"
                    onProvideFeedback={onProvideFeedback}
                />
            </S.Report>
        )
    }

    return null
}
