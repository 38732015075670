/** @format */

import { ActionTypes } from "../actions";

const defaultState = {
  solutions: [],
  count: 0,
  solutionsPagination: {},
  isLoading: {
    solutions: false
  }
};

export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case ActionTypes.LOADING_SOLUTION_MY_SOLUTIONS_ARCHIVE:
        return {
          ...state,
          isLoading: {
            solutions: true
          }
        };
      case ActionTypes.LOADED_SOLUTION_MY_SOLUTIONS_ARCHIVE:
        return {
          ...state,
          solutions: action.payload.results,
          count: action.payload.count,
          solutionsPagination: action.payload,
          isLoading: {
            solutions: false
          }
        };
      case ActionTypes.LOADED_SOLUTION_MY_SOLUTIONS_ARCHIVE_V2:
        return {
          ...state,
          solutions: action.payload,
          solutionsPagination: false,
          isLoading: {
            solutions: false
          }
        };
      case ActionTypes.UPDATE_SOLUTION_ARCHIVE_COUNT:
        const addOne = action.payload;
        let newCount = state.count;

        if (addOne) {
          newCount = newCount + 1;
        } else {
          newCount = newCount - 1;
        }

        return {
          ...state,
          count: newCount,
        };
      case ActionTypes.DONE_SOLUTION_UNARCHIVE: {
        const solutionCopy = [...state.solutions];
        let index = null;
        for (const i in solutionCopy) {
          if (solutionCopy[i].id === action.payload.id) {
            index = i;
            break;
          }
        }
        if (index) solutionCopy.splice(index, 1);

        return {
          ...state,
          solutions: solutionCopy,
        };
      }
      case ActionTypes.RESET_PROGRAMS_AND_SOLUTIONS:
        return {
          ...defaultState
        }
      default:
        return state;
    }
  } catch (error) {
    console.error(
      `Error in appState reducer: ${error.message || error.code || error}`,
      error
    );
  }
};
