import React, {Component} from 'react';
import PropTypes from 'prop-types';
import CustomPieChart from "./CustomPieChart";
import './index.scss'

class ContentSummary extends Component {

    state = {
        activeIndex: 0
    };

    render() {
        const width = 385*2;
        return (
            <div className={'content-summary'}>
                <CustomPieChart style={{borderRight:'1pt solid lightgrey'}} data={this.props.genders} title={"Gender"} width={width/2} height={250}/>
                <CustomPieChart data={this.props.content} title={"Content"} width={width/2} height={250}/>
            </div>
        );
    }
}

ContentSummary.propTypes = {
    genders: PropTypes.array

};

export default ContentSummary;
