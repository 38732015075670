import React, {Component} from 'react';
import './index.scss';
import _ from 'underscore';
import {Button} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import classNames from "classnames";
import Department from './Department';
import Kpis from './Kpis';
import Level from './Level';
import Office from './Office';
import Tenure from './Tenure';
import Year from './Year';
import FiltersSelected from "./FiltersSelected";
import {loadCompanyFilters} from "../../../sagas/network";
import {createFiltersPayload} from "./utils";

const FilterContext = React.createContext();
class Filters extends Component {
    state = {
        // component to backend data key mapping
        dataMap: {
            'Year': 'years',
            'Office': 'offices',
            'Level': 'levels',
            'Department': 'departments',
            'Tenure': 'tenures',
            'Kpis': 'kpis',
            'Group': 'groups'
        },
        valMap: {
            'Year': 'year',
            'Office': 'office',
            'Level': 'level',
            'Department': 'department',
            'Tenure': 'tenure',
            'Kpis': 'kpi',
            'Group': 'group'
        },
        values: {
            year: this.props.filters?.years?.[0]
        },
        filters: this.props.filters,
        hidden: this.props.hidden,
    };

    componentDidMount() {
        this._clearFilters()
    }

    componentDidUpdate() {
        const { filters, propsToStateChange, hidden, yearAliasMap, latestSurveyYear } = this.props;
        
        if (propsToStateChange && filters !== this.state.filters) {
            this.setState({ filters })
        }

        // When user filters by a previous survey, selects a non-overview tab, and then 
        // clicks back to overview, reset the filters to their original state
        if (hidden != this.state.hidden) {
            if (yearAliasMap) {
                this._updateYearWiseFilters(yearAliasMap[latestSurveyYear])
            } else {
                this._updateYearWiseFilters(latestSurveyYear);
            }
            this.setState({hidden: hidden})
        }
    }

    _updateYearWiseFilters (year) {
        // Transform the alias label back into a year using the yearAliasMap
        if (this.props.yearAliasMap) {
            year = Object.entries(this.props.yearAliasMap).find(entry => entry[1] == year)?.[0]
        }

        loadCompanyFilters(year ? `year=${year}` : "")
            .then(res => {
                let years = []
                if (this.props.bespokePage == "demographics") {
                    years = res.data.bespoke_demographic_quarters?
                    res.data.bespoke_demographic_quarters.map(quarter => `${quarter[0]}Q${quarter[1]}`):
                    [];
                } else if (this.props.bespokePage == "engagement") {
                    years = res.data.bespoke_engagement_quarters?
                    res.data.bespoke_engagement_quarters.map(quarter => `${quarter[0]}Q${quarter[1]}`):
                    [];
                } else {
                    years = res.data.quarters?
                    res.data.quarters.map(quarter => `${quarter[0]}Q${quarter[1]}`):
                    [];
                }
                this.setState({
                    ...this.state,
                    filters: { ...this.state.filters, ...res.data, years },
                    values: {
                        year
                    },
                });
                setTimeout(() => this.props.onChange && this.props.onChange(this.state.values, this.state.filters), 0);        
            });
    }

    _onChange (type, value) {
        this.setState({
            values: !value ? _.omit(this.state.values, type) : {
                ...this.state.values,
                [type]: value
            }
        });

        if (type === "year") {
            this._updateYearWiseFilters(value);
        }

        // Send back the filters values on next tick
        setTimeout(() => this.props.onChange && this.props.onChange(this.state.values, this.state.filters), 0);
    }

    _applyFilters () {
        this.props.applyFilters(createFiltersPayload(this.state.values, this.props.filters), this.state.filters);
        setTimeout(() => this.props.doUpdateCompanyFilters && this.props.doUpdateCompanyFilters(this.state.filters), 0);
        this.props.filterWasApplied && this.props.filterWasApplied();
    }

    _clearFilters () {
        this.setState({
            ...this.state,
            values: {
                ...Object.keys(this.state.values).reduce((all, key) => {
                    if (key === "year") {
                        if (this.props.yearAliasMap) {
                            all[key] = this.props.yearAliasMap[this.props.latestSurveyYear]
                        } else {
                            all[key] = this.props.latestSurveyYear
                        }
                    } else {
                        all[key] = [];
                    }
                    return all;
                }, {})
            }
        });

        setTimeout(() => {
            if (this.props.yearAliasMap) {
                this._updateYearWiseFilters(this.props.yearAliasMap[this.props.latestSurveyYear])
            } else {
                this._updateYearWiseFilters(this.props.latestSurveyYear);
            }
        }, 0);

        this.props.clearFilters && this.props.clearFilters(this.state.values);
    }

    render() {
        const { submittable = true, hidden, t, values: propValues, yearAliasMap } = this.props;
        const { values: stateValues, dataMap, valMap, filters } = this.state;
        const values = propValues || stateValues;
        if (_.isEmpty(filters.years)) { 
            return null;
        }

        return (
            <React.Fragment>
                <div className={classNames("filters-container bootstrap-override", { hidden: hidden })}>
                    <FilterContext.Provider value={{
                        data: filters,
                        metadata: filters.metadata,
                        onChange: this._onChange.bind(this),
                        values: values,
                        dataMap: dataMap,
                        valMap: valMap,
                        yearAliasMap: yearAliasMap
                    }}>
                        { this.props.children }
                    </FilterContext.Provider>
                    {
                        submittable
                            ? <div className="filter-actions">
                                <Button variant="primary" className="apply-filter" onClick={this._applyFilters.bind(this)}>{ t("ANALYZE.FILTERS.SUBMIT") }</Button>
                                <Button variant="light" className="clear-filter" onClick={this._clearFilters.bind(this)}>{ t("ANALYZE.FILTERS.RESET") }</Button>
                            </div>
                            : null
                    }
                </div>


            </React.Fragment>
        );
    }
}

export default withTranslation()(Filters);
export {
    Department,
    Kpis,
    Level,
    Office,
    Tenure,
    FilterContext,
    FiltersSelected,
    Year
}
