import styled from 'styled-components'
import { Skeleton as MuiSkeleton } from '@material-ui/lab'
import { Carousel as DdsCarousel } from '@diversioteam/diversio-ds'

export const Carousel = styled(DdsCarousel)`
    .swiper-slide {
        margin-right: 0;
    }
`

export const Skeleton = styled(MuiSkeleton)`
    border-radius: 15px;
`
