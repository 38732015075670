import React, { useState } from 'react';
import Card from '../../utils/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import { TwitterTimelineEmbed } from 'react-twitter-embed'
import { Nav } from 'react-bootstrap';
import './index.scss';

const ILNnews = (props) => {
    const [selected, setSelected] = useState(null)
    const { news = [] } = props
    const formatDate = (prop) => {
        let dt = new Date(prop)
        const year  = dt.getFullYear();
        const month = (dt.getMonth() + 1).toString().padStart(2, "0");
        const day   = dt.getDate().toString().padStart(2, "0");
        const result = year + '/' + month + '/' + day
        return result
    }

    return (
        <div className="iln-news">
            <Card className="iln-news-card">
                <div className={"bootstrap-override"}>
                    <Nav className={"analyze-nav iln-nav"} justify variant="tabs" defaultActiveKey="/analyze/diversity">
                        <Nav.Item>
                            <Nav.Link
                                onClick={() => { setSelected(!selected) }}
                                className={!selected ? "selected" : null}
                            >
                                News
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link
                             onClick={() => { setSelected(!selected) }}
                             className={selected ? "selected" : null}
                             >
                                Twitter
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
                {!selected ? 
                news.length > 1 ? news.map((row, index) => {
                    return (
                        <div key={index}>
                            <div className="news-container" key={index}>
                                <div className="news-header">
                                    <h5 className="news-site">{row.site}</h5>
                                    <h5>{formatDate(row.datetime)}</h5>
                                </div>
                                <h3>{row.title}</h3>
                                <a href={"//" + row.link} target="_blank"rel="noopener noreferrer">
                                    Read Full Story
                                </a>
                            </div>
                            <hr />
                        </div>
                    )
                }) :
                        <div className="news-loading">
                            <CircularProgress style={{ color: '#27829E' }} />
                        </div> :
                    <TwitterTimelineEmbed
                        sourceType="profile"
                        screenName="ilninfo"
                    />
                }
            </Card>
        </div>
    )
}

export default ILNnews;
