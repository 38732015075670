import styled from 'styled-components'

import { CardHeader as InclusionCardHeader } from '../../inclusion.styles'

export const Overview = styled.div<{ expanded: boolean }>`
    width: 100%;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    gap: 60px 0;
    padding-top: 12px;
    max-height: ${({ expanded }) => (expanded ? '100%' : '65px')};
    overflow: hidden;

    .dds-heat-map-header-cell {
        flex: 1 0 25%;
    }

    .dds-heat-map-header-cell__value {
        line-height: 31px;
    }
`

export const CardHeader = styled(InclusionCardHeader)`
    padding-bottom: 10px;
`
