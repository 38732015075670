import { AxiosError } from 'axios'

import { getKpiScoreMetadata } from 'api/actions/analyze/kpiScoreMetadata/kpiScoreMetadataActions'
import { GetKpiScoreMetadata } from 'api/actions/analyze/kpiScoreMetadata/kpiScoreMetadataActions.types'
import { Queries } from 'api/actions/analyze/kpiScoreMetadata/queries.enum'
import { useQuery } from 'hooks/useQuery'
import { preventRefetchQueryOptions } from 'utils/queryOptions'

export const useGetKpiScoreMetadata = () => {
    return useQuery<GetKpiScoreMetadata, AxiosError>({
        queryKey: [Queries.getKpiScoreMetadata],
        queryFn: getKpiScoreMetadata,
        ...preventRefetchQueryOptions,
    })
}
