import React, { forwardRef, useEffect, useMemo, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import {
    CategoryFilter,
    EnterpriseFilter,
    InclusionFilterPanel,
    InclusionFilterPanelBlock,
    InclusionFilterPanelProps,
    filterCascaderSelectedValues,
    CategoryFilterState,
    IntersectionalityFilterRef,
    Profile,
    clearCascaderSelectedValues,
} from '@diversioteam/diversio-ds'
import { parseYearQuarter } from 'utils'
import { _btoa_json_encode } from 'components/Analyze/Filters/utils'
import _ from 'underscore'
import { ErrorBoundary } from '@sentry/react'
import { useParams } from 'react-router-dom'
import mixpanel from 'mixpanel-browser'

import { profilesColors } from 'utils/profilesCors'
import { withErrorBoundary } from 'config/withErrorBoundary/withErrorBoundary'
import { useBenchmarks } from 'hooks/benchmarks/useBenchmarks'
import { useGetCompanyFilters } from 'hooks/companyFilters/useGetCompanyFilters'
import { useGetCompanyProfiles } from 'hooks/companyProfiles/useGetCompanyProfiles'
import { useGetCompanyProfilesOptions } from 'hooks/companyProfiles/useGetCompanyProfilesOptions'
import { useCreateCompanyProfile } from 'hooks/companyProfiles/useCreateCompanyProfile'
import { useUpdateCompanyProfile } from 'hooks/companyProfiles/useUpdateCompanyProfile'
import { useDeleteCompanyProfile } from 'hooks/companyProfiles/useDeleteCompanyProfile'
import { AnalyzeView } from 'types/analyze.enum'
import { Queries } from 'api/queries.enum'
import { useBreadcrumbs } from 'hooks/useBreadcrumbs'
import { generateCompanyURI } from 'sagas/helpers/generateCompanyURI'
import { useMessage } from 'hooks/useMessage'
import { useSurveys } from 'hooks/useSurveys/useSurveys'
import { useGetMultipleInclusionScoresSkeleton } from 'hooks/inclusion/useGetMultipleInclusionScoresSkeleton'
import { AppRoute } from 'routing/AppRoute.enum'
import TAXONOMIES from 'utils/taxonomies'

import { useFilters } from './../../hooks/useFilters'
import { IntersectionalityFilters } from './../../IntersectionalityFilters'
import { InclusionParams } from './../inclusion.types'
import { FilterPaneSkeleton } from './filterPaneSkeleton'
import { FilterPaneProps, Route } from './filterPane.types'

import './index.scss'

const MAX_NUMBER_OF_PROFILES = 4

const DEFAULT_ROUTE_BLOCKS: InclusionFilterPanelBlock[] = ['filters']

const CUSTOM_ROUTE_BLOCKS: Record<string, { blocks: InclusionFilterPanelBlock[] }> = {
    target_groups: {
        blocks: ['intersectionality', 'filters', 'resultsOverTime'],
    },
}

const FilterPaneWithoutEB = forwardRef<IntersectionalityFilterRef, FilterPaneProps>(function FilterPane(
    { handleOnTabChange, selectedTab, selectedTabType }: FilterPaneProps,
    ref,
) {
    const queryClient = useQueryClient()
    const { setLinks } = useBreadcrumbs()
    const { showMessage } = useMessage()

    const {
        setResultsOverTime,
        setSelectedFilters,
        setFiltersOpen,
        setSelectedSurveysFilter,
        filtersOpen,
        selectedFiltersPayload,
        selectedFilters,
        profilesActive,
        setProfilesActive,
        defaultSelectedProfileId,
        resultsOverTime,
        handleToggleFiltersInProfilesActive,
        isFiltersInProfilesActive,
    } = useFilters()

    const { survey } = useSurveys()

    const handleShowResultsOverTime = () => {
        mixpanel.track(TAXONOMIES.ANALYZE_INCLUSION_RESULTS_OVER_TIME_TOGGLE)

        setResultsOverTime?.(!resultsOverTime)
    }

    const [activeRouteKey, setActiveRouteKey] = useState<string | undefined>(selectedTab)

    const [surveyFilters, setSurveyFilters] = useState<string[]>([])
    const [routes, setRoutes] = useState<Route[]>([])

    const { primaryIndustryBenchmark, primaryNationalBenchmark } = useBenchmarks()

    const { tab, overTime } = useParams<InclusionParams>()

    const params = useMemo(() => {
        return {
            ...selectedFiltersPayload,
            year: parseYearQuarter(survey),
            key: selectedTab,
        }
    }, [selectedFiltersPayload, survey, selectedTab])

    // Filter APIs only need year as a query param
    const filterParams = useMemo(() => {
        return {
            year: parseYearQuarter(survey),
        }
    }, [survey])

    const queryGetCompanyFilters = useGetCompanyFilters(filterParams)
    const queryGetCompanyProfiles = useGetCompanyProfiles(AnalyzeView.Inclusion, filterParams)
    const queryGetCompanyProfilesOptions = useGetCompanyProfilesOptions(AnalyzeView.Inclusion, filterParams)
    const mutationCreateCompanyProfile = useCreateCompanyProfile(AnalyzeView.Inclusion, params)
    const mutationUpdateCompanyProfile = useUpdateCompanyProfile(AnalyzeView.Inclusion, params)
    const mutationDeleteCompanyProfile = useDeleteCompanyProfile(AnalyzeView.Inclusion, params)

    const availableInclusionCategories = queryGetCompanyFilters.data?.filtersPane.inclusion || []

    const getInclusionCategory = (tab: string) => {
        return availableInclusionCategories.find(({ key, uuid }) => [key, uuid].includes(tab))
    }

    const selectedInclusionCategory = getInclusionCategory(tab)

    useEffect(() => {
        if (selectedTab !== 'target_groups') {
            setResultsOverTime(false)
        }
    }, [selectedTab, setResultsOverTime])

    useEffect(() => {
        const inclusionRoutes = availableInclusionCategories.map((inclusionTab) => {
            return {
                key: inclusionTab.key,
                label: inclusionTab.name,
                blocks: CUSTOM_ROUTE_BLOCKS[inclusionTab.key]?.blocks || DEFAULT_ROUTE_BLOCKS,
                type: inclusionTab.type,
            }
        })

        if (inclusionRoutes) {
            setRoutes(inclusionRoutes)
        }
    }, [availableInclusionCategories])

    useGetMultipleInclusionScoresSkeleton(
        routes.map(({ key, type }) => ({
            key: key || '',
            ...(type === 'bespoke' && { type }),
        })),
        { enabled: routes.length > 0 },
    )

    useEffect(() => {
        if (activeRouteKey) {
            if (availableInclusionCategories.length === 0) {
                return
            }

            const filter = _.findWhere(availableInclusionCategories || [], {
                uuid: activeRouteKey,
            })

            const validKey =
                _.findWhere(availableInclusionCategories || [], {
                    type: activeRouteKey,
                }) || filter

            const navigateTo = validKey ? activeRouteKey : 'target_groups'

            handleOnTabChange(navigateTo, filter?.type || '')
        }
    }, [handleOnTabChange, availableInclusionCategories, activeRouteKey])

    useEffect(() => {
        const links = [
            {
                label: 'Inclusion',
                to: generateCompanyURI(AppRoute.AnalyzeInclusion),
            },
            selectedInclusionCategory
                ? {
                      label: selectedInclusionCategory.name,
                      to: generateCompanyURI(`${AppRoute.AnalyzeInclusion}/${tab}/${location.search}`),
                  }
                : [],
        ].flat()

        if (overTime) {
            links.push({
                label: 'Results Over Time',
                to: generateCompanyURI(`${AppRoute.AnalyzeInclusion}/${tab}/results-over-time/${location.search}`),
            })
        }

        setLinks(links)
    }, [activeRouteKey, location.search, overTime, selectedInclusionCategory])

    useEffect(() => {
        const parsedSurveys = surveyFilters.map((survey) => {
            return survey.split(', ').reverse().join('')
        })

        return setSelectedSurveysFilter?.(_btoa_json_encode(parsedSurveys))
    }, [surveyFilters, setSelectedSurveysFilter])

    useEffect(() => {
        queryClient.invalidateQueries({
            queryKey: [Queries.getInclusionData, params],
        })
    }, [primaryIndustryBenchmark, primaryNationalBenchmark, params, queryClient])

    const hasData = queryGetCompanyFilters.data && queryGetCompanyProfiles.data && queryGetCompanyProfilesOptions.data

    const handleChangeActiveRoute = (key: string) => {
        mixpanel.track(TAXONOMIES.ANALYZE_INCLUSION_DEMOGRAPHIC_CHANGE, {
            availableDemographic: routes.map(({ key }) => key),
            nextDemographic: key,
            prevDemographic: activeRouteKey,
        })

        setActiveRouteKey(key)
    }

    const handleMultiSurveysApply = (options?: string[]) => {
        // Since the whitelabel options can have varied labels, this function makes
        // sure that the correct survey from the API is plucked based on the selected
        // option label and reparse the value to the correct format i.e. Q2, 2023 which
        // is then automatically handled by API middleware.
        const parsedOptions = options?.map((option) => {
            const selectedSurvey = queryGetCompanyFilters.data?.surveys.find(
                (surveyOption) => surveyOption.name === option,
            )

            return `Q${selectedSurvey?.quarter}, ${selectedSurvey?.year}`
        })

        setSurveyFilters(parsedOptions || [])
    }

    const handleToggleFilters = () => {
        setFiltersOpen(!filtersOpen)
    }

    const handleToggleIntersectionalityFilter = () => {
        setProfilesActive?.(!profilesActive)
    }

    const handleFilterDelete: InclusionFilterPanelProps['onDeleteFilter'] = (filter) => {
        setSelectedFilters?.(filterCascaderSelectedValues(selectedFilters || [], filter))
    }

    const handleSubmitProfiles = async (profiles: Profile[]) => {
        // Method that returns all promises for create requests
        // If a profile is already created, the request is changed to update
        const profilesCreateUpdateMutations = () => {
            return profiles.map(async (profile) => {
                const demographicsCategories = queryGetCompanyProfilesOptions.data?.filters.map(({ key }) => ({
                    name: key,
                    selected_options: null,
                }))

                const demographicsPayload = (demographicsCategories || []).map(({ name }) => {
                    const filter = profile.filters?.find((filter) => filter.key === name)

                    if (filter) {
                        return {
                            name,
                            selected_options: filter.options,
                        }
                    }

                    return {
                        name,
                        selected_options: null,
                    }
                })

                const payload = {
                    label: profile.name,
                    demographics: demographicsPayload,
                }

                if (!profile.id) {
                    return await mutationCreateCompanyProfile.mutateAsync(payload)
                }

                return await mutationUpdateCompanyProfile.mutateAsync({
                    id: profile.id,
                    values: payload,
                })
            })
        }

        const deletedProfiles = queryGetCompanyProfiles.data?.profiles.flatMap(({ uuid }) => {
            if (profiles.find((profile) => profile.id === uuid)) {
                return []
            }

            return uuid
        })

        // Method that returns the list of promises to delete the profiles
        const profilesDeleteMutations = () => {
            return (
                deletedProfiles?.map(async (deletedProfile) => {
                    return await mutationDeleteCompanyProfile.mutateAsync(deletedProfile, {
                        onError: () => {
                            showMessage({ message: 'Profile deletion was not possible', type: 'error' })
                        },
                    })
                }) || []
            )
        }

        // This ensures that update/create happens before deleting profiles
        await Promise.all([...profilesCreateUpdateMutations()])
        await Promise.all([...profilesDeleteMutations()])

        setProfilesActive?.(false)

        await queryClient.invalidateQueries({
            queryKey: [Queries.getInclusionCompanyProfiles, filterParams],
        })

        await queryClient.invalidateQueries({
            queryKey: [Queries.getInclusionData, selectedTabType ? { ...params, type: selectedTabType } : {}],
        })
    }

    if (hasData) {
        // label and value needs to be same in order to show correct selected option inthe selectbox
        const surveyOptions = queryGetCompanyFilters.data.surveys.map(({ name }) => ({
            label: name,
            value: name,
        }))

        const filtersTypeIsComplex = queryGetCompanyFilters.data?.filtersModal.some(
            ({ filtersType }) => filtersType === 'complex',
        )

        const enableResultsOverTime = queryGetCompanyFilters.data.surveys.length > 1

        const categoryFilterData = queryGetCompanyFilters.data.filtersModal.map(({ name, key, options }) => ({
            name,
            key,
            options: Array.isArray(options) ? options : [],
        }))

        const profiles = queryGetCompanyProfiles.data.profiles.map(({ uuid, color, label, filters }) => ({
            id: uuid,
            color: profilesColors[color - 1],
            name: label,
            filters: filters.flatMap(({ selectedOptions, ...rest }) => ({
                ...rest,
                options: selectedOptions || [],
            })),
        }))

        const hasMaxNumberOfProfiles = profiles.length === MAX_NUMBER_OF_PROFILES

        const nextProfilesColors = profilesColors.flatMap((color, index) => {
            if (queryGetCompanyProfiles.data.profiles.find(({ color }) => color === index + 1)) {
                return []
            }

            return color
        })

        const getSurveyFilterLabels = (surveys: string[]): string[] => {
            return surveys.map(
                (survey) =>
                    queryGetCompanyFilters.data?.surveys.find(
                        (option) => `Q${option.quarter}, ${option.year}` === survey,
                    )?.name || '',
            )
        }

        const handleSurveysFiltersDelete = (filter: string) => {
            // Parse the value to correct format as surveyFilters to remove the deleted value
            const selectedSurvey = queryGetCompanyFilters.data?.surveys.find((option) => option.name === filter)

            if (selectedSurvey) {
                setSurveyFilters((prevState) =>
                    prevState.filter(
                        (prevFilter) => prevFilter !== `Q${selectedSurvey.quarter}, ${selectedSurvey.year}`,
                    ),
                )
            }
        }

        const handleClearFilters = () => {
            setSelectedFilters(clearCascaderSelectedValues(selectedFilters))
        }

        return (
            <>
                {!filtersTypeIsComplex && (
                    <ErrorBoundary>
                        <CategoryFilter
                            open={filtersOpen}
                            onSubmit={(data) => {
                                setSelectedFilters?.(data)
                                handleToggleFilters()
                            }}
                            onClose={handleToggleFilters}
                            data={categoryFilterData}
                            defaultSelected={selectedFilters as CategoryFilterState}
                        />
                    </ErrorBoundary>
                )}

                {filtersTypeIsComplex && (
                    <ErrorBoundary>
                        <EnterpriseFilter
                            data={queryGetCompanyFilters.data.filtersModal}
                            open={filtersOpen}
                            onSubmit={(data) => {
                                setSelectedFilters?.(data)
                                handleToggleFilters()
                            }}
                            onClose={handleToggleFilters}
                            defaultSelected={selectedFilters || []}
                        />
                    </ErrorBoundary>
                )}

                <IntersectionalityFilters
                    open={profilesActive}
                    data={queryGetCompanyProfilesOptions.data.filters}
                    profiles={profiles}
                    defaultActiveProfileId={defaultSelectedProfileId || profiles[0]?.id}
                    nextProfilesColors={nextProfilesColors}
                    onSubmit={handleSubmitProfiles}
                    onClose={handleToggleIntersectionalityFilter}
                    ref={ref}
                />

                <ErrorBoundary>
                    <InclusionFilterPanel
                        routes={routes}
                        activeRouteKey={activeRouteKey}
                        enableResultsOverTime={enableResultsOverTime}
                        multiSurveysSelectProps={{
                            values: getSurveyFilterLabels(surveyFilters),
                            options: surveyOptions,
                            disableAfterRootsSelected: 4,
                            onApply: handleMultiSurveysApply,
                        }}
                        profilesButtonType={hasMaxNumberOfProfiles ? 'edit' : 'create'}
                        onChangeActiveRoute={handleChangeActiveRoute}
                        onShowFilters={handleToggleFilters}
                        onShowResultsOverTime={handleShowResultsOverTime}
                        onCreateProfiles={handleToggleIntersectionalityFilter}
                        filters={selectedFilters || []}
                        onDeleteFilter={handleFilterDelete}
                        onClearFilters={handleClearFilters}
                        surveyFilters={getSurveyFilterLabels(surveyFilters)}
                        onSurveyFilterDelete={handleSurveysFiltersDelete}
                        isResultOverTimeActive={resultsOverTime}
                        isFiltersInProfilesActive={isFiltersInProfilesActive}
                        onToggleFiltersInProfilesActive={handleToggleFiltersInProfilesActive}
                    />
                </ErrorBoundary>
            </>
        )
    }

    return <FilterPaneSkeleton />
})

export const FilterPane = withErrorBoundary<FilterPaneProps & { ref: React.Ref<IntersectionalityFilterRef> }>(
    FilterPaneWithoutEB,
    {},
)
