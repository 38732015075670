import { Toast as DdsToast, colors, getFontStyle } from '@diversioteam/diversio-ds'
import styled from 'styled-components'

import { Form as AuthForm } from './../auth.styles'

export { Container, Logo, TextLink } from './../auth.styles'

export const Form = styled(AuthForm)`
    width: 576px;
`

export const Toast = styled(DdsToast)`
    && {
        width: calc(100% - 60px);
        gap: 24px;
    }
`

export const MaintenanceMessage = styled.div`
    text-align: center;
    margin-top: 40px;

    p {
        ${getFontStyle('p01Regular')}
        color: ${colors.primary65}
    }

    b {
        color: ${colors.primary70};
    }
`
