import React, { ChangeEvent, useState } from 'react'
import { ArrowLeftIcon, Button, CheckmarkIcon, colors, EmailIcon } from '@diversioteam/diversio-ds'

import { useUserMetadata } from 'hooks/metadata/useUserMetadata'
import { useSendTrainingEmail } from 'hooks/metadata/useSendTrainingEmail'

import { MessageProps } from './message.types'
import * as S from './message.styles'

export const Message = ({ trainingTitle, onCloseMessageView, onCloseSidePanel }: MessageProps) => {
    const { data } = useUserMetadata()

    const mutationSendTrainingEmail = useSendTrainingEmail()

    const userUUID = data?.uuid || ''

    const email = data?.email || '[email]'
    const company = data?.currentCompany?.name || '[company_name]'

    const fullName = data?.fullName
    const hasFullName = !!fullName
    const fullNamePlaceholder = '[name]'

    const initialContent = `Hi Diversio Sales Team,

I am a member of ${company}. I am interested in learning more about ${trainingTitle} trainings.
    
Thank you,
    
${hasFullName ? fullName : fullNamePlaceholder}`

    const [textAreaValue, setTextAreaValue] = useState(initialContent)

    const hasFullNamePlaceHolder = textAreaValue.includes(fullNamePlaceholder)

    const errorResponse = mutationSendTrainingEmail.error?.response

    const handleChangeTextArea = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setTextAreaValue(event.target.value)
    }

    const handleSendTrainingEmail = () => {
        mutationSendTrainingEmail.mutate({
            userUUID,
            values: {
                subject: `${email} from ${company} is interested in ${trainingTitle}`,
                body: textAreaValue,
                to_replace: hasFullNamePlaceHolder ? ['name'] : [],
            },
        })
    }

    const getErrorMessage = () => {
        const code = errorResponse?.status
        const message = errorResponse?.data.detail

        if (code === 429 && message) {
            const time = message.match(/[0-9]+/)

            if (time) {
                const timeout = Math.ceil(Number(time) / 60)

                return `You have reached the limit for this operation. Please try again in ${timeout} minutes. Thank you for your patience!`
            }
        }

        return (
            <>
                Please try again later or send us a message directly at{' '}
                <a href="mailto:hello@diversio.com">hello@diversio.com</a>
            </>
        )
    }

    return (
        <S.Container>
            {(mutationSendTrainingEmail.isIdle || mutationSendTrainingEmail.isLoading) && (
                <>
                    <Button color="transparent" iconOnly onClick={onCloseMessageView} aria-label="Go back">
                        <ArrowLeftIcon type="line" />
                    </Button>

                    <S.Title>Send a Message</S.Title>

                    <S.Description>Enter additional details in the message below</S.Description>

                    <S.Textarea onChange={handleChangeTextArea} value={textAreaValue} autoFocus />

                    <S.SendButtonWrapper>
                        <Button
                            onClick={handleSendTrainingEmail}
                            rightIcon={<EmailIcon type="line" />}
                            fullWidth
                            loading={mutationSendTrainingEmail.isLoading}
                        >
                            Send Message
                        </Button>
                    </S.SendButtonWrapper>
                </>
            )}

            {mutationSendTrainingEmail.isSuccess && (
                <S.Status>
                    <CheckmarkIcon width={80} height={80} type="bold" color={colors.green50} />

                    <S.StatusTitle>Message sent!</S.StatusTitle>

                    <S.StatusDescription>Thank you, someone from our team will reach out</S.StatusDescription>

                    <Button onClick={onCloseSidePanel}>Close</Button>
                </S.Status>
            )}

            {mutationSendTrainingEmail.isError && (
                <S.Status>
                    <CheckmarkIcon width={80} height={80} type="bold" color={colors.lobster50} />

                    <S.StatusTitle>Something went wrong</S.StatusTitle>

                    <S.StatusDescription>{getErrorMessage()}</S.StatusDescription>

                    <Button onClick={onCloseSidePanel}>Close</Button>
                </S.Status>
            )}
        </S.Container>
    )
}
