import React from 'react'
import { BarChart, colors } from '@diversioteam/diversio-ds'
import { useMediaQuery } from '@material-ui/core'

import { useBenchmarks } from 'hooks/benchmarks/useBenchmarks'
import { breakpoints } from 'styles/theme/mediaQueries'
import { GroupIdentifier } from 'api/actions/analyze/diversityData/diversityDataActions.types'

import { BarChartCardProps } from './barChartCard.types'
import { BenchmarkLegendItem } from './BenchmarkLegendItem'
import { GroupLegendItem } from './GroupLegendItem'
import * as S from './barChartCard.styles'

export const BarChartCard = ({ chartResults, group, isLegendVisible }: BarChartCardProps) => {
    const { secondaryIndustryBenchmark } = useBenchmarks()

    const matches = useMediaQuery(`(min-width:${breakpoints.md}px)`)

    const hasMainGroups = chartResults.some(
        ({ groupDetails }) => groupDetails?.groupIdentifier === GroupIdentifier.DominantNonDominantGroup,
    )
    const hasBreakdownGroups = chartResults.some(
        ({ groupDetails }) =>
            groupDetails?.groupIdentifier === GroupIdentifier.NonDominantGroupBreakdownData ||
            groupDetails?.groupIdentifier === GroupIdentifier.NonDominantGroupBreakdownDataMultiple,
    )
    const hasAdditionalIdentifiers = chartResults.some(
        ({ groupDetails }) => groupDetails?.groupIdentifier === GroupIdentifier.AdditionalIdentifiers,
    )

    const benchmarkLegend = [
        { color: colors.purple30, label: 'National Benchmark', isVisible: true },
        { color: colors.blue40, label: 'Industry Benchmark', isVisible: true },
        { color: colors.tangerine40, label: 'Industry Benchmark', isVisible: secondaryIndustryBenchmark },
    ]

    const groupLegend = [
        { color: colors.primary80, label: 'Dominant & Non Dominant Groups', isVisible: hasMainGroups },
        { color: colors.primary65, label: 'Non-Dominant Group Breakdown', isVisible: hasBreakdownGroups },
        { color: colors.blue70, label: 'Additional Identifiers', isVisible: hasAdditionalIdentifiers },
    ]

    return (
        <S.BarChartCardWrapper>
            <S.BarChartHeader>
                <S.BarChartTitle>{group} Breakdown</S.BarChartTitle>

                {isLegendVisible && (
                    <>
                        <S.BenchmarkLegendList>
                            {benchmarkLegend.map(({ isVisible, ...props }) => {
                                if (isVisible) {
                                    return <BenchmarkLegendItem key={props.label} {...props} />
                                }

                                return null
                            })}
                        </S.BenchmarkLegendList>

                        <S.GroupsLegendList>
                            {groupLegend.map(({ isVisible, ...props }) => {
                                if (isVisible) {
                                    return <GroupLegendItem key={props.label} {...props} />
                                }

                                return null
                            })}
                        </S.GroupsLegendList>
                    </>
                )}
            </S.BarChartHeader>

            <BarChart
                enableTooltipAnimation={true}
                data={chartResults}
                hideXAxisTick={!matches}
                brush={{ startIndex: 0, endIndex: 15 }}
            />
        </S.BarChartCardWrapper>
    )
}
