import { getFontStyle } from '@diversioteam/diversio-ds'
import styled, { css } from 'styled-components'

import { media } from 'styles/theme/mediaQueries'

export const ProcessingCard = styled.section<{ skeleton?: boolean }>`
    grid-column-start: post;
    grid-column-end: queue;
    align-self: stretch;
    border-radius: 24px;
    border-left: 1px solid ${({ theme }) => theme.colors.secondary100Transparent};
    background-color: ${({ theme }) => theme.colors.primaryWhite};
    padding: 24px;
    display: flex;

    ${({ skeleton }) => {
        if (skeleton) {
            return css`
                flex-direction: column;
                justify-content: center;
                align-items: center;
            `
        }
    }}

    ${media.sm} {
        padding: 48px;
    }

    .swiper {
        flex: 1 0 0;
    }

    .swiper-slide {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .swiper-pagination-bullet {
        opacity: 1;
        background: ${({ theme }) => theme.colors.secondary100};
    }

    .swiper-pagination-bullet-active {
        background: ${({ theme }) => theme.colors.primary70};
    }
`

export const Title = styled.h2`
    width: 650px;
    max-width: 100%;
    margin: 0 0 24px;
    text-align: center;
    color: ${({ theme }) => theme.colors.primary70};

    ${getFontStyle('cardHeaderBold')};
`

export const Description = styled.p`
    margin: 0;
    text-align: center;
    color: ${({ theme }) => theme.colors.primary70};
    opacity: 0.3;

    ${getFontStyle('cardHeaderBold')};
`
