import React from 'react'
import {
    Autocomplete,
    CloseIcon,
    getComponentClassName,
    TextField,
    Tooltip,
    UnstableChip,
} from '@diversioteam/diversio-ds'
import { AutocompleteGetTagProps } from '@diversioteam/diversio-ds/dist/components/core/Autocomplete/autocomplete.types'

import { useToolsFilters } from 'hooks/tools/useToolsFilters'
import { ToolFilterProps } from 'types/tools/toolFilters.types'
import { ParsedFilterOption } from 'types/programs/common.types'
import './index.scss'

export const ToolsFilters = ({ handleFilterChange, values }: ToolFilterProps) => {
    const { data: filters, isLoading } = useToolsFilters()

    if (isLoading) {
        return null
    }

    const renderOption = (option: ParsedFilterOption) => (
        <>
            {option.title}
            <span className={`${getComponentClassName('Autocomplete')}-option-category`}>{option.category}</span>
        </>
    )

    const renderTags = (value: ParsedFilterOption[], getTagProps: AutocompleteGetTagProps) =>
        value.map((option, index) => {
            const tagProps = getTagProps({ index })

            return (
                <UnstableChip
                    key="index"
                    label={option.title}
                    size="small"
                    rightIcon={<CloseIcon type="line" />}
                    onRightIconClick={() => tagProps.onDelete?.()}
                    {...tagProps}
                />
            )
        })

    const getLimitTagsText = (name: string, more: number) => {
        const filterInFocus = filters?.filter((filter) => filter.name === name)?.[0]
        if (!filterInFocus) {
            return
        }

        const valuesIds = values?.[name].split(',')
        const selectedValues = filterInFocus.options
            .filter((option) => valuesIds?.includes(`${option.value}`))
            .map((option) => option.title)
        const valuesTitles = selectedValues.slice(1, selectedValues.length).join(', ')

        return (
            <Tooltip title={valuesTitles} size="medium" variant="light">
                <div>+{more}</div>
            </Tooltip>
        )
    }

    return (
        <div className="filters-dropdown-container">
            {filters?.map((data, id) => {
                return (
                    <Autocomplete
                        key={`tool-filter-${id}`}
                        getOptionLabel={(option) => option.title}
                        groupBy={(option) => option.category}
                        id="multiple"
                        multiple={data.type === 'multiselect'}
                        options={data.options}
                        renderInput={(params) => (
                            <TextField {...params} label={data.label} placeholder="Select option" />
                        )}
                        onChange={(e, values) => {
                            handleFilterChange(data.name, values)
                        }}
                        renderOption={renderOption}
                        renderTags={renderTags}
                        limitTags={1}
                        getLimitTagsText={(more) => getLimitTagsText(data.name, more)}
                    />
                )
            })}
        </div>
    )
}
