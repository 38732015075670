import { TableCell, colors, getFontStyle } from '@diversioteam/diversio-ds'
import styled from 'styled-components'

export const SelectInsight = styled.div`
    width: 100%;
    display: grid;
    grid-template-areas:
        'header header'
        'list avatars'
        'list custom';
    grid-template-columns: 552px 300px;
    grid-column-gap: 65px;
    grid-template-rows: auto 300px auto;
`

export const Steps = styled.div`
    grid-area: header;
    margin-left: 60px;
    margin-bottom: 27px;
`

export const StepsTitle = styled.h1`
    color: ${colors.primary90};

    ${getFontStyle('h2Semibold')}
`

export const StepsDescription = styled.p`
    margin-block-start: 0;
    margin-block-end: 0;
    color: ${colors.secondary500};

    ${getFontStyle('captionRegular')}
`

export const ListWrapper = styled.div`
    grid-area: list;
`

export const List = styled.div<{ hasLoadedMore: boolean }>`
    display: flex;
    flex-direction: column;
    overflow: scroll;
    pointer-events: none;
    touch-action: none;
    max-height: ${({ hasLoadedMore }) => {
        if (hasLoadedMore) {
            return '390px'
        }

        return '367px'
    }};
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-left: 40px;
    margin-left: -40px;
    padding-right: 40px;
    margin-right: -40px;

    &::-webkit-scrollbar {
        display: none;
    }
`

export const ListItem = styled(TableCell)`
    height: 117px;
    justify-content: space-between;
    margin-bottom: 8px;
    pointer-events: all;

    &:hover {
        cursor: pointer;
    }

    &:last-of-type {
        margin-bottom: 0;
    }
`

export const Title = styled.div`
    margin-block-start: 0;
    margin-block-end: 0;
    width: 284px;
`

export const Status = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
`

export const StatusText = styled.div`
    margin-block-start: 0;
    margin-block-end: 0;
    color: ${colors.primary90};

    ${getFontStyle('captionRegular')}
`

export const Avatars = styled.div`
    grid-area: avatars;
    justify-self: center;
    width: 100%;
    height: 300px;
    margin-bottom: 56px;
`

export const Custom = styled.div`
    grid-area: custom;
    box-sizing: border-box;
    width: 100%;
    height: 94px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    margin-top: 56px;
    padding: 15px 28px 24px;
    background-color: ${colors.primaryWhite};
    border-radius: 15px;
`

export const CustomDescription = styled.div`
    margin: 0;
    color: ${colors.secondary400};

    ${getFontStyle('captionRegular')}
`

export const NoInsights = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 300px;
    background-color: ${colors.primaryWhite};
    border-radius: 15px;
    box-shadow: 6px 12px 30px rgba(0, 0, 0, 0.06);
`

export const NoInsightsText = styled.div`
    display: block;
    text-align: center;
    color: ${colors.primary65};
    padding: 0 30px;
    margin-bottom: 30px;

    ${getFontStyle('cardHeaderBold')}

    p {
        margin: 0;
    }
`

export const LoadMore = styled(TableCell)`
    height: 94px;
    margin-top: 8px;

    &.dds-table-cell {
        padding: 0;
    }

    .dds-button {
        height: 100%;
        width: 100%;
    }
`
