import styled from 'styled-components'

export const Tool = styled.div`
    min-width: 300px;
    max-width: 342px;
`

export const Footer = styled.footer`
    display: flex;
    align-items: center;
`

export const Label = styled.span`
    margin-left: 6px;
`
