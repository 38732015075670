import React from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

export const FreeLegend = ({
    demographic,
    demographicA,
    demographicB,
    demographicC,
    overallDemographicA,
    overallDemographicB,
    overallDemographicC,
    demographicName
}) => {

    const formatPercent = (demographic) => {
        if(demographic == 'N/A') return "N/A"
        return `${demographic}%`
    }
    return (
        <div className="barchart-legend-table">
            <TableContainer className="freetext-table">
                <Table>
                    <TableBody>
                        <TableRow className={"freetext-table__header"}>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell className="freetext-cell cell-style__medium">{demographicName["dom"]}</TableCell>
                            <TableCell className="freetext-cell cell-style__medium">{demographicName["non_dom"]}</TableCell>
                            <TableCell className="freetext-cell cell-style__medium">PNTS</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><div class={'box ' + demographic + '_primary'} /></TableCell>
                            <TableCell className="cell-style__medium">% of Total Respondents</TableCell>
                            <TableCell className="cell-style__bold">{formatPercent(overallDemographicA)}</TableCell>
                            <TableCell className="cell-style__bold">{formatPercent(overallDemographicB)}</TableCell>
                            <TableCell className="cell-style__bold">{formatPercent(overallDemographicC)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><div class={'box ' + demographic + '_secondary'} /></TableCell>
                            <TableCell className="freetext-table__pain-cell cell-style__medium">% of Total Pain Points</TableCell>
                            <TableCell className="cell-style__bold">{formatPercent(demographicA)}</TableCell>
                            <TableCell className="cell-style__bold">{formatPercent(demographicB)}</TableCell>
                            <TableCell className="cell-style__bold">{formatPercent(demographicC)}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}