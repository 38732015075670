import React from 'react'
import { SingleSelectProps } from '@diversioteam/diversio-ds'

import { METRICS } from '../../Tables'

import { MetricSelectProps } from './metricSelect.types'
import * as S from './metricSelect.styles'

export const MetricSelect = ({ availableMetrics, selectedMetric, onSelectMetric }: MetricSelectProps) => {
    const args: SingleSelectProps<METRICS> = {
        minWidth: 0,
        options: availableMetrics.map((metric) => ({ label: metric, value: metric })),
        placeholder: 'Select an option',
        variant: 'block',
        color: 'light',
        a11y: {
            'aria-label': 'Metric Select',
        },
    }

    const handleChange: SingleSelectProps<METRICS>['onChange'] = (optionValue) => {
        onSelectMetric(optionValue)
    }

    return <S.SingleSelect {...args} onChange={handleChange} value={selectedMetric} />
}
