import React, { useState } from 'react'
import { getActiveCompany } from 'utils'
import { useDispatch } from 'react-redux'
import { ActionTypes } from 'actions'

import { useUpdateUserMetadataFeatureFlags } from 'hooks/metadata/useUpdateUserMetadataFeatureFlags'
import { useUserMetadata } from 'hooks/metadata/useUserMetadata'
import { FeatureFlag } from 'types/featureFlag.types'
import { VersionsContext } from '../versionsContext/VersionsContext'

import { VersionsContextControllerProps } from './VersionsContextController.types'

export const VersionsContextController = ({ children }: VersionsContextControllerProps) => {
    const dispatch = useDispatch()
    const mutation = useUpdateUserMetadataFeatureFlags()
    const { data: userMetadata } = useUserMetadata()
    const AVAILABLE_VERSIONS = {
        v1: 'v1',
        v2: 'v2',
    }
    const [version, setVersion] = useState<string | undefined>(undefined)
    const [isVersionSwitchable, setIsVersionSwitchable] = useState<boolean>(false)
    const [showModal, setShowModal] = useState<boolean>(false)

    const _setVersion = async (newVersion: string) => {
        if (!!version && newVersion !== version) {
            if (!!version) {
                // Reset all programs and solutions page to fetch v2 data
                dispatch({ type: ActionTypes.RESET_PROGRAMS_AND_SOLUTIONS })
                const activeCompany = getActiveCompany(userMetadata?.companies)

                const featureFlagToPatch = activeCompany.featureFlags.filter(
                    (flag: FeatureFlag) => flag.name === `home-analyze-${newVersion}`,
                )?.[0] as FeatureFlag

                const userVersionPreferenceFlags = userMetadata?.featureFlags?.filter(
                    (flag: FeatureFlag) => flag.name === 'home-analyze-v1-v2',
                )?.[0]

                const payload = {
                    uuid: userVersionPreferenceFlags?.uuid || '', // FeatureFlag's UUID (repeated in URL as well)
                    value: featureFlagToPatch.uuid, // UUID of feature flag available at company level
                    objectUuid: userMetadata?.uuid || '', // User's own UserMetdata UUID (repeated in URL as well)
                }

                await mutation.mutateAsync(payload)
            }
        }
        setVersion(newVersion)
    }

    return (
        <VersionsContext.Provider
            value={{
                version,
                setVersion: _setVersion,
                showModal,
                isVersionSwitchable,
                setIsVersionSwitchable,
                setShowModal,
                AVAILABLE_VERSIONS,
            }}
        >
            {children}
        </VersionsContext.Provider>
    )
}
