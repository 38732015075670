import styled from 'styled-components'
import { getFontStyle } from '@diversioteam/diversio-ds'

export const AccordionWrapper = styled.div`
    width: 100%;
    margin: 0 0 18px;
    background-color: ${({ theme }) => theme.colors.primaryGray0};
    border: 1px solid ${({ theme }) => theme.colors.primaryGray2};
    border-radius: 12px;
    overflow: hidden;
    cursor: pointer;
`

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 18px;
    color: ${({ theme }) => theme.colors.primary70};

    ${getFontStyle('p02Semibold')};
`

export const Content = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-right: 18px;
    padding-bottom: 18px;

    li {
        color: ${({ theme }) => theme.colors.secondary500};

        ${getFontStyle('p02Regular')};
    }
`
