import { loadRiskBanner } from 'sagas/network'
import { parseYearQuarter, stringifyQueryParams } from 'utils'

import { Queries } from 'api/queries.enum'
import { GetRiskBannerResponse } from 'api/actions/home/riskBanner/riskBannerActions.types'

import { useQuery } from './useQuery'
import { useSurveys } from './useSurveys/useSurveys'

export const useGetRiskBanner = () => {
    const { survey } = useSurveys()

    const params = {
        year: parseYearQuarter(survey),
    }

    const query = useQuery<GetRiskBannerResponse, Error>({
        queryKey: [Queries.getRiskBanner, ...Object.values(params)],
        queryFn: () => loadRiskBanner(stringifyQueryParams(params)),
        enabled: survey !== null,
    })

    return query
}
