import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import './index.scss';

export const DashboardHoverTooltip = ({ content, placement="bottom", ...props }) => {
    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
      setOpen(false);
    };
  
    const handleTooltipOpen = () => {
      setOpen(true);
    };

    return (
            <Tooltip arrow title={content} placement={placement}
                     PopperProps={{
                         disablePortal: true,
                     }}
                     onClose={handleTooltipClose}
                     disableFocusListener
                     disableTouchListener
                     className='clickable'
                     classes={{ popper: "custom-hover-tooltip-popper", tooltip: "custom-hover-tooltip", arrow: "custom-hover-tooltip--arrow" }}
                     open={open}
                    >
                { React.cloneElement( props.children, { onMouseEnter: handleTooltipOpen, onMouseLeave: handleTooltipClose } ) }
            </Tooltip>
        
    );
};
