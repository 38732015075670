import React, { useContext } from 'react';
import Select from "../../utils/Select";
import { FilterContext } from "./index";
import {withTranslation} from "react-i18next";


const yearQuarterParser = (yyyyQn) => {
    // Year filter can be repurposed to show alias names if yearAliasMap is present in the props
    const { yearAliasMap } = useContext(FilterContext);
    if (yearAliasMap) {
        return yearAliasMap[yyyyQn]
    }

    const parsed = yyyyQn.match(/(\d{4})Q(\d)/);
    if ((parsed && parsed[1]) && parsed[2]) {
        return `${parsed[1]} Q${parsed[2]}`;
    }

    return yyyyQn;

}

const Year = React.memo(({ label, valId = "year", dataId = "years", onOpen = () => {}, onClose = () => {}, t }) => {
    const { data, onChange, values, yearAliasMap } = useContext(FilterContext);

    // Get options to be shown in the Year dropdown
    const getOptions = () => {
        return !yearAliasMap 
                   ? data[dataId]
                   : data[dataId].map(year => yearAliasMap?.[year])
    }

    label = label || t("ANALYZE.FILTERS.YEAR");
    return <Select
    onOpen={onOpen}
    onClose={onClose}
    renderValue={yearQuarterParser}
    options={getOptions()}
    label={label}
    optionType={""}
    onChange={value => onChange(valId, value)}
                   selectedValues={values[valId] || data[dataId][0]}/>
});

export default withTranslation()(Year);
