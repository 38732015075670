import { AxiosError } from 'axios'

import { GetSolutionGoalCardOptionsResponse } from 'api/actions/goals/goalsActions.types'
import { Queries } from 'api/actions/goals/queries.enum'
import { getSolutionGoalCardOptions } from 'api/actions/goals/goalsActions'
import { useQuery } from 'hooks/useQuery'

export const useGetSolutionGoalCardOptions = () => {
    const query = useQuery<GetSolutionGoalCardOptionsResponse, AxiosError>({
        queryKey: [Queries.getSolutionGoalCardOptions],
        queryFn: getSolutionGoalCardOptions,
    })

    return query
}
