import _ from 'lodash';

// Attribution: https://dev.to/ridhamz/react-make-your-own-pagination-5d2m

export function paginate(items, pageNumber, pageSize){
    const startIndex = (pageNumber - 1) * pageSize;
    const endIndex = startIndex + pageSize;
 
    return _(items)
             .slice(startIndex, endIndex)
             .value();
 }
