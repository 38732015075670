import styled from 'styled-components'
import { Breadcrumbs as DdsBreadcrumbs } from '@diversioteam/diversio-ds'

import { media } from 'styles/theme/mediaQueries'

export const AnalyzeBreadcrumbsWrapper = styled.div`
    display: none;

    ${media.md} {
        display: flex;
        align-items: center;
        margin-right: auto;
    }
`

export const Breadcrumbs = styled(DdsBreadcrumbs)`
    &.dds-breadcrumbs {
        margin-left: auto;
        margin-right: auto;
    }

    .MuiBreadcrumbs-li a {
        color: ${({ theme }) => theme.colors.secondary500};
        font-size: 12px;
        text-transform: capitalize;
    }
`
