import { parseYearQuarter, stringifyQueryParams } from 'utils'

import { getDiversityBreakdown } from '../sagas/network'
import { Queries } from 'api/queries.enum'
import { GetDiversityBreakdownResponse } from 'api/actions/home/diversityBreakdown/diversityBreakdownActions.types'

import { useQuery } from './useQuery'
import { useSurveys } from './useSurveys/useSurveys'

export const useGetDiversityBreakdown = () => {
    const { survey } = useSurveys()

    const params = {
        year: parseYearQuarter(survey),
    }

    return useQuery<GetDiversityBreakdownResponse, Error>({
        queryKey: [Queries.getDiversityBreakdown, ...Object.values(params)],
        queryFn: () => getDiversityBreakdown(stringifyQueryParams(params)),
        enabled: survey !== null,
    })
}
