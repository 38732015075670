import React, { useEffect, useState } from 'react'
import moment from 'moment'

import { useCreateJobAdvert } from 'hooks/lumi/useCreateJobAdvert'

import { Banner } from './Banner'
import { Post } from './Post'
import { Queue } from './Queue'
import { ProgressBar } from './ProgressBar'
import { ProcessingCard } from './ProcessingCard'
import { CompletedCard } from './CompletedCard'
import { useLumi } from './hooks/useLumi'
import { Stage } from './context/LumiContext.types'
import * as S from './lumi.styles'

export const Lumi = () => {
    const { stage, onChangeJobId, onChangeStage } = useLumi()
    const [textAreaValue, setTextAreaValue] = useState('')
    const mutation = useCreateJobAdvert(onChangeJobId)

    const createJobAdvert = async () => {
        try {
            const title = moment().format('Do_MMM_YYYY_hh_mm_ss_A')

            await mutation.mutateAsync({
                title,
            })
        } catch (error) {
            onChangeStage(Stage.Error)
        }
    }

    useEffect(() => {
        if (stage === Stage.Init) {
            createJobAdvert()
        }
    }, [stage])

    return (
        <S.LumiWrapper stage={stage}>
            {stage === Stage.Processing && <ProgressBar />}

            <Banner />

            {[Stage.Init, Stage.UpdateData].includes(stage) && (
                <>
                    <Post textAreaValue={textAreaValue} setTextAreaValue={setTextAreaValue} />

                    <Queue hasTextAreaContent={!!textAreaValue} />
                </>
            )}

            {[Stage.Processing, Stage.Error].includes(stage) && <ProcessingCard />}

            {stage === Stage.Completed && <CompletedCard />}
        </S.LumiWrapper>
    )
}
