export const getNextPageParam = (next: string | null) => {
    if (next === null) {
        return undefined
    }

    const nextPageUrl = new URL(next)
    const params = new URLSearchParams(nextPageUrl.search)

    return params.get('page') ?? undefined
}
