import { Button } from '@diversioteam/diversio-ds'
import React, { useState } from 'react'
import mixpanel from 'mixpanel-browser'

import { FileMetadata, ProcessingStatus, Stage } from '../context/LumiContext.types'
import { useLumi } from '../hooks/useLumi'
import { useProcessJobAdvert } from 'hooks/lumi/useProcessJobAdvert'
import TAXONOMIES from 'utils/taxonomies'

import { AcceptedFile } from './AcceptedFile'
import { EmptyList } from './EmptyList'
import * as S from './queue.styles'
import { QueueProps } from './queue.types'

export const Queue = ({ hasTextAreaContent }: QueueProps) => {
    const { jobId, acceptedFiles, onChangeStage, onChangeAcceptedFiles } = useLumi()
    const [isProcessing, setIsProcessing] = useState(false)
    const mutation = useProcessJobAdvert()

    const hasFailedFiles = acceptedFiles.some(({ processingStatus }) => processingStatus === ProcessingStatus.Failed)
    const hasAllCompleted = acceptedFiles.every(
        ({ processingStatus }) => processingStatus === ProcessingStatus.Completed,
    )

    const handleTransformAdsClicked = async () => {
        if (hasAllCompleted) {
            return onChangeStage(Stage.Processing)
        }

        try {
            await mutation.mutateAsync(jobId)
            mixpanel.time_event(TAXONOMIES.LUMI_TRANSFORM_JOB_AD)

            onChangeStage(Stage.Processing)
        } catch (error) {
            onChangeStage(Stage.Error)
        }
    }

    const handleRemoveFile = (fileMetadata: FileMetadata) => {
        mixpanel.track(TAXONOMIES.LUMI_REMOVE_FILE, {
            jobId,
            name: fileMetadata.path,
            type: fileMetadata.file.type,
            size: fileMetadata.file.size,
        })
        onChangeAcceptedFiles((prevFiles) =>
            prevFiles.filter((currentFileMetadata) => currentFileMetadata.file !== fileMetadata.file),
        )
    }

    return (
        <S.QueueWrapper>
            <S.Title>Queue</S.Title>

            {acceptedFiles.length === 0 && <EmptyList />}

            {acceptedFiles.length > 0 && (
                <>
                    <S.List>
                        {acceptedFiles.map((acceptedFile) => (
                            <AcceptedFile
                                key={acceptedFile.path}
                                acceptedFile={acceptedFile}
                                onChangeProcessing={(value) => setIsProcessing(value)}
                                onRemoveFile={() => handleRemoveFile(acceptedFile)}
                            />
                        ))}
                    </S.List>

                    <Button
                        color="primary"
                        onClick={handleTransformAdsClicked}
                        disabled={isProcessing || hasTextAreaContent || hasFailedFiles}
                    >
                        Transform Job Ads
                    </Button>

                    <S.Description>Lumi™ can make mistakes. Check important info.</S.Description>
                </>
            )}
        </S.QueueWrapper>
    )
}
