import { getActiveCompany } from 'utils'

import { Company } from 'api/actions/useMetadata/userMetadataActions.types'

import { useUserMetadata } from './metadata/useUserMetadata'

export const useGetActiveCompanyInformation = () => {
    const { data: userMetadata } = useUserMetadata()

    // Assertion due to no ts support in getActiveCompany
    const companyInformation = getActiveCompany(userMetadata?.companies) as Company

    return { companyInformation }
}
