import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Card from '../../utils/Card'
import { OverlayTrigger, Popover } from 'react-bootstrap';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import CalendarLegend from './CalendarLegend'
import './index.scss';

const calendarDate = (startDate, endDate) => {
    const dateStart = startDate.toDateString()
    const dateEnd = endDate.toDateString()

    const timeStart = startDate.toLocaleTimeString().replace(/(.*)\D\d+/, '$1')
    const timeEnd = endDate.toLocaleTimeString().replace(/(.*)\D\d+/, '$1')

    if (dateStart === dateEnd) {
        return dateStart + " " + timeStart + " - " + timeEnd
    }
    return dateStart + " " + timeStart + " - " + dateEnd + " " + timeEnd
}

const CalendarPopover = ({ event }) => {
    let popoverClickRootClose = (
        <Popover id="popover-trigger-click-root-close" style={{ zIndex: 10000 }}>
            <div className="iln-calendar-event-container" style={{ borderColor: !event.category? '#27829E' : event.category.color, borderStyle: 'solid', borderWidth: '2px' }}>
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell className="icon-column" align="right">
                                    <img className="icon-iln-calendar-box" style={{ background: !event.category? '#27829E' : event.category.color  }}/>
                                </TableCell>
                                <TableCell className="info-column" align="left">
                                    <div>
                                        <h2>{event.title}</h2>
                                        <p>{calendarDate(event.start, event.end)}</p>
                                    </div>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="icon-column" align="right">
                                    <img className="icon-iln-event-time"/>
                                </TableCell>
                                <TableCell className="info-column" align="left">
                                    <p>{event.attendees.toString()}</p>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="icon-column" align="right">
                                    <img className="icon-iln-description"/>
                                </TableCell>
                                <TableCell className="info-column" align="left">
                                    <p>{event.description}</p>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="icon-column" align="right">
                                    <img className="icon-iln-calendar"/>
                                    </TableCell>
                                <TableCell className="info-column" align="left">
                                    <p>{event.host}</p>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </Popover>
    );

    return (
        <div>
            <div>
                <OverlayTrigger id="help" trigger="click" rootClose container={this} placement="top" overlay={popoverClickRootClose}>
                    <div>{event.title}</div>
                </OverlayTrigger>
            </div>
        </div>
    );
}

const localizer = momentLocalizer(moment);

const ILNCalendar = (props) => {
    const [showLegend, setShowLegend] = React.useState(true)
    const { data } = props

    const formatCalendarData = (data) => {
        let result = []
        for(var i in data) {
            const startDate = new Date(data[i]['start'])
            const endDate = new Date(data[i]['end'])
            const eventObj = {
                title: data[i]['title'],
                start: startDate,
                end: endDate,
                description: data[i]['desc'],
                allDay: data[i]['all_day'],
                host: data[i]['host'],
                attendees: data[i]['attendees'],
                category: data[i]['category']
            }
            result.push(eventObj)
        }
        return result
    }

    const eventStyleGetter = (event) => {
        // If a calendar event is null, default to ILN Green
        const backgroundColor = !event.category? '#27829E' : event.category.color
        const style = {
            backgroundColor: backgroundColor
        };
        return {
            style: style
        };
    }

    return (
        <div className="iln-calendar">
            <Card className={showLegend ? "iln-calendar-card-close iln-card" : "iln-calendar-card-open iln-card"}>
                <div className="calendar-title">
                    <h2>ILN Calendar</h2>
                    <h6 onClick={() => setShowLegend(!showLegend)}>{showLegend ? "Hide Legend" : "Show Legend"}</h6>
                </div>
                <Calendar
                    localizer={localizer}
                    events={formatCalendarData(data)}
                    step={60}
                    defaultDate={new Date(Date.now())}
                    components={{
                        event: CalendarPopover
                    }}
                    eventPropGetter={eventStyleGetter}
                />
            </Card>
            <CalendarLegend events={data} showAnimation={showLegend ? "legend-animation-open" : "legend-animation-close"} />
        </div>
    )
}

export default ILNCalendar
