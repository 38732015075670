import { getFontStyle } from '@diversioteam/diversio-ds'
import styled from 'styled-components'

import { media } from 'styles/theme/mediaQueries'

export const CompletedCard = styled.section`
    grid-column-start: post;
    grid-column-end: queue;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 24px;
    border-left: 1px solid ${({ theme }) => theme.colors.secondary100Transparent};
    background-color: ${({ theme }) => theme.colors.primaryWhite};
    padding: 24px;

    ${media.lg} {
        padding: 48px;
    }
`

export const Title = styled.h2`
    width: 650px;
    max-width: 100%;
    margin: 0 0 24px;
    text-align: center;
    color: ${({ theme }) => theme.colors.primary70};

    ${getFontStyle('cardHeaderBold')};
`

export const Buttons = styled.div`
    display: flex;
    gap: 12px;
`
