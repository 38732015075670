import React from 'react'

import { LumiContextController } from './context/LumiContextController'
import { Lumi } from './Lumi'

export const LumiContainer = () => {
    return (
        <LumiContextController>
            <Lumi />
        </LumiContextController>
    )
}
