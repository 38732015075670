import { getFontStyle, Toast as DdsToast } from '@diversioteam/diversio-ds'
import styled from 'styled-components'

export const Title = styled.h2`
    color: ${({ theme }) => theme.colors.primary90};
    margin: 0 0 20px;

    ${getFontStyle('h3Bold')}
`

export const CodeButtons = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    margin: 0 0 24px;
`

export const Form = styled.form`
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 600px;
    padding: 40px;
    border-radius: 30px;
    background-color: ${({ theme }) => theme.colors.primaryWhite};
    border: 1px solid ${({ theme }) => theme.colors.primaryGray2};
`

export const Toast = styled(DdsToast)`
    & button {
        display: none;
    }
    border-radius: 12px;
    margin: 0 0 24px;
    padding: 10px;
`

export const Description = styled.p`
    margin: 0 0 24px;
    color: ${({ theme }) => theme.colors.secondary500};

    ${getFontStyle('p02Regular')}
`

export const ClipBoardButtons = styled.div`
    display: flex;
    gap: 12px;
    width: 100%;
    justify-content: flex-end;
`

export const ClipboardToast = styled.button`
    display: flex;
    align-items: center;
    gap: 6px;
    width: 100%;
    flex: 1;
    background: none;
    border: none;
    background-color: ${({ theme }) => theme.colors.primaryGray1};
    cursor: pointer;
    border-radius: 12px;
    padding: 0 12px;
    margin-right: 12px;
    color: ${({ theme }) => theme.colors.primary90};

    ${getFontStyle('p02Regular')};
`

export { Button, Container } from './../auth.styles'
