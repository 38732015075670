import { useQuery } from '@tanstack/react-query'
import { getDiversityCompanyProfilesOptions, getInclusionCompanyProfilesOptions } from 'sagas/network'
import { stringifyQueryParams } from 'utils'

import { AnalyzeView } from 'types/analyze.enum'
import {
    CompanyProfilesParams,
    GetCompanyProfilesOptionsResponse,
} from 'api/actions/analyze/companyProfiles/companyProfilesActions.types'
import { Queries } from 'api/queries.enum'

export const useGetCompanyProfilesOptions = (view: AnalyzeView, params: CompanyProfilesParams) => {
    const queryKey =
        view === AnalyzeView.Diversity
            ? [Queries.getDiversityCompanyProfilesOptions]
            : [Queries.getInclusionCompanyProfilesOptions]
    const queryFn =
        view === AnalyzeView.Diversity ? getDiversityCompanyProfilesOptions : getInclusionCompanyProfilesOptions

    const query = useQuery<GetCompanyProfilesOptionsResponse, Error>({
        queryKey: [...queryKey, params],
        queryFn: () => {
            return queryFn(stringifyQueryParams(params))
        },
        enabled: !!params?.year,
        keepPreviousData: true,
    })

    return query
}
