import React, {useState} from 'react';
import { COLORS, CURSOR_HALF_WIDTH, CONTAINER_HEIGHT,
    CHART_OFFSET,
    CHART_STROKE,
    Y_OFFSET, CURSOR_GREY, CURSOR_OPACITY, CURSOR_LINE, CURSOR_LINE_OPACITY, X_AXIS_HEIGHT } from '../constants';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Rectangle, Tooltip } from 'recharts';
import CustomizedAxisTick from '../../../utils/CustomizedAxisTick';
import { kpiFormatter } from '../../../../utils';
import _ from 'lodash';
import './index.scss';
import { INSUFFICIENT_DATA } from 'components/AnalyzeV2/Inclusion/Heatmap/heatmap.utils';

const CustomCursor = props => {
    const { x } = props;
    const width = CURSOR_HALF_WIDTH * 2;
    const chartHeight = CONTAINER_HEIGHT - CHART_OFFSET;
    const yOffset = Y_OFFSET;
    const xOffset = width / 2;
    return (
        <>
            <Rectangle fill={CURSOR_GREY} fillOpacity={CURSOR_OPACITY} x={x - xOffset} y={yOffset} width={width} height={chartHeight} />
            <Rectangle fill={CURSOR_LINE} fillOpacity={CURSOR_LINE_OPACITY} stroke="none" x={x-1} y={yOffset} width={2} height={chartHeight} />
        </>
    );
};

const CustomTooltip = ({ payload, selectedKpis, t }) => {
    let content = null;

    if (!_.isEmpty(payload)) {
        content = (
            <div className="custom-tooltip inclusion-tracker-custom-tooltip">
                <h4 className="inclusion-tracker-custom-tooltip__title">{payload[0].payload.date}</h4>
                {selectedKpis.map((k, i) => {
                    const color = COLORS[i];
                    return (
                        <div className="inclusion-tracker-custom-tooltip__row">
                            <div className="inclusion-tracker-custom-tooltip__line"
                            style={{
                                    borderColor: color}}>
                            </div>
                            <div className="inclusion-tracker-custom-tooltip__label_score">
                                {kpiFormatter(k)}
                            </div>
                            <div className="inclusion-tracker-custom-tooltip__value">
                                {payload[0].payload[k] === INSUFFICIENT_DATA ? '-' : payload[0].payload[k]}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }

    return content;
  };

const InclusionTrackerLineChart = ({ data, selectedKpis }) => {
    const [ activeXCoord, setActiveXCoord ] = useState(0);
    const [ activeIndex, setActiveIndex ] = useState(null);

    return (
        <ResponsiveContainer width="100%" height={CONTAINER_HEIGHT}>
        <LineChart
                            data={data}
                            margin={{
                                top: 5, right: 30, left: 20, bottom: 5,
                            }}
                            onMouseMove={state => {
                                state.isTooltipActive && 
                                setActiveXCoord(state.activeCoordinate.x);
                                setActiveIndex(state.activeTooltipIndex);
                                }}
                            onMouseLeave={() => {
                                setActiveIndex(null);
                            }}
                    >
                    <Tooltip
                        content={<CustomTooltip selectedKpis={selectedKpis}/>}
                        cursor={<CustomCursor x={activeXCoord}/>}/>
                    <XAxis dataKey="date" 
                    height={X_AXIS_HEIGHT}
                    padding={{ left: CURSOR_HALF_WIDTH, right: CURSOR_HALF_WIDTH }}
                    tickLine={false}
                    axisLine={false}
                    interval={0}
                    tickMargin={10}
                    tick={<CustomizedAxisTick activeIndex={activeIndex} />} />
                    <YAxis type="number" fontSize="12" tick={{fill: CURSOR_LINE}} tickMargin={5} width={6} domain={[dataMin => (Math.floor(dataMin)) , 10]}/>
                    <CartesianGrid stroke={CHART_STROKE} vertical={false} />
                    {
                        selectedKpis.map((kpi, i) => (
                        <Line key={i} type="monotone"
                        isAnimationActive={false}
                        dataKey={ kpi }
                        stroke={ COLORS[i] }
                        strokeWidth={2}
                        dot={{ stroke: COLORS[i], strokeWidth: 1, r: 3, fill: COLORS[i]}}
                        activeDot={
                                { stroke: COLORS[i], strokeWidth: 1, r: 3, fill: COLORS[i]}
                            } />
                        ))
                    }
                    </LineChart>
                    </ResponsiveContainer>
    );
}

export default InclusionTrackerLineChart;
