import { ActionTypes } from '../actions';


const defaultState = {
    inclusionBreakdown: {
        data:[],
        metadata: {}
    },
    diversityBreakdown: [],
    diversityComposition: [],
    inclusionMetrics: [],
    previousInclusionMetrics: [],
    inclusionTracker: [],
    diversityBreakdownByRole: {
        data: {},
        labels: {}
    },
    freeText: [],
    filters: {
        levels:[],
        departments:[],
        tenures: [],
        genders: {},
        years: [],
        quarters: [],
        offices: [],
        non_dominant_group: {},
        metadata: {},
        target_demographics: {},
        current_or_selected_quarter: [],
        bespoke_questions: [],
        bespoke_quarters: [],
        bespoke_demographic_quarters: [],
        bespoke_engagement_quarters: [],
    },
};

export const getKpis = meta => {
    return meta.kpi_keys.map(kpi => {
        switch (kpi) {
            case "INCLUSIVE_CULTURE":
                return {
                    name: "Inclusive Culture",
                    helperText: meta.kpi_keys_help[kpi]
                };

            case "FAIR_MANAGEMENT":
                return {
                    name: "Fair Management",
                    helperText: meta.kpi_keys_help[kpi]
                };

            case "CAREER_DEVELOPMENT":
                return {
                    name: "Career Development",
                    helperText: meta.kpi_keys_help[kpi]
                };

            case "WORKPLACE_FLEXIBILITY":
                return {
                    name: "Workplace Flexibility",
                    helperText: meta.kpi_keys_help[kpi]
                };

            case "WORKPLACE_SAFETY":
                return {
                    name: "Workplace Safety",
                    helperText: meta.kpi_keys_help[kpi]
                };
            case "RECRUITING_HIRING":
                return {
                    name: "Recruiting & Hiring",
                    helperText: meta.kpi_keys_help[kpi]
                };
        }
    })
};

export default (state = defaultState, action) => {
    try {
        switch(action.type) {
            case ActionTypes.LOADED_INCLUSION_BREAKDOWN_DATA:{
                return {
                    ...state,
                    inclusionBreakdown: action.payload.inclusion_breakdown
                };
            }
            case ActionTypes.LOADING_COMPANY_INFORMATION: {
                return {
                    ...defaultState
                }
            }
            case ActionTypes.LOADING_COMPANY_FILTERS:{
                return {
                    ...state,
                    isLoading: true
                };
            }
            case ActionTypes.LOADED_COMPANY_FILTERS:{
                const years = action.payload.quarters?
                    action.payload.quarters.map(quarter => `${quarter[0]}Q${quarter[1]}`):
                    [];
                return {
                    ...state,
                    isLoading: false,
                    filters: {...state.filters, ...action.payload, years}
                };
            }
            case ActionTypes.LOADED_BESPOKE_COMPANY_FILTERS:{
                const years = action.payload.bespoke_quarters?
                    action.payload.bespoke_quarters.map(quarter => `${quarter[0]}Q${quarter[1]}`):
                    [];
                return {
                    ...state,
                    isLoading: false,
                    filters: {...state.filters, ...action.payload, years}
                };
            }
            case ActionTypes.DONE_UPDATE_COMPANY_FILTERS:{
                return {
                    ...state,
                    isLoading: false,
                    filters: {...state.filters, bespoke_questions: action.payload.bespoke_questions}
                };
            }
            case ActionTypes.LOADED_DIVERSITY_BREAKDOWN_DATA:{
                return {
                    ...state,
                    diversityComposition: action.payload.diversity_composition,
                    diversityBreakdown: action.payload.diversity_breakdown,
                    diversityBreakdownHelpText: action.payload.help_text
                };
            }
            case ActionTypes.LOADING_INCLUSION_METRICS_DATA: {
                return {
                    ...state,
                }
            }
            case ActionTypes.LOADED_INCLUSION_METRICS_DATA:{
                return {
                    ...state,
                    inclusionMetrics: action.payload.inclusion_metrics.length ? action.payload.inclusion_metrics[0].data : [],
                    previousInclusionMetrics: action.payload.previous_inclusion_metrics.length ? action.payload.previous_inclusion_metrics[0].data : [],
                    overall: {
                        industry: action.payload.inclusion_metrics.length ? action.payload.inclusion_metrics[0].industry_overall_score : [],
                        company: action.payload.inclusion_metrics.length ? action.payload.inclusion_metrics[0].overall_score : [],
                        date: action.payload.inclusion_metrics.length ? action.payload.inclusion_metrics[0].date : [],
                    },
                    previousOverall: {
                        industry: action.payload.previous_inclusion_metrics.length ? action.payload.previous_inclusion_metrics[0].industry_overall_score : [],
                        company: action.payload.previous_inclusion_metrics.length ? action.payload.previous_inclusion_metrics[0].overall_score : [],
                        date: action.payload.previous_inclusion_metrics.length ? action.payload.previous_inclusion_metrics[0].date : [],
                    },
                    metadata: getKpis(action.payload.metadata)
                };
            }
            case ActionTypes.LOADING_NON_OVERVIEW_METRICS_DATA:
                return {
                    ...state,
                    loadingNonOverviewMetrics: true,
                }
            case ActionTypes.LOADED_NON_OVERVIEW_METRICS_DATA:
                return {
                    ...state,
                    loadingNonOverviewMetrics: false,
                    inclusionMetrics: action.payload.inclusion_metrics,
                    previousInclusionMetrics: action.payload.previous_inclusion_metrics,
                    overall: {
                        industry: action.payload.inclusion_metrics.length ? action.payload.inclusion_metrics[0].industry_overall_score : [],
                        company: action.payload.inclusion_metrics.length ? action.payload.inclusion_metrics[0].overall_score : [],
                        date: action.payload.inclusion_metrics.length ? action.payload.inclusion_metrics[0].date : [],
                    },
                    previousOverall: {
                        industry: action.payload.previous_inclusion_metrics.length ? action.payload.previous_inclusion_metrics[0].industry_overall_score : [],
                        company: action.payload.previous_inclusion_metrics.length ? action.payload.previous_inclusion_metrics[0].overall_score : [],
                        date: action.payload.previous_inclusion_metrics.length ? action.payload.previous_inclusion_metrics[0].date : [],
                    },
                    metadata: getKpis(action.payload.metadata)
                };
            case ActionTypes.LOADED_INCLUSION_TRACKER_DATA:
                return {
                    ...state,
                    inclusionTracker: action.payload.inclusion_metrics
                };
            case ActionTypes.LOADED_DIVERSITY_TRACKER_DATA:
                return {
                    ...state,
                    diversityTracker: action.payload
                };
            case ActionTypes.LOADING_DIVERSITY_BREAKDOWN_BY_ROLE_DATA:
                return {
                    ...state,
                    diversityBreakdownByRole: {
                        ...state.diversityBreakdownByRole,
                        loading: true
                    }
                };
            case ActionTypes.LOADED_DIVERSITY_BREAKDOWN_BY_ROLE_DATA:
                return {
                    ...state,
                    diversityBreakdownByRole: {
                        ...action.payload,
                        loading: false,
                        helpText: action.payload.help_text
                    },
                };
            case ActionTypes.LOADED_FREE_TEXT:{
                return {
                    ...state,
                    freeText: action.payload
                };
            }
            case ActionTypes.DONE_SIGNOUT:
                return {
                    ...defaultState
                }
            default:
                return state;

        }
    } catch(error) {
        console.error(`Error in appState reducer: ${ error.message || error.code || error }`, error);
    }
};
