import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { AuthErrorResponse, AuthResponse, RequestVerificationCodePayload } from 'api/actions/auth/authActions.types'
import { requestVerificationCodeAction } from 'api/actions/auth/authActions'
import { Mutations } from 'api/mutations.enum'

export const useRequestVerificationCode = () => {
    const mutation = useMutation<AuthResponse, AxiosError<AuthErrorResponse>, RequestVerificationCodePayload>({
        mutationKey: [Mutations.requestVerificationCode],
        mutationFn: requestVerificationCodeAction,
    })

    return mutation
}
