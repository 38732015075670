import { HeatMapHeaderCellProps } from '@diversioteam/diversio-ds'

import { isDataUnavailable } from './../heatmap.utils'
import { RawOverallInclusionData } from './header.types'

export const getCompareBadgeCells = (data: RawOverallInclusionData): HeatMapHeaderCellProps['compareBadgeCells'] => {
    const badgeCells: HeatMapHeaderCellProps['compareBadgeCells'] = []

    if (!isDataUnavailable(data.benchmarkDifference)) {
        const isDataNegative = isNegative(data.benchmarkDifference)

        badgeCells.push({
            compareBadgeProps: {
                color: isDataNegative ? 'lobster' : 'green',
                type: isDataNegative ? 'decrease' : 'increase',
                value: Math.abs(data.benchmarkDifference),
                disableBackground: true,
            },
            text: 'Benchmark',
        })
    }

    if (!isDataUnavailable(data.previousDifference)) {
        const isDataNegative = isNegative(data.previousDifference)

        badgeCells.push({
            compareBadgeProps: {
                color: isDataNegative ? 'lobster' : 'green',
                type: isDataNegative ? 'decrease' : 'increase',
                value: Math.abs(data.previousDifference),
                disableBackground: true,
            },
            text: 'Previous',
        })
    }

    return badgeCells
}

export const isNegative = (n: number): boolean => {
    return n < 0
}
