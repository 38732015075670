import { useContext } from 'react'

import { FiltersContext } from './../context/FiltersContext'

export const useFilters = () => {
    const filtersContext = useContext(FiltersContext)

    if (filtersContext === undefined) {
        throw new Error('useFilters must be used within an FiltersContextController')
    }

    return filtersContext
}
