import mixpanel from "mixpanel-browser";
import TAXONOMIES from "./taxonomies";

export const getPageName = (page) => {
  switch (page) {
    case "all-programs":
      return 'AllPrograms'
    case "goals":
      return 'GetStarted'
    case "my-solutions":
      return 'MySolutions'
    case "my-solutions-archive":
      return 'MySolutionsArchive'
    case "recommended":
      return 'Recommended'
    case "tools":
      return 'Tools'
      case "trainings":
        return 'Trainings'
  }
}

export const mapProgramToTracking = (program, isApiResponse = false) => {
  if (isApiResponse) {
    return mapProgramToTracking({
      id: program.data.solution?.id,
      title: program.data.title,
      company_solution: {
        id: program.data.id,
        is_active: program.data.is_active
      },
      is_saved: true
    })
  }

  return {
    programId: program.id,
    ...(program.company_solution && {
      companySolutionId: program.company_solution.id,
    }),
    active: !!program.company_solution?.is_active,
    saved: !!program.is_saved,
    programName: program.title,
    ...(program.recommendation_label &&
      program.recommendation_label.reduce((obj, { text }) => {
          return {
            ...obj,
            [text]: true,
          };
      }, {})
    )
  }
}

export const mapToolToTracking = (tool) => ({
  toolName: tool.title,
  toolId: tool.id
})

export const handleClickTool = (e, tool, page) => {
  const classNamesOfElementsToBeOmitted = ['button', 'icon']
  const isElementToOmit = classNamesOfElementsToBeOmitted.filter(className => e.target.className.includes(className)).length > 0;

  if (isElementToOmit) {
    return;
  } 

  mixpanel.track(`${getPageName(page)}: ${TAXONOMIES.CLICKS_ON_TOOLS}`, mapToolToTracking(tool))
}
