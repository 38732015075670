import { useDispatch } from 'react-redux'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { Actions } from 'actions'

import { createGoal } from 'api/actions/goals/goalsActions'
import { CreateGoalPayload } from 'api/actions/goals/goalsActions.types'
import { Mutations } from 'api/actions/goals/mutations.enum'
import { Queries } from 'api/actions/goals/queries.enum'

export const useCreateGoal = () => {
    const dispatch = useDispatch()
    const queryClient = useQueryClient()

    const mutation = useMutation<void, AxiosError<Record<string, string[]>>, CreateGoalPayload>({
        mutationKey: [Mutations.createGoal],
        mutationFn: createGoal,
        onSuccess: async () => {
            await queryClient.invalidateQueries([Queries.getInsightData])

            dispatch(
                Actions.showToast({
                    type: 'success',
                    message: 'Successfully created a new Goal!',
                }),
            )
        },
        onError: (error) => {
            const firstError = Object.keys(error.response?.data || {})
            const message = 'Please fill out the missing data'

            Actions.showToast({
                type: 'warning',
                message: `${firstError} - ${message}`,
            })
        },
    })

    return mutation
}
