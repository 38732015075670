import styled from 'styled-components'
import { colors, getFontStyle } from '@diversioteam/diversio-ds'

export const CreateGoal = styled.div`
    width: 100%;
    display: grid;
    grid-template-rows: 200px auto;
    grid-template-areas:
        'steps avatars'
        'goal-card score-card';
    justify-content: space-between;
    gap: 20px 18px;
`

export const Steps = styled.div`
    grid-area: steps;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 60px;
`

export const StepsItem = styled.div`
    max-width: 450px;
`

export const StepsItemSecondary = styled(StepsItem)`
    margin-top: 15px;
`

export const TitlePrimary = styled.div`
    color: ${colors.primary90};

    ${getFontStyle('h2Semibold')}
`

export const DescriptionPrimary = styled.div`
    margin-block-start: 0;
    margin-block-end: 0;
    color: ${colors.secondary500};

    ${getFontStyle('captionRegular')}
`

export const TitleSecondary = styled.div`
    color: ${colors.primary90};

    ${getFontStyle('h4Bold')}
`

export const DescriptionSecondary = styled.p`
    margin-block-start: 0;
    margin-block-end: 0;
    color: ${colors.secondary500};

    ${getFontStyle('p02Regular')}
`

export const Action = styled.div`
    display: inline;
    padding: 0;
    background: none;
    border: none;
    color: ${colors.primary50};

    ${getFontStyle('p02Underline')}

    &:hover {
        cursor: pointer;
    }
`

export const Avatars = styled.div`
    grid-area: avatars;
    justify-self: center;
`

export const GoalCardWrapper = styled.div`
    grid-area: goal-card;
    width: 552px;
`

export const ScoreCardWrapper = styled.div`
    grid-area: score-card;
    width: 378px;
`
