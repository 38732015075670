import React, { ChangeEvent, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { AlertIcon, Button, colors, DownloadIcon, EditIcon } from '@diversioteam/diversio-ds'
import mixpanel from 'mixpanel-browser'

import { useLumi } from '../hooks/useLumi'
import { FileMetadata, ProcessingStatus, UploadStatus } from '../context/LumiContext.types'
import { useCreateJobAdvertItem } from 'hooks/lumi/useCreateJobAdvertItem'
import TAXONOMIES from 'utils/taxonomies'

import * as S from './post.styles'
import { PostProps } from './post.types'

export const Post = ({ textAreaValue, setTextAreaValue }: PostProps) => {
    const [isTextAreaView, setIsTextAreaView] = useState(false)
    const { onChangeAcceptedFiles, jobId } = useLumi()
    const mutation = useCreateJobAdvertItem()

    const handleFileUpload = async (files: File[]) => {
        const newAcceptedFiles: FileMetadata[] = files.map((file) => ({
            file,
            path: file.name,
            uploadStatus: UploadStatus.Loading,
            processingStatus: ProcessingStatus.Pending,
            uuid: undefined,
        }))

        onChangeAcceptedFiles((prevAcceptedFiles: FileMetadata[]) => [...prevAcceptedFiles, ...newAcceptedFiles])

        for (const fileMetadata of newAcceptedFiles) {
            const formData = new FormData()
            formData.append('file', fileMetadata.file)

            await mutation.mutateAsync({ jobId, formData, fileMetadata })
        }

        const uploadedFiles = newAcceptedFiles.map((fileMetadata) => ({
            name: fileMetadata.path,
            type: fileMetadata.file.type,
            size: fileMetadata.file.size,
        }))

        mixpanel.track(TAXONOMIES.LUMI_UPLOADED_FILES, { uploadedFiles, fileCount: uploadedFiles.length, jobId })
    }

    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
        accept: {
            'text/plain': ['.txt'],
            'application/msword': ['.doc'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
            'application/pdf': ['.pdf'],
        },
        onDropAccepted: handleFileUpload,
    })

    const handleToggleTextAreaView = () => {
        setIsTextAreaView((prevState) => !prevState)
    }

    const handleChangeTextArea = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setTextAreaValue(event.target.value)
    }

    const handleTextToFile = () => {
        if (!textAreaValue.trim()) return

        const blob = new Blob([textAreaValue], { type: 'text/plain' })
        // TODO: Harcoded file name for now. Maybe have the ability to generate unique file names
        const fileName = 'text-input.txt'
        const file = new File([blob], fileName, { type: 'text/plain' })

        mixpanel.track(TAXONOMIES.LUMI_WRITTEN_JOB_AD, { jobId, type: file.type, size: file.size })

        handleFileUpload([file])

        setTextAreaValue('')
    }

    return (
        <S.PostWrapper>
            {!isTextAreaView && (
                <>
                    <S.Title>Submit your job post</S.Title>

                    <S.Dropzone {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
                        <input {...getInputProps()} />

                        {isDragReject ? (
                            <>
                                <S.IconWrapper isDragReject={isDragReject}>
                                    <AlertIcon type="bold" color={colors.primaryWhite} />
                                </S.IconWrapper>

                                <S.DropzoneTitle>File format not supported</S.DropzoneTitle>
                            </>
                        ) : (
                            <>
                                <S.IconWrapper>
                                    <DownloadIcon type="bold" color={colors.primary50} />
                                </S.IconWrapper>

                                <S.DropzoneTitle>Drag and Drop Files to upload.</S.DropzoneTitle>
                            </>
                        )}

                        <S.DropzoneDescription>Accepted formats .doc, .docx, .pdf, .txt</S.DropzoneDescription>
                    </S.Dropzone>

                    <Button
                        onClick={handleToggleTextAreaView}
                        rightIcon={<EditIcon type="line" />}
                        color="secondaryLight"
                    >
                        Enter Text Instead
                    </Button>
                </>
            )}

            {isTextAreaView && (
                <>
                    <S.Title>Submit your job post</S.Title>

                    <S.Textarea
                        placeholder="Enter job post here"
                        onChange={handleChangeTextArea}
                        value={textAreaValue}
                        autoFocus
                    />

                    <S.TextareaButtons>
                        <Button onClick={handleToggleTextAreaView} color="secondaryLight">
                            Back
                        </Button>

                        <Button onClick={handleTextToFile} color="primary" disabled={textAreaValue.length === 0}>
                            Add to queue
                        </Button>
                    </S.TextareaButtons>
                </>
            )}
        </S.PostWrapper>
    )
}
