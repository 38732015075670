import React from 'react';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import Card from '../../utils/Card';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { stableSort, getComparator, numericalList } from '../../../utils/tableSorting'
import './index.scss';

const MemberTable = (props) => {
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [page, setPage] = React.useState(0);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('index');
    const [filterText, setFilterText] = React.useState('')

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterTextChange = (event) => {
        setFilterText(event.target.value)
        setPage(0)
    }

    const Mailto = ({ email, subject, body, children }) => {
        return (
          <a href={`mailto:${email}?subject=${encodeURIComponent(subject) || ''}&body=${encodeURIComponent(body) || ''}`}>{children}</a>
        );
      };

    const filterMemberRows = (rows) => {

        const filteredRowsByName = rows.filter(row => {
            const rowName = row.name.toLowerCase()

            if (rowName.indexOf(filterText.toLowerCase()) === -1){
                return
            }

            const filteredRowName = rowName.indexOf(filterText.toLowerCase()) >= 0

            return filteredRowName
        })

        const filteredRowsByMember = rows.filter(row => {
            const rowMember = row.member.toLowerCase()

            if (rowMember.indexOf(filterText.toLowerCase()) === -1){
                return
            }

            const filteredRowMember = rowMember.indexOf(filterText.toLowerCase()) >= 0

            return filteredRowMember
        })

        const filteredRowsByRole = rows.filter(row => {
            const rowRole = row.role.toLowerCase()

            if (rowRole.indexOf(filterText.toLowerCase()) === -1){
                return
            }

            const filteredRowRole = rowRole.indexOf(filterText.toLowerCase()) >= 0

            return filteredRowRole
        })

        const filteredRowsByCommittee = rows.filter(row => {
            const rowCommittee = row.committee.toLowerCase()

            if (rowCommittee.indexOf(filterText.toLowerCase()) === -1){
                return
            }

            const filteredRowCommittee = rowCommittee.indexOf(filterText.toLowerCase()) >= 0

            return filteredRowCommittee
        })

        const totalFilteredRows = filteredRowsByName.concat(filteredRowsByMember, filteredRowsByRole, filteredRowsByCommittee)
        const filteredResult = [...new Set(totalFilteredRows)]
        // Apply numerical list values for displaying the data
        numericalList(filteredResult)
        return (
            <div className="iln-members">
                <TablePagination
                    rowsPerPageOptions={[5,10,15,25]}
                    component="div"
                    count={filteredResult.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className="styled-tablecell"></TableCell>
                                <TableCell className="styled-tablecell">Name</TableCell>
                                <TableCell className="styled-tablecell">Organization</TableCell>
                                <TableCell className="styled-tablecell">Role</TableCell>
                                <TableCell className="styled-tablecell">ILN Committee</TableCell>
                                <TableCell className="styled-tablecell">Email</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {stableSort(filteredResult, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    return (
                                        <TableRow key={index} style={index % 2 ? { background: "#F6F6F6" } : { background: "white" }}>
                                            <TableCell className="iln-cell-style" align="center">{row.index}</TableCell>
                                            <TableCell className="iln-member-name">{row.name}</TableCell>
                                            <TableCell className="iln-cell-style">{row.member}</TableCell>
                                            <TableCell className="iln-cell-style">{row.role}</TableCell>
                                            <TableCell className="iln-cell-style">{row.committee}</TableCell>
                                            <TableCell className="iln-cell-style">
                                                <Mailto email={row.email} subject="" body="">
                                                    {row.email.toLowerCase()}
                                                </Mailto>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        )
    }

    const searchList = ['Member', 'Organization']

    return (
        <div className="iln-member-container">
            <Card className="iln-card">
                <h2>ILN Directory</h2>
                <div className="iln-table-search">
                    <h3>Search</h3>
                    <TextField
                        id="outlined-basic"
                        size="small"
                        label="Search"
                        variant="outlined"
                        value={filterText}
                        onChange={handleFilterTextChange}
                    // TODO: Figure out icon styling to fit properly in the textbox
                    // InputProps={{
                    //     endAdornment: (
                    //       <InputAdornment position="start">
                    //         <div className="icon-iln-search"/>
                    //       </InputAdornment>
                    //     ),
                    //   }}
                    />
                </div>
                {filterMemberRows(props.members)}
            </Card>
        </div>
    )
}

export default MemberTable;
