import React from 'react'
import { Skeleton } from "@material-ui/lab"

export const RecommendedCarouselSkeleton = () => {
  return (
      <>
        <Skeleton height={56} width={300} />
        <Skeleton variant="rect" height={277} width="100%" />
      </>
  )
}