import React from 'react';
import './index.scss';

const ILNPoweredBy = ( ) => {
    return(
        <div className="iln-powered">
            <div className="icon-iln-powered-by" />
        </div>
    )
}

export default (ILNPoweredBy);
