import { getFontStyle } from '@diversioteam/diversio-ds'
import styled from 'styled-components'

export const NoInsightsWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const Header = styled.div`
    margin-bottom: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
`

export const IconWrapper = styled.div`
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.lobster00};
`

export const Title = styled.h2`
    color: ${({ theme }) => theme.colors.primary90};

    ${getFontStyle('h2Bold')}
`

export const CardsList = styled.ul`
    display: flex;
    gap: 24px;
    list-style-type: none;
`

export const Card = styled.li`
    width: 264px;
    padding: 24px;
    border-radius: 15px;
    background-color: ${({ theme }) => theme.colors.primaryWhite};
`

export const CardTitle = styled.h3`
    margin-bottom: 12px;
    color: ${({ theme }) => theme.colors.primary90};

    ${getFontStyle('p02Semibold')}
`

export const CardDescription = styled.h3`
    color: ${({ theme }) => theme.colors.secondary500};

    a {
        text-decoration: none;
        color: inherit;
    }

    ${getFontStyle('p02Regular')}
`
