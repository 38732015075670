import { ReturnButton as DdsReturnButton, colors } from '@diversioteam/diversio-ds'
import styled, { keyframes } from 'styled-components'

const slideInFromTop = keyframes`
    0% {
      transform: translateY(-100%);
      opacity: 0;
    }
  
    25% {
      transform: translateY(-75%);
      opacity: 0;
    }
  
    50% {
      transform: translateY(-50%);
      opacity: 0;
    }
  
    85% {
      transform: translateY(-5%);
      opacity: 0.25;
    }
  
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  `

export const Goals = styled.div<{ isModalOpen: boolean }>`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow-y: hidden;
    filter: ${({ isModalOpen }) => {
        if (isModalOpen) {
            return 'blur(8px)'
        }
    }};
`

export const ToastWrapper = styled.div`
    width: 100%;
    position: absolute;
    top: 79px;
    left: 0;
    z-index: 1;
    animation: 0.4s ease-out 0s 1 ${slideInFromTop};
`

export const Header = styled.header<{ activeStep: number }>`
    box-sizing: border-box;
    width: 100%;
    min-height: 79px;
    padding-left: ${({ activeStep }) => {
        if (activeStep === 0) {
            return '118px'
        }
    }};
    display: flex;
    align-items: center;
    background-color: ${colors.primaryWhite};
`

export const ReturnButton = styled(DdsReturnButton)`
    margin-left: 25px;
    margin-right: 25px;
`

export const Container = styled.div`
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: relative;
    overflow-y: auto;
`

export const Wrapper = styled.div<{ clippedHeight: number }>`
    height: ${({ clippedHeight }) => {
        return `calc(100% - 29px + ${clippedHeight}px)`
    }};
    width: 100%;
    max-width: 948px;
    margin-top: 29px;
    position: relative;
`

export const StickyWrapper = styled.div`
    position: sticky;
    top: 29px;
`

export const Stepper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
`
