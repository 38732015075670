import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Actions } from 'actions'
import mixpanel from 'mixpanel-browser'

import { VerifyLogin } from 'components/Account/VerifyLogin'
import { BackupCodes } from 'components/Account/BackupCodes'
import { RootState } from 'reducers/rootState.types'
import TAXONOMIES from 'utils/taxonomies'
import { LoginFormData } from 'components/Auth/Login/login.types'

export const BackupCodesContainer = () => {
    const dispatch = useDispatch()
    const { username, backupCodes, isLoadingExistingBackupCodes, isLoadingNewBackupCodes } = useSelector(
        (state: RootState) => state.auth,
    )
    const [user, setUser] = useState<LoginFormData>()
    const [isCopiedToClipboard, setIsCopiedToClipboard] = useState(false)

    useEffect(() => {
        return () => {
            dispatch(Actions.resetBackupCodes())
        }
    }, [])

    const handleCopySuccess = () => {
        setIsCopiedToClipboard(true)
        setTimeout(() => {
            setIsCopiedToClipboard(false)
        }, 3000)
    }

    const handleBackupCodeClick = (backupCode: string) => {
        navigator.clipboard.writeText(backupCode).then(handleCopySuccess)
    }

    const handleCopyAllCodesClick = (backupCodes: string[]) => {
        const codesString = backupCodes.join('\n')

        navigator.clipboard.writeText(codesString).then(handleCopySuccess)
    }

    const handleAuthSubmit = (user: LoginFormData) => {
        setUser(user)
        dispatch(Actions.doFetchExistingBackupCodes(user))
        mixpanel.track(TAXONOMIES.DOWNLOAD_BACKUP_CODE, {
            backupClickedAt: new Date().toISOString(),
        })
    }

    const handleFetchNewCodes = () => {
        dispatch(Actions.doFetchNewBackupCodes(user))
        mixpanel.track(TAXONOMIES.REFRESH_BACKUP_CODE, {
            refreshBackupClickedAt: new Date().toISOString(),
        })
    }

    return backupCodes?.length ? (
        <BackupCodes
            isGeneratingNewBackupCodes={isLoadingNewBackupCodes}
            backupCodes={backupCodes}
            isCopiedToClipboard={isCopiedToClipboard}
            onClickBackupCode={handleBackupCodeClick}
            onClickCopyAllCodes={handleCopyAllCodesClick}
            onClickGenerateNewCodes={handleFetchNewCodes}
        />
    ) : (
        <VerifyLogin isLoading={isLoadingExistingBackupCodes} onSubmit={handleAuthSubmit} username={username} />
    )
}
