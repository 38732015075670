import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { AuthErrorResponse, InitializeSsoPayload, InitializeSsoResponse } from 'api/actions/auth/authActions.types'
import { initializeSsoAction } from 'api/actions/auth/authActions'
import { Mutations } from 'api/mutations.enum'

export const useInitializeSso = () => {
    const mutation = useMutation<InitializeSsoResponse, AxiosError<AuthErrorResponse>, InitializeSsoPayload>({
        mutationKey: [Mutations.initializeSso],
        mutationFn: initializeSsoAction,
    })

    return mutation
}
