import { AxiosRequestHeaders, InternalAxiosRequestConfig } from 'axios'

import { getCompanyUUID } from 'utils/getCompanyUUID/getCompanyUUID'

export const requestSuccessInterceptor = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    const accessToken = localStorage.getItem('auth_access_token')
    const companyUUID = getCompanyUUID() || ''

    const updatedConfig = {
        ...config,
        headers: {
            ...config.headers,
            ...(accessToken && { Authorization: `JWT ${accessToken}` }),
        } as AxiosRequestHeaders,
    }

    if (companyUUID && updatedConfig.url) {
        updatedConfig.url = updatedConfig.url.replace('companyUUID', companyUUID)
    }

    return updatedConfig
}
