import { useQuery } from '@tanstack/react-query'
import { getMyReports } from 'sagas/network'

import { Queries } from 'api/queries.enum'
import { GetMyReportsResponse } from 'api/actions/reporting/reporting.types'

export const useGetMyReports = () => {
    const query = useQuery<GetMyReportsResponse, Error>({
        queryKey: [Queries.getMyReports],
        queryFn: getMyReports,
        keepPreviousData: true,
    })

    return query
}
