import { getCompanyUUID } from '../../utils/getCompanyUUID/getCompanyUUID'

import { rootURI } from './rootURI'

export const generateCompanyURI = (url = '', isCompanySpecific = true) => {
    // Pass isCompanySpecific argument as false if you don't want the `/company/uuid` to
    // be appended to the URL
    if (url.indexOf('/company/') > -1) {
        return url
    }
    const companyUUID = getCompanyUUID()
    const companyPath = isCompanySpecific ? `/company/${companyUUID}` : ''

    return `${companyPath}${rootURI(url)}`
}
