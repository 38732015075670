import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { Actions } from 'actions'

import { AuthErrorResponse, EnableMfaMutationPayload, EnableMfaResponse } from 'api/actions/auth/authActions.types'
import { Mutations } from 'api/mutations.enum'
import { requestEnableMfaAction } from 'api/actions/auth/authActions'
import { handleAuthCredentials } from 'utils/handleAuthCredentials'
import { LocalStorageKey } from 'containers/Auth/auth.types'
import { RootState } from 'reducers/rootState.types'

export const useEnableMfa = () => {
    const { uuid: userUUID } = useSelector((state: RootState) => state.auth)
    const dispatch = useDispatch()

    const mutation = useMutation<EnableMfaResponse, AxiosError<AuthErrorResponse>, EnableMfaMutationPayload>({
        mutationFn: requestEnableMfaAction,
        mutationKey: [Mutations.enableMFA],
        onSuccess: (response, variables) => {
            if (variables.payload.enroll === false) {
                // Clear the local storage so that the user can now be considered authenticated
                localStorage.removeItem(`${LocalStorageKey.FirstTimeMfaUser}${userUUID}`)
            }
            dispatch(Actions.enableMfa(handleAuthCredentials(response)))
        },
        onError: () => {
            dispatch(Actions.doSignout())
        },
    })

    return mutation
}
