import {ActionTypes} from '../actions';

const CommunicationsErrorCodes = {
    anonymityNotMet: 1
};

// Just for reference
const errorSchema = {
    id: 1, // an int passed by the component that invoked the request uniquely identifying which request failed
    timestamp: 1561234123412341234,  //a valid timestamp int
    httpStatusCode: 502,  // status code from server
    httpResponseBody: {}, //response body from server
    customStatusCode: CommunicationsErrorCodes.anonymityNotMet,  // int from CommunicationsErrorCode
    details: {}, // any additional detail to help components manage error
};


const defaultCommunications = {
    authentication: {
        slack_token: null,
        github_token: null,
        slack_calc_days_max: null, // max # of days we allow a client to scrape slack for. (we limit this b.c.
                                   // otherwise client could scrape for a long time and tie up one of our machines)
        errors: [] // containing errors that may have occurred during api calls
    },
    slack_analytics: {
        data: {},  // containing slack analysis data retrieved from api requests
        metadata: {},  // containing metadata about the analysis data retrieved from apis (eg. slack channels)
        last_retrieved: null, // time stamp of last data fetch (failed or successful)
        errors: []  // containing errors that may have occurred during api calls
    },
    github_analytics: {
        data: {},
        metadata: {},
        errors: []
    }
};

function appendErrorFromPayload(subComponent, payload) {
    return {
        ...subComponent,
        errors: [...subComponent.errors, payload],
        last_retrieved: Date.now()
    }
}


///////////////////////////////////
// authentication reducer functions
const authenticationReducers = {};

authenticationReducers[ActionTypes.LOADED_COMMUNICATIONS_TOKENS] = (authentication, {payload}) => {
    const {slack_token, github_token, slack_calc_days_max} = payload;
    return {...authentication, slack_token, github_token, slack_calc_days_max};
};

authenticationReducers[ActionTypes.DONE_UPDATE_COMMUNICAITONS_TOKENS] = (authentication, {payload}) => {
    const {slack_token, github_token} = payload;
    return {...authentication, slack_token, github_token};
};

authenticationReducers[ActionTypes.DONE_DELETE_COMMUNICAITONS_TOKENS] = (authentication) => {
    return {...authentication, slack_token: null, github_token: null};
};

authenticationReducers[ActionTypes.DONE_SIGNOUT] = () => {
    return { ...defaultCommunications };
}

authenticationReducers[ActionTypes.FAILED_LOADING_COMMUNICATIONS_TOKENS] = (authentication, {payload}) => appendErrorFromPayload(authentication, payload);


////////////////////////////////////
// slack_analytics reducer functions
const slackAnalyticsReducers = {};

slackAnalyticsReducers[ActionTypes.LOADED_COMMUNICATIONS_SLACK_ANALYTICS] = (slack_analytics, {payload}) => {
    return {
        ...slack_analytics,
        data: payload.data,
        metadata: payload.metadata,
        last_retrieved: Date.now()
    };
};

slackAnalyticsReducers[ActionTypes.FAILED_LOADING_COMMUNICATIONS_SLACK_ANALYTICS] = (slack_analytics, {payload}) => appendErrorFromPayload(slack_analytics, payload);


/////////////////////////////////////
// github_analytics reducer functions
const githubAnalyticsReducers = {};

githubAnalyticsReducers[ActionTypes.LOADED_COMMUNICATIONS_GITHUB_ANALYTICS] = (github_analytics, {payload}) => {
    return {
        ...github_analytics,
        data: payload.data,
        metadata: payload.metadata,
        last_retrieved: Date.now()
    }
};

githubAnalyticsReducers[ActionTypes.FAILED_LOADING_COMMUNICATIONS_GITHUB_ANALYTICS] = (github_analytics, {payload}) => appendErrorFromPayload(github_analytics, payload);


export default ({authentication, slack_analytics, github_analytics} = defaultCommunications, action) => {
    try {
        return {
            authentication: authenticationReducers[action.type] ? authenticationReducers[action.type](authentication, action) : authentication,
            slack_analytics: slackAnalyticsReducers[action.type] ? slackAnalyticsReducers[action.type](slack_analytics, action) : slack_analytics,
            github_analytics: githubAnalyticsReducers[action.type] ? githubAnalyticsReducers[action.type](github_analytics, action) : github_analytics
        }
    } catch (error) {
        console.error(`Error in communications reducer for action type: ${action.type}`, error);
        return {authentication, slack_analytics, github_analytics}
    }
};
