import DashboardRoutes from 'dashboardRoutes'
import React, { useEffect } from 'react'
import { Route, useHistory } from 'react-router-dom'
import _ from 'underscore'

import { useVersion } from 'hooks/useVersion'
import { getUUIDFromURL } from '../utils/getUUIDFromURL/getUUIDFromURL'
import { useUserMetadata } from 'hooks/metadata/useUserMetadata'

import { CompanyProps } from './Company.types'

export const Company = (props: CompanyProps) => {
    const { data: userMetadata } = useUserMetadata()
    const companyUUID = getUUIDFromURL()

    const versionsContext = useVersion()
    const isHomeAnalyzeV2Enabled = versionsContext?.version === versionsContext?.AVAILABLE_VERSIONS.v2
    const history = useHistory()

    useEffect(() => {
        const savedCompanyUUID = sessionStorage.getItem('companyUUID')
        const defaultCompanyUUID = companyUUID || savedCompanyUUID
        let persistFilters = true
        let uuid = ''

        if (!isHomeAnalyzeV2Enabled) {
            persistFilters = false
        }

        uuid = defaultCompanyUUID || props.companyInformation.uuid

        const foundCompany = userMetadata?.companies && _.findWhere(userMetadata.companies, { uuid })

        if (!foundCompany) {
            uuid = props.companyInformation.uuid
            persistFilters = false
            sessionStorage.setItem('companyUUID', uuid)
        }

        const defaultCompany = userMetadata && _.findWhere(userMetadata.companies, { uuid })

        if (!foundCompany && !defaultCompany) {
            return
        }

        const activeDashboard = foundCompany || defaultCompany

        activeDashboard && props.setActiveDashboard(activeDashboard)

        if (
            location.pathname.indexOf('company/') === -1 ||
            savedCompanyUUID !== uuid ||
            location.pathname.indexOf(uuid) === -1
        ) {
            if (!sessionStorage.getItem('companyUUID') || savedCompanyUUID !== uuid) {
                sessionStorage.setItem('companyUUID', uuid)
            }

            if (companyUUID && companyUUID !== uuid) {
                persistFilters = false
            }

            history.replace(
                `/company/${uuid}${location.pathname.replace(/\/company\/[a-z0-9A-Z\-]*/gm, '')}${
                    persistFilters ? location.search : ''
                }`,
            )
        }
    }, [companyUUID, props.companyInformation.uuid, history, location.pathname, isHomeAnalyzeV2Enabled])

    return (
        <Route path="/company/:companyUUID">
            <DashboardRoutes {...props} />
        </Route>
    )
}
