import React, {Component} from 'react';
import './index.scss';
import Card from '../../../utils/Card';
import {populateFocusAreaSpecification} from "./FocusAreaSpecification";

class FocusAreas extends Component {

    insightToComponent(data, index) {
        let {amount, moreGroup, lessGroup} = data.discrepancy;
        const precision = amount >= 4 ? 1 : (amount >= 1.05 ? 10 : 100);
        amount = Math.round(amount * precision) / precision;
        let message = data.focusAreaStr(amount, moreGroup, lessGroup);
        // message = message[0].toUpperCase() + message.slice(1);
        return (
            <div className={'insight'} key={index}>
                <div className={'number'}>{amount}x</div>
                <div className={'message'}>{message}</div>
            </div>
        )
    }

    render() {
        const metricData = populateFocusAreaSpecification(this.props.data, this.props.metadata).map(x => {
            let moreGroup, lessGroup, amount;
            if (x.data.Women > x.data.Men) {
                amount = x.data.Women / x.data.Men;
                moreGroup = 'women';
                lessGroup = 'men';
            } else {
                amount = x.data.Men / x.data.Women;
                moreGroup = 'men';
                lessGroup = 'women';
            }
            return Object.assign(x, {discrepancy: {amount, moreGroup, lessGroup}})
        }).filter(x => x.discrepancy.amount !== Infinity && !isNaN(x.discrepancy.amount));
        const top3 = metricData.sort((a, b) => b.discrepancy.amount - a.discrepancy.amount).slice(0, 3);
        const focusAreas = top3.map(this.insightToComponent);
        return (
            <Card>
                <div className={'focus-areas'}>
                    <h2>Areas of Focus</h2>
                    {focusAreas.length?<p>The Top 3 metrics with the greatest discrepancies between men and women.</p>:null}
                    <div className={'insights'}>

                        {focusAreas.length?focusAreas:
                            <p>Not enough data was found for the provided channels to ensure user-anonymity. Please try entering channels that are more active.</p>
                        }
                    </div>
                </div>
            </Card>
        );
    }
}

export default FocusAreas;
