import styled from 'styled-components'
import { getFontStyle } from '@diversioteam/diversio-ds'

import { media } from 'styles/theme/mediaQueries'

export const TrainingWrapper = styled.div<{ isSelected: boolean }>`
    height: 100%;
    padding: 32px;
    border-radius: 16px;
    background-color: ${({ theme }) => theme.colors.primaryWhite};
    cursor: pointer;
    padding: 24px;

    outline: ${({ isSelected, theme }) => {
        if (isSelected) {
            return `4px solid ${theme.colors.primary40}`
        }
    }};

    ${media.lg} {
        padding: 32px;
    }
`

export const Header = styled.header`
    display: flex;
    gap: 12px;
    flex-direction: column-reverse;

    ${media.lg} {
        flex-direction: row;
        justify-content: space-between;
    }
`

export const Title = styled.h3`
    margin: 0 0 18px;
    color: ${({ theme }) => theme.colors.primary90};

    ${getFontStyle('h2Bold')}
`

export const Image = styled.div`
    height: 120px;
    width: 120px;
    flex-shrink: 0;
    border-radius: 12px;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colors.primary20};

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`

export const ChipsList = styled.ul`
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 8px;
    list-style-type: none;
`

export const ChipsListItem = styled.li`
    .dds-chip {
        border: 1px solid ${({ theme }) => theme.colors.primary20};
        background-color: ${({ theme }) => theme.colors.primary10};
    }

    .dds-chip__label {
        color: ${({ theme }) => theme.colors.primary50};

        ${getFontStyle('captionBoldM')};
    }
`

export const Level = styled(ChipsListItem)`
    color: ${({ theme }) => theme.colors.secondary500};

    ${getFontStyle('captionRegularM')}
`
export const KpisList = styled.ul`
    margin: 18px 0 0;
    padding: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 16px;
    row-gap: 8px;
    list-style-type: none;
`

export const KpisListItem = styled.li`
    display: flex;
    gap: 6px;

    color: ${({ theme }) => theme.colors.secondary500};

    ${getFontStyle('captionRegularM')}
`
