import { getEventReminders } from 'sagas/network'

import {
    GetEventRemindersResponse,
    GetEventRemindersTransformedResponse,
} from 'api/actions/home/eventReminders/eventRemindersActions'
import { Queries } from 'api/queries.enum'
import { useQuery } from 'hooks/useQuery'

import { transformData } from './transformData'

export const useGetEventReminders = () => {
    return useQuery<GetEventRemindersResponse, Error, GetEventRemindersTransformedResponse>({
        queryKey: [Queries.getEventReminders],
        queryFn: getEventReminders,
        select: transformData,
    })
}
