import React, { Component } from 'react';
import { Text } from 'recharts';

/* 
A standardized custom x-axis for use across Bar and Line Charts.
Pass a legendFormatter(value) function as prop to customize legend formatting
(i.e. for translations or capitalization).
*/
class CustomizedAxisTick extends Component {
    render() {
      const { x, y, payload, activeIndex, legendFormatter, extraClassName, tickWidth } = this.props;
      const className = `bchart__custom-tick ${activeIndex === payload.index ? "bchart__custom-tick-active" : ''} ${ extraClassName || ''}`;

      return (
        <g transform={`translate(${x},${y})`}>
          <Text
            width={tickWidth || 200}
            x={0}
            y={0}
            dy={16}
            verticalAnchor="start"
            textAnchor="middle"
            className={className}
          >
            {legendFormatter ? legendFormatter(payload.value): payload.value}
           </Text>
        </g>
      );
    }
  }

export default CustomizedAxisTick;
