import { Button } from '@diversioteam/diversio-ds'
import styled from 'styled-components'

export const Container = styled.div`
    margin: 30px auto;
    text-align: center;
`

export const Message = styled.h3`
    margin-bottom: 16px;
`

export const BackBtn = styled(Button)``
