import React, { Component } from 'react';
import './index.scss';
import classNames from "classnames";
import * as m from '../../../../translations/mapping';
import { mapT } from '../../../../translations/utils';
import {upperSnakeCase} from "../../../../utils";
import { INSUFFICIENT_DATA } from 'components/AnalyzeV2/Inclusion/Heatmap/heatmap.utils';

const PREFER_NOT_TO_ANSWER = "Prefer not to answer";
const DOMINANT_GROUPS = {
    "Gender": ["Male", "Female"],
    "Ethnicity": ["White"],
    "Sexual Orientation": ["Heterosexual"],
    "Disability": ["People without disabilities"],
}

const NON_DOMINANT_GROUPS = {
    "Gender": ["Female+", "Non-Binary/Intersex/Trans"],
    "Ethnicity": ["POC"],
    "Sexual Orientation": ["LGBTQ+"],
    "Disability": ["People with disabilities"]
}

export const reorder = (compData) => {
    // temp: make sure PNTS is last
    let newCompData = [];
    let pntsItem = null;
    for (const d of compData) {
        if (d.name === PREFER_NOT_TO_ANSWER) {
            pntsItem = d;
        } else {
            newCompData.push(d);
        }
    }
    if (pntsItem) {
        newCompData.push(pntsItem);
    }

    return newCompData;
}

export const DiversitySnapshotDetail = (props) => {
    const { compositionData, breakdownData, ndg, title, profile, t } = props;

    const reshapeDisability = (breakdown) => {
        // Shape of this payload is different: https://app.clickup.com/t/7ew52q
        // It is also sometimes not present for companies whose surveys did not include disability breakdowns
        if ((!breakdown || !breakdown[0]) || !breakdown[0]["name"]) {
            return null;
        }
        let disabilityBreakdown = {};
        for (var idx in breakdown) {

            const yesIndex = breakdownData[idx]["value"].findIndex(obj => (obj["name"] === "Yes"))
            disabilityBreakdown[breakdownData[idx]["name"]] = breakdownData[idx]["value"][yesIndex]["value"]
        }

        return disabilityBreakdown;
    }

    let breakdown;
    if (title === "Disability") {
        breakdown = reshapeDisability(breakdownData);
    } else {
        breakdown = breakdownData;
    }

    const getDomGroup = (compositionData, ndg) => {
        return compositionData.filter(d => (d.name !== ndg && d.name !== PREFER_NOT_TO_ANSWER))[0].name;
    }

    const getNdgList = (breakdown, compositionData, ndg) => {
        if (!breakdown) {
            return null;
        }
        let ndgList = {};
        // In gender composition we can have Female+ in response when there are not enough responses
        // under Non-Binary/Intersex/Trans, hence in that case the key Non-Binary/Intersex/Trans is
        // not present and everything under it along with Women is part of Women+. In this case 
        // Women is shown as an option under Women+ in breakdown.
        if (title == "Gender") {
            for (const obj of compositionData) {
                if (obj.name === "Female+") {
                    ndgList["Female"] = breakdown["Female"];
                }
            }
        }
        // remove members of compositionData from breakdown to get NDG members
        const dg = getDomGroup(compositionData, ndg);
        for (const member of Object.keys(breakdown)) {
            // For the ethnicity breakdown, we want to display all ethnicities except for PNTA and the 
            // dominant ethnicity
            if (title == "Ethnicity") {
                if (member !== dg && member !== PREFER_NOT_TO_ANSWER) {
                    ndgList[member] = breakdown[member];
                }
            } else {
                if (!DOMINANT_GROUPS[title].includes(member) && member !== PREFER_NOT_TO_ANSWER) {
                    ndgList[member] = breakdown[member];
                }
            }
        }
        return ndgList;
    }

    const ndgList = getNdgList(breakdown, compositionData, ndg);

    const getColor = (value) => {
        if (value === INSUFFICIENT_DATA) {
            return 'gray';
        }
    }

    const valueCalc = (value) => {
        if (value === INSUFFICIENT_DATA) {
            return '';
        } else {
            return (value ? value.toFixed(1) : 0) + "%";
        }
    }

    return (
        <div className="diversity-snapshot-detail">
            <div className="diversity-snapshot-detail__title">
                {t(`GROUP_TYPES.${upperSnakeCase(title)}`, title)}
            </div>
            <div className="diversity-snapshot-detail__bullets">
                    {reorder(compositionData).map(d => {
                        return (
                        <>
                        <div className="diversity-snapshot-detail__detail-row">
                            <div className="diversity-snapshot-detail__detail-name">{
                                profile.email == 'amazing_investor_demo_2@diversio.com' && d.name == 'White'
                                ?
                                'Dominant group' :
                                mapT(m.GROUP_BREAKDOWN, d.name)}</div>
                            <div className={classNames(["diversity-snapshot-detail__detail-number", getColor(d.value)])}>{`${valueCalc(d.value)}`}</div>
                        </div>
                        <>{ ((NON_DOMINANT_GROUPS[title].includes(d.name) && ndgList) && (
                            <div className="diversity-snapshot-detail__ndg-breakdown">
                                <div className="diversity-snapshot-detail__ndg-list-wrapper">
                                    <ul>
                                    {Object.keys(ndgList).map(k => (
                                        <li className="diversity-snapshot-detail__ndg-breakdown-item">
                                            <div className="diversity-snapshot-detail__ndg-breakdown-name">{mapT(m.GROUP_BREAKDOWN, k)}</div>
                                            <div className={classNames(["diversity-snapshot-detail__ndg-breakdown-number", getColor(ndgList[k])])}>{`${valueCalc(ndgList[k])}`}</div>
                                        </li>
                                    ))}
                                    </ul>
                                </div>
                            </div>
                        )) }</>
                    </>
                    )
                    }
                     )}
            </div>
            <div className="diversity-snapshot-detail__end-spacer">

            </div>
        </div>
    );
}
