import React, {useContext, useState} from 'react';
import {FilterContext} from "./index";
import * as m from '../../../translations/mapping';
import { mapT } from '../../../translations/utils';
import { getOfficeRegionLabel, getTenureLabel } from '../../../utils/labels';
import _ from 'underscore';
import './index.scss';
import {withTranslation} from "react-i18next";

const FiltersSelected = ({values, allFilters, companyName=null, t}) => {
    const [open, setOpen] = useState(false);
    let filterContext = useContext(FilterContext);
    values = filterContext ? filterContext.values : values;

    if (!values || !allFilters) {
        return null;
    }

    const transformType = (type) => {
        if (!type) {
            return null;
        }

        if (type.toLowerCase() === "office") {
            return getOfficeRegionLabel(companyName)
        }

        if (type.toLowerCase() === "tenure") {
            return getTenureLabel(companyName)
        }

        else return mapT(m.FILTER_LABELS, type)
    }


    const makeContent = () => {
        let hasFilter = false;

        return (
            <>
            {
            Object.keys(values).map((type) => {
                // key 'department' in selected filters corresponds to 'departments' in allFilters
                const pluralKey = `${type}s`;
                // TODO:: @sahanarula Show nested object structure for n level filter fields
                if (!(_.isArray(values[type]) || _.isString(values[type]))) {
                    return null;
                }
                if (values[type] && allFilters[pluralKey]) {
                    if (values[type].length < allFilters[pluralKey].length || type === "year") {
                        hasFilter = true;
                        if (values[type].length === 0) {
                            return <span className={"selected-filters__filter-row"}><span className={"selected-filters__type"}>{
                                transformType(type)
                                }: </span>{"None"}</span>
                        } else {
                            return <span className={"selected-filters__filter-row"}><span className={"selected-filters__type"}>{
                                transformType(type)
                                }: </span>{values[type].join ? values[type].join(', ') : values[type]}</span>
                        }
                    } else {
                        return <span className={"selected-filters__filter-row"}><span className={"selected-filters__type"}>{transformType(type)}: </span>{"All"}</span>
                    }
                }
                else {
                    return null;
                }
            }
        )
            }
            {(!hasFilter) && (<span>None</span>)}
        </>
        );
    }

    const content = makeContent();

    return (
        <div className={"selected-filters"}>
            <button className={"selected-filters__button"} onClick={() => (setOpen(!open))}>
            <div className={"selected-filters__top-row"}>
                <div className={"selected-filters__label"}>
                    {t("ANALYZE.TABS.FILTERS")}
                </div>
                <div className={"selected-filters__caret-wrapper"}>
                    <div className={`selected-filters__${open ? "caret-up" : "caret-down"}`}></div>
                </div>
            </div>
            </button>
            {open && (
                <>
                <br/>
                {content}
                </>
            )}
        </div>
    )

};

export default withTranslation()(FiltersSelected);
