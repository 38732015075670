import styled from 'styled-components'
import { Card } from '@material-ui/core'
import { getFontStyle } from '@diversioteam/diversio-ds'

export const TooltipWrapper = styled(Card)`
    width: 265px;
    padding: 17.5px 20px;

    &.MuiPaper-root {
        box-shadow: 163px 141px 86px rgba(35, 7, 110, 0.01), 92px 79px 73px rgba(35, 7, 110, 0.05),
            41px 35px 54px rgba(35, 7, 110, 0.09), 10px 9px 30px rgba(35, 7, 110, 0.1),
            0px 0px 0px rgba(35, 7, 110, 0.1);
        border-radius: 10px;
    }

    hr {
        margin: 17.5px -20px 10px -20px;
        border-color: ${({ theme }) => theme.colors.secondary300};
        border-width: 0.5px;
        border-style: solid;
        opacity: 0.1;
    }
`

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
`

export const Title = styled.div`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.primary90};
`

export const Steps = styled.div`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.primary90};
`

export const Content = styled.div`
    width: 200px;
    color: ${({ theme }) => theme.colors.primary90};

    ${getFontStyle('p02Regular')}
`

export const Actions = styled.div`
    margin-top: 20px;
    display: flex;
    justify-content: end;
    gap: 10px;
`
