import { parseYearQuarter, stringifyQueryParams } from 'utils'

import { getDiversityBreakdownByRole } from '../sagas/network'
import { Queries } from 'api/queries.enum'
import { GetDiversityBreakdownByRoleResponse } from 'api/actions/home/diversityBreakdownByRole/diversityBreakdownByRoleActions.types'

import { useQuery } from './useQuery'
import { useSurveys } from './useSurveys/useSurveys'

export const useGetDiversityBreakdownByRole = () => {
    const { survey } = useSurveys()

    const params = {
        year: parseYearQuarter(survey),
    }

    const query = useQuery<GetDiversityBreakdownByRoleResponse, Error>({
        queryKey: [Queries.getDiversityBreakdownByRole, ...Object.values(params)],
        queryFn: () => {
            return getDiversityBreakdownByRole(stringifyQueryParams(params))
        },
        enabled: survey !== null,
    })

    return query
}
