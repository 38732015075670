import { generatePath } from 'react-router-dom'

export const endpoints = {
    lumi: {
        createJobAdvert: 'lumi/company/companyUUID/job-adverts/',
        processJobAdvert: (jobUUID: string): string =>
            generatePath('lumi/company/companyUUID/job-adverts/:jobUUID/process/', { jobUUID }),
        getJobAdvertDetail: (jobUUID: string): string =>
            generatePath('lumi/company/companyUUID/job-adverts/:jobUUID/', { jobUUID }),
        deleteJobAdvert: (jobUUID: string): string =>
            generatePath('lumi/company/companyUUID/job-adverts/:jobUUID/', { jobUUID }),
        createJobAdvertItem: (jobUUID: string): string =>
            generatePath('lumi/company/companyUUID/job-adverts/:jobUUID/items/', { jobUUID }),
        getJobAdvertItemList: (jobUUID: string): string =>
            generatePath('lumi/company/companyUUID/job-adverts/:jobUUID/items/', { jobUUID }),
        uploadJobFile: (jobUUID: string, jobFileUUID: string): string =>
            generatePath('lumi/company/companyUUID/job-adverts/:jobUUID/items/:jobFileUUID/', { jobUUID, jobFileUUID }),
        deleteJobFile: (jobUUID: string, jobFileUUID: string): string =>
            generatePath('lumi/company/companyUUID/job-adverts/:jobUUID/items/:jobFileUUID/', {
                jobUUID,
                jobFileUUID,
            }),
        downloadJobAdvert: (jobUUID: string): string =>
            generatePath('lumi/company/companyUUID/job-adverts/:jobUUID/download-processed-files/', { jobUUID }),
    },
}
