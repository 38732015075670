import React, { useEffect, useState } from 'react'
import { Button, CloseIcon, EmailIcon, Tooltip, UnstableChip as Chip } from '@diversioteam/diversio-ds'

import { Accordion } from './Accordion'
import { SidePanelProps } from './sidePanel.types'
import * as S from './sidePanel.styles'
import { Message } from './Message'

export const SidePanel = (props: SidePanelProps) => {
    const {
        title,
        subtitle,
        imageUrl,
        altText,
        tags,
        certificateProgram,
        partOfCertificateProgram,
        level,
        objectives,
        linkToMarketingWebsite,
        onClose,
    } = props
    const [isMessageViewOpen, setIsMessageViewOpen] = useState(false)

    const handleOpenMessageView = () => {
        setIsMessageViewOpen(true)
    }

    const handleCloseMessageView = () => {
        setIsMessageViewOpen(false)
    }

    useEffect(() => {
        handleCloseMessageView()
    }, [title])

    return (
        <S.SidePanelWrapper>
            <Tooltip title="Close">
                <S.CloseButton color="transparent" iconOnly onClick={onClose}>
                    <CloseIcon type="bold" />
                </S.CloseButton>
            </Tooltip>

            {isMessageViewOpen && (
                <Message onCloseMessageView={handleCloseMessageView} onCloseSidePanel={onClose} trainingTitle={title} />
            )}

            {!isMessageViewOpen && (
                <>
                    <S.Container>
                        {imageUrl && (
                            <S.Image key={imageUrl}>
                                <img src={imageUrl} alt={altText} />
                            </S.Image>
                        )}

                        <S.Title>{title}</S.Title>

                        <S.Subtitle>{subtitle}</S.Subtitle>

                        <S.ChipsList>
                            {tags.includes('live') && (
                                <S.ChipsListItem>
                                    <Chip label="Live" size="xsmall" />

                                    <S.ChipDescription>
                                        Course is offered by a live instructor either in person or virtually
                                    </S.ChipDescription>
                                </S.ChipsListItem>
                            )}

                            {tags.includes('async') && (
                                <S.ChipsListItem>
                                    <Chip label="Self-paced" size="xsmall" />

                                    <S.ChipDescription>Course is offered inside eLearning portal</S.ChipDescription>
                                </S.ChipsListItem>
                            )}

                            {partOfCertificateProgram && (
                                <S.ChipsListItem>
                                    <Chip label={certificateProgram} size="xsmall" />

                                    <S.ChipDescription>
                                        This course is part of the {certificateProgram} certificate program
                                    </S.ChipDescription>
                                </S.ChipsListItem>
                            )}

                            {level && (
                                <S.ChipsListItem>
                                    <Chip label={level} size="xsmall" />

                                    <S.ChipDescription>Level of difficulty</S.ChipDescription>
                                </S.ChipsListItem>
                            )}
                        </S.ChipsList>

                        {objectives.length > 0 && (
                            <S.Objectives>
                                <S.ObjectivesTitle>Objectives</S.ObjectivesTitle>

                                {objectives.map((objective, index) => (
                                    <Accordion {...objective} key={index} />
                                ))}
                            </S.Objectives>
                        )}
                    </S.Container>

                    <S.Buttons>
                        <Button component="a" href={linkToMarketingWebsite} target="_blank" color="secondary">
                            Learn more
                        </Button>

                        <Button onClick={handleOpenMessageView} rightIcon={<EmailIcon type="line" />}>
                            Contact Sales
                        </Button>
                    </S.Buttons>
                </>
            )}
        </S.SidePanelWrapper>
    )
}
