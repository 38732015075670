import styled from 'styled-components'
import { SingleSelect as DdsSingleSelect, getFontStyle } from '@diversioteam/diversio-ds'

export const SingleSelect = styled(DdsSingleSelect)`
    .dds-input-button__placeholder {
        color: ${({ theme }) => theme.colors.secondary500};

        ${getFontStyle('p02Medium')}
    }
` as typeof DdsSingleSelect
