import React from 'react'
import { ErrorBoundary } from '@sentry/react'

import { sentryErrorBoundaryOptions } from './sentryErrorBoundaryOptions'

/**
 * For a given component props object of type `P`, if `P` includes a React ref,
 * return the ref's instance type (e.g. `HTMLDivElement`). Otherwise, return `never`.
 *
 * Similar to `React.ElementRef<C>`, but note that `React.ElementRef` accepts the type
 * of a *component* as its type argument, rather than the type of its props.
 */
type RefInstance<P> = 'ref' extends keyof P
    ? P['ref'] extends React.Ref<infer InstanceType>
        ? InstanceType
        : never
    : never

// the comma in <P,> is required; otherwise it gets interpreted as JSX,
// rather than as a type parameter
export const withErrorBoundary = <P,>(
    Component: React.ComponentType<P>,
    errorBoundaryProps?: { [key: string]: string },
): React.ForwardRefExoticComponent<React.PropsWithoutRef<P> & React.RefAttributes<RefInstance<P>>> => {
    const Wrapped = React.forwardRef<RefInstance<P>, P>((props, ref) => {
        return (
            <ErrorBoundary {...errorBoundaryProps} {...sentryErrorBoundaryOptions}>
                <Component {...props} ref={ref} />
            </ErrorBoundary>
        )
    })

    const name = Component.displayName || Component.name || 'Unknown'
    Wrapped.displayName = `withSentryErrorBoundary(${name})`

    return Wrapped
}
