import { call, put } from 'redux-saga/effects';
import { Actions, ActionTypes } from '../actions';
import { loadPainpointData, loadDiversityCompositionData, loadMetricOverviewData
    , loadFocusAreaData, loadInclusionScoreData,
    loadExecSummaryData, informPurchase, setActiveDashboard, loadEmployeeOverviewData } from './network';
import { getCompanyFilters } from './analyze';
import i18n from "../i18n";
import _ from "underscore";
// All data sagas to add to middleware.
export default [
    [
        ActionTypes.GET_PAINPOINT_DATA
        , getPainpointData
    ],
    [
        ActionTypes.GET_DIVERSITY_COMPOSITION_DATA
        , getDiversityCompositionData
    ],
    [
        ActionTypes.GET_METRIC_OVERVIEW_DATA
        , getMetricOverviewData
    ],
    [
        ActionTypes.GET_FOCUS_AREA_DATA
        , getFocusAreaData
    ],
    [
        ActionTypes.GET_INCLUSION_SCORE_DATA
        , getInclusionScoreData
    ],
    [
        ActionTypes.GET_EXEC_SUMMARY_DATA
        , getExecSummaryData
    ],
    [
        ActionTypes.DO_INFORM_PURCHASE,
        doInformPurchase
    ],
    [   ActionTypes.DO_ACTIVE_DASHBOARD,
        createActiveDashboard
    ],
    [   ActionTypes.GET_EMPLOYEE_OVERVIEW_DATA, 
        getEmployeeOverviewData 
    ],
    [
        ActionTypes.RELOAD_DASHBOARD,
        reloadDashboard
    ]
];

function * getPainpointData ({payload}) {
    yield put(Actions.loadingPainpointData());

    const response = yield call(loadPainpointData, payload);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedPainpointData(response.data));
    }
}

function * getDiversityCompositionData () {
    yield put(Actions.loadingDiversityCompositionData());

    const response = yield call(loadDiversityCompositionData);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedDiversityCompositionData(response.data));
    }
}

function * getMetricOverviewData () {
    yield put(Actions.loadingMetricOverviewData());

    const response = yield call(loadMetricOverviewData);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedMetricOverviewData(response.data));
    }
}


function * getFocusAreaData () {
    yield put(Actions.loadingFocusAreaData());

    const response = yield call(loadFocusAreaData);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedFocusAreaData(response.data));
    }
}


function * getInclusionScoreData () {
    yield put(Actions.loadingInclusionScoreData());

    const response = yield call(loadInclusionScoreData);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedInclusionScoreData(response.data));
    }
}

function * getExecSummaryData () {
    yield put(Actions.loadingExecSummaryData());

    const response = yield call(loadExecSummaryData);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedExecSummaryData(response.data));
    }
}

function * doInformPurchase () {
    yield put(Actions.doingInformPurchase());

    const response = yield call(informPurchase);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
        yield put(Actions.doneInformPurchase());
    } else {
        yield put(Actions.doneInformPurchase());
    }
}

function * getEmployeeOverviewData () {

    yield put(Actions.loadingEmployeeOverviewData());

    const response = yield call(loadEmployeeOverviewData);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedEmployeeOverviewData(response.data));
    }
}

function * createActiveDashboard({payload}) {
    yield put(Actions.loadingCompanyInformation());
    const response = yield call(setActiveDashboard, payload);
    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        // The order of the call/put matters
        yield call(getCompanyFilters)
        yield put(Actions.doneActiveDashboard())
        yield put(Actions.reloadDashboard())
    }
}

function * reloadDashboard() {
    location.reload()
}
