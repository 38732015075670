import React from "react";
import {mean, percent} from "../../utils/functions";

function capitalizeFirst(str){
    str = str[0].toUpperCase() + str.slice(1);
    return str;
}

const FocusAreaSpecification = [
    //////////////////////
    // Structural
    {
        populateData: function (gender, metadata) {
            return mean(gender.post_frequency) * metadata.calc_period_days;
        },
        focusAreaStr: (amount, moreGroup, lessGroup) => {
            amount = <span className={'highlighted'}>{amount}</span>;
            moreGroup = <span className={'highlighted'}>{moreGroup}</span>;
            lessGroup = <span>{lessGroup}</span>;
            return (
                <div>
                    On average, {moreGroup} post {amount} times more than {lessGroup}. This suggests that {moreGroup} feel more comfortable sharing their thoughts with the team.
                </div>
            )
        }
    },
    {
        populateData: function (gender) {
            return percent(gender.after_hours);
        },
        focusAreaStr: (amount, moreGroup, lessGroup) => {
            amount = <span className={'highlighted'}>{amount}</span>;
            const moreGroupCapital = <span className={'highlighted'}>{capitalizeFirst(moreGroup)}</span>;
            moreGroup = <span className={'highlighted'}>{moreGroup}</span>;
            lessGroup = <span>{lessGroup}</span>;
            return (
                <div>
                    {moreGroupCapital} send {amount} times more messages after hours than {lessGroup}. This suggests that {moreGroup} are more likely to work after hours.
                </div>
            )
        }
    },
    {
        // Calculated out of the provided channels
        populateData: function (gender) {
            return mean(gender.n_channels);
        },
        focusAreaStr: (amount, moreGroup, lessGroup) => {
            amount = <span className={'highlighted'}>{amount}</span>;
            moreGroup = <span className={'highlighted'}>{moreGroup}</span>;
            lessGroup = <span>{lessGroup}</span>;
            return (
                <div>
                    On average, {moreGroup} participate in {amount}x as many channels as {lessGroup}. This may indicate that {moreGroup} are more integrated within the organization.
                </div>
            )
        }
    },

    {
        populateData: function (gender) {
            return mean(gender.channel_size);
        },
        focusAreaStr: (amount, moreGroup, lessGroup) => {
            // amount = <span className={'highlighted'}>{amount}</span>;
            moreGroup = <span>{capitalizeFirst(moreGroup)}</span>;
            lessGroup = <span className={'highlighted'}>{lessGroup}</span>;
            return (
                <div>
                    {moreGroup} tend to participate in larger channels than {lessGroup}, which suggests that {lessGroup} are more likely to work in smaller groups.
                </div>
            )
        }
    },


    /////////////////////////
    //Tone and Content
    {
        // small change to 'average proportion'
        populateData: function (gender) {
            return percent(gender.edited);
        },
        focusAreaStr: (amount, moreGroup, lessGroup) => {
            amount = <span className={'highlighted'}>{amount}</span>;
            const moreGroupCapitalized = <span className={'highlighted'}>{capitalizeFirst(moreGroup)}</span>;
            moreGroup = <span className={'highlighted'}>{moreGroup}</span>;
            lessGroup = <span>{lessGroup}</span>;
            return (
                <div>
                    {moreGroupCapitalized} edit their own posts {amount} times more than {lessGroup}. This suggests that {moreGroup} are more self-reflective or less confident.
                </div>
            )
        }
    },
    // already included below...
    // {
    //     populateData: function (gender) {
    //         return mean(gender.n_emojis);
    //     },
    //     focusAreaStr: (amount, moreGroup, lessGroup) => {
    //         amount = <span className={'highlighted'}>{amount}</span>;
    //         const moreGroupCapitalized = <span className={'highlighted'}>{capitalizeFirst(moreGroup)}</span>;
    //         moreGroup = <span className={'highlighted'}>{moreGroup}</span>;
    //         lessGroup = <span>{lessGroup}</span>;
    //         return (
    //             <div>
    //                 {moreGroupCapitalized} use {amount} times more emojis than {lessGroup}. This could mean that {moreGroup} feel more comfortable with casual communication.
    //             </div>
    //         )
    //     }
    // },
    {
        populateData: function (gender) {
            return mean(gender.emoji_sentiment);
        },
        focusAreaStr: (amount, moreGroup, lessGroup) => {
            amount = <span className={'highlighted'}>{amount}</span>;
            const moreGroupCapitalized = <span className={'highlighted'}>{capitalizeFirst(moreGroup)}</span>;
            moreGroup = <span className={'highlighted'}>{moreGroup}</span>;
            lessGroup = <span>{lessGroup}</span>;
            return (
                <div>
                    {moreGroupCapitalized} use {amount} times more positive emojis than {lessGroup}. This may indicate that {moreGroup} feel more positive about their team and environment.
                </div>
            )
        }
    },
    // Probably don't want this, these will always show with above as same number^
    // {
    //     populateData: function (gender) {
    //         return percent(gender.emoji_sentiment);
    //     },
    //     focusAreaStr: (amount, moreGroup, lessGroup) => {
//
// amount = <span className={'highlighted}>{amount}</span>;
// moreGroup = <span className={'highlighted'}>{moreGroup}</span>;
// lessGroup = <span>{lessGroup}</span>;
//    r(
//
//   <div>
//    }eturn // {lessGroup} use ${amoutn} times more negative emojis than {moreGroup}. This suggests that {lessGroup} feel more negative about their team and environment.
//    ,
    /////////////////////////
    // Replies and Reactions
    {
        populateData: function (gender) {
            return mean(gender.n_replies);
        },
        focusAreaStr: (amount, moreGroup, lessGroup) => {
            amount = <span className={'highlighted'}>{amount}</span>;
            moreGroup = <span>{moreGroup}</span>;
            lessGroup = <span className={'highlighted'}>{lessGroup}</span>;
            return (
                <div>
                    On average, {moreGroup} receive {amount} times more replies on their posts than {lessGroup}. This could mean that that {lessGroup}’s opinions are subconsciously less valued or sought out.
                </div>
            )
        }
    },
    {
        populateData: function (gender) {
            return mean(gender.reply_speed) * 24 * 60;
        },
        focusAreaStr: (amount, moreGroup, lessGroup) => {
            amount = <span className={'highlighted'}>{amount}</span>;
            moreGroup = <span className={'highlighted'}>{moreGroup}</span>;
            lessGroup = <span>{capitalizeFirst(lessGroup)}</span>;
            return (
                <div>
                    {lessGroup} receive a reply to a post {amount} times faster than {moreGroup} on average. This may indicate that the team is generally less engaged and collaborative with {moreGroup}.
                </div>
            )
        }
    },
    {
        populateData: function (gender) {
            return mean(gender.reaction_sentiment);
        },
        focusAreaStr: (amount, moreGroup, lessGroup) => {
            amount = <span className={'highlighted'}>{amount}</span>;
            moreGroup = <span>{moreGroup}</span>;
            lessGroup = <span className={'highlighted'}>{lessGroup}</span>;
            return (
                <div>
                    On average, {moreGroup} receive {amount} times more positive reactions than {lessGroup}. This suggests that {moreGroup} are treated more positively by their team.
                </div>
            )
        }
    },
    // {
    //     populateData: function (gender) {
    //         return percent(gender.reaction_sentiment);
    //     },
    //     focusAreaStr: (amount, moreGroup, lessGroup) =>Negative Emoji Reactions Received. On average, {lessGroup} receive {amount} times more negative reactions than {moreGroup}. This suggests that {lessGroup} are treated more negatively by their team.
    // },


    /////////////////////////
    // Collaboration
    {
        populateData: function (gender, metadata) {
            return mean(gender.user_reply_frequency) * metadata.calc_period_days;
        },
        focusAreaStr: (amount, moreGroup, lessGroup) => {
            amount = <span className={'highlighted'}>{amount}</span>;
            moreGroup = <span>{moreGroup}</span>;
            lessGroup = <span className={'highlighted'}>{lessGroup}</span>;
            return (
                <div>
                    On average, {moreGroup} reply to posts {amount} times more than {lessGroup}. This suggests that {moreGroup} feel more comfortable contributing to team discussions or helping out fellow employees.
                </div>
            )
        }
    },
    {
        populateData: function (gender) {
            return mean(gender.user_reply_speed) * 24 * 60;
        },
        focusAreaStr: (amount, moreGroup, lessGroup) => {
            amount = <span className={'highlighted'}>{amount}</span>;
            moreGroup = <span>{moreGroup}</span>;
            lessGroup = <span className={'highlighted'}>{lessGroup}</span>;
            return (
                <div>
                    On average, {lessGroup} reply to posts {amount} times faster than {moreGroup}. This could mean that {lessGroup} feel more comfortable jumping in and sharing their opinion.
                </div>
            )
        }
    },
    {
        populateData: function (gender) {
            return mean(gender.n_emojis);
        },
        focusAreaStr: (amount, moreGroup, lessGroup) => {
            amount = <span className={'highlighted'}>{amount}</span>;
            const moreGroupCapitalized = <span className={'highlighted'}>{capitalizeFirst(moreGroup)}</span>;
            moreGroup = <span className={'highlighted'}>{moreGroup}</span>;
            lessGroup = <span>{lessGroup}</span>;
            return (
                <div>
                    {moreGroupCapitalized} post {amount} times more emojis than {lessGroup} on average. This may point to {moreGroup} having more informal relationships with co-workers and/or feeling more comfortable expressing sentiment.
                </div>
            )
        }
    },
    {
        populateData: function (gender) {
            return percent(gender.women_citations);
        },
        focusAreaStr: (amount, moreGroup, lessGroup) => {
            amount = <span className={'highlighted'}>{amount}</span>;
            moreGroup = <span className={'highlighted'}>{moreGroup}</span>;
            lessGroup = <span>{lessGroup}</span>;
            return (
                <div>
                    Women are tagged in posts {amount} times more by {moreGroup} than {lessGroup}. This suggests that {moreGroup} are more likely than {lessGroup} to collaborate with women.
                </div>
            )
        }
    },
];

export default FocusAreaSpecification

// provided data, populates data for each spec with calculated value
export function populateFocusAreaSpecification(data, metadata) {
    return FocusAreaSpecification.map(x => {
        x.data = Object.keys(data).reduce((acc, gender) => {
            acc[gender] = x.populateData(data[gender], metadata);
            return acc
        }, {});
        return x
    });
}
