import React, {Component} from 'react';
import slack from '../slack.png';
import './index.scss'
import {SLACK_AUTHORIZE_URL} from "../../utils/constants";

class SlackAuthenticate extends Component {
    render() {

        return (
            <div className={'slack-token-help'}>
                <div>
                    <div>
                        The Slack feature shows summary statistics based on activity in your workspace.
                        This includes an overview of employee interactions, major discrepancies between
                        genders, and a deeper dive of posts across all channels.
                    </div>
                    <div>
                        To begin,
                        <a href={SLACK_AUTHORIZE_URL}> authenticate the Diversio Slack App </a>
                    </div>
                </div>
                <div>
                    <img src={slack}/>
                </div>
            </div>
        )
    }
}

export default SlackAuthenticate;
