import { useContext } from 'react'

import { UrlStateContext } from 'context/urlState/urlStateContext/UrlStateContext'

export const useUrlState = () => {
    const context = useContext(UrlStateContext)

    if (context === undefined) {
        throw new Error('UrlStateContext must be within UrlStateContextController')
    }

    return context
}
