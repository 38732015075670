import React from 'react'
import { CompareBadge } from '@diversioteam/diversio-ds'

import { AvgImpactProgramTagProps } from './avgImpactProgramTag.types'
import * as S from './avgImpactProgramTag.styles'

export const AverageImpactProgramTag = ({ value, label }: AvgImpactProgramTagProps) => (
    <S.AverageImpactProgramTagWrapper>
        <CompareBadge type="increase" value={value} color="green" />

        <div>
            {label} <S.LabelTextSecondary>| Avg impact</S.LabelTextSecondary>
        </div>
    </S.AverageImpactProgramTagWrapper>
)
