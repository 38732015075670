import React, { useEffect, useState } from 'react'

import { useBenchmarks } from 'hooks/benchmarks/useBenchmarks'
import { BenchmarksContext } from '../benchmarksContext/BenchmarksContext'
import { BenchmarkTransformed as Benchmark } from 'api/actions/home/benchmarks/benchmarksActions.types'

import { BenchmarksContextControllerProps } from './BenchmarksContextController.types'

export const BenchmarksContextController = ({ children }: BenchmarksContextControllerProps) => {
    const [secondaryIndustryBenchmark, setSecondaryIndustryBenchmark] = useState<Benchmark>()

    const { primaryIndustryBenchmark } = useBenchmarks()

    useEffect(() => {
        if (!primaryIndustryBenchmark) {
            return
        }

        if (!secondaryIndustryBenchmark) {
            return
        }

        if (primaryIndustryBenchmark.uuid === secondaryIndustryBenchmark.uuid) {
            setSecondaryIndustryBenchmark(undefined)
        }
    }, [primaryIndustryBenchmark, secondaryIndustryBenchmark])

    const handleChangeSecondaryIndustryBenchmark = (benchmark?: Benchmark) => {
        if (benchmark) {
            return setSecondaryIndustryBenchmark(benchmark)
        }

        return setSecondaryIndustryBenchmark(undefined)
    }

    return (
        <BenchmarksContext.Provider
            value={{
                secondaryIndustryBenchmark,
                onChangeSecondaryIndustryBenchmark: handleChangeSecondaryIndustryBenchmark,
            }}
        >
            {children}
        </BenchmarksContext.Provider>
    )
}
