import React, { useCallback, useEffect, useMemo, useState } from 'react'
import mixpanel from 'mixpanel-browser'

import { useDebounce } from 'hooks/useDebounce'
import { useExportBespokeFreeTexts } from 'hooks/freeTexts/export/useExportBespokeFreeTexts'
import { useExportFreeTexts } from 'hooks/freeTexts/export/useExportFreeTexts'
import TAXONOMIES from 'utils/taxonomies'

import { FreeTextCategory } from './../freeTexts.types'
import { FreeTextsStateContext } from './FreeTextsStateContext'
import { InclusionTab } from './FreeTextsStateContext.types'
import { FreeTextsStateContextControllerProps } from './FreeTextsStateContextController.types'

export const FreeTextsStateContextController = ({ children }: FreeTextsStateContextControllerProps) => {
    const [searchValue, setSearchValue] = useState('')
    const [activeInclusionTab, setActiveInclusionTab] = useState<InclusionTab>('ALL')
    const [selectedInclusionPainPoint, setSelectedInclusionPainPoint] = useState<string | null>(null)
    const debouncedSearchValue = useDebounce(searchValue, 500)

    const mutationExportInclusionFreeTexts = useExportFreeTexts({
        search: debouncedSearchValue,
        pain_point: selectedInclusionPainPoint || undefined,
        kpi: activeInclusionTab !== 'ALL' ? activeInclusionTab : undefined,
    })

    const mutationExportBespokeFreeTexts = useExportBespokeFreeTexts({
        search: debouncedSearchValue,
        category: 'bespoke',
    })

    const mutationExportEngagementFreeTexts = useExportBespokeFreeTexts({
        search: debouncedSearchValue,
        category: 'engagement',
    })

    useEffect(() => {
        setSelectedInclusionPainPoint(null)
    }, [activeInclusionTab])

    const handleChangeSearchValue = (searchValue: string) => {
        setSearchValue(searchValue)
    }

    const handleChangeActiveInclusionTab = (inclusionTab: InclusionTab) => {
        if (inclusionTab !== 'ALL') {
            mixpanel.track(TAXONOMIES.ANALYZE_INCLUSION_FREE_TEXT_METRIC_SELECTED, {
                inclusionTab,
            })
        }

        setActiveInclusionTab(inclusionTab)
    }

    const handleChangeSelectedInclusionPainPoint = (painPoint: string | null) => {
        if (painPoint !== null) {
            mixpanel.track(TAXONOMIES.ANALYZE_INCLUSION_FREE_TEXT_PAIN_POINT_SELECTED, {
                painPoint,
            })
        }

        setSelectedInclusionPainPoint(painPoint)
    }

    const handleDownload = useCallback(
        (freeTextCategory: FreeTextCategory) => {
            if (freeTextCategory === FreeTextCategory.Inclusion) {
                return mutationExportInclusionFreeTexts.mutate()
            }

            if (freeTextCategory === FreeTextCategory.Bespoke) {
                return mutationExportBespokeFreeTexts.mutate()
            }

            if (freeTextCategory === FreeTextCategory.Engagement) {
                return mutationExportEngagementFreeTexts.mutate()
            }
        },
        [mutationExportBespokeFreeTexts, mutationExportEngagementFreeTexts, mutationExportInclusionFreeTexts],
    )

    const contextValue = useMemo(
        () => ({
            searchValue,
            debouncedSearchValue: debouncedSearchValue,
            onChangeSearchValue: handleChangeSearchValue,
            activeInclusionTab,
            onChangeActiveInclusionTab: handleChangeActiveInclusionTab,
            selectedInclusionPainPoint,
            onChangeSelectedInclusionPainPoint: handleChangeSelectedInclusionPainPoint,
            onDownload: handleDownload,
        }),
        [activeInclusionTab, debouncedSearchValue, handleDownload, searchValue, selectedInclusionPainPoint],
    )

    return <FreeTextsStateContext.Provider value={contextValue}>{children}</FreeTextsStateContext.Provider>
}
