import React from 'react'
import { getKpiLabel, KpiIcon, UnstableChip as Chip } from '@diversioteam/diversio-ds'

import { TrainingsProps } from './training.types'
import * as S from './training.styles'

export const Training = (props: TrainingsProps) => {
    const { title, imageUrl, altText, tags, level, kpis, isSelected } = props

    return (
        <S.TrainingWrapper isSelected={isSelected}>
            <S.Header>
                <div>
                    <S.Title>{title}</S.Title>

                    <S.ChipsList>
                        {tags.includes('popular') && (
                            <S.ChipsListItem>
                                <Chip label="Popular" size="xsmall" />
                            </S.ChipsListItem>
                        )}
                        {tags.includes('new') && (
                            <S.ChipsListItem>
                                <Chip label="New" size="xsmall" />
                            </S.ChipsListItem>
                        )}
                        {tags.includes('live') && (
                            <S.ChipsListItem>
                                <Chip label="Live" size="xsmall" />
                            </S.ChipsListItem>
                        )}
                        {tags.includes('async') && (
                            <S.ChipsListItem>
                                <Chip label="Self-paced" size="xsmall" />
                            </S.ChipsListItem>
                        )}

                        {level && <S.Level>{level} · Level</S.Level>}
                    </S.ChipsList>
                </div>

                <S.Image>{imageUrl && <img src={imageUrl} alt={altText} />}</S.Image>
            </S.Header>

            <S.KpisList>
                {kpis.map(({ key }) => (
                    <S.KpisListItem key={key}>
                        <KpiIcon kpi={key} size="xsmall" />

                        {getKpiLabel(key)}
                    </S.KpisListItem>
                ))}
            </S.KpisList>
        </S.TrainingWrapper>
    )
}
