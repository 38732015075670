import { useMutation, useQueryClient } from '@tanstack/react-query'
import { updateUserMetadataFeatureFlags } from 'sagas/network'

import { Mutations } from 'api/mutations.enum'
import { Queries } from 'api/queries.enum'
import { FeatureFlagUpdateRequest } from 'types/featureFlag.types'

import { useUserMetadata } from './useUserMetadata'

export const useUpdateUserMetadataFeatureFlags = () => {
    const queryClient = useQueryClient()
    const { data: userMetadata } = useUserMetadata()

    const mutation = useMutation<void, Error, FeatureFlagUpdateRequest>({
        mutationKey: [Mutations.updateUserMetadataFeatureFlags],
        mutationFn: (payload) => {
            return updateUserMetadataFeatureFlags(userMetadata?.uuid, payload)
        },
        onSuccess: async () => {
            await queryClient.refetchQueries({
                queryKey: [Queries.getUserMetadata],
            })
        },
        onError: () => {
            debugger
        },
    })

    return mutation
}
