import { InclusionScoreAnalysisCardProps } from '@diversioteam/diversio-ds'

import { GetInclusionHeatmapResponse } from 'api/actions/home/inclusionHeatmap/inclusionHeatmapActions.types'
import { GetPastSurveysResponse } from 'api/actions/home/pastSurveys/pastSurveysActions.types'

export const parseData = ({
    pastSurveysData,
    inclusionHeatMapData,
}: {
    pastSurveysData: GetPastSurveysResponse
    inclusionHeatMapData: GetInclusionHeatmapResponse[]
}): InclusionScoreAnalysisCardProps<'a'>['results'] => {
    return [...pastSurveysData.pastSurveys].reverse().map(({ yearQuarterLabel, inclusionScore }, dataIndex) => {
        const primaryBenchmark = inclusionScore.industries.find(({ isPrimary }) => isPrimary)

        return {
            yearQuarterLabel,
            score: inclusionScore.score || 'N/A',
            benchmark: {
                name: primaryBenchmark?.label || '',
                value: primaryBenchmark?.score || null,
            },
            kpis: inclusionHeatMapData[dataIndex][0].columns.map(({ key }, kpiIndex) => {
                const current = inclusionHeatMapData[dataIndex][0].data[0].scores[kpiIndex].current || 'N/A'
                const previous = inclusionHeatMapData[dataIndex][0].data[0].scores[kpiIndex].previous || 'N/A'

                return {
                    key,
                    current,
                    previous,
                }
            }),
        }
    })
}
