import React, { useCallback } from 'react'
import { Redirect, useParams } from 'react-router-dom'

import { AppRoute } from 'routing/AppRoute.enum'
import { ResetPasswordFields, ResetPasswordFormData } from 'components/Auth/ResetPassword/resetPassword.types'
import { ResetPassword } from 'components/Auth/ResetPassword'
import { useResetPassword } from 'hooks/auth/useResetPassword/useResetPassword'

import { LoginLocationState } from './../auth.types'

export const ResetPasswordContainer = () => {
    const { token } = useParams<{ token: string }>()

    const mutationResetPassword = useResetPassword()

    const handleSubmit = useCallback(
        async (values: ResetPasswordFormData): Promise<boolean> => {
            try {
                await mutationResetPassword.mutateAsync({
                    password: values[ResetPasswordFields.password],
                    password_confirm: values[ResetPasswordFields.confirmPassword],
                    token,
                })

                return true
            } catch (error) {
                return false
            }
        },
        [mutationResetPassword, token],
    )

    if (mutationResetPassword.isSuccess) {
        const state: LoginLocationState = {
            mutation: { isSuccess: mutationResetPassword.isSuccess, data: mutationResetPassword.data },
        }

        return (
            <Redirect
                to={{
                    pathname: AppRoute.Login,
                    state,
                }}
            />
        )
    }

    return (
        <ResetPassword
            isLoading={mutationResetPassword.isLoading}
            data={mutationResetPassword.data}
            isSuccess={mutationResetPassword.isSuccess}
            isError={mutationResetPassword.isError}
            error={mutationResetPassword.error}
            onSubmit={handleSubmit}
        />
    )
}
