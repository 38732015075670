import {
    GetToolsResponse,
    GetToolsResponseTransformed,
    MediaType,
} from 'api/actions/home/solutions/solutionsActions.types'

export const transformData = (data: GetToolsResponse): GetToolsResponseTransformed => {
    return data.map(({ title, category, mediaType, targetGroupsV2, url }) => {
        return {
            title,
            category,
            mediaType: mediaType.toLowerCase() as Lowercase<MediaType>,
            targetGroup: targetGroupsV2.map(({ displayName }) => displayName).join(', '),
            url,
        }
    })
}
