import { useQuery } from '@tanstack/react-query'
import { getDiversityCompanyProfiles, getInclusionCompanyProfiles } from 'sagas/network'
import { stringifyQueryParams } from 'utils'

import { AnalyzeView } from 'types/analyze.enum'
import {
    CompanyProfilesParams,
    GetCompanyProfilesResponse,
} from 'api/actions/analyze/companyProfiles/companyProfilesActions.types'
import { Queries } from 'api/queries.enum'

export const useGetCompanyProfiles = (view: AnalyzeView, params: CompanyProfilesParams) => {
    const queryKey =
        view === AnalyzeView.Diversity ? [Queries.getDiversityCompanyProfiles] : [Queries.getInclusionCompanyProfiles]
    const queryFn = view === AnalyzeView.Diversity ? getDiversityCompanyProfiles : getInclusionCompanyProfiles

    const query = useQuery<GetCompanyProfilesResponse, Error>({
        queryKey: [...queryKey, params],
        queryFn: () => {
            return queryFn(stringifyQueryParams(params))
        },
        enabled: !!params?.year,
        keepPreviousData: true,
    })

    return query
}
