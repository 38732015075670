import React from 'react';
import loadingGif from '../../../assets/images/loading.gif';
import "./index.scss";

const Loader = (props) => {
    const { inline = false } = props;
    return (
        <div className={inline ? 'loader-inline': 'loader-container'}>
            <img src={loadingGif} alt="Loading..."/>
        </div>
    );
};

export default Loader;
