import React, { forwardRef } from 'react'
import {
    IntersectionalityFilter,
    IntersectionalityFilterProps,
    IntersectionalityFilterRef,
} from '@diversioteam/diversio-ds'

import { withErrorBoundary } from 'config/withErrorBoundary/withErrorBoundary'

const IntersectionalityFiltersWithoutEB = forwardRef<IntersectionalityFilterRef, IntersectionalityFilterProps>(
    function IntersectionalityFilters(props, ref) {
        return <IntersectionalityFilter {...props} ref={ref} />
    },
)

export const IntersectionalityFilters = withErrorBoundary<
    IntersectionalityFilterProps & { ref: React.Ref<IntersectionalityFilterRef> }
>(IntersectionalityFiltersWithoutEB, {})
