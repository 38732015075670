import React from 'react'
import { ActiveProgramSkeleton } from "@diversioteam/diversio-ds"

export const ActiveProgramListSkeleton = ({ numberOfElements }) => {
  const array = new Array(numberOfElements).fill('');

  return array.map((_element, index) => {
    return (
        <ActiveProgramSkeleton key={index} />
      )
  })
}