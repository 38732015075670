import React, { useContext } from 'react';
import Select from "../../utils/Select";
import { FilterContext } from "./index";
import _ from "underscore";
import {withTranslation} from "react-i18next";

const Level = React.memo(({ label, valId = "level", onOpen = () => {}, onClose = () => {}, dataId = "levels", t }) => {
    const { data, onChange, values } = useContext(FilterContext);
    label = label || t("ANALYZE.FILTERS.LEVEL");
    if (_.isEmpty(data[dataId])) { return null; }
    return <Select
                options={data[dataId]}
                label={ label }
                onOpen={onOpen}
                onClose={onClose}
                multiple={true}
                onChange={value => onChange(valId, value)}
                selectedValues={_.deepClone(values[valId] || data[dataId])}/>
});

Level.className = "Level";

export default withTranslation()(Level);
