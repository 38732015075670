import * as Sentry from '@sentry/react';
import { RewriteFrames } from "@sentry/integrations";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();
const releaseVersion = require('../package.json').version;
const url = `${process.env.REACT_APP_DASHBOARD_API_ROOT}/configuration/`;

export default function initializeSentry() {
    Sentry.init({
        release: releaseVersion,
        dsn: process.env.REACT_APP_SENTRY_DSN,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        tracesSampleRate: 0.02,
        integrations: [
            new Sentry.Replay(),
            new RewriteFrames({root: '/'}),
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
            }),
        ],
        tracePropagationTargets: [
            "localhost",
            /^https:\/\/dashboard-api\.diversio\.com\/dashboardapp/,
            /^https:\/\/staging-dashboard-api\.diversio\.com\/dashboardapp/
        ],
        ignoreErrors: [
            'ResizeObserver loop completed with undelivered notifications.',
            'ResizeObserver loop limit exceeded',
            'TypeError: Failed to fetch',
            'TypeError: NetworkError when attempting to fetch resource.',
            'Event `Event` (type=unhandledrejection) captured as promise rejection',
            'Non-Error promise rejection captured'
        ],
    });
}
