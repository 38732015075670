import { getFontStyle } from '@diversioteam/diversio-ds'
import styled from 'styled-components'

export const Footer = styled.div`
    position: absolute;
    bottom: -12px;
    transform: translateY(100%);
    display: flex;
    gap: 4px;
`

export const Link = styled.a`
    color: ${({ theme }) => theme.colors.primary60};

    ${getFontStyle('p03Underline')}
`

export const LinksDivider = styled.span`
    color: ${({ theme }) => theme.colors.secondary500};

    ${getFontStyle('p03Medium')}
`
