import React from 'react'
import { DiversityChartCard, DiversityChartCardSkeleton } from '@diversioteam/diversio-ds'

import { useGetDiversityBreakdownByRole } from 'hooks/useGetDiversityBreakdownByRole'
import { withErrorBoundary } from 'config/withErrorBoundary/withErrorBoundary'

export const DiversityBreakdownByRoleWithoutEB = () => {
    const { data, isLoading } = useGetDiversityBreakdownByRole()

    if (isLoading) {
        return <DiversityChartCardSkeleton data-testid="diversity-breakdown-by-role-skeleton" />
    }

    if (!data || data.results.length === 0) {
        return null
    }

    return <DiversityChartCard enableAnimation data={data.results} data-testid="diversity-breakdown-by-role" />
}

export const DiversityBreakdownByRole = withErrorBoundary(DiversityBreakdownByRoleWithoutEB, {})
