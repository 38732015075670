import React from 'react';
import './index.scss';
import { COLORS } from '../constants';
import { withTranslation } from 'react-i18next';
import { kpiFormatter } from '../../../../utils';

const Legend = (props) => {
    const { selected, t } = props;
    return (
        <div className="role-breakdown__legend">
            {
                Object.values(selected).map((group, i) => {
                    return(
                        <>
                            <div 
                            className="role-breakdown__legend legend-line"
                            style={{ backgroundColor: COLORS[i] }}>
                            </div>
                            <div
                            key={i}
                            className={`role-breakdown__legend legend-group`}>
                                {kpiFormatter(group)}
                            </div>
                        </>
                    )
                })
            }
        </div>
    );
}

export default withTranslation()(Legend);
