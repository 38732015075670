import React, { Component, useState } from 'react';
import "./index.scss";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Rectangle, ResponsiveContainer } from 'recharts';
import _ from 'underscore';
import CustomizedAxisTick from '../../../utils/CustomizedAxisTick';
import * as m from '../../../../translations/mapping';
import {getTranslation, mapT} from '../../../../translations/utils';
import { withTranslation } from 'react-i18next';
import { getIndustryCompType } from '../../../../utils';
import truncate from 'lodash/truncate';

import {
    CHART_OFFSET,
    X_AXIS_HEIGHT,
    CONTAINER_HEIGHT,
    Y_OFFSET,
    CURSOR_GREY,
    CURSOR_LINE,
    CURSOR_OPACITY,
    CURSOR_LINE_OPACITY,
    CURSOR_HALF_WIDTH,
    COLORS,
    CHART_STROKE,
    IDATA_SUFFIX } from '../constants';

const MTNM = "MTNM";

const legendFormatter = (value) => {
    return truncate(mapT(m.ROLE, value), {'length': 30});
}

const CustomTooltip = ({ active, payload, showInd, colorMap, groups, activeIndex, mtnmList, t, companyName }) => {
    const _getLineInfo = (groups, id) => {
        const color = colorMap[groups.dataKey]
        const dashed = groups.payload.label.endsWith(IDATA_SUFFIX) ? true : false;
        return { color, dashed };
    }

    let compType;
    if (companyName) {
        compType = getIndustryCompType(companyName);
    }

    // if industry data is included, rearrange the data
    // so that each category is followed by its industry comparison
    let newPayload = [];
    let label = null;

    if (payload && active) {
        let indPayload = null;
        const filteredLabels = payload.filter((p) => {
            if (!(p && p["payload"])) {
                return false;
            }
            return p["payload"].label !== null});
        if (filteredLabels && filteredLabels.length > 0) {
            label = _.maybe(filteredLabels[0], "payload", "label");
        }
        if (!label) {
            label = "";
        }
        const cleanLabel = label.substring(0, label.indexOf(IDATA_SUFFIX))
        if (showInd) {
            indPayload = payload.filter((p) => (p["payload"].label.endsWith(IDATA_SUFFIX)))
        }
        const dataPayload = payload.filter((p) => !(p["payload"].label.endsWith(IDATA_SUFFIX)))

        // reassemble
        for (const g of groups) {
            const findMatchingPayload = dataPayload.filter((p) => {
                return p.dataKey == g });
            if (findMatchingPayload.length > 0) {
                newPayload.push(findMatchingPayload[0])
            } else {
                // check if on MTNM list
                if (mtnmList[activeIndex] && mtnmList[activeIndex].includes(g)) {
                    newPayload.push({
                        dataKey: g,
                        name: g,
                        value: MTNM,
                        payload: {
                            label: cleanLabel
                        }
                    })
                }
            }

            if (showInd && indPayload) {
                const findMatchingInd = indPayload.filter((p) => {
                    return p.dataKey == g });
                if (findMatchingInd.length > 0) {
                    newPayload.push(findMatchingInd[0])
                }
            }

        }
    }

    if (newPayload && newPayload[0] && active) {
        let mtnmOn = false;
        return (
            <div className="custom-tooltip role-breakdown__custom-tooltip">
                <h4 className="role-breakdown__custom-tooltip-title">{newPayload[0].payload.label || null}</h4>
                {
                    newPayload.map((groups, id) => {
                        const { color, dashed } = _getLineInfo(groups, id);
                        const labelString = (
                            dashed ?
                            (<span className="role-breakdown__tooltip-comparison-type">{
                                compType ? `${t(compType)} ${t("AVERAGE")}`: ""
                                }</span>) :
                            mapT(m.GROUP_DOM_NONDOM, groups.name)
                        );
                        if (groups.value == MTNM)
                            { mtnmOn = true; }
                        return (
                            <div className="role-breakdown__custom-tooltip-row">
                                <div className="role-breakdown__custom-tooltip-line"
                                style={{
                                    borderColor: color,
                                    borderStyle: dashed ? "dashed" : "solid" }}>
                                </div>
                                <div className={`role-breakdown__custom-tooltip-label ${groups.value == MTNM? "mtnm": ""}`}>
                                    {labelString}
                                </div>
                                <div className={`role-breakdown__custom-tooltip-value ${groups.value == MTNM? "mtnm": ""}`}>
                                    {`${groups.value == MTNM ? "" : groups.value+"%"}`}
                                </div>
                            </div>
                        )
                    })
                }
                {mtnmOn &&
                    <div className="role-breakdown__custom-tooltip__mntm-note">
                    {`*${getTranslation("ANALYZE.DIVERSITY_BREAKDOWN_BY_ROLE.MTNM_TOOLTIP", companyName)}`}
                </div>
                }

            </div>
        )
    }
    return null;
};

const CustomCursor = props => {
    const { x } = props;
    const width = CURSOR_HALF_WIDTH * 2;
    const chartHeight = CONTAINER_HEIGHT - CHART_OFFSET;
    const yOffset = Y_OFFSET;
    const xOffset = width / 2;
    return (
        <>
            <Rectangle fill={CURSOR_GREY} fillOpacity={CURSOR_OPACITY} x={x - xOffset} y={yOffset} width={width} height={chartHeight} />
            <Rectangle fill={CURSOR_LINE} fillOpacity={CURSOR_LINE_OPACITY} stroke="none" x={x-1} y={yOffset} width={2} height={chartHeight} />
        </>
    );
};

const _makeColorMap = (selected) => {
    // generate map of dataKeys to Colors, for use in the legend
    let colorMap = {};
    for (const [i, s] of selected.entries()) {
        colorMap[s] = COLORS[i]
    }
    return colorMap;
}

const DiversityLineChart = ({ selected, data, idata, showIndustry, mtnmList, companyName, t }) => {
    const [ activeXCoord, setActiveXCoord ] = useState(0);
    const [ activeIndex, setActiveIndex ] = useState(null);
    const colorMap = _makeColorMap(selected);

    const getTickWidth = (dataLen) => {
        // dynamically calculate x axis tick width
        // based on # of data points;
        // native recharts does not handle this well
        if (!dataLen || dataLen < 6) { return 200; }
        else if (dataLen === 6) { return 100; }
        else return Math.floor(500 / dataLen);
    }

    return (
        <div className="line-chart-container">
            <ResponsiveContainer width="100%" height={CONTAINER_HEIGHT}>
            <LineChart
                margin={{
                    top: 5, right: 30, left: 20, bottom: 5,
                }}
                onMouseMove={state => {
                    state.isTooltipActive &&
                    setActiveXCoord(state.activeCoordinate.x);
                    setActiveIndex(state.activeTooltipIndex);
                    }}
                onMouseLeave={() => {
                    setActiveIndex(null);
                }}
            >

                <CartesianGrid stroke={CHART_STROKE} vertical={false} />
                 <XAxis
                    dataKey="label"
                    height={X_AXIS_HEIGHT}
                    type="category"
                    allowDuplicatedCategory={false}
                    tick={<CustomizedAxisTick
                    activeIndex={activeIndex}
                    legendFormatter={legendFormatter}
                    extraClassName="role-breakdown__x-axis"
                    tickWidth={getTickWidth(data ? data.length: 0)}/>}
                    padding={{ left: CURSOR_HALF_WIDTH, right: CURSOR_HALF_WIDTH }}
                    tickLine={false}
                    axisLine={false}
                    interval={0}
                    tickMargin={10}
                />
                <YAxis
                    type="number"
                    fontSize="12"
                    tick={{fill: CURSOR_LINE}}
                    tickMargin={5}
                    width={10}
                    ticks={[20, 40, 60, 80, 100]}
                />
                <Tooltip
                offset={20}
                allowEscapeViewBox={true}
                content={<CustomTooltip
                groups={selected}
                activeIndex={activeIndex}
                showInd={showIndustry}
                colorMap={colorMap}
                mtnmList={mtnmList}
                companyName={companyName}
                t={t}
                />}
                    cursor={<CustomCursor x={activeXCoord}/>}/>
                {
                    selected.map((selectedGroup, index) =>
                        <Line
                            key={index}
                            data={data}
                            type="none"
                            dataKey={selectedGroup}
                            stroke={COLORS[index]}
                            activeDot={
                                { stroke: COLORS[index], strokeWidth: 1, r: 3, fill: COLORS[index]}
                            }
                            strokeWidth={2}
                            isAnimationActive={false}
                            dot={{ stroke: COLORS[index], strokeWidth: 1, r: 3, fill: COLORS[index]}} />
                        )
                }
                {showIndustry &&
                    selected.map((selectedGroup, index) =>
                        <Line
                            key={`${index}${IDATA_SUFFIX}`}
                            data={idata}
                            type="none"
                            dataKey={selectedGroup}
                            stroke={COLORS[index]}
                            strokeWidth={2}
                            strokeDasharray="5 3"
                            isAnimationActive={false}
                            activeDot={
                                { stroke: COLORS[index], strokeWidth: 2, r: 3, fill: '#ffffff'}
                            }
                            dot={{ strokeDasharray:"0 0", stroke: COLORS[index], strokeWidth: 2, r: 3, fill: '#ffffff'}} />
                        )
                }
            </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

export default withTranslation()(DiversityLineChart);
