import { getFontStyle } from '@diversioteam/diversio-ds'
import styled from 'styled-components'

export const Details = styled.div`
    position: absolute;
    right: 0;
    padding-right: 30px;
    text-align: right;
    color: ${({ theme }) => theme.colors.secondary500};

    ${getFontStyle('captionBold')};
`

export {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    LoadMoreButtonWrapper,
    Responses,
    Response,
} from './../../freeTexts.styles'
