import React from 'react'
import { Button } from '@diversioteam/diversio-ds'

import { SwitchModalProps } from './../versionSwitchModal.types'
import * as S from './../versionSwitchModal.styles'

export const V2SwitchModal = ({ open = false, onDismiss, onProceed }: SwitchModalProps) => {
    return (
        <S.Modal closeButtonDisabled={false} open={open}>
            <S.Image $version="v2" />

            <S.Title data-testid="v2-switch-modal-title">
                We&apos;re thrilled to unveil features that redefine your experience!
            </S.Title>

            <S.Subtitle data-testid="v2-switch-modal-subtitle">
                Your journey towards inclusive excellence just got an upgrade. Ready to explore?
            </S.Subtitle>

            <S.Actions>
                <Button color="secondary" onClick={onDismiss}>
                    Dismiss
                </Button>

                <Button onClick={onProceed}>Explore New Design</Button>
            </S.Actions>
        </S.Modal>
    )
}
