import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Actions } from 'actions'
import { Redirect } from 'react-router-dom'

import { SaveBackupCodes } from 'components/Auth/SaveBackupCodes'
import { RootState } from 'reducers/rootState.types'
import { RedirectAuthenticatedUser } from '../RedirectAuthenticatedUser'
import { AppRoute } from 'routing/AppRoute.enum'

export const SaveBackupCodesContainer = () => {
    const dispatch = useDispatch()
    const { backupCodes } = useSelector((state: RootState) => state.auth)
    const [isCopiedToClipboard, setIsCopiedToClipboard] = useState(false)
    const [hasSeenBackupCodes, setHasSeenBackupCodes] = useState(false)

    const handleCopySuccess = () => {
        setIsCopiedToClipboard(true)
        setTimeout(() => {
            setIsCopiedToClipboard(false)
        }, 3000)
    }

    const handleBackupCodeClick = (backupCode: string) => {
        navigator.clipboard.writeText(backupCode).then(handleCopySuccess)
    }

    const handleCopyAllCodesClick = (backupCodes: string[]) => {
        const codesString = backupCodes.join('\n')

        navigator.clipboard.writeText(codesString).then(handleCopySuccess)
    }

    const handleClickContinue = () => {
        setHasSeenBackupCodes(true)
    }

    if (hasSeenBackupCodes) {
        return <RedirectAuthenticatedUser />
    }

    if (!backupCodes) {
        dispatch(Actions.doSignout())
        return <Redirect to={AppRoute.Login} />
    }

    return (
        <SaveBackupCodes
            backupCodes={backupCodes}
            isCopiedToClipboard={isCopiedToClipboard}
            onClickBackupCode={handleBackupCodeClick}
            onClickCopyAllCodes={handleCopyAllCodesClick}
            onClickContinue={handleClickContinue}
        />
    )
}
