/* Style constants used in multiple places throughout the app,
but ONLY if they cannot be defined via SCSS (i.e. recharts colors).

If it can be written as SCSS, please configure in the root /styles folder!
*/

export const LINE_CHART_COLORS = [
    "#6221EA",
    "#CFB4F7",
    "#3A8EE6",
    "#6E0F74",
    "#0725E8",
    "#FBC02D",
    "#FF2455",
    "#B6BFF6",
    "#FEBECD",
];

/* Other misc constants */
const LEVEL_MAP = {
    "Vice President": "VP",
    "Entry-level/Analyst": "Entry-level",
    "Prefer not to answer": "No answer",
    "Support Staff": "Support"
}

export const levelMap = (levelStr) => {
    return LEVEL_MAP[levelStr] || levelStr;
}
