import React from 'react'
import {
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    CheckIcon,
    Dialog,
} from '@diversioteam/diversio-ds'
import { useTranslation } from 'react-i18next'

import { EnableMFAProps } from './enableMFA.types'

export const EnableMFA = ({ onClickRemindLater, onClickTurnOn2FA }: EnableMFAProps) => {
    const { t } = useTranslation()

    return (
        <Dialog open={true}>
            <DialogTitle>{t('AUTH.MFA.ENABLE_MFA_TITLE')}</DialogTitle>

            <DialogContent>
                <DialogContentText>{t('AUTH.MFA.ENABLE_MFA_DESCRIPTION')}</DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button onClick={onClickRemindLater} color="secondaryLight">
                    {t('AUTH.MFA.ENABLE_MFA_REMIND_LATER')}
                </Button>
                <Button onClick={onClickTurnOn2FA} rightIcon={<CheckIcon type="line" />}>
                    {t('AUTH.MFA.ENABLE_2FA_TURN_ON')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
