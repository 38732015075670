import React, { forwardRef, useMemo, useState } from 'react'
import {
    HeatMapPanel,
    HeatMapPanelProps,
    filterCascaderSelectedValues,
    IntersectionalityFilterRef,
    IntersectionalityFilterDeleteDialog,
    Backdrop,
    isCoreKpi,
    KpiKey,
} from '@diversioteam/diversio-ds'
import { RadialBarChartProps } from '@diversioteam/diversio-ds/dist/components/core/RadialBarChart/radialBarChart.types'
import { parseYearQuarter } from 'utils'
import _ from 'underscore'
import { useQueryClient } from '@tanstack/react-query'
import { _btoa_json_encode } from 'components/Analyze/Filters/utils'
import { ErrorBoundary } from '@sentry/react'
import { Skeleton } from '@material-ui/lab'

import { useInclusionPanelData } from 'hooks/inclusionPanelData/useInclusionPanelData'
import { useGetCompanyFilters } from 'hooks/companyFilters/useGetCompanyFilters'
import { withErrorBoundary } from 'config/withErrorBoundary/withErrorBoundary'
import { useGetCompanyProfiles } from 'hooks/companyProfiles/useGetCompanyProfiles'
import { useBenchmarks } from 'hooks/benchmarks/useBenchmarks'
import { useDeleteCompanyProfile } from 'hooks/companyProfiles/useDeleteCompanyProfile'
import { useSurveys } from 'hooks/useSurveys/useSurveys'
import { AnalyzeView } from 'types/analyze.enum'
import { Queries } from 'api/queries.enum'
import { InclusionPanelParamsProps } from 'api/actions/inclusionPanel/inclusionPanel.types'
import { useGetKpiScoreMetadata } from 'hooks/inclusion/useGetKpiScoreMetadata'

import { useFilters } from './../../../hooks/useFilters'
import { filterUnavailableData, isDataUnavailable } from './../heatmap.utils'
import { isCellProfile, isCellSideColumn, getBarChartData, getTabType } from './panel.utils'
import { HeatmapPanelColors, HeatmapTablePanelProps } from './panel.types'

import './index.scss'

const panelTableToSubtitleMapping = {
    bespokeMetrics: 'Bespoke',
    engagementMetrics: 'Engagement',
    InclusionMetrics: 'Inclusion',
}

const HeatmapTablePanelWithoutEB = forwardRef<IntersectionalityFilterRef, HeatmapTablePanelProps>(
    function HeatmapTablePanel(
        { handleClose, selectedCellData, selectedIndustry, selectedTab, setSelectedCellInfo, setPanelVisible },
        ref,
    ) {
        const selectedCell = selectedCellData.cell
        const isTargetGroupsTab = selectedTab === 'target_groups'
        const queryClient = useQueryClient()
        const {
            setFiltersOpen,
            selectedFilters,
            setSelectedFilters,
            selectedFiltersPayload,
            setProfilesActive,
            setDefaultSelectedProfileId,
            isFiltersInProfilesActive,
        } = useFilters()

        const { primaryIndustryBenchmark, secondaryIndustryBenchmark } = useBenchmarks()

        const { survey } = useSurveys()

        const [profileToDelete, setProfileToDelete] = useState<string | null>(null)
        const [profileDeleteDialogOpen, setProfileDeleteDialogOpen] = useState(false)

        const filtersInProfilesDataParams = (() => {
            if (selectedTab !== 'target_groups') {
                return {}
            }

            if (isFiltersInProfilesActive) {
                return {
                    filters_in_profiles: isFiltersInProfilesActive,
                }
            }

            return {}
        })()

        const filterParams = {
            ...selectedFiltersPayload,
            ...filtersInProfilesDataParams,
            year: parseYearQuarter(survey),
            key: selectedTab,
        }

        const profileParams = {
            year: filterParams.year,
        }

        // Filter APIs only need year as a query param
        const companyFiltersParams = useMemo(() => {
            return {
                year: parseYearQuarter(survey),
            }
        }, [survey])

        const queryGetCompanyFilters = useGetCompanyFilters(companyFiltersParams)
        const queryGetCompanyProfiles = useGetCompanyProfiles(AnalyzeView.Inclusion, profileParams)
        const mutationDeleteCompanyProfile = useDeleteCompanyProfile(AnalyzeView.Inclusion, profileParams)
        const kpiProps = {
            header: {
                kpi: {
                    kpiKey: selectedCell?.key || 'NO_KPI',
                    description: filterUnavailableData(selectedCell?.kpi?.description),
                    question: filterUnavailableData(selectedCell?.kpi?.questionTitle),
                },
            },
        }

        const isCompanyRow = selectedCellData.row?.code === 'company'
        const isBenchmarkRow = [primaryIndustryBenchmark?.uuid, secondaryIndustryBenchmark?.uuid].includes(
            selectedCellData.row?.code,
        )

        const getTargetValue = () => {
            const targetType = selectedCellData.row?.type

            if (isCompanyRow) {
                return 'company'
            } else if (isTargetGroupsTab) {
                return targetType
            } else {
                return undefined
            }
        }

        const getInclusionPanelPayload = (): InclusionPanelParamsProps => {
            return {
                tab_type: {
                    key: selectedTab,
                    type: getTabType(selectedTab, queryGetCompanyFilters.data?.filtersPane.inclusion),
                },
                row_type: {
                    code: selectedCellData.row.code,
                    type: getTargetValue(),
                },
                metrics_type: selectedCellData?.tableType,
                column_type: selectedCellData.column
                    ? {
                          key:
                              selectedCellData.tableType === 'InclusionMetrics'
                                  ? selectedCellData.column.key
                                  : undefined,
                          code:
                              selectedCellData.tableType !== 'InclusionMetrics'
                                  ? selectedCellData.column.key
                                  : undefined,
                      }
                    : undefined,
            }
        }

        const panelPayload = getInclusionPanelPayload()

        const params = useMemo(
            () => ({
                inclusion_side_panel_data: _btoa_json_encode(panelPayload),
                ...filterParams,
            }),
            [panelPayload],
        )

        const { data: kpiScoreMetadata } = useGetKpiScoreMetadata()

        const { data: panelData, isLoading: isLoadingPanelData } = useInclusionPanelData(params)

        const handleConfirmDeleteProfile = () => {
            if (!profileToDelete) {
                return
            }

            mutationDeleteCompanyProfile.mutate(profileToDelete, {
                onSuccess: async () => {
                    await queryClient.invalidateQueries({
                        queryKey: [Queries.getInclusionCompanyProfiles, profileParams],
                    })

                    setProfileDeleteDialogOpen(false)
                    setSelectedCellInfo(undefined)
                    setPanelVisible(false)
                },
            })
        }

        const getSelectedProfile = () => {
            return queryGetCompanyProfiles.data?.profiles.filter((profile) => {
                return profile.label === selectedCell?.inclusionTitle
            })[0]
        }

        const getSelectedProfileGroups = () => {
            const selectedProfile = getSelectedProfile()

            const profiles = selectedProfile?.filters.map(({ selectedOptions, ...rest }) => ({
                ...rest,
                options: selectedOptions,
            }))

            return profiles
        }

        const bespokeProps = {
            header: {
                title: filterUnavailableData(selectedCell?.kpi?.title || selectedCell?.inclusionTitle),
                helperText: filterUnavailableData(panelTableToSubtitleMapping[selectedCellData.tableType]),
            },
            bespoke: filterUnavailableData(selectedCell?.kpi?.questionTitle),
        }

        const profileSharedProps = {
            profiles: getSelectedProfileGroups(),
            profilesTitle: filterUnavailableData(selectedCell?.inclusionTitle),
            onEditProfile: () => {
                const selectedProfile = getSelectedProfile()
                setDefaultSelectedProfileId?.(selectedProfile?.uuid || '')
                setProfilesActive?.(true)
            },
            onRenameProfile: () => {
                const selectedProfile = getSelectedProfile()
                setDefaultSelectedProfileId?.(selectedProfile?.uuid || '')
                setProfilesActive?.(true)

                if (ref && typeof ref !== 'function') {
                    ref.current?.onToggleEditProfileName()
                }
            },
            onDeleteProfile: () => {
                if (queryGetCompanyProfiles.data?.profiles.length === 2) {
                    return alert('Profile cannot be deleted. At least 2 profiles are required')
                }
                const selectedProfile = getSelectedProfile()
                setProfileToDelete(selectedProfile?.uuid || '')
                setProfileDeleteDialogOpen(true)
            },
        }

        const bespokeProfileProps = {
            ...bespokeProps,
            ...profileSharedProps,
        }

        const profileProps = {
            header: {
                kpi: {
                    kpiKey: selectedCell?.key || 'NO_KPI',
                    description: filterUnavailableData(selectedCell?.kpi?.description),
                    question: filterUnavailableData(selectedCell?.kpi?.questionTitle),
                },
            },
            ...profileSharedProps,
        }

        const isBenchmarkAvailable = () => {
            const selectedBenchmark =
                selectedCell?.kpi?.benchmarks.filter((benchmark) => benchmark.label === selectedIndustry?.label)[0] ||
                selectedCell?.kpi?.benchmarks.filter((benchmark) => benchmark.isPrimary)[0] ||
                selectedCell?.kpi?.benchmarks[0]
            return !isDataUnavailable(selectedBenchmark?.score || 'N/A')
        }

        const getLegendProps = () => {
            if (!selectedCell?.kpi?.benchmarks) {
                return {}
            }

            const legendProps = {
                infoItemProps: {
                    label: 'Benchmark N/A',
                    tooltipTitle: 'Benchmark available only for Target Groups',
                },
            }

            if (!isTargetGroupsTab) {
                return legendProps
            } else if (!isBenchmarkAvailable()) {
                legendProps.infoItemProps.tooltipTitle = 'Data source unreliable'
                return legendProps
            } else {
                return {}
            }
        }

        const overviewSurveySharedProps: Pick<HeatMapPanelProps<'a'>, 'header'> = kpiProps

        const inclusionDemographicSharedProps: Pick<
            HeatMapPanelProps<'a'>,
            'header' | 'groups' | 'kpiAccordionProps'
        > = {
            header: {
                title: selectedCell?.inclusionTitle,
                helperText: selectedCell?.inclusionSecondarySubtitle,
            },
            kpiAccordionProps: kpiProps.header.kpi,
        }

        const sideCellPanelSharedProps: Pick<HeatMapPanelProps<'a'>, 'header' | 'groups'> = {
            header: {
                title: selectedCell?.inclusionTitle,
                helperText: selectedCell?.inclusionSecondarySubtitle,
            },
        }

        const getSharedProps = () => {
            if (!isCoreKpi(selectedCell?.kpi?.key as KpiKey)) {
                if (selectedCell?.profileColor) {
                    return bespokeProfileProps
                } else if (!bespokeProps.bespoke) {
                    // Selected cell is a side cell
                    return sideCellPanelSharedProps
                } else {
                    return bespokeProps
                }
            } else if (selectedCell?.profileColor) {
                return profileProps
            } else if (selectedTab === 'target_groups') {
                return overviewSurveySharedProps
            } else {
                return inclusionDemographicSharedProps
            }
        }

        const heatmapPanelColors: HeatmapPanelColors =
            panelData?.scoreBreakdown?.scaleType === 'three_point'
                ? ['green20', 'primary20', 'lobster10']
                : ['green30', 'green20', 'primary20', 'lobster10', 'lobster20', 'fuchsia10']

        const getResponses = () => {
            if (isBenchmarkRow) {
                return undefined
            }

            if (isLoadingPanelData) {
                return kpiScoreMetadata?.defaultLabels?.map((label) => ({
                    name: label,
                    value: 0,
                    color: 'primaryGray0',
                    valueRenderer: () => <Skeleton width={50} />,
                }))
            }

            const totalScores =
                panelData?.scoreBreakdown?.values?.reduce((reducer, response) => {
                    return reducer + response.value
                }, 0) || 0
            const format = panelData?.scoreBreakdown?.format

            const tableName =
                panelTableToSubtitleMapping[
                    selectedCellData?.tableType as keyof typeof panelTableToSubtitleMapping
                ]?.toLowerCase()

            return panelData?.scoreBreakdown?.values.map((response, idx) => ({
                name: response.label,
                value: format === 'count' ? Math.round((response.value / totalScores) * 100) : response.value,
                color: heatmapPanelColors[idx],
                valueRenderer: () => {
                    if (format === 'count') {
                        return response.value
                    } else {
                        return `${response.value}%`
                    }
                },
                isNoResponse: response.label === 'No Response',
                noResponseMessage: `The ${tableName} breakdown represents everyone who responded. The ${tableName} Score only contains the responses from people who identified their demographics.`,
            }))
        }

        const getDominantGroups = () => {
            const currentGroups = panelData?.dominantGroupData?.current.data?.map((group) => group.label) || []
            const previousGroups = panelData?.dominantGroupData?.previous.data?.map((group) => group.label) || []
            const isChanged = panelData?.dominantGroupData?.changed

            const groups = isChanged ? [currentGroups, previousGroups] : [currentGroups]

            return {
                groups,
                groupsTitle: 'Dominant Groups',
            }
        }

        const getDiversityGroups = () => {
            return {
                groups: [panelData?.diversityData.map(({ label }) => label) || []],
                groupsTitle: !isCellSideColumn(selectedCell) ? filterUnavailableData(selectedCell?.inclusionTitle) : '',
            }
        }

        const getDefaultGroups = () => {
            const hasTitle = selectedCell.inclusionTitle !== undefined
            const hasSubtitle =
                selectedCell.inclusionSecondarySubtitle !== undefined || selectedCell.inclusionSubtitle !== undefined

            if (!hasTitle || !hasSubtitle) {
                return
            }

            if (isTargetGroupsTab || isCompanyRow) {
                return
            }

            return {
                groups: [
                    [
                        `${selectedCell.inclusionTitle}・${(
                            selectedCell.inclusionSecondarySubtitle || selectedCell.inclusionSubtitle
                        )?.replace('Bespoke / ', '')}`,
                    ],
                ],
                groupsTitle: 'Groups',
            }
        }

        const getGroups = () => {
            if (panelData?.dominantGroupData) {
                return getDominantGroups()
            } else if (panelData?.diversityData) {
                return getDiversityGroups()
            } else {
                return getDefaultGroups()
            }
        }

        const getGroupsProps = () => {
            const groupsProps = getGroups()

            if (!groupsProps?.groups.length && isCellSideColumn(selectedCell) && !isCellProfile(selectedCell)) {
                setPanelVisible(false)
            }

            return groupsProps
        }

        const getRadialBarChartProps = (): RadialBarChartProps | undefined => {
            if (!barChartData.length) {
                return
            }

            return {
                size: 'small',
                enableAnimation: true,
                range: [0, 100],
                data: barChartData,
                legend: getLegendProps(),
            }
        }

        const sharedProps = getSharedProps()

        const barChartData = useMemo(() => {
            return getBarChartData({
                selectedCell,
                primaryIndustryBenchmark,
                secondaryIndustryBenchmark,
                selectedIndustry,
            })
        }, [selectedCell, selectedIndustry, selectedTab])

        if (!selectedCell || !panelData) {
            return null
        }

        const profileNameToDelete =
            queryGetCompanyProfiles.data?.profiles.find(({ uuid }) => uuid === profileToDelete)?.label || ''

        const getFilters = () => {
            if (isCellProfile(selectedCell) && !filtersInProfilesDataParams.filters_in_profiles) {
                return undefined
            }

            const filtersToShow = selectedFilters?.filter(({ options }) => {
                if (typeof options === 'object') {
                    if (Array.isArray(options)) {
                        return options.length > 0
                    }

                    return true
                }

                return false
            })

            if (_.isEmpty(filtersToShow)) {
                return undefined
            }

            return filtersToShow
        }

        return (
            <div className="heatmap-table-panel-container">
                {profileToDelete && (
                    <ErrorBoundary>
                        <IntersectionalityFilterDeleteDialog
                            open={profileDeleteDialogOpen}
                            profileName={profileNameToDelete}
                            onClose={() => setProfileDeleteDialogOpen(false)}
                            onConfirm={handleConfirmDeleteProfile}
                            hideBackdrop={false}
                            BackdropComponent={Backdrop}
                        />
                    </ErrorBoundary>
                )}

                <ErrorBoundary>
                    <HeatMapPanel
                        onClose={handleClose}
                        onDeleteFilter={(filter) => {
                            setSelectedFilters?.(filterCascaderSelectedValues(selectedFilters || [], filter))
                        }}
                        onClearFilters={() => setSelectedFilters?.([])}
                        onEditFilters={() => setFiltersOpen?.(true)}
                        onEditProfile={undefined}
                        radialBarChartProps={getRadialBarChartProps()}
                        {...sharedProps}
                        filters={getFilters()}
                        responses={getResponses()}
                        {...getGroupsProps()}
                    />
                </ErrorBoundary>
            </div>
        )
    },
)

export const HeatmapTablePanel = withErrorBoundary<
    HeatmapTablePanelProps & { ref: React.Ref<IntersectionalityFilterRef> }
>(HeatmapTablePanelWithoutEB, {})
