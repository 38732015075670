import React from 'react';
import {FiltersSelected} from "../../Filters";
import "./index.scss";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import {getIndustryCompType} from "../../../../utils";
import FormGroup from "@material-ui/core/FormGroup/FormGroup";
import { withTranslation } from "react-i18next";
import * as m from '../../../../translations/mapping';
import { mapT } from '../../../../translations/utils';

const Legend = ({ allFilters, selectedFilters, toggleIndustryAverage, isIndustryAverageSelected, companyName, t }) => {
    function _getConsolidatedData(data) {
        if (!data) { return; }
        let values = [];
        Object.keys(data).map(function(key, index) {
            values = values.concat(data[key])
        });
        return values.map(d => mapT(m.GROUP_BREAKDOWN, d)).join(", ");
    }

    return (
        <div id={"legend-container"}>
            <div className={"legend-left"}>
                <div className={"group-legend-a"}>{t("ANALYZE.FILTERS.GROUP_A")}: <span>{ _getConsolidatedData(selectedFilters.group_a) }</span></div>
                <p className={"group-legend-b"}>{t("ANALYZE.FILTERS.GROUP_B")}: <span>{ _getConsolidatedData(selectedFilters.group_b) }</span></p>
                {/*<FormGroup row className="role-breakdown__industry-toggle">*/}
                {/*    <FormControlLabel*/}
                {/*        control={<Switch*/}
                {/*            checked={isIndustryAverageSelected}*/}
                {/*            onChange={toggleIndustryAverage}*/}
                {/*            disableRipple*/}
                {/*            name="industrySwitch" />}*/}
                {/*        label={`${t('ANALYZE.DIVERSITY_BREAKDOWN_BY_ROLE.SHOW')} ${t(getIndustryCompType(companyName))} ${t("AVERAGE")}`}*/}
                {/*    />*/}
                {/*</FormGroup>*/}
                {/*<p>Industry Average</p>*/}
            </div>
        </div>
    );
};

export default withTranslation()(Legend);
