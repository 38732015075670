/** @format */

import { ActionTypes } from "../actions";
import _ from 'lodash';

const defaultState = {
  options: {},
  insightData: {},
  recommendedPrograms: [],
  recommendedPagination: {},
  goalCreated: false,
  endOfPrograms: false,
  isLoaded: {
    recommendedPrograms: false
  },
};

export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case ActionTypes.LOADED_SOLUTION_GOALS_OPTIONS:
        return {
          ...state,
          options: {
            ...action.payload,
            kpi_mapping: action.payload.kpi_values.map(kpi => {
              return {[kpi.name]: kpi.name}
            })
          },
        };
      case ActionTypes.LOADED_GOALS_OPTIONS_V2:
      case ActionTypes.LOADED_SOLUTION_GOALS_OPTIONS_V2:
        return {
          ...state,
          options: {
            ...action.payload,
            kpiMapping: action.payload.kpiValues.map(kpi => {
              return {[kpi.name]: kpi.name}
            })
          },
        };
      case ActionTypes.LOADED_SOLUTION_GOALS_INSIGHT_DATA:
        const insightData = {
          ...action.payload,
          mapping: {
            ...action.payload.mapping,
            score_card_mapping: _.invert(action.payload.mapping.kpi_mapping),
          }
        }
        return {
          ...state,
          insightData: insightData,
        };

      case ActionTypes.LOADED_SOLUTION_GOALS_INSIGHT_DATA_V2:
        return {
          ...state,
          insightData: {
            ...action.payload,
            mapping: {
              ...action.payload.mapping,
              scoreCardMapping: _.invert(action.payload.mapping.kpiMapping)
            }
          },
        };
      case ActionTypes.LOADED_RECOMMENDED_INSIGHTS:
        return {
          ...state,
          insightData: {
            ...action.payload,
            mapping: {
              ...action.payload.mapping,
              icon_mapping: Object.keys(action.payload.mapping.kpiMapping).map(key => ({[key]: key})),
              score_card_mapping: _.invert(action.payload.mapping.kpiMapping),
              kpi_code_map: _.invert(action.payload.mapping.kpiMapping)
            }
          },
        };
      case ActionTypes.DONE_SOLUTION_GOALS_CREATE: {
        let insightCopy = {...state.insightData };
        const values = action.payload;
        
        for (const i in insightCopy.insights) {
          const insight = insightCopy.insights[i];
          let targetGroup = null
          for(const j in insightCopy.target_groups) {
            if(insightCopy.target_groups[j].id === values.targetGroup) {
              targetGroup = insightCopy.target_groups[j]
              break
            }
          }
          if (
            insight.kpi.id === values.kpi &&
            insight.target_group === targetGroup.name
          ) {
            insight.goal_created = true;
            break;
          }
        }
        return {
          ...state,
          goalCreated: true,
          insightData: insightCopy,
        };
      }
      case ActionTypes.DONE_CREATE_GOAL: {
        let insightCopy = {...state.insightData };
        const values = action.payload;
        
        for (const i in insightCopy.insights) {
          const insight = insightCopy.insights[i];
          const targetGroupId = insightCopy.targetGroup?.[insight.targetGroup]?.id
          
          if (
            insight.kpi.id === values.kpi &&
            targetGroupId === values.targetGroup
          ) {
            insight.goalCreated = true;
            break;
          }
        }
        return {
          ...state,
          goalCreated: true,
          insightData: insightCopy,
        };
      }
      case ActionTypes.DONE_SOLUTION_GOALS_CREATE_ERROR:
        return {
          ...state,
          goalCreated: false,
        };
      case ActionTypes.LOADED_SOLUTION_GOALS_RECOMMENDED_PROGRAMS: {
        const appendedPrograms = [...state.recommendedPrograms];
        const newPrograms = action.payload.results;

        for (const i in newPrograms) {
          appendedPrograms.push(newPrograms[i]);
        }
        return {
          ...state,
          recommendedPrograms: appendedPrograms,
          recommendedPagination: action.payload,
          isLoaded: {
            recommendedPrograms: true
          },
        };
      }
      case ActionTypes.GET_SOLUTION_GOALS_RESET: {
        return {
          ...state,
          recommendedPrograms: [],
          recommendedPagination: {},
          goalCreated: false,
        };
      }
      case ActionTypes.GOALS_PAGE_ADD_PROGRAM: {
        const programID = action.payload.program;
        const newCompanySolution = action.payload.companySolution;
        const value = true;
        const programsCopy = [...state.recommendedPrograms];
        for (const i in programsCopy) {
          if (programsCopy[i].id === programID) {
            programsCopy[i].is_saved = value;
            programsCopy[i].company_solution = newCompanySolution;
            break;
          }
        }
        return {
          ...state,
          recommendedPrograms: programsCopy,
        };
      }
      case ActionTypes.GOALS_PAGE_REMOVE_PROGRAM: {
        const programID = action.payload.program;
        const value = false;
        const programsCopy = [...state.recommendedPrograms];
        for (const i in programsCopy) {
          if (programsCopy[i].id === programID) {
            programsCopy[i].is_saved = value;
            programsCopy[i].company_solution = null;
            break;
          }
        }
        return {
          ...state,
          recommendedPrograms: programsCopy,
        };
      }
      case ActionTypes.GOALS_PAGE_ACTIVATE: {
        const programID = action.payload.program.id;
        const value = true;
        const programsCopy = [...state.recommendedPrograms];
        const newCompanySolution = action.payload.companySolution;
        for (const i in programsCopy) {
          if (programsCopy[i].id === programID) {
            programsCopy[i].company_solution.is_active = value;
            programsCopy[i].company_solution = newCompanySolution;
            break;
          }
        }
        return {
          ...state,
          recommendedPrograms: programsCopy,
        };
      }
      case ActionTypes.GOALS_PAGE_DEACTIVATE: {
        const programID = action.payload.program.id;
        const value = false;
        const programsCopy = [...state.recommendedPrograms];
        for (const i in programsCopy) {
          if (programsCopy[i].id === programID) {
            programsCopy[i].company_solution.is_active = value;
            break;
          }
        }
        return {
          ...state,
          recommendedPrograms: programsCopy,
        };
      }
      case ActionTypes.END_OF_GOALS_PAGE_PROGRAMS:
        return {
          ...state,
          endOfPrograms: action.payload
        }
      default:
        return state;
    }
  } catch (error) {
    console.error(
      `Error in appState reducer: ${error.message || error.code || error}`,
      error
    );
  }
};
