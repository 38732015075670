import React, {useContext} from 'react';
import Select from '../../utils/Select';
import {FilterContext} from "./index";
import _ from 'underscore';
import {withTranslation} from "react-i18next";

const Office = React.memo(({ label , valId = "office", onOpen = () => {}, onClose = () => {}, dataId = "offices", t }) => {
    const { data, onChange, values } = useContext(FilterContext);
    label = label || t("ANALYZE.FILTERS.OFFICE");
    if (_.isEmpty(data[dataId])) { return null; }
    const MenuProps = {
        variant: "menu",
        getContentAnchorEl: null,
        classes: { paper: 'filter-dropdown-container' }
    };

    return <Select
                options={data[dataId]}
                label={ label }
                multiple={true}
                onOpen={onOpen}
                onClose={onClose}
                onChange={value => onChange(valId, value)}
                selectedValues={_.deepClone(values[valId] || data[dataId])}/>;
});

export default withTranslation()(Office);
