import { breakpoints } from './mediaQueries'

type ColumnSpacing = {
    margin: number
    gutter: number
}

export const columnsSpacing: Record<keyof typeof breakpoints, ColumnSpacing> = {
    sm: {
        margin: 24,
        gutter: 10,
    },
    md: {
        margin: 24,
        gutter: 12,
    },
    lg: {
        margin: 48,
        gutter: 24,
    },
}
