import React from 'react';
import {Button, Container} from "react-bootstrap";
import Link from '../utils/Link';
import history from "../../history";
import classNames from "classnames";
import GlobalSurveyErrors from "./GlobalSurveyErrors";
import _ from 'underscore';
import {withTranslation} from "react-i18next";

const Progress = ({ selected, handleNext, handleBack, errors, isSaving, handleReset, draftSurveys, supportedLanguages, selfServe, entity="Survey", t }) => {
    const { pathname } = history.location;
    const steps = [
        "init",
        "setup",
        "create",
        "review",
        "approved",
        "draft",
        "requires-updates",
        "launch",
        "live"
    ];
    let currentStep = steps.indexOf(pathname.split('/')[2]) + 1;

    return (
        <div className={"survey-header"}>
            <div id={"survey-progress"}>
                <Container>
                    <ul>
                        <li className={classNames("progress-step progress-step-setup", { selected: ["setup", "create", "review", "live"].includes(selected) })}>Setup</li>
                        <li className={classNames("progress-step progress-step-create", { selected: ["create", "review", "live"].includes(selected) })}>Survey Editing</li>
                        <li className={classNames("progress-step progress-step-review", { selected: ["review", "live"].includes(selected) })}>{ selfServe ? `${entity} Creation` : "Review & Approve" }</li>
                        <li className={classNames("progress-step progress-step-live", { selected: ["live"].includes(selected) })}>Survey is Live</li>
                        {
                            handleBack && <li><Link to={"#"} className={classNames({'disabled-link': isSaving})} onClick={handleBack}>Back</Link></li>
                        }
                        { handleNext && <li><Button className={"next-btn"} disabled={isSaving} onClick={handleNext}>{isSaving ? "Saving": "Next"}</Button></li>}
                        {/*<li><Link to={`/survey/${steps[currentStep - 2]}`}>Draft</Link></li>*/}
                        { draftSurveys &&  <li><Link to={"#"} className={classNames({'disabled-link': isSaving})} onClick={draftSurveys}>Save Draft</Link></li> }
                        { handleReset && <li><Link to={"#"} className={classNames({'disabled-link': isSaving})} onClick={() => {window.confirm("Are you sure you want to exit the survey?") && handleReset()}}>Cancel</Link></li> }
                    </ul>
                </Container>
            </div>
            <div className={"global-survey-errors"}>
                {
                    errors && errors.non_field_errors
                    && <GlobalSurveyErrors errors={errors}/>
                }
                {
                    !_.isEmpty(_.maybe(errors, 0))
                    && <>
                        <GlobalSurveyErrors errors={{ non_field_errors: ["Something went wrong. Please fix the errors mentioned below and try again."] }}/>
                        {
                            _.values(errors).map((surveyErrors, id) => {
                                if (_.isEmpty(surveyErrors) || _.isEmpty(_.maybe(surveyErrors, "questions"))) {
                                    return null;
                                }
                                let surveyLanguage = supportedLanguages && supportedLanguages[id];
                                let questionIdsWithErrors = _.maybe(surveyErrors, "questions").reduce((reducer, error, id) => {
                                    if (!_.isEmpty(error)) {
                                        reducer.push(id+1);
                                    }
                                    return reducer;
                                }, []);
                                return <GlobalSurveyErrors errors={{ non_field_errors: [`Error in question(s) ${questionIdsWithErrors.join(",")} in the ${t(`LANGUAGES.${surveyLanguage.toUpperCase()}`)} survey`] }}/>
                            })
                        }
                    </>
                }
            </div>
        </div>

    );
};

export default withTranslation()(Progress);
