import { ActionTypes } from '../actions';

const defaultState = {
    ilnMembers: [],
    ilnMilestones: [],
    ilnInitiatives: [],
    ilnInvestmentRegions: [],
    ilnAumBreakdown: [],
    ilnNews: [],
    ilnCalendar: [],
};

export default (state = defaultState, action) => {
    try {
        switch(action.type) {
            case ActionTypes.LOADING_ILN_MEMBERS:
                return {
                    ...state
                };
            case ActionTypes.LOADED_ILN_MEMBERS:
                return {
                    ...state,
                    ilnMembers: action.payload
                };
            case ActionTypes.LOADED_ILN_MILESTONES:
                return {
                    ...state,
                    ilnMilestones: action.payload
                };
            case ActionTypes.LOADED_ILN_INITIATIVES:
                return {
                    ...state,
                    ilnInitiatives: action.payload
                };
            case ActionTypes.LOADED_ILN_INVESTMENT_REGIONS:
                return {
                    ...state,
                    ilnInvestmentRegions: action.payload
                };
            case ActionTypes.LOADED_ILN_AUM_BREAKDOWN:
                return {
                    ...state,
                    ilnAumBreakdown: action.payload
                };
            case ActionTypes.LOADED_ILN_NEWS:
                return {
                    ...state,
                    ilnNews: action.payload
                }
            case ActionTypes.LOADED_ILN_CALENDAR:
                return {
                    ...state,
                    ilnCalendar: action.payload
                }
            case ActionTypes.DONE_SIGNOUT:
                return {
                    ...defaultState
                };
            default:
                return state;
        }
    } catch(error) {
        console.error(`Error in appState reducer: ${ error.message || error.code || error }`, error);
    };
}
