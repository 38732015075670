import { AxiosError } from 'axios'

import { GetInsightDataResponse } from 'api/actions/goals/goalsActions.types'
import { Queries } from 'api/actions/goals/queries.enum'
import { getInsightData } from 'api/actions/goals/goalsActions'
import { useQuery } from 'hooks/useQuery'

export const useGetInsightData = () => {
    const query = useQuery<GetInsightDataResponse, AxiosError>({
        queryKey: [Queries.getInsightData],
        queryFn: getInsightData,
    })

    return query
}
