import React, {Component} from 'react';
import moment from 'moment';
import Survey from "../components/Survey";
import {connect} from "react-redux";
import {Actions} from "../actions";
import _ from "underscore";
import mixpanel from "mixpanel-browser";
import TAXONOMIES from "../utils/taxonomies";

const pageCategories = {
    "Experience": 1,
    "Workforce": 2,
    "Demographic": 3
};

class SurveyContainer extends Component {
    state = {
        loader: true
    };

    componentDidUpdate(prevProps) {
        if (this.state.loader) {
            setTimeout(() => this.setState({ loader: false }), 1000);
        }
    }

    handleSetupNext (values) {
        const { selectedSurvey } = this.props;
        localStorage.setItem("is_self_serve_navigated_to_home_post_dashboard_creation", "0");
        this.setState({ loader: true });
        mixpanel.track(TAXONOMIES.CLICKED_SURVEY_SETUP_NEXT, {
            ...values,
            deployingFor: values.deployingWith,
            supportedLanguages: values.language,
        })
        if (selectedSurvey) {
            const payload = {
                ...selectedSurvey,
                deploying_for: values.deployingWith,
                supported_languages: values.language,
                survey_type: values.surveyType,
                download_responses: values.downloadResponses,
                employee_count: values.employeeCount,
                draft: true,
            };
            this.props.doEditSurveyInfo(payload);
        } else {
            const payload = {
                deploying_for: values.deployingWith,
                supported_languages: values.language,
                survey_type: values.surveyType,
                download_responses: values.downloadResponses,
                employee_count: values.employeeCount,
                name: `${this.props.company}'s Diversity & Inclusion Survey`,
            };
            this.props.doSaveSurveyInfo(payload);
        }
    }

    handleSetupBack (values) {
        this.props.setCurrentSelectedSurveyPage("init");
        mixpanel.track(TAXONOMIES.CLICKED_SURVEY_SETUP_BACK)
    }

    _createSurveyPayload (data) {
        if (!data.surveys) {
            return data;
        }

        return {
            ...data,
            surveys: _.isEmpty(data.surveys) ? null : data.surveys.map(survey => {
                return {
                    ...survey,
                    questions: survey.questions && [...survey.questions.map(question => {
                        return {
                            ...question,
                            page_category: pageCategories[question.page_category],
                            choices: question.choices ? [
                                ...question.choices.filter(choice => !!choice.label)
                            ] : []
                        };
                    })]
                }
            })
        }
    }

    saveSurvey (options) {
        const { selectedSurvey } = this.props;

        const payload = this._createSurveyPayload({
            ...selectedSurvey,
            ...options
        });

        if (["DRF", "UPF", "UFF"].includes(selectedSurvey.status)) {
            this.props.doEditSurveys(payload);
        } else {
            this.props.doSaveSurveys(payload);
        }
    }

    handleCreateNext () {
        if (window.confirm("Once you make the survey live, you cannot make any changes. Would you like to proceed?")) {
            this.setState({ loader: true });
            this.saveSurvey({ draft: false })
            mixpanel.track(TAXONOMIES.CLICKED_SURVEY_CREATE_NEXT)
        }
    }

    handleCreateBack () {
        this.props.setCurrentSelectedSurveyPage("setup");
        mixpanel.track(TAXONOMIES.CLICKED_SURVEY_CREATE_BACK)
    }

    handleDraftSurveys() {
        this.setState({ loader: true });
        this.saveSurvey({ draft: true })
        mixpanel.track(TAXONOMIES.CLICKED_SAVE_DRAFT)
    }

    handleSurveyReset () {
        mixpanel.track(TAXONOMIES.CLICKED_CANCEL_BUTTON, {
            from: this.props.currentSelected
        })
        if (this.props.currentSelected !== "setup") {
            this.props.doResetSurveyInfo(this.props.selectedSurvey.id);
        } else {
            this.props.resetSurveyInfo();
        }
    }

    handleSurveyClose () {
        if (window.confirm("Once you close the survey you'll not be able to set it back to live. Would you like to proceed?")) {
            const payload = this._createSurveyPayload({
                ...this.props.selectedSurvey,
                status: "CLS"
            });

            this.props.doEditSurveyInfo(payload);
            mixpanel.track(TAXONOMIES.CLICKED_CLOSE_SURVEY)
        }
    }

    componentWillUnmount() {
        this.props.resetSurveyInfo();
        window.onbeforeunload = () => {};
    }

    componentDidMount() {
        mixpanel.track(TAXONOMIES.VIEW_PAGE, {
            name: "Survey",
        })

        window.onbeforeunload = (e) => {
            if (!this.props.isSurveySaved && !["live", "processing"].includes(this.props.currentSelected)) {
                return window.confirm("Are you sure you want to exit? All of your unsaved changes will be gone")
            }
        };
    }

    render() {
        return <>
            <Survey
                handleSetupBack={this.handleSetupBack.bind(this)}
                handleSetupNext={this.handleSetupNext.bind(this)}
                handleCreateNext={this.handleCreateNext.bind(this)}
                handleCreateBack={this.handleCreateBack.bind(this)}
                handleReset={this.handleSurveyReset.bind(this)}
                handleClose={this.handleSurveyClose.bind(this)}
                draftSurveys={this.handleDraftSurveys.bind(this)}
                defaultLoader={this.state.loader}
                { ...this.props }/>
        </>
    }
}

const mapStateToProps = state => {
    return {
        ...state.survey,
        ...state.profile,
    }
};

export default connect(mapStateToProps, Actions)(SurveyContainer);
