import React from 'react'
import { YourGoalsCardSkeleton } from "@diversioteam/diversio-ds"

export const YourGoalsListSkeleton = ({ numberOfElements }) => {
  const array = new Array(numberOfElements).fill('');

  return (
    <>
      <li className="your-goals-list-item">
        <YourGoalsCardSkeleton selected />
      </li>
      {
        array.map((_element, index) => {
          return (
            <li className="your-goals-list-item" key={index}>
              <YourGoalsCardSkeleton />
            </li>
          )
        })
      }
    </>
  )
}