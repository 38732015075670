import React from 'react'
import { Button, DownloadIcon, EditIcon } from '@diversioteam/diversio-ds'
import mixpanel from 'mixpanel-browser'

import { useDownloadJobAdvert } from 'hooks/lumi/useDownloadJobAdvert'
import TAXONOMIES from 'utils/taxonomies'

import { useLumi } from './../hooks/useLumi'
import * as S from './completedCard.styles'

export const CompletedCard = () => {
    const { jobId, onResetStage } = useLumi()
    const { mutate } = useDownloadJobAdvert()

    const onClickDownloadButton = () => {
        mixpanel.track(TAXONOMIES.LUMI_DOWNLOAD_JOB_AD)
        mutate(jobId)
    }

    const onClickNewProject = () => {
        mixpanel.track(TAXONOMIES.LUMI_NEW_PROJECT)
        onResetStage()
    }

    return (
        <S.CompletedCard>
            <S.Title>Your job posts are ready</S.Title>

            <S.Buttons>
                <Button onClick={onClickDownloadButton} rightIcon={<DownloadIcon type="line" />} color="primary">
                    Download
                </Button>

                <Button color="secondary" onClick={onClickNewProject} rightIcon={<EditIcon type="line" />}>
                    New Project
                </Button>
            </S.Buttons>
        </S.CompletedCard>
    )
}
