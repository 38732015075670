import { BarChartDataItem, ChartTableProps } from '@diversioteam/diversio-ds'

type Company = {
    label: string
    score: number
}

type BenchmarkItem = {
    label: string
    score: number
    uuid: string
    isActive: boolean
    isPrimary: boolean
}

export enum GroupIdentifier {
    DominantNonDominantGroup = 'dominant_non_dominant_group',
    NonDominantGroupBreakdownData = 'non_dominant_group_breakdown_data',
    NonDominantGroupBreakdownDataMultiple = 'non_dominant_group_breakdown_data_multiple',
    AdditionalIdentifiers = 'additional_identifiers',
}

export enum AdditionalGroupIdentifier {
    Transgender = 'transgender',
    Pnta = 'pnta',
    Indigenous = 'indigenous',
}

export type Result = {
    group: string
    company: Company
    nationalBenchmarks?: BenchmarkItem[]
    industries?: BenchmarkItem[]
    totalGroupRepresentatives: number
    totalParticipants: number
    subRows?: Result[]
    groupDetails?: {
        groupIdentifier: GroupIdentifier
        additionalGroupIdentifier?: AdditionalGroupIdentifier
    }
}

export type AlertItem = {
    identity: string
    label: string
    description: string
}

export type Alerts = {
    messages: AlertItem[]
    errors: AlertItem[]
}

export type DiversityDataResponse = {
    group: string
    alerts: Alerts
    results: Result[]
    year: number
    quarter: 1 | 2 | 3 | 4
    yearQuarterLabel?: string
    subtitle?: string
    helpText?: string
}

export interface TableData {
    group?: string
    subRows?: Omit<TableData, 'subRows'>[]
    [key: string]: string | number | Omit<TableData, 'subRows'>[] | undefined
}

export type TransformedBarChartDataItem = BarChartDataItem & Pick<Result, 'groupDetails'>

export type TransformedData = DiversityDataResponse & {
    chartResults: TransformedBarChartDataItem[]
    tableResults: ChartTableProps<TableData>
}

export type DiversityDataParams = {
    value: string
    type?: string
    year?: string
    export_format?: string
}
