export const convertTo24Hour = (time12h: string): string => {
    try {
        const [time, period] = time12h.split(' ')

        if (!time || !period) {
            throw new Error('Invalid time format')
        }

        const [hours, minutes] = time.split(':')
        let hoursNumeric: number = parseInt(hours, 10)
        const minutesNumeric: number = parseInt(minutes, 10)

        if (isNaN(hoursNumeric) || isNaN(minutesNumeric)) {
            throw new Error('Invalid time format')
        }

        if (hoursNumeric < 1 || hoursNumeric > 12) {
            throw new Error('Invalid hour value')
        }

        if (minutesNumeric < 0 || minutesNumeric >= 60) {
            throw new Error('Invalid minute value')
        }

        if (period !== 'AM' && period !== 'PM') {
            throw new Error('Invalid period format')
        }

        if (period === 'PM' && hoursNumeric !== 12) {
            hoursNumeric += 12
        } else if (period === 'AM' && hoursNumeric === 12) {
            hoursNumeric = 0
        }

        const hoursFormatted: string = hoursNumeric.toString().padStart(2, '0')
        const minutesFormatted: string = minutes.padStart(2, '0')

        return `${hoursFormatted}:${minutesFormatted}+00:00`
    } catch (error) {
        return '00:00+00:00'
    }
}
