import { Placement } from 'react-joyride'

const rightStart: Placement = 'right-start'
const topStart: Placement = 'top-start'
const leftStart: Placement = 'left-start'

export const getSteps = (hasMultiplePastSurveys: boolean) => {
    return [
        {
            target: '[data-joyride="inclusion-score"]',
            content: hasMultiplePastSurveys
                ? 'View your overall progress with your Inclusion Score, and click the arrow to expand and dive deeper!'
                : "Measure your organization's overall inclusion efforts and click the arrow to view your results over time!",
            title: 'Inclusion Score',
            disableBeacon: true,
            placement: rightStart,
        },
        {
            target: '[data-joyride="home-benchmark-switch"]',
            content: 'Adjust the sector you are benchmarking against',
            title: 'Benchmark Your Score',
            placement: rightStart,
        },
        {
            target: '[data-joyride="diversity-breakdown"]',
            content: 'Dive into representation across multiple levels and scroll down to analyze employee experience ',
            title: 'High-Level Breakdown',
            placement: topStart,
        },
        {
            target: '[data-joyride="side-panel"]',
            content: 'Stay up-to-date with important days of recognition, DEI events, trending resources, and more',
            title: 'Inclusive Content',
            placement: leftStart,
        },
    ]
}
