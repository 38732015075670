import React from 'react';
import './index.scss';

export default (props)=>{
    const className = `${props.className || ''} card`;
    return (
        <div className={className} style={props.style}>
            {props.children}
        </div>
    )
};
