import { KpiKey } from '@diversioteam/diversio-ds'

import { PainPoint } from 'types/common'

export const getCleanedKpiCodes = (painPoints: PainPoint[]) => {
    const kpis = painPoints?.map((point) => point.kpi?.code)
    const uniqKpis = new Set(kpis)

    const keys = kpis.filter((key) => key !== 'NO_KPI')

    return keys.length > 0 ? (Array.from(uniqKpis) as [KpiKey] | [KpiKey, KpiKey]) : undefined
}
