import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ToastProps } from './toast.types'
import * as S from './toast.styles'

export const Toast = ({ isLoading, isSuccess, data, isError, error, translationPrefix }: ToastProps) => {
    const { t } = useTranslation()

    const [isToastOpen, setIsToastOpen] = useState(false)

    useEffect(() => {
        if (isSuccess || isError) {
            setIsToastOpen(true)
        }

        if (isLoading) {
            setIsToastOpen(false)
        }
    }, [isLoading, isSuccess, isError])

    const getSuccessMessage = () => {
        const message = data?.data?.message || data?.detail || ''
        const translatedMessage = t([`${translationPrefix}.${message}`, message])

        return translatedMessage
    }

    const getErrorMessage = () => {
        const message =
            error?.response?.data.detail ||
            error?.response?.data.error?.message ||
            error?.response?.data.errors?.__all__?.join(', ') ||
            'AUTH.ERROR_UNSPECIFIC'
        const translatedMessage = t([`${translationPrefix}.${message}`, message])

        if (error?.response?.status === 429 && message) {
            const time = message.match(/[0-9]+/)

            if (time) {
                const timeout = Math.ceil(Number(time) / 60)

                return t('AUTH.MFA.MESSAGES.TOO_MANY_REQUESTS', { timeout })
            }
        }

        return translatedMessage
    }

    const handleCloseToast = () => {
        setIsToastOpen(false)
    }

    const renderToast = () => {
        if (isSuccess) {
            return (
                <S.Toast open={isToastOpen} message={getSuccessMessage()} onClick={handleCloseToast} type="success" />
            )
        }

        if (isError) {
            return <S.Toast open={isToastOpen} message={getErrorMessage()} onClick={handleCloseToast} type="error" />
        }
    }

    return <S.ToastBox>{renderToast()}</S.ToastBox>
}
