import { diversioApiV2 } from 'api/axios/axiosInstance'

import { GetKpiScoreMetadata } from './kpiScoreMetadataActions.types'
import { endpoints } from './endpoints'

export const getKpiScoreMetadata = async (): Promise<GetKpiScoreMetadata> => {
    const response = await diversioApiV2.get(endpoints.kpiScoreMetadata)

    return response.data
}
