import React, { useEffect, useState } from 'react'
import Lottie from 'react-lottie'
import { AlertIcon, Button, CheckmarkCircleIcon, colors } from '@diversioteam/diversio-ds'

import { FileMetadata, ProcessingStatus, UploadStatus } from 'components/Lumi/context/LumiContext.types'
import * as loadingCircle from 'assets/lottie/lumi/loadingCircle.json'
import { useDeleteJobFile } from 'hooks/lumi/useDeleteJobFile'
import { useLumi } from 'components/Lumi/hooks/useLumi'

import * as S from './acceptedFile.styles'

export const AcceptedFile = ({
    acceptedFile,
    onChangeProcessing,
    onRemoveFile,
    ...props
}: {
    acceptedFile: FileMetadata
    onChangeProcessing: (value: boolean) => void
    onRemoveFile: () => void
}) => {
    const [isLoading, setIsLoading] = useState(true)
    const mutation = useDeleteJobFile()
    const { jobId } = useLumi()

    useEffect(() => {
        if (acceptedFile.uploadStatus === UploadStatus.Loading) {
            setIsLoading(true)
            onChangeProcessing(true)
        } else {
            setIsLoading(false)
            onChangeProcessing(false)
        }
    }, [acceptedFile.uploadStatus, onChangeProcessing])

    const hasFailed = acceptedFile.processingStatus === ProcessingStatus.Failed

    const handleRemoveAcceptedFile = async () => {
        try {
            const uuid = acceptedFile.uuid
            if (uuid) {
                await mutation.mutateAsync({
                    jobId: jobId,
                    jobFileId: uuid,
                })
                onRemoveFile()
            }
        } catch (error) {
            console.error('Failed to remove file:', error)
        }
    }

    const bytesToSize = (bytes: number) => {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']

        if (bytes === 0) return 'n/a'

        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString(), 10)

        if (i === 0) return `${bytes} ${sizes[i]}`

        return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`
    }

    const renderIcon = () => {
        if (isLoading) {
            return (
                <Lottie
                    options={{
                        loop: 1,
                        autoplay: true,
                        animationData: loadingCircle,
                        rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                        },
                    }}
                />
            )
        }

        if (hasFailed) {
            return <AlertIcon type="line" color={colors.tangerine50} />
        }

        return <CheckmarkCircleIcon color={colors.primary40} />
    }

    return (
        <S.AcceptedFile hasFailed={hasFailed} {...props}>
            <S.IconWrapper>{renderIcon()}</S.IconWrapper>

            <S.Details>
                <S.Path>{acceptedFile.path}</S.Path>
                <S.Size>
                    {hasFailed
                        ? 'We’re having issues with this file. Remove it to proceed.'
                        : bytesToSize(acceptedFile.file.size)}
                </S.Size>
            </S.Details>

            <S.Buttons>
                <Button color="secondaryError" onClick={handleRemoveAcceptedFile}>
                    Remove
                </Button>
            </S.Buttons>
        </S.AcceptedFile>
    )
}
