import { useContext } from 'react'

import { LumiContext } from '../context/LumiContext'

export const useLumi = () => {
    const context = useContext(LumiContext)

    if (context === undefined) {
        throw new Error('LumiContext must be within LumiContextController')
    }

    return context
}
