import { loadInspirationalQuotes } from 'sagas/network'

import { Queries } from 'api/queries.enum'
import { GetInspirationalQuotesResponse } from 'api/actions/home/inspirationalQuotes/inspirationalQuotesActions.types'

import { useQuery } from './useQuery'

export const useGetInspirationalQuotes = () => {
    const query = useQuery<GetInspirationalQuotesResponse, Error>({
        queryKey: [Queries.getInspirationalQuotes],
        queryFn: loadInspirationalQuotes,
    })

    return query
}
