import { call, put } from 'redux-saga/effects';
import { Actions, ActionTypes } from '../actions';
import { submitResponse, saveEmail, loadEmailResponse } from './network';

// All data sagas to add to middleware.
export default [
    // [ActionTypes.LOAD_DATA, loadData]
    [ActionTypes.DO_SUBMIT_RESPONSE, doSubmitResponse],
    [ActionTypes.DO_SAVE_EMAIL, doSaveEmail],
    [ActionTypes.GET_EMAIL_RESPONSE, getEmailResponse]
];

function * doSubmitResponse ({ payload }) {
    yield put(Actions.doingSubmitResponse());

    const response = yield call(submitResponse, payload);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.doneSubmitResponse(response.data));
    }
}

function * doSaveEmail ({ payload }) {
    yield put(Actions.doingSaveEmail());

    const response = yield call(saveEmail, payload);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.doneSaveEmail(response.data));
    }
}

function * getEmailResponse ({ payload }) {
    yield put(Actions.loadingEmailResponse());

    const response = yield call(loadEmailResponse, payload);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedEmailResponse(response.data));
    }
}
