import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { getBespokeFreeTexts } from 'sagas/network'
import { stringifyQueryParams } from 'utils'
import { useRef } from 'react'

import { Queries } from 'api/queries.enum'
import {
    GetBespokeFreeTextsParams,
    GetBespokeSingleQuestionResponse,
} from 'api/actions/analyze/freeTexts/freeTextsActions.types'
import { useSurveys } from 'hooks/useSurveys/useSurveys'
import { getNextPageParam } from 'utils/getNextPageParam'
import { preventRefetchQueryOptions } from 'utils/queryOptions'

export const useGetBespokeFreeTextsSingleQuestion = (
    defaultParams?: GetBespokeFreeTextsParams,
    options?: { enabled?: boolean },
) => {
    const queryInitialPage = useRef(defaultParams?.page || 1)
    const queryClient = useQueryClient()
    const { survey } = useSurveys()

    const selectedSurveyParams = survey
        ? {
              year: survey.year,
              quarter: survey.quarter,
          }
        : {}

    const params: GetBespokeFreeTextsParams = { ...selectedSurveyParams, ...defaultParams }

    const hasBespokeFreeTextsQueryData = !!queryClient.getQueryData([
        Queries.getBespokeFreeTexts,
        { ...params, bespoke_question: undefined },
    ])

    const query = useInfiniteQuery<GetBespokeSingleQuestionResponse, Error>({
        queryKey: [Queries.getBespokeFreeTexts, params],
        queryFn: ({ pageParam = queryInitialPage.current }) =>
            getBespokeFreeTexts(stringifyQueryParams({ ...params, page: pageParam })),
        getNextPageParam: ({ next }) => getNextPageParam(next),
        enabled: hasBespokeFreeTextsQueryData && options?.enabled,
        ...preventRefetchQueryOptions,
    })

    return query
}
