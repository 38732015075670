import React, {Component} from 'react';
import Form, {FormInputTypes} from '../../../utils/Form';
import SimpleBarChart from "../../utils/BarChart";
import './index.scss'
import Card from '../../../utils/Card';
import {populateChartSpecification} from "./ChartSpecification";
import {categoryChoices, discrepancyChoices} from '../../utils/constants'
import {formatDate} from "../../utils/functions";
import Tooltip from '@material-ui/core/Tooltip';


const subheadings = {
    [categoryChoices.Structural]: 'Structural indicators of how and when employees engage',
    [categoryChoices.ToneAndContent]: 'Tone and content of employee communications',
    [categoryChoices.RepliesAndReactions]: 'Insights on message replies and reactions',
    [categoryChoices.Collaboration]: 'Indicators of team collaboration'
};

const tooltipClasses = {
    tooltip: {
        backgroundColor: 'white',
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: 11,
    },
};

function TooltipPopup() {
    const emptyBoxStyle = {
        width: "12px",
        height: '12px',
        background: "rgba(196, 196, 196, 0.38)",
        border: "0.5px dashed rgba(102, 104, 106, 0.38)",
        boxSizing: "border-box",
        display: 'inline-block',
        margin: '0px 2px',
    };
    return <div>
        Data that does not meet the anonymity threshold for a group is displayed as N/A (
        <div style={emptyBoxStyle}>{"   "}</div>
        ).
    </div>
}

class DeepDive extends Component {
    state = {
        filters: {
            category: categoryChoices.Structural,
            discrepancy: discrepancyChoices.highestToLowest
        }
    };

    getCategoryFormInputs() {
        return [
            [
                {
                    name: 'category',
                    label: 'Category',
                    type: FormInputTypes.select,
                    defaultValue: this.state.filters.category,
                    properties: {options: Object.values(categoryChoices).map(x => ({label: x, value: x}))},
                    required: false
                },
                {
                    name: 'discrepancy',
                    label: 'Discrepancy',
                    type: FormInputTypes.select,
                    defaultValue: this.state.filters.discrepancy,
                    properties: {options: Object.values(discrepancyChoices).map(x => ({label: x, value: x}))},
                    required: false
                },
            ]
        ]
    }

    applyFilters({category, discrepancy}) {
        this.setState({filters: {category, discrepancy}})
    }

    chartFilter(chartSpec) {
        return chartSpec.category === this.state.filters.category;
    }

    render() {
        const chartSpec = populateChartSpecification(this.props.data, this.props.metadata)
            .filter(this.chartFilter.bind(this));

        return (
            <Card>
                <div className={'deep-dive'}>
                    <h2>Deep Dive</h2>
                    <div className={'subtitle'}>
                        <p>Employee interactions categorized based on assessment type.</p>
                        <div>
                            <Tooltip title={<TooltipPopup/>} classes={{tooltip: 'deepdive-tooltip'}} placement="top">
                                <div>Tips</div>
                            </Tooltip>
                        </div>
                    </div>
                    <div className={'filters'}>
                        <Form
                            inputs={this.getCategoryFormInputs()}
                            onSubmit={this.applyFilters.bind(this)}
                            hideSubmit
                        />
                    </div>
                    <div className="header-info">
                        <div className="left">
                            <div>{subheadings[this.state.filters.category]}</div>
                        </div>
                        <div className="right">
                            <div>
                                {`${formatDate(this.props.metadata.cutoff)} - ${formatDate(this.props.metadata.latest)} | ${this.props.metadata.channels.join(", ")}`}
                            </div>
                        </div>
                    </div>
                    <div className={'graphs'}>
                        {chartSpec.map((x, index) => <SimpleBarChart key={"graph_deepdive_index" + index}
                                                                     order={this.state.filters.discrepancy} {...x} />)}
                    </div>
                </div>
            </Card>
        );
    }
}

export default DeepDive;
