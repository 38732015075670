export const toASCII = (text: string) =>
    Array.from(text)
        .map((char) => {
            let c = char.charCodeAt(0)

            if (c >= 0xff00 && c <= 0xffef) {
                c = 0xff & (c + 0x20)
            }

            return String.fromCharCode(c)
        })
        .join('')
