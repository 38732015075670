import React, { useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'

import { UrlState } from '../urlStateContext/UrlStateContext.types'

import { UrlStateContext } from './../urlStateContext/UrlStateContext'
import { UrlStateContextControllerProps } from './UrlStateContextController.types'

const parseSearchParams = (urlState: string) => ({ ...queryString.parse(urlState) })
const stringifySearchParams = (object: UrlState) => queryString.stringify(object)

export const UrlStateContextController = ({ children }: UrlStateContextControllerProps) => {
    const history = useHistory()

    const { search } = useLocation()

    const searchParams = useMemo(() => new URLSearchParams(search), [search])

    const urlState = parseSearchParams(searchParams.toString())

    const setUrlState = (newState: UrlState, options?: { replace: boolean }) => {
        const currentSearchParams = parseSearchParams(searchParams.toString())

        const mergedSearchParams = {
            ...(options?.replace ? {} : currentSearchParams),
            ...newState,
        }

        history.replace({
            pathname: location.pathname,
            search: stringifySearchParams(mergedSearchParams),
        })
    }

    const removeFromUrlState = (propertyKey: string) => {
        const newSearchParamsObject = { ...parseSearchParams(searchParams.toString()) }

        delete newSearchParamsObject[propertyKey]

        history.replace({
            pathname: location.pathname,
            search: stringifySearchParams(newSearchParamsObject),
        })
    }

    return (
        <UrlStateContext.Provider value={{ urlState, setUrlState, removeFromUrlState }}>
            {children}
        </UrlStateContext.Provider>
    )
}
