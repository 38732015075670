import React from 'react';
import classNames from 'classnames';
import './index.scss';
import Tooltip from '@material-ui/core/Tooltip';

export const PiePlaceholder = ({ isLoading }) => {
    let classes = ["animated-background", "piechart-placeholder", {"loading": isLoading}];
    return (
        <Tooltip title="There is insufficient data to show you the demographic breakdown for these groups"
            placement="top">
            {/* <div className={ classNames(classes) }>
                <div className="background-masker round-thumb"></div>
                <div className="background-masker title-top"></div>
                <div className="background-masker title-bottom"></div>
                <div className="background-masker legend-top"></div>
                <div className="background-masker legend-bottom"></div>
                <div className="background-masker legend-top"></div>
                <div className="background-masker legend-bottom"></div>
                <div className="background-masker legend-top"></div>
                <div className="background-masker legend-bottom"></div>
            </div> */}
            <div className="pieplaceholder-container">
                <svg width="211" height="270" viewBox="0 0 211 302" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect y="221" width="211" height="23" fill="#DADADA" fillOpacity="0.54"/>
                    <rect y="250" width="211" height="23" fill="#DADADA" fillOpacity="0.54"/>
                    <rect y="279" width="211" height="23" fill="#DADADA" fillOpacity="0.54"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M100.866 142.459C140.004 142.459 171.732 110.568 171.732 71.2293C171.732 31.8904 140.004 0 100.866 0C61.7277 0 30 31.8904 30 71.2293C30 110.568 61.7277 142.459 100.866 142.459Z" fill="#DADADA" fillOpacity="0.54"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M101.259 94.5171C114.104 94.5171 124.517 84.1039 124.517 71.2585C124.517 58.4132 114.104 48 101.259 48C88.4132 48 78 58.4132 78 71.2585C78 84.1039 88.4132 94.5171 101.259 94.5171Z" fill="white"/>
                </svg>
            </div>
        </Tooltip>
    );
};

// export default Placeholders;
