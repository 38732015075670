import React, { useEffect } from 'react';
import { Nav } from 'react-bootstrap';
import Link from '../utils/Link';
import {Column, Row} from "../utils/FlexBox";
import history from "../../history";
import Diversity from "./Diversity";
import Inclusion from "./Inclusion";
import _ from 'underscore';
import Loader from "../utils/Loader";
import {withTranslation} from "react-i18next";
import { isCifarGlobalCall, isCifarScholars, isCifarDLRL, isCifarGlobalCallOverall, isBessemerBriefPortfolio, isBessemerAnonymized, isCaiPortfolio, isOntarioTeachersPensionPlanBoardOTPP, isMitacsBoard, isBMCWinterSchool, isCifarSolutionsNetwork, isTrueVentures, IsAltasPortfolio, isMila, isBCIApplicants, isBCI, isBCIStudents, isClydeCo} from '../../utils/companyChecks'
import { getCompanyRelativePathName } from 'sagas/util';
import './index.scss'
import { generateCompanyURI } from 'sagas/helpers/generateCompanyURI';
import { ViewTracking } from 'hoc/ViewTracking';
import { useUserMetadata } from 'hooks/metadata/useUserMetadata';
import { getActiveCompany } from 'utils';

const Analyze = (props) => {
    const { data: userMetadata } = useUserMetadata();
    const { pathname } = history.location;
    const { t } = props;

    useEffect(() => {
        if (getCompanyRelativePathName(history.location.pathname) === "/analyze") {
            history.replace("/analyze/diversity");
        }
    }, [pathname]);

    const renderComp = (pathname) => {
        switch(pathname) {
            case '/analyze/diversity':
                return (
                    <ViewTracking viewTrackingName="Analyze v1 - Diversity">
                        <Diversity {...props} />
                    </ViewTracking>
                );
            case '/analyze/inclusion':
                return (
                    <ViewTracking viewTrackingName="Analyze v1 - Inclusion">
                        <Inclusion { ...props } />
                    </ViewTracking>
                );
        }
    };

    if (_.isEmpty(props.analyze.filters.years)) {
        return <Loader />
    }

    const isCustomInclusion = () => {
        const companyName = getActiveCompany(userMetadata?.companies)?.name
        const isCustom = (
        isOntarioTeachersPensionPlanBoardOTPP(companyName) 
        || isCifarScholars(companyName) 
        || isCifarGlobalCall(companyName) 
        || isCifarDLRL(companyName) 
        || isCifarGlobalCallOverall(companyName) 
        || isBessemerBriefPortfolio(companyName) 
        || isBessemerAnonymized(companyName) 
        || isCaiPortfolio(companyName) 
        || isMitacsBoard(companyName) 
        || isBMCWinterSchool(companyName) 
        || isCifarSolutionsNetwork(companyName) 
        || isTrueVentures(companyName) 
        || IsAltasPortfolio(companyName) 
        || isMila(companyName)
        || isBCIApplicants(companyName)
        || isBCI(companyName)
        || isBCIStudents(companyName)
        || isClydeCo(companyName)
        );

        if (isCustom) {
            return true
        }

        return false;
    }
    return (
        <div>
            <div className="App">
                <Row>
                    <Column grow={1}>
                        <h1 className={"transparent-header analyze-title"}>{t("ANALYZE.TITLE")}</h1>
                    </Column>
                </Row>
                <Row>
                    <Column grow={1}>
                        <div className={"bootstrap-override"}>
                            <Nav className={"analyze-nav"} justify variant="tabs" defaultActiveKey="/analyze/diversity">
                                <Nav.Item>
                                    <Nav.Link active={getCompanyRelativePathName(history.location.pathname) === "/analyze/diversity"} as={Link} to={generateCompanyURI("/analyze/diversity")}>{t("ANALYZE.TABS.DIVERSITY")}</Nav.Link>
                                </Nav.Item>
                                {isCustomInclusion() ? null :
                                <Nav.Item>
                                    <Nav.Link active={getCompanyRelativePathName(history.location.pathname) === "/analyze/inclusion"} as={Link} to={generateCompanyURI("/analyze/inclusion")}>{t("ANALYZE.TABS.INCLUSION")}</Nav.Link>
                                </Nav.Item>
                                }
                            </Nav>
                        </div>
                    </Column>
                </Row>

                {
                    renderComp(getCompanyRelativePathName(history.location.pathname))
                }
            </div>
        </div>
    );
};

export default withTranslation()(Analyze);
