import { useLocation } from 'react-router-dom'

import { generateCompanyURI } from 'sagas/helpers/generateCompanyURI'

export const useIsAllowedPath = () => {
    const location = useLocation()

    const isAllowedPath = (allowedPaths: string[]) => {
        return allowedPaths.some((path) => location.pathname.includes(generateCompanyURI(path)))
    }

    return { isAllowedPath }
}
