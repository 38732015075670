/** @format */

import { ActionTypes } from "../actions";

const defaultState = {
  insights: [],
  programs: [],
  goalOptions: [],
  clientGoals: [],
  pagination: {},
  goalCreated: false,
  endOfRecommendedPrograms: false,
  isLoading: {
    programs: false,
    insights: false
  },
  isLoadingMore: {
    programs: false
  }
};

export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case ActionTypes.LOADING_SOLUTION_RECOMMENDED_INSIGHTS:
      case ActionTypes.LOADING_RECOMMENDED_INSIGHTS:
        return {
          ...state,
          isLoading: {
            ...state.isLoading,
            insights: true
          }
        };
      case ActionTypes.LOADING_SOLUTION_RECOMMENDED_PROGRAMS:
      case ActionTypes.LOADING_RECOMMENDED_PROGRAMS:
        return {
          ...state,
          isLoading: {
            ...state.isLoading,
            programs: true
          }
        };
      case ActionTypes.LOADING_SOLUTION_MORE_RECOMMENDED_PROGRAMS:
      case ActionTypes.LOADING_MORE_RECOMMENDED_PROGRAMS: {
        return {
          ...state,
          isLoadingMore: {
            ...state.isLoading,
            programs: true
          }
        };
      }
      case ActionTypes.LOADED_SOLUTION_RECOMMENDED_INSIGHTS:
      case ActionTypes.LOADED_RECOMMENDED_INSIGHTS:
        return {
          ...state,
          insights: action.payload,
          isLoading: {
            ...state.isLoading,
            insights: false
          }
        };
      case ActionTypes.LOADED_SOLUTION_RECOMMENDED_PROGRAMS:
      case ActionTypes.LOADED_RECOMMENDED_PROGRAMS:
        return {
          ...state,
          programs: action.payload.results,
          pagination: action.payload,
          isLoading: {
            ...state.isLoading,
            programs: false
          }
        };
      case ActionTypes.LOADED_SOLUTION_MORE_RECOMMENDED_PROGRAMS:
      case ActionTypes.LOADED_MORE_RECOMMENDED_PROGRAMS: {
        const appendedPrograms = [
          ...state.programs,
          ...action.payload.results
        ];

        return {
          ...state,
          programs: appendedPrograms,
          pagination: action.payload,
          isLoadingMore: {
            ...state.isLoading,
            programs: false
          }
        };
      }
      case ActionTypes.LOADED_SOLUTION_RECOMMENDED_CREATE_GOAL:
        return {
          ...state,
        };
      case ActionTypes.LOADED_SOLUTION_RECOMMENDED_GOAL_OPTIONS:
        return {
          ...state,
          goalOptions: action.payload,
        };
      case ActionTypes.LOADED_SOLUTION_RECOMMENDED_CLIENT_GOALS:
        return {
          ...state,
          clientGoals: action.payload,
        };
      case ActionTypes.DONE_SOLUTION_RECOMMENDED_GOALS_CREATE: {
        let insightCopy = { ...state.insights };
        const values = action.payload;

        for (const i in insightCopy.insights) {
          const insight = insightCopy.insights[i];
          if (
            insight.kpi.id === values.kpi &&
            insight.target_group === values.targetGroup
          ) {
            insight.goal_created = true;
            break;
          }
        }
        return {
          ...state,
          insights: insightCopy,
          goalCreated: true,
        };
      }
      case ActionTypes.DONE_CREATE_GOAL: {
        let insightCopy = {...state.insights };
        const values = action.payload;
        
        for (const i in insightCopy.insights) {
          const insight = insightCopy.insights[i];
          const targetGroupId = insightCopy.targetGroup?.[insight.targetGroup]?.id
          
          if (
            insight.kpi.id === values.kpi &&
            targetGroupId === values.targetGroup
          ) {
            insight.goalCreated = true;
            break;
          }
        }
        return {
          ...state,
          goalCreated: true,
          insights: insightCopy,
        };
      }
      case ActionTypes.RECOMMENDED_PAGE_ADD_PROGRAM: {
        const programID = action.payload.program;
        const newCompanySolution = action.payload.companySolution;
        const value = true;
        const programsCopy = [...state.programs];
        for (const i in programsCopy) {
          if (programsCopy[i].id === programID) {
            programsCopy[i].is_saved = value;
            programsCopy[i].company_solution = newCompanySolution;
            break;
          }
        }
        return {
          ...state,
          programs: programsCopy,
        };
      }
      case ActionTypes.DONE_SAVE_RECOMMENDED_PROGRAM_V2: {
        const programID = action.payload.program;
        const newCompanySolution = action.payload.companySolution;
        const value = true;
        const programsCopy = [...state.programs];
        for (const i in programsCopy) {
          if (programsCopy[i].id === programID) {
            programsCopy[i].isSaved = value;
            programsCopy[i].companySolution = newCompanySolution;
            break;
          }
        }
        return {
          ...state,
          programs: programsCopy,
        };
      }
      case ActionTypes.RECOMMENDED_PAGE_REMOVE_PROGRAM: {
        const programID = action.payload.program;
        const value = false;
        const programsCopy = [...state.programs];
        for (const i in programsCopy) {
          if (programsCopy[i].id === programID) {
            programsCopy[i].is_saved = value;
            programsCopy[i].company_solution = null;
            break;
          }
        }
        return {
          ...state,
          programs: programsCopy,
        };
      }
      case ActionTypes.DONE_REMOVE_RECOMMENDED_SOLUTION_V2: {
        const programsCopy = state.programs.map(program => {
          if (program.companySolution?.id === action.payload.solution) {
            return {
              ...program,
              isSaved: false,
              companySolution: null
            }
          }

          return program
        });

        return {
          ...state,
          programs: programsCopy,
        };
      }
      case ActionTypes.RECOMMENDED_PAGE_ACTIVATE: {
        const programID = action.payload.program.id;
        const value = true;
        const programsCopy = [...state.programs];
        for (const i in programsCopy) {
          if (programsCopy[i].id === programID) {
            programsCopy[i].company_solution.is_active = value;
            programsCopy[i].company_solution = newCompanySolution;
            break;
          }
        }
        return {
          ...state,
          programs: programsCopy,
        };
      }
      case ActionTypes.RECOMMENDED_PAGE_DEACTIVATE: {
        const programID = action.payload.program.id;
        const value = false;
        const programsCopy = [...state.programs];
        for (const i in programsCopy) {
          if (programsCopy[i].id === programID) {
            programsCopy[i].company_solution.is_active = value;
            break;
          }
        }
        return {
          ...state,
          programs: programsCopy,
        };
      }
      case ActionTypes.DONE_CHANGE_RECOMMENDED_SOLUTION_STATUS:
        const programsCopy = state.programs.map(program => {
          if (program.companySolution?.id === action.payload.id) {
            return {
              ...program,
              companySolution: {
                ...action.payload
              }
            }
          }

          return program
        });

        return {
          ...state,
          programs: programsCopy,
        };
      case ActionTypes.RECOMMENDED_PAGE_RESET_GOAL_CREATED:
        return {
          ...state,
          goalCreated: false,
        };
      case ActionTypes.RESET_RECOMMENDED_PAGE:
        return {
          ...state,
          insights: [],
          programs: [],
          goalOptions: [],
          clientGoals: [],
          pagination: {},
          goalCreated: false,
          endOfRecommendedPrograms: false
        };
      case ActionTypes.END_OF_RECOMMENDED_PROGRAMS:
        return {
          ...state,
          endOfRecommendedPrograms: action.payload
        }
      
      case ActionTypes.RESET_PROGRAMS_AND_SOLUTIONS:
        return {
          ...defaultState
        }
      default:
        return state;
    }
  } catch (error) {
    console.error(
      `Error in appState reducer: ${error.message || error.code || error}`,
      error
    );
  }
};
