import React, {Component} from 'react';
import {PieChart, Cell, Pie, Sector} from "recharts";
import './index.scss'

const pieColors = ['#6221EA', '#cfb4f7', '#d7cee4'];

const renderActiveShape = (props) => {
    const {
        cx, cy, innerRadius, outerRadius, startAngle, endAngle,
        fill, payload
    } = props;

    return (
        <g>
            <text fontSize="20" x={cx} y={cy} dy={0} textAnchor="middle" fill={'black'}>{`${payload.value}`}</text>
            <text x={cx} y={cy} dy={16} textAnchor="middle" fill={'black'}>{`${payload.name}`}</text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
        </g>
    );
};

function PieLegend(props) {
    return (
        <div className={'pie-legend'}>
            {props.title}
            <ul>
                {props.items.map((x, index) => {
                    return (
                        <li key={index}
                            className={index === props.activeIndex ? 'active' : ''}
                            onMouseEnter={()=>{props.onMouseEnter(undefined, index)}}
                        >
                            <div className={'dot'} style={{backgroundColor: x.color}}/>
                            <span style={{float: 'left'}}>{x.name}</span>
                            <span style={{float: 'right'}}>{x.value}</span>
                        </li>
                    )
                })}
            </ul>
        </div>
    );
}


class CustomPieChart extends Component {

    state = {
        activeIndex: 0
    };

    getFormattedData() {
        const sums = this.props.data.reduce((acc, val) => {
            acc[val] = acc[val] === undefined ? 1 : acc[val] + 1;
            return acc;
        }, {});
        return Object.keys(sums).map((name, index) => {
            return {name: name, value: sums[name], color: pieColors[index % pieColors.length]}
        })
    }

    onPieEnter = (data, index) => {
        this.setState({
            activeIndex: index,
        });
    };

    render() {
        const data = this.getFormattedData();
        const sampleSize = data.reduce((acc, v) => {
            return acc + v.value
        }, 0);
        const piLegendData = [...data].map(x => {
            return Object.assign({}, x, {value:`${Math.round(x.value*100/sampleSize)}%`});
        });
        const legend = PieLegend({onMouseEnter:this.onPieEnter.bind(this), items: piLegendData, title: this.props.title, activeIndex: this.state.activeIndex});
        return (
            <div className={'summary-graph'} style={this.props.style}>
                <PieChart width={220} height={this.props.height}>
                    <Pie
                        data={data}
                        dataKey={'value'}
                        nameKey={'name'}
                        innerRadius={50}
                        outerRadius={100}
                        fill="#8884d8"
                        activeIndex={this.state.activeIndex}
                        activeShape={renderActiveShape}
                        onMouseEnter={this.onPieEnter.bind(this)}
                    >
                        {data.map((datum, index) => {
                            return <Cell key={index} fill={datum.color}/>
                        })}
                    </Pie>
                </PieChart>
                {legend}
            </div>
        )
    }
}

export default CustomPieChart;
