import React from 'react';
import './index.scss';
import { getIndustryCompType } from '../../../../utils';
import { withTranslation } from 'react-i18next';

const Legend = ({ companyName, t }) => {
    const compType = getIndustryCompType(companyName);

    return (
        <div className="perf-time-legend">
            <div className="perf-time-legend__company">{companyName}</div>
            <div className="perf-time-legend__industry-average">{`${t(compType)} ${t("AVERAGE")}`}</div>
        </div>
    );
}

export default withTranslation()(Legend);
