import React from 'react';
import { DashboardTooltip } from '../../../utils/DashboardTooltip';
import './index.scss';
import { MIN_DELTA } from '../../../../utils/comparison';
import { withTranslation } from 'react-i18next';
import * as m from '../../../../translations/mapping';
import { mapT } from '../../../../translations/utils';
import { getIndustryCompType } from '../../../../utils';
import _ from 'underscore';
import plusArrow from '../../../../images/arrows/plus.svg';
import minusArrow from '../../../../images/arrows/minus.svg'

const DiversityCompositionDetails = (props) => {
    const { t, companyName, detailData: data, profile } = props;

    if (!data) {
        return null;
    }
    
    const isSprucegrove = companyName == "Sprucegrove" || companyName == "Sprucegrove Shareholders"

    const compType = getIndustryCompType(companyName);

    const generateGroupLabel = () => {
        if (!(data.ndList.length > 0)) {
            return <div className="div_comp_detail__small-text">{`${mapT(m.GROUP_DOM_NONDOM, data.domKey.nd)}`}</div>
        }

        return (
            <DashboardTooltip content={
                generateCategoryText(data.domKey.nd, data.ndList)
                }>
                <div className="div_comp_detail__small-text">{`${mapT(m.GROUP_DOM_NONDOM, data.domKey.nd)}`}</div>
            </DashboardTooltip>
        );
    }
    
    const generateCategoryText = (ndg, ndList) => {
        const text = `${mapT(m.GROUP_DOM_NONDOM, ndg)} ${t("HOME.DIVERSITY_COMPOSITION.includes_indiv")}:`
        
        const bullets = (
            <ul className="div_comp_detail__tooltip_list">
                {ndList.map(i => (<li className="div_comp_detail__tooltip_bullet" key={i}>{mapT(m.GROUP_BREAKDOWN, i)}</li>))}
            </ul>
        );

        return (
            <span>
                <p className="div_comp_detail__tooltip_heading_text">{text}</p>
                {bullets}
            </span>
        );
    }

    const generateArrowText = (preposition, ndg) => {
        const text = `${t("HOME.DIVERSITY_COMPOSITION.you_are", {
            "aboveBelow": t(`HOME.DIVERSITY_COMPOSITION.ABOVE_BELOW.${preposition}`)
        })} ${mapT(m.GROUP_DOM_NONDOM, ndg)}.`;
        return (
                <p>{text}</p>
        );
    }

    const generateArrow = (value = data.data.nd, comp = data.idata.nd) => {
        const above = (value >= (comp + MIN_DELTA))
        const below = (value <= (comp - MIN_DELTA))
        let arrow;
        let preposition;

        if (above) {
            arrow = plusArrow;
            preposition = "above";
        } else if (below) {
            arrow = minusArrow;
            preposition = "below";
        } else {
            // equal
            return null;
        }

        const tooltipText = generateArrowText(preposition, data.domKey.nd);

        return (
                <DashboardTooltip content={tooltipText}>
                    <div className={"div_comp_detail__arrow_img"}>
                        <img src={arrow}/>
                    </div>
                </DashboardTooltip>
        );
    }

    const formatPercentOrEmpty = (data) => {
        if (`${data}` === "undefined") {
            return "";
        }
        return `${data}%`;
    }

    
    const dataDClass = `div_comp_detail__data_circle div_comp_detail__d ${data.data.d ? '' : 'div_comp_detail__undefined'}`;
    const dataNdClass = `div_comp_detail__data_circle div_comp_detail__nd ${data.data.nd ? '' : 'div_comp_detail__undefined'}`;
    const dataPntsClass = `div_comp_detail__data_circle div_comp_detail__pnts ${data.data.pnts ? '' : 'div_comp_detail__undefined'}`;

    // Used to keep the ordering consistent accross different dashboards
    // "Women", "Racial & Ethnic Minorities" "LGBTQ+2", "Disability / mental health identified" will always be the center row
    // in the legend
    const domGroup = data.domKey.nd === 'Male' || data.domKey.nd === 'Heterosexual' || data.domKey.nd === 'White' || data.domKey.nd === 'Persons without disability'
    const showIndustryData = !(data.type == "Ethnicity" && data.domKey.d !== "White") || isSprucegrove

    return (
        <div className="div_comp_detail">
            <div className="div_comp_detail__header_row">
                <div className="div_comp_detail__type_col"><div className="div_comp_detail__small-text">{mapT(m.GROUP, data.type)}</div></div>
                <div className="div_comp_detail__arrow"></div>
                <div className="div_comp_detail__top_label_col"><div className="div_comp_detail__company_name div_comp_detail__small-text">{companyName}</div></div>
                {showIndustryData && 
                <div className="div_comp_detail__top_label_col"><div className="div_comp_detail__industry_avg div_comp_detail__small-text">
                    {`${t(compType)} ${t("AVERAGE")}`}
                </div></div>}
            </div>
            <div className="div_comp_detail__data_row">
                <div className="div_comp_detail__label_col"><div className="div_comp_detail__small-text">{`${mapT(m.GROUP_MISC, data.domKey.pnts)}`}</div></div>
                <div className="div_comp_detail__arrow"></div>
                <div className="div_comp_detail__data_col">
                    <div className={dataPntsClass}><div className="div_comp_detail__small-text">{formatPercentOrEmpty(data.data.pnts)}</div>
                    </div>
                </div>
                {showIndustryData && 
                <div className="div_comp_detail__data_col">
                    <div className="div_comp_detail__data_circle div_comp_detail__ipnts"><div className="div_comp_detail__small-text">{formatPercentOrEmpty(data.idata.pnts)}</div></div>
                </div>}
            </div>
            {domGroup ?
                <div className="div_comp_detail__data_row">
                    <div className="div_comp_detail__label_col"><div className="div_comp_detail__small-text">{`${(profile.email == 'amazing_investor_demo_2@diversio.com' && data.domKey.d == "White") ?
                            'Dominant group' :
                            mapT(m.GROUP_DOM_NONDOM, data.domKey.d)
                        }`}</div></div>
                    <div className="div_comp_detail__arrow"></div>
                    <div className="div_comp_detail__data_col">
                        <div className={dataDClass}><div className="div_comp_detail__small-text">{formatPercentOrEmpty(data.data.d)}</div>
                        </div>
                    </div>
                    <div className="div_comp_detail__data_col">
                        <div className="div_comp_detail__data_circle div_comp_detail__id"><div className="div_comp_detail__small-text">{formatPercentOrEmpty(data.idata.d)}</div></div>
                    </div>
                </div> :
                <div className="div_comp_detail__data_row">
                    <div className="div_comp_detail__label_col">
                        {generateGroupLabel()}
                    </div>
                    <div className="div_comp_detail__arrow">{showIndustryData && generateArrow()}</div>
                    <div className="div_comp_detail__data_col">
                        <div className={dataNdClass}><div className="div_comp_detail__small-text">{formatPercentOrEmpty(data.data.nd)}</div>
                        </div>
                    </div>
                    {showIndustryData &&
                    <div className="div_comp_detail__data_col">
                        <div className="div_comp_detail__data_circle div_comp_detail__ind"><div className="div_comp_detail__small-text">{formatPercentOrEmpty(data.idata.nd)}</div></div>
                    </div>}
                </div>}
            {domGroup ? 
            <div className="div_comp_detail__data_row div_comp_detail__data_row_last">
                    <div className="div_comp_detail__label_col">
                        {generateGroupLabel()}
                    </div>
                    <div className="div_comp_detail__arrow">{generateArrow()}</div>
                    <div className="div_comp_detail__data_col">
                        <div className={dataNdClass}><div className="div_comp_detail__small-text">{formatPercentOrEmpty(data.data.nd)}</div>
                        </div>
                    </div>
                    <div className="div_comp_detail__data_col">
                        <div className="div_comp_detail__data_circle div_comp_detail__ind"><div className="div_comp_detail__small-text">{formatPercentOrEmpty(data.idata.nd)}</div></div>
                    </div>
                </div>
            :<div className="div_comp_detail__data_row div_comp_detail__data_row_last">
                <div className="div_comp_detail__label_col"><div className="div_comp_detail__small-text">{`${
                    (profile.email == 'amazing_investor_demo_2@diversio.com' && data.domKey.d == "White") ? 
                    'Dominant group' :
                    mapT(m.GROUP_DOM_NONDOM, data.domKey.d)
                    }`}</div></div>
                <div className="div_comp_detail__arrow"></div>
                <div className="div_comp_detail__data_col">
                    <div className={dataDClass}><div className="div_comp_detail__small-text">{formatPercentOrEmpty(data.data.d)}</div>
                    </div>
                </div>
                {showIndustryData && 
                <div className="div_comp_detail__data_col">
                    <div className="div_comp_detail__data_circle div_comp_detail__id"><div className="div_comp_detail__small-text">{formatPercentOrEmpty(data.idata.d)}</div></div>
                </div>}
            </div>}
        </div>
    );
};

export default withTranslation()(DiversityCompositionDetails);
