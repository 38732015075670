export const DATA_COLOR = {
    "dom": "#6221EA",
    "nondom": "#9169ED",
    "pnts": "#D0BEF7",
    "min_thresh": "#ebecf0",
    "min_thresh_fill_opacity": "0.68",
    "min_thresh_stroke": "#322352",
    "min_thresh_stroke_opacity": "0.38",
}

export const IDATA_COLOR = {
    "dom": "#1032E4",
    "nondom": "#536BEC",
    "pnts": "#B6BFF6",
    "min_thresh": "#ebecf0",
    "min_thresh_stroke": "#322352",
    "min_thresh_stroke_opacity": "0.38",
    "fill_opacity": "0.87",
}

export const BAR = {
    size: 42,
    gap: 12,
}

export const GRID = {
    line: "#EBECF0",
    lineWidth: 2,
}
