import React from 'react';
import Card from '../../../utils/Card';
import './index.scss'

const CalendarLegend = ({ events, showAnimation }) => {
    const arr = []

    for (const i in events) {
        if (!events[i]["category"]) continue

        const obj = {
            "category_name": events[i]["category"]["category_name"],
            "color": events[i]["category"]["color"]
        }
        arr.push(obj)
    }

    // Remove duplicate objects with the same Category Name
    const cleanArray = [...new Map(arr.map(item => [item['category_name'], item])).values()]

    return (
        <Card className={"iln-calendar-legend-card iln-card " + showAnimation}>
            <h2 className={showAnimation + "-text"}>Legend</h2>
            <br />
            {cleanArray && cleanArray.map((row, index) => {
                return (
                    <div className={"iln-legend-item " + showAnimation + "-text"} key={index}>
                        <span className="dot" style={{ backgroundColor: row.color }} />
                        <p>{row.category_name}</p>
                    </div>
                )
            })}
        </Card>
    )
}

export default CalendarLegend