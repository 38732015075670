import { useMutation } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { AxiosError } from 'axios'
import { Actions } from 'actions'

import { Mutations } from 'api/mutations.enum'
import { AuthErrorResponse, LoginResponse, SsoCallbackPayload } from 'api/actions/auth/authActions.types'
import { ssoCallbackAction } from 'api/actions/auth/authActions'
import { handleAuthCredentials } from 'utils/handleAuthCredentials'

export const useSsoCallback = () => {
    const dispatch = useDispatch()

    const mutation = useMutation<LoginResponse, AxiosError<AuthErrorResponse>, SsoCallbackPayload>({
        mutationKey: [Mutations.ssoCallback],
        mutationFn: ssoCallbackAction,
        onSuccess: (response) => {
            dispatch(Actions.doneSsoCallback(handleAuthCredentials(response)))
        },
    })

    return mutation
}
