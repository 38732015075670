import React, { useState } from 'react'

import { BreadcrumbsContext } from '../breadcrumbsContext/BreadcrumbContext'
import { BreadcrumbLink } from '../breadcrumbsContext/BreadcrumbContext.types'

import { BreadcrumbsContextControllerProps } from './BreadcrumbsContextController.types'

export const BreadcrumbsContextController = ({ children }: BreadcrumbsContextControllerProps) => {
    const [links, setLinks] = useState<BreadcrumbLink[]>([])

    return (
        <BreadcrumbsContext.Provider
            value={{
                links,
                setLinks,
            }}
        >
            {children}
        </BreadcrumbsContext.Provider>
    )
}
