import { getFontStyle } from '@diversioteam/diversio-ds'
import styled from 'styled-components'

export const ProgressBar = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 998;
`

export const BarBackground = styled.div`
    background-color: ${({ theme }) => theme.colors.primaryGray2};
    width: 100%;
    height: 32px;
    position: relative;
    overflow: hidden;
`

export const BarFill = styled.div`
    padding: 4px 12px;
    background-color: ${({ theme }) => theme.colors.green30};
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    height: 100%;
    text-align: right;
    transition: width 1s linear;
    align-items: center;
    color: ${({ theme }) => theme.colors.primary70};
    z-index: 10;
    position: relative;

    ${getFontStyle('p02Medium')};
`

export const Info = styled.div`
    position: absolute;
    top: 4px;
    right: 12px;
    z-index: 0;
    color: ${({ theme }) => theme.colors.primary70};

    ${getFontStyle('p02Medium')};
`
