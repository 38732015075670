import { ActionTypes } from '../actions';
import { getKpis } from './analyze';

const defaultState = {
    bespokeDemographics: [],
    inclusionMetrics: [],
    previousInclusionMetrics: [],
};


export default (state = defaultState, action) => {
    try {
        switch(action.type) {
            case ActionTypes.LOADING_BESPOKE_DEMOGRAPHICS:{
                return {
                    ...state,
                    isLoading: true
                };
            }
            case ActionTypes.LOADED_BESPOKE_DEMOGRAPHICS:{
                return {
                    ...state,
                    bespokeDemographics: action.payload,
                    isLoading: false,
                };
            }
            case ActionTypes.LOADING_INCLUSION_METRICS_DATA_BESPOKE: {
                return {
                    ...state,
                }
            }
            case ActionTypes.LOADED_INCLUSION_METRICS_DATA_BESPOKE:{
                return {
                    ...state,
                    inclusionMetricsBespoke: action.payload.inclusion_metrics.length ? action.payload.inclusion_metrics[0].data : [],
                    previousInclusionMetricsBespoke: action.payload.previous_inclusion_metrics.length ? action.payload.previous_inclusion_metrics[0].data : [],
                    overall: {
                        industry: action.payload.inclusion_metrics.length ? action.payload.inclusion_metrics[0].industry_overall_score : [],
                        company: action.payload.inclusion_metrics.length ? action.payload.inclusion_metrics[0].overall_score : [],
                        date: action.payload.inclusion_metrics.length ? action.payload.inclusion_metrics[0].date : [],
                    },
                    previousOverall: {
                        industry: action.payload.previous_inclusion_metrics.length ? action.payload.previous_inclusion_metrics[0].industry_overall_score : [],
                        company: action.payload.previous_inclusion_metrics.length ? action.payload.previous_inclusion_metrics[0].overall_score : [],
                        date: action.payload.previous_inclusion_metrics.length ? action.payload.previous_inclusion_metrics[0].date : [],
                    },
                    metadata: getKpis(action.payload.metadata)
                };
            }
            case ActionTypes.LOADING_NON_OVERVIEW_METRICS_DATA_BESPOKE:
                return {
                    ...state,
                    loadingNonOverviewMetrics: true,
                }
            case ActionTypes.LOADED_NON_OVERVIEW_METRICS_DATA_BESPOKE:
                return {
                    ...state,
                    loadingNonOverviewMetrics: false,
                    inclusionMetricsBespoke: action.payload.inclusion_metrics,
                    previousInclusionMetricsBespoke: action.payload.previous_inclusion_metrics,
                    overall: {
                        industry: action.payload.inclusion_metrics.length ? action.payload.inclusion_metrics[0].industry_overall_score : [],
                        company: action.payload.inclusion_metrics.length ? action.payload.inclusion_metrics[0].overall_score : [],
                        date: action.payload.inclusion_metrics.length ? action.payload.inclusion_metrics[0].date : [],
                    },
                    previousOverall: {
                        industry: action.payload.previous_inclusion_metrics.length ? action.payload.previous_inclusion_metrics[0].industry_overall_score : [],
                        company: action.payload.previous_inclusion_metrics.length ? action.payload.previous_inclusion_metrics[0].overall_score : [],
                        date: action.payload.previous_inclusion_metrics.length ? action.payload.previous_inclusion_metrics[0].date : [],
                    }, 
                    metadata: getKpis(action.payload.metadata)
                };
            case ActionTypes.DONE_SIGNOUT:
                return {
                    ...defaultState
                }
            default:
                return state;

        }
    } catch(error) {
        console.error(`Error in appState reducer: ${ error.message || error.code || error }`, error);
    }
};
