import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { UploadJobFileMutationPayload, JobAdvertItemResponse } from 'api/actions/lumi/lumiActions.types'
import { uploadJobFileAction } from 'api/actions/lumi/lumiActions'
import { Mutations } from 'api/actions/lumi/mutations.enum'
import { useLumi } from 'components/Lumi/hooks/useLumi'
import { FileMetadata, UploadStatus } from 'components/Lumi/context/LumiContext.types'

export const useUploadJobFile = () => {
    const { onChangeAcceptedFiles } = useLumi()

    const handleFileUploadSuccess = (file: File, uuid: string) => {
        onChangeAcceptedFiles((prevAcceptedFiles: FileMetadata[]) => {
            return prevAcceptedFiles.map((fileMetadata) =>
                fileMetadata.file === file
                    ? { ...fileMetadata, uploadStatus: UploadStatus.Completed, uuid }
                    : fileMetadata,
            )
        })
    }

    const handleFileUploadError = (file: File) => {
        onChangeAcceptedFiles((prevAcceptedFiles: FileMetadata[]) => {
            return prevAcceptedFiles.map((fileMetadata) =>
                fileMetadata.file === file ? { ...fileMetadata, uploadStatus: UploadStatus.Failed } : fileMetadata,
            )
        })
    }

    const mutation = useMutation<JobAdvertItemResponse, AxiosError<null>, UploadJobFileMutationPayload>({
        mutationFn: uploadJobFileAction,
        mutationKey: [Mutations.uploadJobFile],
        onSuccess: (response, variables) => {
            handleFileUploadSuccess(variables.fileMetadata.file, response.uuid)
        },
        onError: (error, variables) => {
            handleFileUploadError(variables.fileMetadata.file)
        },
    })

    return mutation
}
