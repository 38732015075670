const COLORS = {
    "INCLUSIVE_CULTURE": "Inclusive Culture",
    "FAIR_MANAGEMENT": "Fair Management",
    "CAREER_DEVELOPMENT": "Career Development",
    "WORKPLACE_FLEXIBILITY": "Workplace Flexibility",
    "WORKPLACE_SAFETY": "Workplace Safety",
    "RECRUITING_HIRING": "Recruiting & Hiring",
};

const defaultState = COLORS;

export default (state = defaultState, action) => {
    try {

        switch(action.type) {
            default:
                return state;

        }

    } catch(error) {
        console.error(`Error in kpimap reducer: ${ error.message || error.code || error }`, error);
    }

};
