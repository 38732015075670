import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { Mutations } from 'api/actions/lumi/mutations.enum'
import { deleteJobAdvert } from 'api/actions/lumi/lumiActions'

export const useDeleteJobAdvert = () => {
    const mutation = useMutation<void, AxiosError, string>({
        mutationFn: deleteJobAdvert,
        mutationKey: [Mutations.deleteJobAdvert],
    })

    return mutation
}
