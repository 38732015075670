import styled from 'styled-components'
import {
    Accordion as MuiAccordion,
    AccordionDetails as MuiAccordionDetails,
    AccordionSummary as MuiAccordionSummary,
    Tab as MuiTab,
} from '@material-ui/core'
import { TabList as MuiTabList, TabPanel as MuiTabPanel } from '@material-ui/lab'
import { getFontStyle } from '@diversioteam/diversio-ds'

import { FreeTextTableStyleProps } from './freeTexts.types'

export const FreeTextsWrapper = styled.section`
    padding: 18px 18px 30px;
    background-color: ${({ theme }) => theme.colors.primaryWhite};
    box-shadow: 6px 12px 30px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
`

export const Topbar = styled.div`
    padding-right: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const TabsWrapper = styled(MuiTabList)<FreeTextTableStyleProps>`
    padding-left: 20px;

    &.MuiTabs-root {
        min-height: 29px;
    }

    .MuiTabs-indicator {
        background-color: ${({ theme }) => theme.colors.primary50};
        display: ${({ size }) => {
            if (size === 'small') {
                return 'none'
            }
        }};
    }

    .MuiTabs-flexContainer {
        flex-wrap: wrap;
        gap: 12px;
    }
`

export const TabPanel = styled(MuiTabPanel)<{ isLoading?: boolean }>`
    &.MuiTabPanel-root {
        padding: 0;

        min-height: ${({ isLoading }) => {
            if (isLoading) {
                return '1200px'
            }
        }};
    }
`

export const Tab = styled(MuiTab)`
    &.MuiTab-root {
        padding: 0 4px 8px 4px;
        min-width: fit-content;
        min-height: 29px;
        color: ${({ theme }) => theme.colors.secondary500};

        ${getFontStyle('captionRegular')}

        &.Mui-selected {
            ${getFontStyle('captionBold')}
        }

        :hover:not(.Mui-selected) {
            color: ${({ theme }) => theme.colors.secondary400};
        }
    }

    .MuiTab-wrapper {
        ::before {
            content: ${({ label }) => {
                return `'${label}'`
            }};
            height: 0;
            display: block;
            overflow: hidden;
            visibility: hidden;

            ${getFontStyle('captionBold')}
        }
    }
`

export const Header = styled.header`
    display: flex;
    flex-direction: column;
`

export const TitleWrapper = styled.div<{ hasMultipleCategories: boolean }>`
    min-height: 40px;
    padding-top: ${({ hasMultipleCategories }) => {
        if (!hasMultipleCategories) {
            return '30px'
        }
    }};
    padding-left: 20px;
    padding-right: 30px;
    margin-top: ${({ hasMultipleCategories }) => {
        if (hasMultipleCategories) {
            return '20px'
        }
    }};
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const Title = styled.h2`
    margin: 0;
    color: ${({ theme }) => theme.colors.primary90};

    ${getFontStyle('cardHeaderBold')};
`

export const Nav = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`

export const Filters = styled.div<FreeTextTableStyleProps>`
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    gap: 10px;
    flex-wrap: ${({ size }) => {
        if (size === 'small') {
            return 'wrap'
        }
    }};
`

export const Accordion = styled(MuiAccordion)`
    &.MuiAccordion-root {
        border-bottom: 1px solid ${({ theme }) => theme.colors.secondary100Transparent};
        box-shadow: none;

        &.Mui-expanded {
            margin: 0;
        }

        &:before {
            display: none;
        }
    }
`

export const AccordionSummary = styled(MuiAccordionSummary)`
    ${getFontStyle('p02Semibold')};

    &.MuiAccordionSummary-root {
        display: flex;
        padding: 0px 16px;
        gap: 14px;
        min-height: 64px;

        &.Mui-expanded {
            min-height: 64px;
        }
    }

    .MuiAccordionSummary-content {
        margin: 0;

        &.Mui-expanded {
            margin: 0;
        }
    }

    .MuiAccordionSummary-expandIcon {
        width: 28px;
        height: 28px;
        order: -1;
        margin-right: 0;
        background-color: none;
        border-radius: 10px;

        &:hover {
            background-color: ${({ theme }) => theme.colors.primary20};
        }
    }

    &.Mui-expanded {
        .MuiAccordionSummary-expandIcon {
            background-color: ${({ theme }) => theme.colors.primary20};
        }
    }
`

export const AccordionDetails = styled(MuiAccordionDetails)`
    &.MuiAccordionDetails-root {
        flex-direction: column;
        padding: 0;
    }
`

export const PainPoints = styled.div`
    display: flex;
    padding: 20px 30px 24px;
    margin: 15px 45px 10px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    border-radius: 20px;
    background-color: ${({ theme }) => theme.colors.primaryGray1};
`

export const PainPointsHeader = styled.div`
    color: ${({ theme }) => theme.colors.primary90};

    ${getFontStyle('captionRegular')}

    strong {
        ${getFontStyle('captionBold')}
    }
`

export const PainPointsList = styled.ul`
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
`

export const PainPointsListItem = styled.li`
    list-style-type: none;
`

export const Responses = styled.article`
    padding-left: 60px;
`

export const Response = styled.p`
    position: relative;
    margin-bottom: 42px;
    padding-left: 15px;
    padding-right: 60px;
    color: ${({ theme }) => theme.colors.primary65};
    border-left: 3px solid ${({ theme }) => theme.colors.secondary100Transparent};

    span:not(.MuiSkeleton-root) {
        background-color: ${({ theme }) => theme.colors.tangerine20};
    }

    ${getFontStyle('p02Regular')};
`

export const LoadMoreButtonWrapper = styled.div`
    padding-top: 10px;
    padding-bottom: 40px;
    display: flex;
    justify-content: center;

    ${getFontStyle('captionBold')};
`
