import React from 'react';
import FocusArea from './FocusArea';
import { Row, Column } from "../../utils/FlexBox";
import './index.scss';
import * as m from '../../../translations/mapping';
import { mapT, getTranslation } from '../../../translations/utils';
import { isPortfolioCompany } from "../../../utils/companyChecks";

import { withTranslation } from 'react-i18next';
import MainCard from '../../utils/MainCard';

const FocusAreas = ({ data, t, companyName }) => {

    const getTitle = () => {
        if (isPortfolioCompany(companyName)) {
            return t("HOME.CARD_HEADERS.PAIN_POINTS.title_p");
        }
        return t("HOME.CARD_HEADERS.PAIN_POINTS.title");
    }

    // Hide card if all three "Top 3 Pain Points" scores are N/A
    const hasData = !(Object.entries(data).filter((entry) => (entry[1].kpi == "N/A")).length == 3)

    return (
        hasData &&
        <MainCard title={getTitle()}
            subheader={t("HOME.CARD_HEADERS.PAIN_POINTS.subheader")}
            toolTipContent={t("HOME.CARD_HEADERS.PAIN_POINTS.tooltip")}>
        <div className="focus-areas">
            <div className="focus-areas__wrapper">
            <Row>
            {
                data.map((d,i) => {
                    return(
                    <Column width="33.33%" key={i}>
                        <FocusArea key={d['name']}
                        label={mapT(m.FOCUS_AREA_NAME, d["name"], companyName)}
                        groups={d['lowest groups']}
                        sublabel={mapT(m.FOCUS_AREA_SUBLABEL, d['name'], companyName)}
                        score={d['kpi'] && Number(d['kpi']*10).toFixed()/10}/>
                    </Column>
                    )
                })
            }
            </Row>
            </div>
        </div>
        </MainCard>
    )
};

export default withTranslation()(FocusAreas);
