import React from 'react'
import { PeopleSurveyedCard, PeopleSurveyedCardSkeleton } from '@diversioteam/diversio-ds'

import { useGetPeopleSurveyedByRole } from 'hooks/useGetPeopleSurveyedByRole'
import { withErrorBoundary } from 'config/withErrorBoundary/withErrorBoundary'

const PeopleSurveyedWithoutEB = () => {
    const { data, isLoading } = useGetPeopleSurveyedByRole()

    if (isLoading) {
        return <PeopleSurveyedCardSkeleton data-testid="people-surveyed-skeleton" />
    }

    if (!data) {
        return null
    }

    return <PeopleSurveyedCard {...data} data-testid="people-surveyed" />
}

export const PeopleSurveyed = withErrorBoundary(PeopleSurveyedWithoutEB, {})
