import { CellProps } from './heatmap.types'

export const INSUFFICIENT_DATA = 'INSUFFICIENT_DATA'
export const NOT_AVAILABLE = 'N/A'

export const NO_DATA = [INSUFFICIENT_DATA, NOT_AVAILABLE]

export const isDataUnavailable = (score?: string | number): boolean => {
    if (!score && score != 0) {
        return true
    }

    return NO_DATA.includes(String(score))
}

export const isDominantCell = (selectedCell: CellProps) => {
    return selectedCell.inclusionTitle === 'Dominant' || selectedCell.inclusionSubtitle === 'Baseline'
}

export const filterUnavailableData = (str: string | undefined) => {
    if (!str || isDataUnavailable(str)) {
        return ''
    }

    return str
}
