import { useDispatch } from 'react-redux'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { deleteReportFromSaved } from 'sagas/network'
import { Actions } from 'actions'

import { Mutations } from 'api/mutations.enum'
import { Queries } from 'api/queries.enum'

export const useDeleteReportFromSaved = () => {
    const queryClient = useQueryClient()
    const dispatch = useDispatch()

    const mutation = useMutation<void, Error, number>({
        mutationKey: [Mutations.deleteReportFromSaved],
        mutationFn: (id) => {
            return deleteReportFromSaved(id)
        },
        onSuccess: () => {
            dispatch(
                Actions.showToast({
                    type: 'warning',
                    message: 'Report has been removed from saved!',
                }),
            )

            queryClient.invalidateQueries([Queries.getReports])
            queryClient.invalidateQueries([Queries.getMyReports])
        },
        onError: () => {
            Actions.showToast({
                type: 'warning',
                message: 'Something went wrong',
            })
        },
    })

    return mutation
}
