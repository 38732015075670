import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { CreateJobAdvertPayload, CreateJobAdvertResponse } from 'api/actions/lumi/lumiActions.types'
import { createJobAdvertAction } from 'api/actions/lumi/lumiActions'
import { Mutations } from 'api/actions/lumi/mutations.enum'

export const useCreateJobAdvert = (onChangeJobId: (jobId: string) => void) => {
    const mutation = useMutation<CreateJobAdvertResponse, AxiosError<null>, CreateJobAdvertPayload>({
        mutationFn: createJobAdvertAction,
        mutationKey: [Mutations.createJobAdvert],
        onSuccess: (response) => {
            onChangeJobId(response?.uuid)
        },
    })

    return mutation
}
